import { Box } from "@mui/material";
import React from "react";
import { SurveyEditorWidget } from "./SurveyEditorWidget";
import { useIsMobile } from "../../../contexts/isMobile";
import {InactivityCheck} from "../../../Components/InactivityCheck";

export function EditorPage() {
  const isMobile = useIsMobile();
  return (
    <Box
      display="flex"
      alignItems="center"
      padding={isMobile ? "1.5rem 1rem" : "1.5rem 2rem"}
      bgcolor={"background.default"}
    >
      <SurveyEditorWidget />
      <InactivityCheck />
    </Box>
  );
}
