import React, { useEffect, useState } from "react";
import { Drawer, Grid, Stack, Box, Typography, Divider } from "@mui/material";
import moment from "moment";
import { SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useGetSurgeryByIDQuery } from "../../../store/surgeriesSlice";
import { useGetProceduresOptionsQuery } from "../../../store/proceduresSlice";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import { SurgeonColummns } from "../PatientTable/DrawerComponents/DataTitlesUtils";

const Content = ({ toggleDrawer, id }) => {
  const isMobile = useIsMobile();
  const { data } = useGetSurgeryByIDQuery(id);
  const {data: procedureTypeData} = useGetProceduresOptionsQuery()

  function formatTime(timeObj) {
    if(timeObj){
      let hour = timeObj.hour;
      let minute = timeObj.minute.toString().padStart(2, '0');
      let period = hour >= 12 ? 'PM' : 'AM';
  
      // Convert hour from 24-hour to 12-hour format
      hour = hour % 12;
      hour = hour ? hour : 12; // If hour is 0, set it to 12
  
      hour = hour.toString().padStart(2, '0');
      
      return `${hour}:${minute} ${period}`;
    }

    return 'N/A'
}
  return (
    <Box sx={{ padding: "2rem" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography variant="p" sx={{ fontSize: "28px", fontWeight: "400" }}>
          Procedure Detail
        </Typography>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                  alignItems: isMobile ? "end" : "center",
                  // borderTop:"1px solid #979797",
                  // borderBottom:"1px solid #979797",
                  
                }}
              >
                <Box
                  sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginRight: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{ fontSize: "15px", marginRight: "2px" }}
                    >
                      Procedure Date :
                    </Typography>
                    <Typography>
                      {data?.data?.surgeryDate
                        ? moment(data?.data?.surgeryDate).format("MM-DD-YYYY")
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{ fontSize: "15px", marginRight: "2px" }}
                  >
                    Procedure Time :
                  </Typography>
                  <Typography>
                    {formatTime(data?.data?.surgeryTime)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
        {data?.data?.surgeon ? (
          <Grid item md={12} xs={12}>
          
            <ProviderInfo
              data={data?.data?.surgeon ? [data?.data?.surgeon] : []}
              tableType={"surgeon"}
              columns={SurgeonColummns}
            />
          
</Grid>)
          : data?.data?.surgeonName && (
            <Grid item md={6} xs={12}>
              <Typography
                variant="h2"
                sx={{ fontSize: "15px", marginRight: "2px" }}
              >
                Surgeon Name
              </Typography>

              <Typography>
                {data?.data?.surgeonName ? data?.data?.surgeonName : "N/A"}
              </Typography>
            </Grid>
          )}

          <Grid item md={6} xs={12}>
            <Typography
              variant="h2"
              sx={{ fontSize: "15px", marginRight: "2px" }}
            >
              Procedure Type
            </Typography>
            <Typography>
                {procedureTypeData ? procedureTypeData?.data?.find(item => item?._id === data?.data?.surgeryType)?.name || 'N/A' : 'N/A'}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              variant="h2"
              sx={{ fontSize: "15px", marginRight: "2px" }}
            >
              Location
            </Typography>
            <Typography>
              {data?.data?.location ? data?.data?.location : "N/A"}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography
              variant="h2"
              sx={{ fontSize: "15px", marginRight: "2px" }}
            >
              Selected Journey
            </Typography>
            <Typography>
                      {data?.data?.assignedJourneys.length > 0 
            ? data.data.assignedJourneys.map(journey => journey.title).join(', ')
            : "N/A"
          }
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography
              variant="h2"
              sx={{ fontSize: "15px", marginRight: "2px" }}
            >
              Description
            </Typography>
            <Typography>
              {data?.data?.description ? data?.data?.description : "N/A"}
            </Typography>
          </Grid>

            <Stack
              direction="row"
              justifyContent={"flex-end"}
              sx={{ marginTop: "1rem", mr: "1rem",   position: "fixed",
                  width:"650px",
              bottom: "1rem",
              right: 0, }}
            >
              <SolidButton
                label="Close"
                onClick={(e) => {
                  toggleDrawer(false);
                }}
              />
            </Stack>
          
        </Grid>
      </Box>
    </Box>
  );
};

export function SurgeryDetailDrawer({ state, toggleDrawer, id }) {
  const isMobile = useIsMobile();
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: !isMobile ? "45%" : "80%",
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Content toggleDrawer={toggleDrawer(false)} id={id} />
      </Drawer>
    </React.Fragment>
  );
}
