import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Portal,
  Select,
} from "@mui/material";
import { convertToObjectArray } from "../../Pages/Patient/utils";

const NoteForm = ({ note, setNote, noteData, data }) => {
  const [renderAgain, setRenderAgain] = useState(false)
  const noteContainer = useRef(null);

  const handleNoteType = (value) => {
    setNote(value)
  }

  useEffect(()=>{
    if(data?.noteType){
      setNote(data?.noteType)
    }
  },[data])

  // Adding this useEffect because initially noteContainer is null and not assigning DOM value properly. This useEffect referesh the DOM and initialize the space in DOM tree.
  useEffect(()=>{
    setRenderAgain(!renderAgain)
  },[])

  return (
    <>
    <FormControl fullWidth ref={noteContainer} sx={{
      textAlign: "left",
    }}>
    <InputLabel id="demo-simple-select-label">Note Type</InputLabel>
    <Portal container={() => noteContainer.current}>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={note}
      label="Note Type"
      onChange={(e) => handleNoteType(e.target.value)}
    >
      {convertToObjectArray(noteData?.data)?.map((item) => (
        <MenuItem key={item?.id} value={item.value}>{item?.title}</MenuItem>
      ))}
    </Select>
    </Portal>
  </FormControl>
    </>
  );
};

export default NoteForm;
