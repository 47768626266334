import { configureStore } from "@reduxjs/toolkit";
import { baseApiSlice } from './baseApiSlice';
import userReducer from './userSlice';
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage,
  }

  const persistedReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
    reducer: {
        user: persistedReducer,
        [baseApiSlice.reducerPath]: baseApiSlice.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(baseApiSlice.middleware),
    devTools: true
})

export const persistor = persistStore(store)

// Function to remove persisted data
export const removePersistedData = async () => {
    try {
      await persistor.purge();
      localStorage.removeItem("persist:root");
      console.log("Persisted data removed successfully.");
    } catch (error) {
      console.log("Error removing persisted data:", error);
    }
  };
