import React from "react";

export function LogoutSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#536878"
        d="M6 2h9a2 2 0 012 2v2h-2V4H6v16h9v-2h2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V4a2 2 0 012-2z"
      ></path>
      <path
        fill="#536878"
        d="M16.09 15.59L17.5 17l5-5-5-5-1.41 1.41L18.67 11H9v2h9.67l-2.58 2.59z"
      ></path>
    </svg>
  );
}
