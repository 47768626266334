import { Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchField from "../../../Components/SearchField";
import OutlinedButton from "../../../Components/OutlinedButton";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import { formsData, } from "./ProfileUtils";
import { AssignFormsModal } from "./ProfileModals/FormsModal";
import { useGetPatientsSurveyQuery } from "../../../store/patientsSurveySlice";
import { CircularLoader, PaginationBar } from "../../../Components";


 
export default function FormsTable({id, user, status, patientName}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const searchAbleFields = ["title"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
const [open,setOpen]=useState(false)
  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const { isLoading, isSuccess, isError, error, data,refetch } =
  useGetPatientsSurveyQuery(
    {userID: user.id,
      id,
    page,});


const handleModal=()=>{
    setOpen(true)
}

const fetchAgain=()=>{
  refetch()
}

function escapeRegex(string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

// Filter data based on searchTerm and "title" field only
const filteredData = data?.data.filter((item) => {
  const escapedSearchTerm = escapeRegex(searchTerm);
  const searchRegex = new RegExp(escapedSearchTerm, "i");
  return searchRegex.test(item?.formId?.form?.title);
});


if (isLoading) return <CircularLoader />;
  return (
    <div id="forms">
      <Paper
        elevation={3}
        sx={{ height: "100%", p: "2rem", borderRadius: "10px", mt: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Forms
        </Typography>
        <Grid container justifyContent={"space-between"} sx={{paddingY: "12px"}}>
            <Grid item md={4} xs={6}>
          <SearchField
            {...{
              searchTerm,
              setSearchTerm,
              options: searchAbleFields,
              selectedOptions: selectedFields,
              handleOptionSelect: handleFieldSelect,
            }}
          />
          </Grid>
          <Grid item xs={3} textAlign={"right"}>
            <OutlinedButton label={"Assign"} handleClick={handleModal}/>
          </Grid>
        </Grid>
        <ProviderInfo data={filteredData || []}  columns={formsData} tableType={"form"} count={data?.totalPages} {...{page,setPage}}/>
      </Paper>
      <AssignFormsModal {...{open,setOpen,pid:id, fetchAgain, status, patientName}}/>
    </div>
  );
}
