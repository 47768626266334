import { Chip, LinearProgress, Tooltip } from "@mui/material";
import React from "react";

export default function CareProgram({ program }) {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          position: "relative",
          borderRadius: "10px",
          overflow: "hidden",
          marginRight: "5px",
        }}
      >
        <Chip
          label={
            program.length > 0
              ? (() => {
                  const formattedText = program[0]
                    .replace(/_/g, " ")
                    .split(/\s+/)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                  const maxLength = 20; // Set the maximum length for the text
                  return formattedText.length > maxLength
                    ? `${formattedText.substring(0, maxLength)}...`
                    : formattedText;
                })()
              : "No Care Program Assigned"
          }
          size="medium"
          sx={{
            marginTop: "-0.2em",
            color: "black",
            backgroundColor:
              program.length > 0 ? "#e5feec" : "rgba(255, 31, 0, 0.08)",
            borderRadius: "8px",
            position: "relative",
          }}
        />
        <LinearProgress
          variant="determinate"
          value={program.length > 0 ? 100 / program.length : 0}
          sx={{
            position: "absolute",
            bottom: "4px",
            left: "2px",
            width: "calc(100% - 4px)",
            borderRadius: "10px",
            height: "3.5px",
            zIndex: 0,
            backgroundColor: program.length > 0 ? "#8fdaa5" : "#D3602F",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#208b40", // Desired color
            },
          }}
        />
      </div>
      <Tooltip
        title={
          program.length > 1
            ? program
                .slice(1) // Exclude the first index
                .map((word) =>
                  word
                    .replace(/_/g, " ")
                    .split(/\s+/)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                )
                .join(", ")
            : "No additional data"
        }
      >
        <Chip
          label={"+" + (program.length > 0 ? program.length - 1 : 0)}
          sx={{ borderRadius: "10px" }}
        />
      </Tooltip>
    </div>
  );
}
