import React from "react";
import { Paper } from "@mui/material";
import { ChartHeader } from "./ChartHeader";
import { BarChart } from "./BarChart";

export function ICD10Chart({ chartData, chartDataError }) {
  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
        border: "1px solid #D0EAE1",
        margin: "20px 0px",
      }}
      elevation={0}
    >
      <ChartHeader />
      <BarChart chartData={chartData} chartDataError={chartDataError} />
    </Paper>
  );
}
