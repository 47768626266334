import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query({
      // query: () => "/tags",
      query: (query = {}) => {
        const { page = 1, limit = 10,userID, ...rest } = query;
        const finalQuery = { page, limit,userID, ...rest };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/tags?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Tags", id: "LIST" },
          ...data.map((p) => ({ type: "Tag", id: p._id })),
        ];
      },
    }),
    getTagByID: builder.query({
      query: (id) => `/tags/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Tags", id: "LIST" },
          { type: "Tag", id: data._id || arg },
        ];
      },
    }),
    addNewTag: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/tags",
          method: "POST",
          body: initialPost
        };
      },
      invalidatesTags: [{ type: "Tags", id: "LIST" }],
    }),
    editTag: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/tags/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ({ data }, error, { id }) => {
        return [
          { type: "Tags", id: "LIST" },
          { type: "Tag", id: data.id || id },
        ];
      },
    }),
    deleteTag: builder.mutation({
      query: (id) => {
        return {
          url: `/tags/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Tags", id: "LIST" }],
    }),
  }),
});

export const {
  useGetTagsQuery,
  useGetTagByIDQuery,
  useAddNewTagMutation,
  useEditTagMutation,
  useDeleteTagMutation,
} = extendedApiSlice;
