import React, { useEffect, useState } from "react";
import { TextField, Box, Typography, Modal, Stack, FormControl, Select, MenuItem, Checkbox, ListItemText, InputLabel, OutlinedInput } from "@mui/material";
import { SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import CloseIcon from "@mui/icons-material/Close";
import {
  useAddNewPhysicianMutation,
  useEditPhysicianMutation,
} from "../../../store/physiciansSlice";
import { toast } from "react-toastify";
import { useGetAllJourneysQuery } from "../../../store/journeysSlice";
import { useSelector } from "react-redux";
import { useAddNewCarePathwayMutation, useEditCarePathwayMutation } from "../../../store/carepathwaysSlice";

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
}

const JourneyDropdown = ({ triggerJourney, setTriggerJourney, journeyListData }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  
    // Store the _id instead of title
    setTriggerJourney(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <FormControl fullWidth sx={{ mt: 2, marginBottom: "0.5em" }}>
      <InputLabel id="demo-multiple-checkbox-label">Select Journey *</InputLabel>
        <Select
          required
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={triggerJourney}
          onChange={handleChange}
          input={<OutlinedInput label="Select Journey *" />}
          renderValue={(selected) => 
            selected
              .map((id) => journeyListData?.data?.find((src) => src._id === id)?.title)
              .join(', ')
          }
          MenuProps={MenuProps}
        >
          {journeyListData?.data?.map((src) => (
            <MenuItem key={src._id} value={src._id}>
              <Checkbox checked={triggerJourney.indexOf(src._id) > -1} />
              <ListItemText primary={src.title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const CarePathwayModal = ({
  open,
  setOpen,
  title,
  refetchAgain,
  data,
}) => {
  const { user } = useSelector((state) => state.user);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [triggerJourney, setTriggerJourney] = useState([]);

  const { data: journeyListData, refetch: refetchJourneyList } = useGetAllJourneysQuery({id:user.id , params: 'exclude=appointment,surgery'});

  const [addNewCarePathway, { isLoading }] = useAddNewCarePathwayMutation();
  const [editCarePathway, { isLoading: isEditLoading }] =
  useEditCarePathwayMutation();
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
    setName(""); // Clear the tag input field
    setTriggerJourney([]);
    setDescription("");
  };

  const onSave = async () => {
    if (name === "" || triggerJourney.length === 0 || description === "") {
      toast.warning("Please fill all fields!");
      return;
    }

    if (data?.id) {
      const body = { name, description, journeyIds: triggerJourney };
      await editCarePathway({ id: data?.id, body })
      .unwrap()
      .then((res) => {
        toast.success("Successfully edited!");
        refetchAgain();
        handleClose();
      })
      .catch((e) => {
        toast.error(e?.data?.error || e?.message || "some error occured!");
      });
    } else {
      await addNewCarePathway({ name, description, journeyIds: triggerJourney})
      .unwrap()
      .then((res) => {
        toast.success("Successfully created!");
        refetchAgain();
        handleClose();
      })
      .catch((e) => {
        toast.error(e?.data?.error || e?.message || "some error occured!");
      });
    }
    handleClose();
  };

  useEffect(() => {
    if (data?.id) {
      setName(data?.name ? data?.name : "");
      setDescription(data?.description ? data?.description : "");
      setTriggerJourney(data?.journeyIds ? data?.journeyIds : []);
    }
  }, [data]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 600,
    bgcolor: "#F5F5F5",
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              required
              id="outlined-required"
              label="Name"
              name="name"
              placeholder="Enter Care Pathway Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              sx={{ width: "100%", mt: 2, marginBottom: "0.5em" }}
            />
            <JourneyDropdown {...{triggerJourney, setTriggerJourney, journeyListData }} />
            <TextField
              required
              id="outlined-multiline-static"
          multiline
          rows={4}
              label="Description"
              name="description"
              placeholder="Enter Description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              sx={{ width: "100%", mt: 2, marginBottom: "0.5em" }}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Box sx={{ ml: "auto" }}>
                <SolidButton
                  label="Save"
                  onClick={onSave}
                  disabled={isLoading || isEditLoading}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
