import React, { useEffect, useState } from "react";
import { TextField, Box, Typography, Modal, Stack } from "@mui/material";
import { SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import CloseIcon from "@mui/icons-material/Close";
import { useAddNewProcedureMutation, useEditProcedureMutation } from "../../../store/proceduresSlice";
import { toast } from "react-toastify";


export const ProcedureTypeModal = ({ open, setOpen, title, refetchAgain, data }) => {
  const [name, setName] = useState("");
  const isMobile = useIsMobile();
  const [addNewProcedure, {isLoading}] =  useAddNewProcedureMutation()
  const [editProcedure, {isLoading: isEditLoading}] = useEditProcedureMutation()

  const handleClose = () => {
    setOpen(false);
    setName(""); // Clear the tag input field
  };

  const onSave = async () => {
    if (name === "" ) {
      toast.warning("Please fill name field!");
      return;
    }

    if(data?.id){
      const body = { name };
      await editProcedure({ id: data?.id, body })
        .unwrap()
        .then((res) => {
          toast.success("Successfully edited!");
          refetchAgain();
          handleClose();
        })
        .catch((e) => {
          toast.error(e?.data?.error || e?.message || "some error occured!");
        });
    }else{
      await addNewProcedure({ name })
          .unwrap()
          .then((res) => {
            toast.success("Successfully created!");
            refetchAgain();
            handleClose();
          })
          .catch((e) => {
            toast.error(e?.data?.error || e?.message || "some error occured!");
          });
    }
    handleClose()
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 600,
    bgcolor: "#F5F5F5",
    p: 4,
  };

  useEffect(()=>{
    if(data?.id){
      setName(data?.name ? data?.name : '')
    }
  }, [data])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
            <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
              <TextField
                required
                id="outlined-required"
                label="Name"
                name="name"
                placeholder="Enter Procedure Type Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                sx={{ width: "100%", mt: 2, marginBottom: '0.5em' }}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Box sx={{ ml: "auto" }}>
                  <SolidButton label="Save" onClick={onSave} disabled={isLoading || isEditLoading} />
                </Box>
              </Box>
            </Box>
        </Box>
      </Modal>
    </div>
  );
};
