import React, { useState } from "react";
import {
  Drawer,
} from "@mui/material";

import { EditUser } from "./EditUser"
import { useGetUserByIDQuery } from "../../store/userManagmentSlice";
import { CircularLoader } from "../../Components";



export function EditDrawer({drawerState, toggleEditDrawer }) {
  const {data, isLoading, isError, isSuccess } = useGetUserByIDQuery(drawerState.id)
  if (isLoading) return <div><CircularLoader/></div>;
  if (isError) return <div>some error</div>;
  
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "80%",
          },
        }}
        anchor="right"
        open={drawerState.open}
        onClose={toggleEditDrawer(false, null)}
      >
        { isSuccess && <EditUser data={data.data} toggleEditDrawer={toggleEditDrawer(false, null)} />}
      </Drawer>
    </React.Fragment>
  );
}
