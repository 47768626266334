import { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Divider, Grid, Typography } from '@mui/material'
import { Chrono } from 'react-chrono'
import { dummyCallData } from '../Data/CallData'
import CircleIcon from '@mui/icons-material/Circle'
import CallIcon from '@mui/icons-material/Call'
import moment from 'moment'
import { useGetPatientQueueCallDetailQuery } from '../../store/patientQueuesSlice'
import { CircularLoader } from '../Loader'
import { useGetCallAgentsQuery, useGetCallsByPatientIDQuery } from '../../store/callSlice'
import CallAccordion from '../../Pages/Patient/PatientProfile/CallDrawers/CallAccordion'
import { AudioPlayer } from '../AudioPlayer'
import { useLocation } from 'react-router-dom'

const findFormattedKeyById = (data, id) => {
	if (data) {
		const key = Object?.keys(data)?.find((key) => data[key] === id)

		if (key) {
			const formattedKey = key?.replace(/_/g, ' ')?.replace(/\b\w/g, (char) => char?.toUpperCase())
			return formattedKey
		} else {
			return 'N/A'
		}
	} else {
		return 'N/A'
	}
}

const ContentAccordion = ({ item, agentData, defaultExpanded, handlePatientRefetch }) => {
	return (
		<Accordion sx={{ border: 'none', boxShadow: 'none' }} defaultExpanded={defaultExpanded}>
			<AccordionSummary aria-controls="panel1-content" id="panel1-header">
				<Grid container spacing={12} alignItems={'center'}>
					<Grid item xs={12} sm={5}>
						<Typography variant="subtitle2">Agent Name:</Typography>
						<Typography variant="body2">{findFormattedKeyById(agentData, item?.agentId)}</Typography>
					</Grid>

					<Grid item xs={12} sm={4}>
						<Typography variant="subtitle2">Call Picked-up:</Typography>
						<Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
							{item?.callPicked ? 'Yes' : item?.callPicked === false ? 'No' : 'N/A'}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={3}>
						<Typography variant="subtitle2">Recording:</Typography>
						<Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
							{item?.recordingUrl ? 'Yes' : 'No'}
						</Typography>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<Divider sx={{ background: '#ffffff', width: '100%', marginBottom: '20px' }} />
				<Box sx={{ display: 'flex', alignItems: 'center', mb: '20px' }}>
					<Typography variant="h6" fontSize={'16px'} sx={{ mr: 2 }}>
						Contact Category
					</Typography>
					<Chip
						label={item?.patientQueue?.taskCategory?.charAt(0).toUpperCase() + item?.patientQueue?.taskCategory?.slice(1) || 'No Contact Category'}
						size="small"
						color={item?.patientQueue?.taskCategory ? 'success' : 'warning'}
					/>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center', mb: '20px' }}>
					<Typography variant="h6" fontSize={'16px'} sx={{ mr: 2 }}>
						Caller Identification
					</Typography>
					<Chip
						label={
							item?.patientQueue?.metadata?.callerIdentification || 'N/A'
						}
						size="small"
						color={'success'}
					/>
				</Box>
				<Grid container sx={{ marginBottom: '20px' }}>
					{item?.noteId?.description ? (
						<Grid item xs={12} sm={12}>
							<CallAccordion title={'Note'} data={item?.noteId?.description} isCallTab={true} noteData={item?.noteId} fetchAgain={handlePatientRefetch} />
						</Grid>
					) : (
						<Grid container>
							<Grid item xs={12} sm={4}>
								<Typography variant="subtitle2">Call Note :</Typography>
							</Grid>

							<Grid item xs={12} sm={8}>
								<Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
									{item?.noteId?.description || 'No Call Note To Show'}
								</Typography>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid container sx={{ marginBottom: '20px' }}>
					{item?.metadata?.call_analysis?.call_summary ? (
						<Grid item xs={12} sm={12}>
							<CallAccordion title={'Summary'} data={item?.metadata?.call_analysis?.call_summary} isCallTab={true} />
						</Grid>
					) : (
						<>
							<Grid item xs={12} sm={4}>
								<Typography variant="subtitle2">Call Summary :</Typography>
							</Grid>

							<Grid item xs={12} sm={8}>
								<Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
									{item?.summary || 'No Call Summary To Show'}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				<Grid container sx={{ marginBottom: '20px' }}>
					{item?.transcriptObject ? (
						<Grid item xs={12} sm={12}>
							<CallAccordion title={'Transcript'} data={item?.transcriptObject || []} />
						</Grid>
					) : (
						<>
							<Grid item xs={12} sm={4}>
								<Typography variant="subtitle2">Transcript :</Typography>
							</Grid>

							<Grid item xs={12} sm={8}>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography variant="body2">No Transcript To Show</Typography>
								</Box>
							</Grid>
						</>
					)}
				</Grid>

				<Grid container sx={{ marginBottom: '20px' }}>
					{item?.recordingUrl ? (
						<Grid item xs={12} sm={12}>
							<Typography variant="h6" fontSize={'16px'} sx={{ mb: 2 }}>
								Call Recording
							</Typography>
							<AudioPlayer url={item?.recordingUrl} />
						</Grid>
					) : (
						<Grid container>
							<Grid item xs={12} sm={4}>
								<Typography variant="subtitle2">Call Recording :</Typography>
							</Grid>

							<Grid item xs={12} sm={8}>
								<Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
									No Call Recording
								</Typography>
							</Grid>
						</Grid>
					)}
				</Grid>
			</AccordionDetails>
		</Accordion>
	)
}

const customContent = (dummyCallData, agentData, handlePatientRefetch) => {
	console.log(dummyCallData, '123')
	return dummyCallData.map((data, index) => (
		<Box key={index}>
			<ContentAccordion item={data} agentData={agentData} defaultExpanded={index === 0} handlePatientRefetch={handlePatientRefetch} />
		</Box>
	))
}

const CustomTitle = ({ dateTime }) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography variant="subtitle" color="textSecondary" fontSize={'14px'}>
				{moment(dateTime).format('DD-MMM-YY')}
			</Typography>
			<Typography variant="body2" color="textSecondary" fontSize={'12px'}>
				{moment(dateTime).format('h:mm A')}
			</Typography>
		</Box>
	)
}

export default function CallTab({ id, isShowCalls, setIsShowCalls, queueIds, isCallType, setIsCallLoading }) {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)

	const [timelineItems, setTimelineItems] = useState([])
	const [filteredData, setFilteredData] = useState([])

	const { data, isLoading, refetch } = useGetCallsByPatientIDQuery({ id, page: 1, limit: 1000 })

	const { data: agentData, isLoading: isLoadingAgent } = useGetCallAgentsQuery()

	const handlePatientRefetch = () => {
		refetch()
	}

	useEffect(() => {
		if (filteredData && !isShowCalls) {
			const timelineDataReturn = filteredData?.map((item) => {
				return { title: <CustomTitle dateTime={item?.createdAt} /> }
			})
			setTimelineItems(timelineDataReturn)
		} else if (isShowCalls) {
			const timelineDataReturn = data?.calls?.map((item) => {
				return { title: <CustomTitle dateTime={item?.createdAt} /> }
			})
			setTimelineItems(timelineDataReturn)
		}
	}, [filteredData, isShowCalls])

	useEffect(() => {
		if (data?.calls && isCallType) {
			const dataToShow = data?.calls?.filter((item) => queueIds.includes(item?.patientQueue?._id))
			setFilteredData(dataToShow)
		} else if (data?.calls && !isCallType) {
			const timelineDataReturn = data?.calls?.map((item) => {
				return { title: <CustomTitle dateTime={item?.createdAt} /> }
			})
			setTimelineItems(timelineDataReturn)
		}
	}, [data])

	useEffect(() => {
		refetch()
		setIsShowCalls(false)
	}, [])

	useEffect(()=>{
		setIsCallLoading(isLoading)
	},[isLoading])

	if (isLoading || isLoadingAgent) return <CircularLoader />

	return (
		<Box
			sx={{
				height: '70vh',
				overflowY: 'hidden',
				overflowX: 'hidden',
			}}
			className="DialogContent"
		>
			{/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          size="small"
          startIcon={<CallIcon />}
          sx={{
            mr: "10px",
            background: "#E9E9E9",
            color: "black",
            "&:hover": {
              backgroundColor: "#bdbdbd",
              color: "white",
            },
          }}
        >
          Create Call
        </Button>
      </Box> */}
			{(!isShowCalls && filteredData && filteredData?.length === 0 && isCallType) ||
			((isShowCalls || !isCallType) && data?.calls && data?.calls?.length === 0) ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '60vh',
					}}
				>
					<Typography variant="h6">{!isShowCalls && isCallType ? 'No Queue Calls Record Found' : 'No Calls Record Found'}</Typography>
				</Box>
			) : (
				<Chrono
					key={timelineItems?.length}
					items={timelineItems}
					hideControls
					disableClickOnCircle
					mode="VERTICAL"
					disableAutoScrollOnClick={true}
					scrollable={false}
					cardHeight={100}
					theme={{ primary: '#0049C6', secondary: '#f5f5f5' }}
					cardPosition="top"
				>
					{customContent(!isShowCalls && isCallType ? filteredData : data?.calls || [], agentData || {}, handlePatientRefetch)}

					<Box className="chrono-icons">
						{timelineItems.map((item, index) => (
							<CircleIcon key={index} sx={{ color: '#0049C6', fontSize: '20px' }} />
						))}
					</Box>
				</Chrono>
			)}
		</Box>
	)
}
