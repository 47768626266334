import { baseApiSlice } from "./baseApiSlice";


export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientTask: builder.query({
      query: (query = {}) => {
        const { userID, page = 1, limit = 10, view, week } = query;
        const finalQuery = { userID, page, limit, view, week };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/dashboard/task/?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Dashboards", id: "LIST" },
          ...data.map((p) => ({ type: "Dashboards", id: p._id })),
        ];
      },
    }),
    getFormCompletionRatio: builder.query({
        query: (query = {}) => {
            const { userID, week } = query;
            const finalQuery = { userID, week };
    
            let params = new URLSearchParams();
            for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
            }
    
            return `/dashboard/form/?${params.toString()}`;
          },
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Dashboards", id: "LIST" },
          { type: "Dashboards", id: data?._id || arg },
        ];
      },
    }),
    getTotalCount: builder.query({
      query: (id)=> `/dashboard/count/${id}`,
      providesTags:({data, ...rest}, error, arg)=>{
        return [
          { type: "Dashboards", id: "LIST" },
          { type: "Dashboards", id: data?._id || arg },
        ];
      },
    }),
    getPatientCount: builder.query({
      query:(id)=> `/dashboard/patientCount/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Dashboards", id: "LIST" },
          { type: "Dashboards", id: data?._id || arg },
        ];
      },
    }),
    getProgramCount: builder.query({
      query:(id)=> `/dashboard/programCount/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Dashboards", id: "LIST" },
          { type: "Dashboards", id: data?._id || arg },
        ];
      },
    }),
    getIcd10Count: builder.query({
      query: (id) => `/dashboard/icd10CodeCount/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Dashboards", id: "LIST" },
          { type: "Dashboards", id: data?._id || arg },
        ];
      },
    })
  }),
});

export const {
  useGetPatientTaskQuery,
  useGetFormCompletionRatioQuery,
  useGetTotalCountQuery,
  useGetPatientCountQuery,
  useGetProgramCountQuery,
  useGetIcd10CountQuery,
} = extendedApiSlice;
