import { Button } from "@mui/material";
import React from "react";

export default function OutlinedButton({ label, handleClick, isLoading }) {
  return (
    <Button
      variant="outlined"
      size="medium"
      sx={{
        borderRadius: "30px",
        borderWidth: "2px",
        mr: 1,
        color: "#0049C6",
        fontWeight: 600,
        textTransform: "capitalize",
      }}
      onClick={handleClick}
      disabled={isLoading ? isLoading : false}
    >
      {label}
    </Button>
  );
}
