import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import NotesIcon from "@mui/icons-material/Notes";

const ViewQnA = ({ chatData }) => {
  return (
    <Box sx={{ padding: "2rem" }}>
      <Grid container spacing={2}>
        {chatData?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginBottom: "12px",
              width: "100%"
            }}
          >
            <Box
              sx={{
                minWidth: "60px",
                paddingRight: "5px",
              }}
            >
              <Typography variant="h2" sx={{ fontSize: "16px" }}>
                {item?.role === "assistant" ? "AI:" : "User:"}
              </Typography>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                paddingLeft: "5px", // Add indentation for all lines
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "pre-line",
                }}
              >
                            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                a: (props) => (
                  <a href={props.href} style={{ color: "#000" }} target="blank">
                    {props.children}
                  </a>
                ),
                p: (props) => (
                  <p style={{ margin: "0px" }}>
                    {props.children}
                  </p>
                ),
              }}
            >
              {item?.content?.replace(/^\`\`\`|`\`\`$/g, "")?.replace(/\n/g, "  \n")}
            </ReactMarkdown>
              </Typography>
            </Box>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default function DialogueAccordion({ title, notesData, chatData }) {
  return (
    <Accordion sx={{ background: "#e0f2fd" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ fontSize: "medium", fontWeight: 700 }}
      >
        {title === "Notes" ? (
          <NotesIcon sx={{ marginRight: "5px" }} />
        ) : (
          <FeedOutlinedIcon sx={{ marginRight: "5px" }} />
        )}{" "}
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {title === "Notes" ? (
          <Typography>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                a: (props) => (
                  <a href={props.href} style={{ color: "#000" }} target="blank">
                    {props.children}
                  </a>
                ),
              }}
            >
              {notesData?.replace(/^\`\`\`|`\`\`$/g, "")?.replace(/\n/g, "  \n")}
            </ReactMarkdown>
          </Typography>
        ) : (
          <ViewQnA chatData={chatData} />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
