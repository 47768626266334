import React, { useEffect } from 'react'
import { Modal, Box, Typography, IconButton, Divider, Select, MenuItem, FormControl, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { CircularLoader, SolidButton } from '../../Components'
import { useGetFindHelpQuery } from '../../store/findHelpSlices'

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90vw',
	height: '85vh',
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: '12px',
}

const dropdownContainerStyle = {
	mb: 2,
	display: 'flex',
	alignItems: 'center',
	gap: 2,
}

const dropdownStyle = {
	minWidth: 250,
	width: 250,
}

const dropdownSelectStyle = {
	height: 36,
	'& .MuiSelect-select': {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
	},
}

const dropdownLabelStyle = {
	fontWeight: 500,
	fontSize: '1rem',
	marginRight: 2,
	width: 100, // Fixed width for labels
	display: 'flex',
	alignItems: 'center', // Align items vertically
}

const additionalTextStyle = {
	mt: 1,
	fontSize: '0.875rem',
	color: 'text.secondary',
}

const textareaContainerStyle = {
	mt: 2,
}

const textareaStyle = {
	width: '100%',
	marginTop: 1,
	padding: 1,
	borderRadius: 1,
	border: '1px solid',
}

const buttonContainerStyle = {
	display: 'flex',
	justifyContent: 'flex-end',
	gap: 1,
	marginTop: 3,
}

const dropdownOptions = [
	{ value: 'medium', label: 'Medium' },
	{ value: 'low', label: 'Low' },
	{ value: 'high', label: 'High' },
]

const Dropdown = ({ label }) => (
	<Box sx={dropdownContainerStyle}>
		<Typography sx={dropdownLabelStyle}>{label}:</Typography>
		<FormControl sx={dropdownStyle}>
			<Select
				displayEmpty
				defaultValue=""
				renderValue={(selected) => {
					if (selected === '') {
						return <em style={{ fontStyle: 'normal' }}>Priority Level</em>
					}
					return selected
				}}
				sx={dropdownSelectStyle}
			>
				{dropdownOptions.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	</Box>
)

const FindHelpModal = ({ open, handleClose, patientId }) => {
	const { data, isLoading, isError, error, refetch } = useGetFindHelpQuery(patientId)

	useEffect(() => {
		refetch()
		console.log(isLoading)
	}, [])

	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={modalStyle}>
				{isLoading ? (
					<CircularLoader />
				) : isError ? (
					<p>{error?.data?.message}</p>
				) : (
					<iframe src={data?.data?.tokenUrl} width="100%" height="100%" style={{ border: 'none' }} title="FindHelp" />
				)}
			</Box>
		</Modal>
	)
}

export default FindHelpModal
