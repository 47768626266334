import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
} from "@mui/material";
import { SolidButton } from "../../../Components";
import CloseIcon from "@mui/icons-material/Close";
import { useEditJourneyNodeMutation } from "../../../store/journeysSlice";
// import { useIsMobile } from "../contexts/isMobile";
import { editNode } from "../FlowV2/utils";
import { toast } from "react-toastify";
import TimeField from "../../../Components/TimeField";
import TimeFieldPicker from "../../../Components/TimeField";
import { useReactFlow } from "reactflow";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius: "10px",
};

const getNextNodeId = (id, allEdges) => {
  const filteredEdges = allEdges?.find(item => item.source === id);
 return filteredEdges?.target
}

export function DelaySelect({ metric, setMetric, defaultMetric = "" }) {
  const handleChange = (event) => {
    setMetric(event.target.value);
  };

  return (
    <Box sx={{ width: "80%" }}>
      <FormControl fullWidth sx={{ marginBottom: "15px" }}>
        {/* <InputLabel id="demo-simple-delay-label">
          {defaultMetric !== ""
            ? "Wait until user completes the survey"
            : "Select Time Delay"}
        </InputLabel> */}
        <Select
          labelId="demo-simple-delay-label"
          id="demo-simple-delay"
          value={metric !== "" ? metric : defaultMetric}
          onChange={handleChange}
        >
          {[
            { id: "minutes", label: "Minutes" },
            { id: "hours", label: "Hours" },
            { id: "days", label: "Days" },
            { id: "weeks", label: "Weeks" },
            { id: "months", label: "Months" },
          ].map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function DelayForSelect({
  delayFor,
  setDelayFor,
  defaultDelayFor = "",
}) {
  const handleChange = (event) => {
    setDelayFor(event.target.value);
  };

  return (
    <Box sx={{ width: "80%" }}>
      <FormControl fullWidth sx={{ marginBottom: "15px" }}>
        {/* <InputLabel id="demo-simple-delay-label">
          {defaultDelayFor !== ""
            ? "Wait until user completes the survey"
            : "Select Delay For"}
        </InputLabel> */}
        <Select
          labelId="demo-simple-delay-label"
          id="demo-simple-delay"
          value={delayFor !== "" ? delayFor : defaultDelayFor}
          // label="Select Delay For"
          onChange={handleChange}
        >
          {[
            { id: "appointment", label: "Appointment" },
            { id: "surgery", label: "Procedure" },
          ].map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function TimeRelationSelect({
  timeRelation,
  setTimeRelation,
  defaultTimeRelation = "",
}) {
  const handleChange = (event) => {
    setTimeRelation(event.target.value);
  };

  return (
    <Box sx={{ width: "80%" }}>
      <FormControl fullWidth sx={{ marginBottom: "15px" }}>
        {/* <InputLabel id="demo-simple-delay-label">
          {defaultTimeRelation !== ""
            ? "Wait until user completes the survey"
            : "Select Time Relations"}
        </InputLabel> */}
        <Select
          labelId="demo-simple-delay-label"
          id="demo-simple-delay"
          value={timeRelation !== "" ? timeRelation : defaultTimeRelation}
          // label="Select Time Relations"
          onChange={handleChange}
        >
          {[
            { id: "before", label: "Before" },
            { id: "after", label: "After" },
            { id: "on", label: "On" },
          ].map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export const EditDelayModal = ({
  open,
  setOpen,
  data,
  id,
  nodeInternals,
  jid,
}) => {
  const {getEdges, getNode} = useReactFlow()
  const [delay, setDelay] = useState(data?.value?.toString() || "1");
  const [metric, setMetric] = useState(data?.metric || "");
  const [delayFor, setDelayFor] = useState(
    data?.delayFor !== "N/A" ? data?.delayFor : null
  );
  const [timeRelation, setTimeRelation] = useState(data?.timeRelation || null);
  const [checkCondition, setCheckCondition] = useState(
    data?.delayFor !== "N/A" ? true : false
  );
  const [checkTimeCondition, setCheckTimeCondition] = useState(false);
  const [date, setDate] = useState("");
  const [hourToRunAt, setHourToRunAt] = useState({});
  const [editJourneyNode] = useEditJourneyNodeMutation();
  const nextNodeId = getNextNodeId(id, getEdges());
  const nextNode = getNode(nextNodeId)
  
  const handleClose = () => {
    setOpen(false);
  };

  // const journeyData = {
  //   method: "delay",
  //   patientId: "6479bb531e31431a60ca5875",
  //   delay: {
  //     method: data?.method,
  //     childNodeId: id,
  //     delay: parseInt(delay),
  //     metric,
  //     value: delay,
  //     delayFor: delayFor && checkCondition ? delayFor : "N/A",
  //     timeRelation: timeRelation && checkCondition ? timeRelation : "before",
  //     // runAtSpecificHour: checkTimeCondition,
  //     hourToRunAt :  timeRelation === 'on' && hourToRunAt.hour && checkCondition ? hourToRunAt : {},
  //   },
  // };

  const journeyData = {
    method: "delay",
    patientId: "6479bb531e31431a60ca5875",
    delay: {
      method: data?.method,
      childNodeId: id,
      delay: parseInt(delay),
      metric,
      value: delay,
      delayFor: delayFor ? delayFor : "N/A",
      timeRelation: timeRelation ? timeRelation : "before",
      runAtSpecificHour: checkTimeCondition,
      hourToRunAt,
    },
  };

  const handleCheckConditionUpdate = (e) => {
    setCheckCondition((checkCondition) => !checkCondition);
  };

  const handleCheckTime = (e) => {
    setCheckTimeCondition((checkTimeCondition) => !checkTimeCondition);
  };

  const handleOnSave = async () => {
    const delayValue = parseInt(delay);

    // Check if delay is greater than 0
    if (delayValue <= 0) {
      toast.error("Delay value must be greater than 0.");
      return;
    }

    // Check if checkCondition is true i.e. event and event is not being selected
    if (checkCondition && delayFor === "N/A") {
      toast.error("Please select an event before saving.");
      return;
    }

    if (checkTimeCondition && Object?.keys(hourToRunAt).length === 0) {
      toast.error("Please fill the Specific Hours field.");
      return;
    }

    if (nextNode?.data?.isFilled) {
      const journeyIdString =
        jid || JSON.parse(localStorage.getItem("journeyId"));

      const edit = await editJourneyNode({
        journeyID: journeyIdString,
        nodeID: id,
        body: { ...journeyData },
      });

      if (edit?.error?.status) {
        toast.error("Something went wrong.Please try again!");
        return;
      }

      if (edit) {
        // If the edit has been saved in the backend, then editNode in UI state to show live updates
        editNode(
          id,
          delay,
          "delay",
          metric,
          nodeInternals,
          delayFor,
          timeRelation,
          checkTimeCondition,
          hourToRunAt
        );
        setOpen(false);
        setDate("");
        toast.success("Successfully edited!");
        //   if (journeyData?.form?.checkCondition !== data.checkCondition) {
        //     window.location="/journeysflow/"+journeyID;
        //   }
      } else {
        // Handle the case when the edit was not successful
        toast.error("Edit failed!");
      }
    } else {
      // If the edit has been saved in the backend, then editNode in UI state to show live updates
      editNode(
        id,
        delay,
        "delay",
        metric,
        nodeInternals,
        delayFor,
        timeRelation,
        checkTimeCondition,
        hourToRunAt
      );
      setOpen(false);
      setDate("");
      toast.success("Successfully edited!");
    }
  };

  //     if (edit) {
  //       // If the edit has been saved in the backend, then editNode in UI state to show live updates
  //       editNode(id, name, method, metric, nodeInternals);
  //       toggleDrawer(false);
  //       toast.success("Successfully edited!");
  //       if (journeyData?.form?.checkCondition !== data.checkCondition) {
  //         window.location="/journeysflow/"+journeyID;
  //       }
  //     } else {
  //       // Handle the case when the edit was not successful
  //       toast.error("Edit failed!");
  //     }
  //     setOpenM(false);
  //   };

  // This useEffect is to change hourToRunAt object i.e. {hour:03, minute:00} into dateTime format for our time picker
  useEffect(() => {
    if (data) {
      const { hour, minute } = data?.hourToRunAt;

      // Create a new date object
      const newDate = new Date();

      // Set the hours and minutes
      newDate.setHours(parseInt(hour, 10), parseInt(minute, 10), 0, 0);

      // Convert to string format like 'Thu Apr 04 2024 08:00:00 GMT+0500 (Pakistan Standard Time)'
      const formattedDate = newDate?.toString();

      setDate(new Date(formattedDate));
      setCheckTimeCondition(data?.runAtSpecificHour)
      setHourToRunAt(data?.hourToRunAt)
    }
  }, [data, open]);

  // This useEffect is reseting the hourToRunAt default value when checkTimeCondition is false
  useEffect(() => {
    if (!checkTimeCondition) {
      setHourToRunAt({});
    }
  }, [checkTimeCondition]);

  useEffect(() => {
    if (!checkCondition) {
      setDelayFor("N/A");
      setTimeRelation("before");
      setDate('');
      setCheckTimeCondition(false)
    }
  }, [checkCondition]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Edit Delivery Schedule
          </Typography>
          <Box
            sx={{ transform: "translate(420px, -30px)", cursor: "pointer" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="delay-option"
                      name="delay-option"
                      value={checkCondition ? "event" : "delay"}
                      onChange={(e) =>
                        setCheckCondition(e.target.value === "event")
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }} // Apply CSS styling directly
                    >
                      <FormControlLabel
                        value="delay"
                        control={<Radio />}
                        label="Delay from Prev Node"
                        sx={{ marginRight: "0px !important" }}
                      />
                      <FormControlLabel
                        value="event"
                        control={<Radio />}
                        label="Delay Based on Event"
                        sx={{ marginLeft: "2.5rem", marginRight: "0px" }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {(timeRelation !== "on" || !checkCondition) && (
                  <>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <p style={{ marginRight: "5px", fontWeight: 600 }}>
                        Delay
                      </p>
                      <TextField
                        type="number"
                        id="outlined-required"
                        placeholder={`Enter ${metric}`}
                        name="title"
                        value={delay}
                        onChange={(e) => {
                          setDelay(e.target.value);
                        }}
                        sx={{
                          width: "80%",
                          "& input[type=number]": {
                            "-moz-appearance": "textfield",
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                          "& input[type=number]::-webkit-outer-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                          "& input[type=number]::-webkit-inner-spin-button": {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },
                        }}
                      />
                      <h2 style={{ marginLeft: "5px", marginTop: "5px" }}>,</h2>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <p style={{ marginRight: "5px", fontWeight: 600 }}>
                        Metric
                      </p>
                      <DelaySelect metric={metric} setMetric={setMetric} />
                    </Grid>
                  </>
                )}

                {checkCondition && (
                  <>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <p style={{ marginRight: "5px", fontWeight: 600 }}>
                        Relation
                      </p>
                      <TimeRelationSelect
                        timeRelation={timeRelation}
                        setTimeRelation={setTimeRelation}
                      />
                      <h2 style={{ marginLeft: "5px", marginTop: "5px" }}>,</h2>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <p style={{ marginRight: "5px", fontWeight: 600 }}>
                        Event
                      </p>
                      <DelayForSelect
                        delayFor={delayFor}
                        setDelayFor={setDelayFor}
                      />
                    </Grid>
                  </>
                )}

                <Grid item md={12} xs={12} textAlign="left">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkTimeCondition}
                        onChange={(e) => handleCheckTime(e)}
                      />
                    }
                    label="Specific Hours"
                  />
                </Grid>

                {checkTimeCondition && (
                  <Grid item md={12} sx={12}>
                    {" "}
                    <TimeFieldPicker
                      {...{ date, setDate, setHourToRunAt }}
                    />{" "}
                  </Grid>
                )}
              </Grid>

              <Stack
                direction="row"
                justifyContent={"flex-end"}
                sx={{ marginTop: "1rem" }}
              >
                <SolidButton label="Save" onClick={handleOnSave} />
              </Stack>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
