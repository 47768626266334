import React, { useEffect, useState } from "react";
import { BoneHealth } from "./BoneHealth";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { careProgramsData } from "../../../careProgramUtils";
import { Typography } from "@mui/material";

const CustomPrevArrow = (props) => {
  return (
    <ArrowBackIcon
      className="slick-prev"
      sx={{
        marginLeft: "0.7rem",
        color: "white",
        background: "#816179",
        padding: "5px",
        height: "32px",
        width: "32px",
        background: "#816179",
        borderRadius: "100%",
        "&:hover": {
          cursor: "pointer", // Change cursor on hover
          background: "#D0EAE1", // Change color on hover
          color: "white",
        },
      }}
      onClick={props.onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  return (
    <ArrowForwardIcon
      className="slick-next"
      sx={{
        marginRight: " 0.7rem",
        color: "white",
        padding: "5px",
        height: "32px",
        width: "32px",
        background: "#816179",
        borderRadius: "100%",
        "&:hover": {
          cursor: "pointer", // Change cursor on hover
          background: "#D0EAE1", // Change color on hover
          color: "white",
        },
      }}
      onClick={props.onClick}
    />
  );
};

export function SlideCount({ data, isError }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [programFilterData, setProgramFilterData] = useState([]);

  useEffect(() => {
    const programData = data
      ?.map((dataItem) => {
        const sameValueData = careProgramsData?.find(
          (careProgramItem) => careProgramItem?.value === dataItem?.program
        );

        if (sameValueData) {
          return {
            label: sameValueData?.label,
            value: sameValueData?.value,
            count: dataItem?.count,
          };
        }

        return null;
      })
      .filter((item) => item !== null);

    setProgramFilterData(programData);
  }, [data]);

  if (isError) return <p>Some Error</p>;

  return (
    <div>
      <Typography variant="h5" paddingTop={2} paddingLeft={2}>
      Care Program Patient Distribution
      </Typography>

{programFilterData?.length === 0 ? 
  <Typography>
      No Care Program found
    </Typography>
    :
      <Slider {...settings}>
        {programFilterData?.map((items, index) => {
          return (
            <div key={index}>
              <BoneHealth
                data={data}
                value={items?.value}
                label={items?.label}
                count={items?.count}
              />
            </div>
          );
        })}
      </Slider>
}
    </div>
  );
}
