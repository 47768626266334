import React, { useEffect, useState, useRef } from "react";
import { IconButton, Badge, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PatientProfileDrawer from "./PatientProfileDrawer";
import ProsDrawer from "../../ProsUI/ProsDrawer"
import RightDrawer from "../../SocialNeedUI/RightDrawer"
import { MessageSvg } from "../../../assets/svgs";
import { MessageDrawer } from "../../../Components/MessageLayout/MessageDrawer";
import {
  useGetChatQuery,
  useMessageSeenMutation,
} from "../../../store/chatSlice";
import { useLocation } from "react-router-dom";
import { useIsAuthenticatedUser } from "../../../contexts/isAuthenticatedUser";
import { useChatValues } from "../../../contexts/chatValue";
import ReceiptIcon from '@mui/icons-material/Receipt';

export function ActionCell({
  id,
  name,
  path,
  patient,
  consent,
  refetchPatientAgain,
  pageName,
  toggleProsDrawer,
  openProsDrawer,
  prosData
}) {
  const location = useLocation();
  const title = location.pathname.split("/")[1];
  const [openDrawer, setOpenDrawer] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [page, setPage] = useState(1);
  const { data, refetch } = useGetChatQuery({ id, sid: "2", page });
  const [messageSeen] = useMessageSeenMutation();
  const [open, setOpen] = useState(false);
  const prevDataRef = useRef();

  const { user } = useIsAuthenticatedUser();
  const {setChatMessages} = useChatValues()

  const handleClose = (event) => {
    setOpen(false);
    setOpenDrawer(false);
    setPage(1);
  };

  const toggleDrawer = (open) => (event) => {
    handleClose();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
    setPage(1);
  };

  const toggleMsgDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    setChatMessages([])
    if (open) {
      // Call the messageSeen mutation when the MessageSvg is clicked
      messageSeen(id).then(() => {
        refetch();
      });
    }
  };

  useEffect(() => {
    if (data) {
      const interval = setInterval(() => {
        refetch();

        if (data?.data !== prevDataRef?.current?.length) {
          prevDataRef.current = data?.data;
          // checking those chats which status is true and sentBy panal
          const trueStatus = data?.data?.filter(
            (obj) => obj.status === true && obj?.sentBy === "user"
          );
          const count = trueStatus?.length;
          setMessageCount(count);
        }
      }, 5000);

      prevDataRef.current = data?.data;
      // checking those chats which status is true and sentBy panal
      const trueStatus = data?.data?.filter(
        (obj) => obj.status === true && obj?.sentBy === "user"
      );
      const count = trueStatus?.length;
      setMessageCount(count);

      return () => clearInterval(interval);
    }
  }, [data]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="View Profile">
          <IconButton
            sx={{
              "&: hover .css-tksnbz-MuiSvgIcon-root ": {
                visibility: "visible",
              },
              padding: "0px",
              mr: 1,
            }}
            onClick={pageName === 'pros' ? toggleProsDrawer({value:true, data: prosData}) : (pageName === 'social' || pageName === 'socialqueue') ? toggleProsDrawer({value:true,data:patient, allNeedsMet: prosData?.allNeedsMet}) : toggleDrawer(true)}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="View Chat">
          <Badge
            badgeContent={messageCount}
            color="secondary"
            onClick={toggleMsgDrawer(true)}
            sx={{ cursor: "pointer" }}
          >
            <MessageSvg />
          </Badge>
        </Tooltip>

        {pageName === 'social' || pageName == 'socialqueue' && (
                  <Tooltip title="Billing">
                  <IconButton
                    sx={{
                      "&: hover .css-tksnbz-MuiSvgIcon-root ": {
                        visibility: "visible",
                      },
                      padding: "0px",
                      p:'2px'
                    }}
                    onClick={toggleProsDrawer(true)}
                  >
                    <ReceiptIcon />
                  </IconButton>
                </Tooltip>
        )}
      </div>
      <MessageDrawer
        toggleDrawer={toggleMsgDrawer}
        state={open}
        consent={consent}
        id={id}
        name={name}
        user={user}
        path={path}
        patient={patient}
        title={title}
        page={page}
        setPage={setPage}
        refetchPatientAgain={refetchPatientAgain}
      />
      <PatientProfileDrawer
        toggleDrawer={toggleDrawer}
        open={openDrawer}
        id={id}
      />
      {
        openProsDrawer?.value && pageName !== "social" && (
          <ProsDrawer 
            toggleDrawer={toggleProsDrawer}
            open={openProsDrawer?.value}
            id={id}
            prosData={openProsDrawer?.data}
          />
        )
      }

{openProsDrawer?.value && pageName === "social" && (
        <RightDrawer open={openProsDrawer?.value} toggleDrawer={toggleProsDrawer} isCHW={false} patient={openProsDrawer?.data} allNeedsMet={openProsDrawer?.allNeedsMet} />
)}
{/* {openProsDrawer && pageName === "socialqueue" && (
        <RightDrawer open={openProsDrawer} toggleDrawer={toggleProsDrawer} isCHW={true} />
)} */}
    </>
  );
}
