import React, { useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  Stack,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { SeachField } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { AddTeamDrawer, TeamsTable } from "./index";
import { CreateTeamModal } from "./CreateTeamModal";
import { useGetTeamsQuery } from "../../../store/teamsSlice";
import { useDebouncedValue } from "@mantine/hooks";
import {useSelector} from "react-redux"
import { hasScope } from "../../../utils";

export function TeamsAccordian({scopes}) {
  const isMobile = useIsMobile();
  const {user} = useSelector(state => state.user);
  const [expanded, setExpanded] = useState(false);
  const [changeText, setChangeText] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["name", "description"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
  const [openAccordian, setOpenAccordian] = useState(false);

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};

  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }
  const { data, isLoading, isError, isSuccess } = useGetTeamsQuery({
    userID: user.id,
    page,
    ...queries,
  });

  // States for CreateTagModal
  const [open, setOpen] = React.useState(false);
  const handleOpenCreateModal = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const direction = isMobile ? "column" : "row";

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearchFieldClick = (event) => {
    event.stopPropagation();
  };
  const toggleDrawer = (openAccordian) => (event) => {
    if (event) {
      event.stopPropagation(); // Stop event propagation
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenAccordian(openAccordian);
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper elevation={3} sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => setChangeText((prev) => !prev)}
            // direction="column" justifyContent="space-between"
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: direction,
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  margin: "8px 2px",
                  width: isMobile ? "100%" : "34%",
                }}
              >
                <Box
                  sx={{
                    borderBottom: changeText ? "5px solid #816179" : "none",
                    display: "initial",
                  }}
                >
                  Teams
                </Box>
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="flex-end"
                alignItems={{ xs: "flex-start", sm: "center" }}
                sx={{ flexGrow: 1 }}
              >
                {hasScope(scopes, 'settings/teams:create') && (
                <Box
                  sx={{
                    marginBottom: { xs: 1, sm: 0 },
                    marginRight: { sm: 2 },
                    width: isMobile ? "100%" : "150px",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      background: "#816179",
                      padding: "5px",
                      height: isMobile ? "30px" : "35px",
                      width: isMobile ? "100%" : "150px",
                      borderRadius: "0.5rem",
                      fontSize: "14px",
                    }}
                    onClick={toggleDrawer(true) }
                  >
                    Create
                  </Button>
                </Box>
                )}
                <Box
                  onClick={handleSearchFieldClick}
                  sx={{
                    width: isMobile ? "100%" : "20%",
                    marginRight: { xs: 0, sm: 2 },
                    marginBottom: isMobile && "8px",
                    ".MuiAutocomplete-inputRoot": { padding: "1px" },
                  }}
                >
                  <SeachField
                    {...{
                      searchTerm,
                      setSearchTerm,
                      options: searchAbleFields,
                      selectedOptions: selectedFields,
                      handleOptionSelect: handleFieldSelect,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: isMobile ? "100%" : "15%",
                    border: "1px solid #d0d0d0",
                    borderRadius: "5px",
                  }}
                >
                  {changeText ? "Reduce" : "Expand"}{" "}
                  {changeText ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Typography>
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <TeamsTable
              {...{ data, isLoading, isError, page, setPage, searchTerm, scopes }}
            />
          </AccordionDetails>
        </Accordion>
        {/* Modal Button */}
        {/* <CreateTeamModal open={open} setOpen={setOpen} title={"Create Team"} /> */}
        {/* Modal Button */}
        <AddTeamDrawer
          toggleDrawer={toggleDrawer}
          state={openAccordian}
          setState={setOpenAccordian}
        />
      </Paper>
    </Grid>
  );
}
