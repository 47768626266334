import React, { useState } from 'react'
import {
	Button,
	Modal,
	Typography,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
	Card,
	CardContent,
	Tooltip,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
	Chip,
	Autocomplete,
	TextField,
} from '@mui/material'
import { useAddNewPatientMutation, useGetPatientsByHospitalIdQuery } from '../store/patientsSlice'
import DemographicsData from '../Pages/Patient/CreatePatient/DemographicsData'
import { useGetPatientQueueCallDetailQuery, useUpdatePatientInPatientQueueMutation } from '../store/patientQueuesSlice'
import { toast } from 'react-toastify'
import { formatPhone } from '../utils'
import { useSelector } from 'react-redux'
import { ArrowBack, PersonAdd, PhoneCallbackRounded } from '@mui/icons-material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useGetCallAgentsQuery } from '../store/callSlice'
import CallAccordion from '../Pages/Patient/PatientProfile/CallDrawers/CallAccordion'
import { AudioPlayer } from './AudioPlayer'

const findFormattedKeyById = (data, id) => {
	if (data) {
		const key = Object?.keys(data)?.find((key) => data[key] === id)

		if (key) {
			const formattedKey = key?.replace(/_/g, ' ')?.replace(/\b\w/g, (char) => char?.toUpperCase())
			return formattedKey
		} else {
			return 'N/A'
		}
	} else {
		return 'N/A'
	}
}

const ContentAccordion = ({ item, agentData, taskCategory }) => {
	return (
		<Box sx={{ marginY: '12px' }}>
			<Box sx={{ display: 'flex', alignItems: 'center', mb: '20px' }}>
				<Typography variant="h6" fontSize={'16px'} sx={{ mr: 2 }}>
					Contact Category
				</Typography>
				<Chip
					label={taskCategory?.charAt(0).toUpperCase() + taskCategory?.slice(1) || 'No Contact Category'}
					size="small"
					color={taskCategory ? 'success' : 'warning'}
				/>
			</Box>
			<Grid container sx={{ marginBottom: '20px' }}>
				{item?.noteId?.description ? (
					<Grid item xs={12} sm={12}>
						<CallAccordion title={'Note'} data={item?.noteId?.description} />
					</Grid>
				) : (
					<Grid container>
						<Grid item xs={12} sm={4}>
							<Typography variant="subtitle2">Call Note :</Typography>
						</Grid>

						<Grid item xs={12} sm={8}>
							<Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
								{item?.noteId?.description || 'No Call Note To Show'}
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid container sx={{ marginBottom: '20px' }}>
				{item?.metadata?.call_analysis?.call_summary ? (
					<Grid item xs={12} sm={12}>
						<CallAccordion title={'Summary'} data={item?.metadata?.call_analysis?.call_summary} />
					</Grid>
				) : (
					<>
						<Grid item xs={12} sm={4}>
							<Typography variant="subtitle2">Call Summary :</Typography>
						</Grid>

						<Grid item xs={12} sm={8}>
							<Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
								{item?.summary || 'No Call Summary To Show'}
							</Typography>
						</Grid>
					</>
				)}
			</Grid>
			<Grid container sx={{ marginBottom: '20px' }}>
				{item?.transcriptObject ? (
					<Grid item xs={12} sm={12}>
						<CallAccordion title={'Transcript'} data={item?.transcriptObject || []} />
					</Grid>
				) : (
					<>
						<Grid item xs={12} sm={4}>
							<Typography variant="subtitle2">Transcript :</Typography>
						</Grid>

						<Grid item xs={12} sm={8}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant="body2">No Transcript To Show</Typography>
							</Box>
						</Grid>
					</>
				)}
			</Grid>

			<Grid container sx={{ marginBottom: '20px' }}>
				{item?.recordingUrl ? (
					<Grid item xs={12} sm={12}>
						<Typography variant="h6" fontSize={'16px'} sx={{ mb: 2 }}>
							Call Recording
						</Typography>
						<AudioPlayer url={item?.recordingUrl} />
					</Grid>
				) : (
					<Grid container>
						<Grid item xs={12} sm={4}>
							<Typography variant="subtitle2">Call Recording :</Typography>
						</Grid>

						<Grid item xs={12} sm={8}>
							<Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
								No Call Recording
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Box>
	)
}
const LinkQueueModal = ({ open, onClose, queueId, refetchQueueAgain, metadata, callresults, taskCategory, callerIdentification }) => {
	const { user } = useSelector((state) => state.user)
	const { data: agentData, isLoading: isLoadingAgent } = useGetCallAgentsQuery()
	const { data: patients, error, isLoading, refetch } = useGetPatientsByHospitalIdQuery()
	const [updatePatientInPatientQueue, { isLoading: isUpdationLoading }] = useUpdatePatientInPatientQueueMutation()
	const [addNewPatient, { isLoading: isPatientLoading }] = useAddNewPatientMutation()
	const { data: callDetail, error: callDetailError, isLoading: callDetailLoading } = useGetPatientQueueCallDetailQuery(queueId)
	const [selectedPatient, setSelectedPatient] = useState('')
	const [creatingNewPatient, setCreatingNewPatient] = useState(false)
	const [showCallDetails, setshowCallDetails] = useState(false)
	const [language, setLanguage] = useState('')
	const [isValidNumber, setIsValidNumber] = useState(null)
	const [state, setState] = useState('')
	const [physician, setPhysician] = useState('')
	const [birthday, setBirthday] = useState('')
	const [mrn, setMrn] = useState('')
	const [team, setTeam] = useState('')
	const [selectedTeam, setSelectedTeam] = useState('')
	const [programTags, setProgramTags] = useState([])
	const [selectedProgramTags, setSelectedProgramTags] = useState('')
	const [selectedTags, setSelectedTags] = useState([])
	const [creatorData, setCreaterData] = useState('')
	const [membersData, setMembersData] = useState([])
	const [selectedPhysician, setSelectedPhysician] = useState(null)
	const [selectedEthnicity, setSelectedEthnicity] = useState('')
	const [selectedRace, setSelectedRace] = useState('')
	const [form, setForm] = useState({
		firstName: '',
		lastName: '',
		gender: '',
		patientSummary: '',
		email: '',
		phone: '',
		address1: '',
		address2: '',
		state: '',
		city: '',
		zip: '',
		tags: [],
		race: '',
		ethnicity: '',
	})

	const onChange = (e) => {
		setForm((form) => ({ ...form, [e.target.name]: e.target.value }))
	}

	const handlePatientChange = (event, newValue) => {
		setSelectedPatient(newValue)
	}

	const handleCreateNewPatient = () => {
		setCreatingNewPatient(true)
		setshowCallDetails(false)
	}

	const handleShowCallDetails = () => {
		setCreatingNewPatient(false)
		setshowCallDetails(true)
	}

	const handleClose = () => {
		setCreatingNewPatient(false)
		setshowCallDetails(false)
		onClose()
	}

	const handleUpdatePatientInPatientQueue = (id) => {
		updatePatientInPatientQueue({
			body: { patientId: id, patientQueueId: queueId },
		})
			.then((res) => {
				toast.success(res?.data?.message)
				setSelectedPatient('')
				refetchQueueAgain()
				handleClose()
			})
			.catch((error) => {
				toast.error('Something went wrong!')
				console.log('ERROR:', error)
			})
	}

	const handleCreate = () => {
		const requiredFields = ['firstName', 'lastName', 'phone']
		const fieldMessages = {
			firstName: 'First Name',
			lastName: 'Last Name',
			phone: 'Phone',
		}
		const missingField = requiredFields.find((field) => !form[field])

		if (missingField) {
			const friendlyFieldName = fieldMessages[missingField]
			toast.error(`${friendlyFieldName} is required!`)
			return
		}

		if (isValidNumber === true) {
			// Formatting phone number by replacing spaces and adding + sign for the number validator
			const formattedPhone = formatPhone(form?.phone)

			if (mrn?.length !== 0 && mrn.length > 20) {
				toast.error('MRN must not exceed 20 words')
				return
			}

			addNewPatient({
				...form,
				phone: formattedPhone,
				birthday,
				language,
				state,
				race: selectedRace,
				ethnicity: selectedEthnicity,
				team,
				physician,
				tags: selectedTags,
				pathway: programTags,
				program: programTags,
				members: [user.id],
				creator: user.id,
			})
				.unwrap()
				.then((data) => {
					refetch()
					handleUpdatePatientInPatientQueue(data?.data?._id)
					handleClose()
				})
				.catch((error) => {
					if (error.data === 'Cast to ObjectId failed for value "" (type string) at path "team" because of "BSONError"') {
						toast.error('Team is required')
					} else {
						toast.error(error.data?.message)
					}
				})
		} else {
			toast.error('The number is not valid!')
		}
	}

	if (isLoading) return <>{console.log('Loading....')}</>

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: creatingNewPatient ? '50vw' : 650,
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 4,
					borderRadius: 2,
					maxHeight: '90vh',
					overflowY: 'auto',
				}}
				className="DialogContent"
			>
				{!creatingNewPatient && !showCallDetails ? (
					<>
						<Typography variant="h6" component="h2" gutterBottom>
							Link the Queue with the Patient
						</Typography>
						<Button variant="contained" onClick={handleShowCallDetails} endIcon={<PhoneCallbackRounded />}>
							Show Call Details
						</Button>
						<FormControl fullWidth margin="normal">
							<Autocomplete
								options={patients && patients?.data}
								value={selectedPatient}
								onChange={handlePatientChange}
								getOptionLabel={(option) => {
									const fullName = `${option.firstName || ''} ${option.lastName || ''}`.trim()
									return fullName
								}}
								isOptionEqualToValue={(option, value) => option._id === value._id}
								sx={{ width: '100%' }}
								renderOption={(props, option) => {
									const { key, ...optionProps } = props
									const fullName = `${option.firstName || ''} ${option.lastName || ''}`.trim()
									return (
										<Box key={option._id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
											{fullName}
										</Box>
									)
								}}
								renderInput={(params) => <TextField {...params} label="Select Patient" />}
							/>
						</FormControl>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
							<Button variant="outlined" color="primary" startIcon={<PersonAdd />} onClick={handleCreateNewPatient} disabled={isUpdationLoading}>
								Create New Patient
							</Button>
							<Button variant="contained" color="primary" onClick={() => handleUpdatePatientInPatientQueue(selectedPatient?._id)} disabled={isUpdationLoading}>
								Save
							</Button>
						</Box>
					</>
				) : !creatingNewPatient && showCallDetails ? (
					<>
						<Box sx={{ display: 'flex', alignItems: 'center', marginY: 2 }}>
							<IconButton onClick={() => setshowCallDetails(false)}>
								<ArrowBack />
							</IconButton>
							<Typography variant="h6" component="h2" sx={{ ml: 1, lineHeight: 1 }}>
								Call Information
							</Typography>
						</Box>
						<Card sx={{ bgcolor: 'grey.200', maxWidth: 650, margin: 'auto', mt: 2 }}>
							<CardContent>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography variant="h6" fontSize={'18px'}>
										Information Provided on Call
									</Typography>
									<Tooltip title="This Information is collected from user during call" arrow>
										<InfoOutlinedIcon
											sx={{
												color: 'gray',
												ml: 1,
												fontSize: '18px',
												cursor: 'pointer',
											}}
										/>
									</Tooltip>
								</Box>
								<Grid container spacing={2} sx={{ mt: 2 }}>
									<Grid item xs={6}>
										<Typography variant="subtitle1" fontWeight="bold">
											Name
										</Typography>
										<Typography variant="body2">{metadata?.name || 'N/A'}</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="subtitle1" fontWeight="bold">
											Date of Birth
										</Typography>
										<Typography variant="body2">{metadata?.dob || 'N/A'}</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="subtitle1" fontWeight="bold">
											Phone Number
										</Typography>
										<Typography variant="body2">{metadata?.phone_number || 'N/A'}</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="subtitle1" fontWeight="bold">
											Caller Identification
										</Typography>
										<Typography variant="body2">{callerIdentification || 'N/A'}</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<Box>
							<ContentAccordion item={callDetail?.data} agentData={agentData} taskCategory={taskCategory} />
						</Box>
						<Button variant="outlined" color="primary" startIcon={<PersonAdd />} onClick={handleCreateNewPatient} disabled={isUpdationLoading}>
							Create New Patient
						</Button>
					</>
				) : (
					<Box>
						<Box sx={{ display: 'flex', alignItems: 'center', marginY: 2 }}>
							<IconButton onClick={() => setCreatingNewPatient(false)}>
								<ArrowBack />
							</IconButton>

							<Typography variant="h6" component="h2" sx={{ ml: 1, lineHeight: 1 }}>
								Create New Patient
							</Typography>
						</Box>
						<DemographicsData
							{...{
								selectedPhysician,
								selectedEthnicity,
								setSelectedEthnicity,
								selectedRace,
								setSelectedRace,
								setSelectedPhysician,
								physician,
								setPhysician,
								form,
								onChange,
								setForm,
								selectedProgramTags,
								setSelectedProgramTags,
								isValidNumber,
								setIsValidNumber,
								birthday,
								setBirthday,
								language,
								setLanguage,
								state,
								setState,
								team,
								setTeam,
								selectedTeam,
								setSelectedTeam,
								programTags,
								setProgramTags,
								selectedTags,
								setSelectedTags,
								mrn,
								setMrn,
							}}
						/>
						{/* Add your new patient creation form here */}
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
							<Button variant="contained" onClick={handleCreate}>
								Save
							</Button>
						</Box>
					</Box>
				)}
			</Box>
		</Modal>
	)
}

export default LinkQueueModal
