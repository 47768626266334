import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import EmailIcon from "@mui/icons-material/Email";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddTaskIcon from "@mui/icons-material/AddTask";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CallIcon from '@mui/icons-material/Call';
import NoteIcon from '@mui/icons-material/Note';
import MessageIcon from '@mui/icons-material/Message';
// import moment from "moment";
import moment from "moment-timezone"

export const TimelinePanel = ({data}) => {
  const dateToETC = (date) => {
    const utcDate = moment.utc(date);
    const estDate = utcDate.tz('America/New_York').format('MMMM Do, YYYY h:mm A');
  
    return estDate;
  };

  const iconsObject = {
    "sms": <MessageIcon />,
    "form": <DescriptionIcon />,
    "assign_journey": <AccountTreeIcon />,
    "task": <AddTaskIcon />,
    "deactivate_patient": <PersonRemoveIcon />,
    "delay": <HourglassBottomIcon />,
    "call": <CallIcon />,
    "note": <NoteIcon />,
    "email": <EmailIcon />
  };

  return (
    <Card
      className="DialogContent"
      sx={{
        border: "solid 1px black",
        height: "-webkit-fill-available",
        overflow: "auto",
      }}
    >
      <CardContent>
        {data?.length !== 0 ? (
        <Timeline position="alternate">
          {data?.map((item, index) => (
            <>
              {index % 2 === 0 ? (
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    {dateToETC(item?.createdAt)}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary">
                    {iconsObject[item?.nodeMethod]}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                      {item?.action}
                    </Typography>
                    <Typography>{item?.description}</Typography>
                  </TimelineContent>
                </TimelineItem>
              ) : (
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    variant="body2"
                    color="text.secondary"
                  >
                    {dateToETC(item?.createdAt)}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary">
                    {iconsObject[item?.nodeMethod]}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                      {item?.action}
                    </Typography>
                    <Typography>{item?.description}</Typography>
                  </TimelineContent>
                </TimelineItem>
              )}
            </>
          ))}
        </Timeline>
        ):(
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'80vh'}}>
            <Typography sx={{fontSize:'larger', fontWeight:'600'}}>No data is available at the moment</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default TimelinePanel;
