import React, { useState, useEffect } from 'react'
import { FormControlLabel, FormGroup, Switch, Grid, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import TimeFieldPicker from '../../../Components/TimeField'

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22,
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}))

function BusinessHoursUI({ activeHours, setActiveHours,businessHours, setBusinessHours }) {
	const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

	const handleToggleChange = (day) => (event) => {
		const newBusinessHours = {
			...businessHours,
			[day]: { ...businessHours[day], isOpen: event.target.checked },
		}

		setBusinessHours(newBusinessHours)

		// Update existing entry or add new entry to active hours array
		const activeHoursArray = Object?.keys(newBusinessHours)?.map((key, index) => {
			const { isOpen, from, to } = newBusinessHours[key]
			const existingEntryIndex = activeHours?.findIndex((item) => item?.day === index)

			if (existingEntryIndex !== -1) {
				// Update existing entry
				return {
					...activeHours[existingEntryIndex],
					enabled: isOpen,
					startHour: isOpen && from ? { hour: from?.getHours(), minute: from?.getMinutes() } : null,
					endHour: isOpen && to ? { hour: to?.getHours(), minute: to?.getMinutes() } : null,
				}
			} else {
				// Add new entry
				return {
					day: index,
					enabled: isOpen,
					startHour: isOpen && from ? { hour: from?.getHours(), minute: from?.getMinutes() } : null,
					endHour: isOpen && to ? { hour: to?.getHours(), minute: to?.getMinutes() } : null,
				}
			}
		})

		setActiveHours(activeHoursArray)
	}

	const handleTimeFieldChange = (day, field) => (time) => {
		setBusinessHours({
			...businessHours,
			[day]: { ...businessHours[day], [field]: time },
		})

		// Update activeHours array
		const updatedActiveHours = activeHours.map((hour) => {
			if (hour.day === DAYS.indexOf(day)) {
				return {
					...hour,
					startHour: field === 'from' ? { hour: time.getHours(), minute: time.getMinutes() } : hour.startHour,
					endHour: field === 'to' ? { hour: time.getHours(), minute: time.getMinutes() } : hour.endHour,
				}
			}
			return hour
		})

		setActiveHours(updatedActiveHours)
	}

	return (
		<Grid container spacing={2} sx-={{ width: '100%' }}>
			<Grid item xs={12}>
				<Typography variant="h6" gutterBottom sx={{marginTop: "14px"}}>
					Business Hours <span style={{fontSize:'small', color:'grey'}}>(Eastern Standard Time (EST) UTC -5)</span>
				</Typography>
			</Grid>
			{Object.entries(businessHours).map(([day, { isOpen, from, to }]) => (
				<Grid key={day} item md={12} xs={12}>
					<FormGroup row sx={{ justifyContent: 'space-between' }}>
						<FormControlLabel
							control={<IOSSwitch sx={{ m: 1 }} checked={isOpen} onChange={handleToggleChange(day)} />}
							label={day.charAt(0).toUpperCase() + day.slice(1)}
						/>
						{isOpen && (
							<div style={{display:'flex', width:'79%', justifyContent:'space-between'}}>
								<Box sx={{ width: '48%'}}>
									<TimeFieldPicker label="From" date={from} onChange={handleTimeFieldChange(day, 'from')} />
								</Box>
								<Box sx={{ width:'48%' }}>
									<TimeFieldPicker label="To" date={to} onChange={handleTimeFieldChange(day, 'to')} />
								</Box>
							</div>
						)}
						{!isOpen && <Typography sx={{ mt: '7px' }}>Closed</Typography>}
					</FormGroup>
				</Grid>
			))}
		</Grid>
	)
}
export default BusinessHoursUI
