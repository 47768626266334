import React, { useState } from "react";
import { Stack, Box,Grid } from "@mui/material";
import { SolidButton, SeachField} from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useNavigate } from "react-router-dom";
import { CreateJourneyModal } from "./CreateJourneyModal";

export function TableHeader({
  searchTerm,
  setSearchTerm,
  options,
  selectedOptions,
  handleOptionSelect,
}) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const width = isMobile ? "100%" : "200px";
  const direction = isMobile ? "column" : "row";
  const stackStyle = {
    paddingTop: isMobile ? "1rem" : "3rem",
    paddingBottom: isMobile ? "1rem" : "3rem",
    paddingRight: isMobile ? "0.5rem" : "1rem",
    paddingLeft: isMobile && "0.5rem",
  };
const [open,setOpen]=useState(false)
  const handleModal = ()=>{
    setOpen(true);
  }

  return (
  <>
   <Box sx={{ padding: isMobile ? "6px" : 3 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={3} textAlign="left">

      <Box
        sx={[
          { height: 40, borderColor: "#979797" },
          {
            "&": { width },
          },
        ]}
      >
        <SeachField
          {...{
            searchTerm,
            setSearchTerm,
            options,
            selectedOptions,
            handleOptionSelect,
          }}
        />
      </Box>
      </Grid>
              <Grid item xs={12} sm={9} textAlign="right" mt={isMobile && 2}>
      <SolidButton label="Create" onClick={handleModal}/>
        </Grid>
    </Grid>
    </Grid>
    </Grid>
    </Box>
    <CreateJourneyModal {...{open,setOpen}} />
    </>
  );
}
