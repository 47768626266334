import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import ChatBotDropdownButton from './ChatBotModeDropdown'
import ConversationTab from './ConversationTab'
import InterventionTab from './InterventionTab'
import { useState } from 'react'
import { useIsMobile } from '../../contexts/isMobile'
import GalleryTab from './GalleryTab'
import CareAllyTab from './CareAllyTab'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import { useLocation } from 'react-router-dom'
import AiAssistantDropdownButton from './AiAssistantDropdownButton'
import AiConversationTab from './AiConversationTab'
import { useGetPatientImagesQuery } from '../../store/patientsSlice'
import { Badge } from '@mui/material'

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 1 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

export default function ProfileTabs({
	refetchAgain,
	chatData,
	isChatLoading,
	isDrawer,
	originalMessage,
	chatbotReply,
	patient,
	reasoning,
	createdAt,
	userFeedback,
	queueId,
	queueStatus,
	setPage,
	page,
	title,
	patientDrawer,
	consent,
	acuity,
	taskCategory,
	value,
	setValue,
	careAllyData,
	refetchCareAllyAgain,
	tabValue,
	status,
	refetchPatientAgain,
	refetchChat,
}) {
	const isMobile = useIsMobile()
	const location = useLocation()
	const pathname = location?.pathname.split('/')[1]
	const [isHumanInterventionAvailable, setIsHumanInterventionAvailable] = useState(false)
	const tabItems = []
	const humanInterventionValue = isHumanInterventionAvailable && (careAllyData ? 3 : 2)
	const [selectedValue, setSelectedValue] = useState(null)
	const [selectedCareAllyValue, setSelectedCareAllyValue] = useState(null)
	const [selectedAiValue, setSelectedAiValue] = useState(null)
	const [agentId, setAgentId] = useState(null)
	const { data } = useGetPatientImagesQuery(patient?._id)
	const handleChange = (event, newValue) => {
		setValue(newValue)
	}
	const AiAssistantValue = (() => {
		if (!careAllyData && (title === 'patients' || title === 'profile')) {
			return 2
		} else if (careAllyData && (title === 'patients' || title === 'profile')) {
			return 3
		} else if ((pathname === 'queue' || pathname === 'dashboard') && !careAllyData) {
			return 3
		} else if (pathname === 'queueprofile' && !careAllyData) {
			return 3
		} else {
			return 4
		}
	})()

	tabItems.push(
		<Tab
			key="conversation"
			label={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						fontSize: '14px',
						fontWeight: 600,
						textTransform: 'capitalize',
						color: value === 0 ? '#000' : 'rgba(0,0,0,0.60)',
					}}
				>
					<ChatBubbleOutlineIcon style={{ marginRight: '5px' }} />
					Conversation
				</div>
			}
			{...a11yProps(0)}
		/>
	)
	tabItems.push(
		<Tab
			key="gallery"
			label={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						textTransform: 'capitalize',
						fontSize: '14px',
						fontWeight: 600,
						color: value === 1 ? '#000' : 'rgba(0,0,0,0.60)',
					}}
				>
					<Badge
						color="error"
						variant="dot"
						invisible={!data?.patientImages || data?.patientImages?.length === 0} // Hide the dot if no data
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<CollectionsOutlinedIcon style={{ marginRight: '5px' }} />
						<span style={{ marginRight: '7px' }}>Gallery</span>
					</Badge>
				</div>
			}
			{...a11yProps(1)}
		/>
	)

	if (careAllyData) {
		tabItems.push(
			<Tab
				key="care-ally"
				label={
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							textTransform: 'capitalize',
							fontSize: '14px',
							fontWeight: 600,
							color: value === 2 ? '#000' : 'rgba(0,0,0,0.60)',
						}}
					>
						<HandshakeOutlinedIcon style={{ marginRight: '5px' }} />
						Care Ally
					</div>
				}
				{...a11yProps(2)}
			/>
		)
	}

	if (title !== 'patients' && title !== 'profile' && tabValue !== 1 && tabValue !== 2) {
		tabItems.push(
			<Tab
				key="intervention"
				label={
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							textTransform: 'capitalize',
							fontSize: '14px',
							fontWeight: 600,
							color: value === 2 ? '#000' : 'rgba(0,0,0,0.60)',
						}}
					>
						<PersonOutlineIcon style={{ marginRight: '5px' }} />
						Human Intervention
					</div>
				}
				{...a11yProps(humanInterventionValue)}
			/>
		)
	}
	tabItems.push(
		<Tab
			key="assistant"
			label={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						textTransform: 'capitalize',
						fontSize: '14px',
						fontWeight: 600,
						color: value === 1 ? '#000' : 'rgba(0,0,0,0.60)',
					}}
				>
					<TipsAndUpdatesIcon style={{ marginRight: '5px' }} />
					AI Agent
				</div>
			}
			{...a11yProps(AiAssistantValue)}
		/>
	)

	React.useEffect(() => {
		if (refetchCareAllyAgain) {
			refetchCareAllyAgain()
		}
	}, [])

	React.useEffect(() => {
		if (tabItems?.length !== 0) {
			setIsHumanInterventionAvailable(title !== 'patients' && title !== 'profile' && tabValue !== 1 && tabValue !== 2)
		}
	}, [tabItems])

	return (
		<Box sx={{ width: '100%' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: isMobile ? 'column' : 'row',
					position: 'sticky',
					top: patientDrawer ? '5.4rem' : '5.8rem',
					backgroundColor: 'white',
					height: isDrawer ? '100%' : '65%',
					zIndex: '990',
					padding: 3,
				}}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					TabIndicatorProps={{
						sx: {
							left: `calc(${value} * 15%)`,
							'&.MuiTabs-indicator': {
								backgroundColor: '#000',
							},
						},
					}}
				>
					{tabItems}
				</Tabs>
				{(value === 0 || (careAllyData && value === 2)) && ( // Add this condition to hide the dropdown when Care Ally tab is selected
					<div style={{ margin: 2 }}>
						<ChatBotDropdownButton
							chatBotEnabled={value === 0 ? patient?.chatMode : careAllyData?.chatMode}
							selectedValue={value === 0 ? selectedValue : selectedCareAllyValue}
							setSelectedValue={value === 0 ? setSelectedValue : setSelectedCareAllyValue}
							patientId={value === 0 ? patient?._id : careAllyData?._id}
							refetch={value === 0 ? refetchPatientAgain : refetchCareAllyAgain}
							value={value}
							pathname={pathname}
							refetchChat={refetchChat}
						/>
					</div>
				)}
				{value === AiAssistantValue && ( // Add this condition to hide the dropdown when Care Ally tab is selected
					<div style={{ margin: 2 }}>
						<AiAssistantDropdownButton
							chatBotEnabled={value === 0 ? patient?.chatMode : careAllyData?.chatMode}
							selectedValue={selectedAiValue}
							setSelectedValue={setSelectedAiValue}
							patientId={value === 0 ? patient?._id : careAllyData?._id}
							refetch={value === 0 ? refetchPatientAgain : refetchCareAllyAgain}
							value={value}
							pathname={pathname}
							refetchChat={refetchChat}
							setAgentId={setAgentId}
						/>
					</div>
				)}
			</div>
			<CustomTabPanel value={value} index={0}>
				<ConversationTab
					id={patient?._id}
					consent={consent}
					isDrawer={isDrawer}
					selectedValue={selectedValue}
					chatBot={patient?.chatBotEnabled}
					page={page}
					setPage={setPage}
					status={status}
					value={value}
					refetchChat={refetchChat}
					chatData={chatData}
					isChatLoading={isChatLoading}
				/>
			</CustomTabPanel>
			{title !== 'patients' && title !== 'profile' && value === humanInterventionValue && (
				<CustomTabPanel value={value} index={humanInterventionValue}>
					<InterventionTab
						isDrawer={isDrawer}
						refetchAgain={refetchAgain}
						queueId={queueId}
						userFeedback={userFeedback}
						originalMessage={originalMessage}
						chatbotReply={chatbotReply}
						patient={patient}
						reasoning={reasoning}
						createdAt={createdAt}
						queueStatus={queueStatus}
						patientDrawer={patientDrawer}
						acuity={acuity}
						taskCategory={taskCategory}
					/>
				</CustomTabPanel>
			)}

			<>
				<CustomTabPanel value={value} index={1}>
					<GalleryTab id={patient?._id} isDrawer={isDrawer} />
				</CustomTabPanel>
				{careAllyData && (
					<CustomTabPanel value={value} index={2}>
						<CareAllyTab
							id={patient?._id}
							consent={careAllyData?.consentResponse}
							isDrawer={isDrawer}
							careAllyData={careAllyData}
							page={page}
							selectedValue={selectedCareAllyValue}
							setPage={setPage}
							status={status}
							value={value}
						/>
					</CustomTabPanel>
				)}
				<CustomTabPanel value={value} index={AiAssistantValue}>
					<AiConversationTab
						id={patient?._id}
						consent={consent}
						isDrawer={isDrawer}
						selectedValue={selectedValue}
						chatBot={patient?.chatBotEnabled}
						page={page}
						setPage={setPage}
						status={status}
						value={value}
						refetchChat={refetchChat}
						chatData={chatData}
						isChatLoading={isChatLoading}
						AiAssistantValue={AiAssistantValue}
						agentId={agentId}
					/>
				</CustomTabPanel>
			</>
		</Box>
	)
}
