import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Tooltip, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify'
import CallNoteModal from '../../../../Components/MessageLayout/CallNoteModal'

function CallAccordion({ title, data, defaultExpanded, isCallTab, noteData, fetchAgain }) {
	const [open, setOpen] = React.useState(false);
	const [editData, setEditData] = React.useState({});

	const copyText = (item)=>  {
		const textToCopy =
		item?.replace(/\n\s*\n/g, "\n")?.replace(/^\`\`\`|`\`\`$/g, "")
	
	  navigator.clipboard.writeText(textToCopy)
		.then(() => {
		  toast.success('Text copied successfully!');
		})
	
	}

	const handleOpenModal = (data) => {
		setEditData({...data})
		setOpen(true)
	}
	return (
		<Accordion defaultExpanded={defaultExpanded}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`panel1-${title}`}
				id={`panel1-${title}`}
				sx={{ background: '#f1f3f4', fontWeight: 600 }}
			>
				View {title}
			</AccordionSummary>
			<AccordionDetails sx={{ background: '#f1f3f4' }}>
				{title === 'Transcript' ? (
					<>
						{data && data.length !== 0 ? (
							data?.map((item, index) => (
								<Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography>
										<span style={{ fontSize: '15px', marginRight: '2px', fontWeight: 800 }}>{item?.role === 'user' ? 'User :' : 'Agent :'}</span>{' '}
										{item?.content}
									</Typography>
								</Box>
							))
						) : (
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography>No Transcript To Show</Typography>
							</Box>
						)}
					</>
				) : (
					<>
					{isCallTab && 
								<Box sx={{textAlign:'end'}}>
									{title ==='Note' && <Tooltip title={`Edit ${title}`} onClick={()=>handleOpenModal(noteData)} >
							  <EditIcon sx={{ fontSize: 'large', fontWeight: '1em', cursor:'pointer', marginRight:'5px' }}/>
							</Tooltip>}
									<Tooltip title={`Copy ${title}`} onClick={()=>copyText(data)} >
							  <ContentCopyIcon sx={{ fontSize: 'large', fontWeight: '1em', cursor:'pointer' }}/>
							</Tooltip>
								</Box>
					}
					<ReactMarkdown
						remarkPlugins={[remarkGfm]}
						components={{
							a: (props) => (
								<a href={props.href} style={{ color: '#000' }} target="blank">
									{props.children}
								</a>
							),
						}}
					>
						{
							    data
								?.split('\n')
								?.map((line) => {
								  if (line.includes(',') && !line.match(/^[-*#]/)) {
									return `\`${line.trim()}\``;
								  }
								  return line;
								})
								?.join('\n')
						}
					</ReactMarkdown>
					</>
				)}
			</AccordionDetails>
			<CallNoteModal {...{ open, setOpen,fetchAgain, setEditData, editData }} />
		</Accordion>
	)
}

export default CallAccordion
