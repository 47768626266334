import { createSlice } from "@reduxjs/toolkit";
import { setSiteIdInLocalStroage } from "../utils";

const initialState = {
  user: {
    email: "",
    token: "",
    role: 0,
    hospital: null,
    scopes: []
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      console.log(action.payload)
      setSiteIdInLocalStroage(state.user.hospital)
    },
    resetUser(state) {
      state.user = {
        email: "",
        token: "",
        role: 0,
        hospial: null,
        scopes: []
      };
    },
  },
});

export const selectUser = (state) => state.user;

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
