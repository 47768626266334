import React, { useEffect, useState } from "react";
import { Drawer, Grid, Stack, Box, Typography } from "@mui/material";
import moment from "moment";
import { AudioPlayer, SolidButton } from "../../../../Components";
import { useIsMobile } from "../../../../contexts/isMobile";
import CallAccordion from "./CallAccordion";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const GenerateNoteButton = () => {
  return (
    <>
      <SolidButton
        label="Generate Note"
        icon={<AutoFixHighIcon />}
        onClick={(e) => {
          alert("This functionality is coming soon...");
        }}
      />
    </>
  );
};

const Content = ({ toggleDrawer, data, agentData }) => {
  const isMobile = useIsMobile();

  const findFormattedKeyById = (data, id) => {
    if (data) {
      const key = Object?.keys(data)?.find((key) => data[key] === id);

      if (key) {
        const formattedKey = key
          ?.replace(/_/g, " ")
          ?.replace(/\b\w/g, (char) => char?.toUpperCase());
        return formattedKey;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography variant="p" sx={{ fontSize: "28px", fontWeight: "400" }}>
          Call Details
        </Typography>
      </Stack>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                alignItems: isMobile ? "end" : "center",
                // borderTop:"1px solid #979797",
                // borderBottom:"1px solid #979797",
              }}
            >
              <Box
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  marginRight: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{ fontSize: "15px", marginRight: "2px" }}
                  >
                    Date :
                  </Typography>
                  <Typography>
                    {moment(data?.createdAt).format("MM-DD-YYYY")}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginLeft: "5px",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{ fontSize: "15px", marginRight: "2px" }}
                >
                  Time :
                </Typography>
                <Typography>{moment(data?.createdAt).format("LT")}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              variant="h2"
              sx={{ fontSize: "15px", marginRight: "2px" }}
            >
              Agent Name
            </Typography>
            <Typography>
              {findFormattedKeyById(agentData, data?.agentId)}
            </Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography
              variant="h2"
              sx={{ fontSize: "15px", marginRight: "2px" }}
            >
              Call Picked-up
            </Typography>
            <Typography>
              {data?.callPicked === true ? "Yes" : "No"}
            </Typography>
          </Grid>

          <Grid item md={12} xs={12}>
            <Typography
              variant="h2"
              sx={{ fontSize: "15px", marginRight: "2px" }}
            >
              Recording
            </Typography>
            <Typography sx={{ marginTop: "10px" }}>
              {data?.recordingUrl ? (
                <AudioPlayer url={data?.recordingUrl} />
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>

          {/* <Grid item md={12} xs={12}>
              <Typography
                variant="h2"
                sx={{ fontSize: "15px", marginRight: "2px" }}
              >
                Note
              </Typography>
              <Typography sx={{marginTop:'10px'}}>
                {data?.notes?.length === 0 ? (
                  <GenerateNoteButton />
                ):(
                  'N/A'
                )}
              </Typography>
          </Grid> */}

          <Grid item md={12} xs={12}>
            <Typography
              variant="h2"
              sx={{ fontSize: "15px", marginRight: "2px" }}
            >
              Transcript
            </Typography>
            <Typography sx={{ marginTop: "10px" }}>
              {data?.transcript ? (
                <CallAccordion
                  title={"Transcript"}
                  data={data?.transcriptObject}
                />
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>

          <Grid item md={12} xs={12} mb={5}>
            <Typography
              variant="h2"
              sx={{ fontSize: "15px", marginRight: "2px" }}
            >
              Summary
            </Typography>
            <Typography sx={{ marginTop: "10px" }}>
              {data?.metadata?.call_analysis?.call_summary ? (
                <CallAccordion
                  title={"Summary"}
                  data={data?.metadata?.call_analysis?.call_summary}
                />
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>

          <Stack
            direction="row"
            justifyContent={"flex-end"}
            sx={{
              marginTop: "1rem",
              mr: "1rem",
              position: "fixed",
              width: "650px",
              bottom: "1rem",
              right: 0,
            }}
          >
            <SolidButton
              label="Close"
              onClick={(e) => {
                toggleDrawer({ value: false, data: null });
              }}
            />
          </Stack>
        </Grid>
      </Box>
    </Box>
  );
};

export function ViewDrawer({ state, toggleDrawer, data, agentData }) {
  const isMobile = useIsMobile();
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: !isMobile ? "45%" : "80%",
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer({ value: false, data: null })}
      >
        <Content
          toggleDrawer={toggleDrawer({ value: false, data: null })}
          data={data}
          agentData={agentData}
        />
      </Drawer>
    </React.Fragment>
  );
}
