import { Box, Fab, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useIsMobile } from '../../contexts/isMobile'
import { useAddCareAllyChatMutation, useGetCareAllyChatQuery } from '../../store/careAllysSlice'
import { DisplayMessageBox } from './DisplayMessagesBox'
import { SendMessageField } from './SendMessageField'
import RecommendedResponseTab from './RecommendedResponseTabs'
import { ArrowDownwardRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'

export default function CareAllyTab({ id, isDrawer, selectedValue, chatBot, consent, careAllyData, value, status, isPatientQueueV2 }) {
	const [message, setMessage] = useState('')
	const [url, setUrl] = useState('')
	const isMobile = useIsMobile()
	const [page, setPage] = useState(1)
	const [isPrevPageLoading, setisPrevPageLoading] = useState(false) // Track loading state for messages and pagination
	const [addCareAllyChat] = useAddCareAllyChatMutation()
	const { data, refetch, isLoading } = useGetCareAllyChatQuery({ id: careAllyData?._id, page })
	const [expanded, setExpanded] = useState(false)
	const [lastMessageId, setLastMessageId] = useState(null)
	const [showLoader, setShowLoader] = useState(false)
	const [patientQuestion, setPatientQuestion] = useState('')
	const [aiGeneratedMessage, setAiGeneratedMessage] = useState('')
	const scrollableRef = useRef()
	const prevDataRef = useRef()
	const sendMessageFieldRef = useRef()
	const [allMessages, setAllMessages] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const calculateScrollHeight = useRef()
	useEffect(() => {
		calculateScrollHeight.current = scrollableRef.current?.scrollHeight
		refetch()
	}, [page, refetch])
	const sentMessage = () => {
		if (careAllyData?.consentResponse !== 'yes') {
			toast.error('Care ally has not provided the consent yet!')
			return
		} else {
			const body = {
				body: message,
				senderId: 'panal',
				sentBy: 'panal',
			}

			addCareAllyChat({ id: careAllyData?._id, body })
			refetch()
			setMessage('')
		}
	}
	const adjustDisplayMessageBoxHeight = () => {
		if (sendMessageFieldRef.current && scrollableRef.current) {
			const sendMessageFieldHeight = sendMessageFieldRef.current.clientHeight
			const maxHeight = isDrawer && isPatientQueueV2 ? 'calc(100vh - 220px)' : isDrawer && !isPatientQueueV2 ? 'calc(100vh - 150px)' : 'calc(100vh - 250px)'
			const adjustedHeight = `calc(${maxHeight} - ${sendMessageFieldHeight}px)`
			scrollableRef.current.style.maxHeight = adjustedHeight

			scrollDown()
		}
	}

	useEffect(() => {
		adjustDisplayMessageBoxHeight()
	}, [sendMessageFieldRef.current?.clientHeight])

	const handleScroll = () => {
		const scrollTop = scrollableRef.current.scrollTop
		if (scrollTop == 0) {
			// Near the top - fetch previous page
			setPage((prevPage) => prevPage + 1)
			setisPrevPageLoading(true)
		}
	}
	useEffect(() => {
		adjustDisplayMessageBoxHeight()
	}, [sendMessageFieldRef.current?.clientHeight])
	const refetchAgain = () => {
		refetch()
	}

	useEffect(() => {
		scrollDown()
	}, [filteredData])

	useEffect(() => {
		refetch()
		setPage(1)
	}, [])

	React.useEffect(() => {
		if (data) {
			const interval = setInterval(() => {
				refetch()
				if (data?.data?.length !== prevDataRef?.current?.length) {
					prevDataRef.current = data?.data
					const tempAllMessages = page === 1 ? data?.data : allMessages.concat(data?.data)
					setAllMessages(tempAllMessages)
					const sortedDate = [...tempAllMessages]?.sort((a, b) => {
						const dateA = new Date(a.createdAt)
						const dateB = new Date(b.createdAt)

						return dateA - dateB
					})

					setFilteredData(sortedDate)
					const userLastMessageArray = data?.data?.filter((item) => item.sentBy === 'user')
					const userLastMessage = userLastMessageArray[userLastMessageArray?.length - 1]
					setLastMessageId(userLastMessage?._id)
					setisPrevPageLoading(false)
				}
			}, 5000)

			const tempAllMessages = page === 1 ? data?.data : allMessages.concat(data?.data)
			setAllMessages(tempAllMessages)
			const sortedDate = [...tempAllMessages]?.sort((a, b) => {
				const dateA = new Date(a.createdAt)
				const dateB = new Date(b.createdAt)

				return dateA - dateB
			})

			setFilteredData(sortedDate)
			const userLastMessageArray = data?.data?.filter((item) => item.sentBy === 'user')
			const userLastMessage = userLastMessageArray[userLastMessageArray?.length - 1]
			setLastMessageId(userLastMessage?._id)
			setisPrevPageLoading(false)
			prevDataRef.current = data?.data

			return () => clearInterval(interval)
		}
	}, [data])

	const scrollDown = () => {
		if (scrollableRef.current && page == 1) {
			scrollableRef.current.scrollTop = scrollableRef.current?.scrollHeight
		} else if (scrollableRef.current && page > 1) {
			scrollableRef.current.scrollTop = scrollableRef.current?.scrollHeight - calculateScrollHeight.current
		}
	}

	if (isLoading)
		return (
			<>
				<Stack spacing={1} py={2}>
					<Skeleton variant="rounded" animation="wave" width={360} height={100} />
					<div style={{ display: 'flex', justifyContent: 'right' }}>
						<Skeleton variant="rounded" animation="wave" width={360} height={100} />
					</div>
					<Skeleton variant="rounded" animation="wave" width={360} height={100} />
				</Stack>
			</>
		)

	return (
		<>
			{isPrevPageLoading && (
				<Typography
					sx={{
						fontSize: '14px',
						fontWeight: 600,
						textAlign: 'center',
						display: 'flex',
						justifyContent: 'center',
						color: 'gray',
					}}
				>
					Fetching Messages . . .
				</Typography>
			)}
			<Box
				className="ScrollDesign"
				ref={scrollableRef}
				sx={{
					overflowY: 'auto', // Enable vertical scrolling if needed
					p: 1,
					marginBottom: '100px',
				}}
				onScroll={handleScroll}
			>
				{filteredData?.length > 0 ? (
					<DisplayMessageBox
						setMessage={setMessage}
						data={filteredData}
						message={message}
						sentMessage={sentMessage}
						selectedValue={selectedValue}
						careAlly={true}
						setShowLoader={setShowLoader}
						setAiGeneratedMessage={setAiGeneratedMessage}
						setPatientQuestion={setPatientQuestion}
					/>
				) : (
					<>
						<Typography
							sx={{
								fontSize: '18px',
								fontWeight: 600,
								mt: '2rem',
								textAlign: 'center',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '50vh',
							}}
						>
							No Messages Found.
						</Typography>
					</>
				)}
			</Box>
			<Box
				sx={{
					position: 'fixed',
					bottom: 0,
					width: isDrawer && isMobile ? '78%' : isDrawer ? '57%' : '64%',
					background: 'white',
				}}
			>
				<div ref={sendMessageFieldRef}>
					<SendMessageField
						id={id}
						refetchAgain={refetchAgain}
						setMessage={setMessage}
						message={message}
						sentMessage={sentMessage}
						url={url}
						status={status}
						setPage={setPage}
						scrollDown={scrollDown}
						setUrl={setUrl}
						isCareAlly={true}
						careAllyData={careAllyData}
						value={value}
						showLoader={showLoader}
						aiGeneratedMessage={aiGeneratedMessage}
						setAiGeneratedMessage={setAiGeneratedMessage}
						patientQuestion={patientQuestion}
						setPatientQuestion={setPatientQuestion}
					/>
				</div>
			</Box>
		</>
	)
}
