import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export function DateField({ date, setDate, label, isBorder }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          sx={[
            {
              "& .MuiOutlinedInput-notchedOutline": {
                border: !isBorder && "none",
                borderBottom: !isBorder && "1px solid #979797 !important",
                borderRadius: !isBorder && 0,
              },
            },
            { "&.MuiStack-root": { padding: "0px !important" } },
            {
              "& .MuiInputLabel-root.Mui-error": {
                fontSize:"1.2rem !important",
                 color: !isBorder && "#979797",
              },
            },
            { width: "100%" },
            // { "&.css-uvi14g-MuiInputBase-root-MuiOutlinedInput-root": { width: "85% !important" } },
          ]}
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          value={date}
          onChange={(newValue) => setDate(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
