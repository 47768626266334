import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'

export function DateTimeField({ dateTime, setDateTime, label }) {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DemoContainer components={['DateTimePicker']}>
				<DateTimePicker
					sx={[
						{
							'& .MuiOutlinedInput-notchedOutline': {
								border: 'none',
								borderBottom: '1px solid #979797 !important',
								borderRadius: 0,
							},
						},
						{ '&.MuiStack-root': { padding: '0px !important' } },
						// {"&.css-a643pv-MuiStack-root":{padding: '0px !important'}},
						{
							'& .MuiInputLabel-root.Mui-error': {
								color: '#3a3939 !important',
								fontSize: '1rem !important',
							},
						},
						{ width: '100%' },
					]}
					label={label}
					value={dateTime}
					onChange={(newValue) => setDateTime(newValue)}
					slotProps={{
						textField: {
							error: false,
						},
						actionBar: {
							actions: ['today', 'accept', 'clear'],
						},
					}}
				/>
			</DemoContainer>
		</LocalizationProvider>
	)
}
