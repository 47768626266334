import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export function ConsentDropdown({data, setData, placeholder, options, metric, allMetric, setAllMetric }) {
  function toCamelCase(str) {
      return str
        .split(' ') // Split the string by spaces
        .map((word, index) =>
          index === 0
            ? word?.toLowerCase() // Convert the first word to lowercase
            : word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase() // Capitalize the first letter of the rest
        )
        .join(''); // Join the words back together
  }

  const handleChange = (event) => {
    setData(event.target.value);
    
    if (metric && metric !== "") {
        if (allMetric?.some((obj) => obj.query === toCamelCase(metric))) {
            const newArray = allMetric.filter(
              (item) => item?.query !== toCamelCase(metric)
            );
            const newArray2 = [...newArray, { metric, key: event.target.value?.key , values: event.target.value?.value , query: toCamelCase(metric) }]
            setAllMetric(newArray2);
            localStorage.setItem('allMetric', JSON.stringify(newArray2));
          }else{
            const newArray = [...allMetric, { metric, key: event.target.value?.key, values: event.target.value?.value , query: toCamelCase(metric) }]
            setAllMetric(newArray);
            localStorage.setItem('allMetric', JSON.stringify(newArray));
          }
      }
  };

  return (
    <Box sx={{ m:1, minWidth: 300 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={data}
          label={placeholder}
          onChange={handleChange}
          renderValue={(selected) => selected?.key || placeholder}
        >
            {options?.map((item, index) => (
                <MenuItem key={index} value={item}>{item?.key}</MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
