import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useGetPatientByIDQuery } from "../../store/patientsSlice";
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export  function PatientBreadcrumb({id}) {
  const navigate = useNavigate()
    const { data:patient, isLoading} = useGetPatientByIDQuery(id,{ refetchOnMountOrArgChange: true });
    if (isLoading) 
        return <>Loading..</>
    const name = `${patient  ? patient.firstName : "Test"} ${patient  ?  patient.lastName : "Test"}`
    const changeLink = (url) => () => {
      navigate(url);
    }

    const handleGoBack = () => {
      navigate(-1);
    };
  return (
    <div role="presentation" style={{
      display:  "flex",
      alignItems:  "center",
      marginTop:3
    }}>
       <ArrowBackIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={handleGoBack}
            />
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" onClick={changeLink(`/patients`)}>
          Patient
        </Link>
        <Link underline="hover" color="inherit" onClick={changeLink(`/profile/${id}`)}>
         Profile
        </Link>
        <Typography color="text.primary">{name}</Typography>
      </Breadcrumbs>
    </div>
  );
}