import React from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import ShieldIcon from "@mui/icons-material/Shield";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import PaymentsIcon from "@mui/icons-material/Payments";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import BlockIcon from "@mui/icons-material/Block";
import GppGoodIcon from "@mui/icons-material/GppGood";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReplyIcon from "@mui/icons-material/Reply";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip
} from "@mui/material";
import { Call, Email } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import CommunicationTable from "./CommunicationTable";
import ReferralModeAccordion from "./ReferralModeAccordion";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const transformSocialReferralConsent = (consent) => {
  const consentMap = {
    yes: "Yes",
    no: "No",
    NO_RESPONSE: "NO RESPONSE",
  };

  return consentMap[consent] || "N/A";
};

const iconMap = {
  food: <RestaurantIcon />,
  transportation: <DirectionsCarIcon />,
  housing: <HouseOutlinedIcon />,
  safety: <ShieldIcon />,
  utility: <TipsAndUpdatesIcon />,
  financial: <PaymentsIcon />,
  urgent: <DirectionsRunIcon />,
};

const GetConsentFormSigned = (formResult) => {
  if (formResult) {
    return formResult[formResult.length - 1]?.result;
  } else {
    return "Pending";
  }
};

function isTitlePresent(dataArray, searchTitle) {
  // Iterate through the array to check if any title's props.title matches the searchTitle
  return dataArray.some((item) => item?.title?.props?.title === searchTitle);
}

function getLoopClosedStatusChip(status){
  if(status === "CLOSED"){
    return <Chip label="Yes" sx={{background:'#28a745', color:'white'}} size="small"/>
  }else if(status === "UNRESOLVED"){
    return <Chip label="No" sx={{background:'#dc3545', color:'white'}} size="small" />
  }else{
    return status
  }
}

export const customContent = (
  handleOpenModal,
  isReferralSubmitted,
  setFifthItemContent,
  fifthItemContent,
  handleAddTimelineBilling,
  setIsBiling,
  isBiling,
  patientDetails,
  data,
  timelineData,
  communicationData
) => [
  isTitlePresent(timelineData, "Screening") && (
    <Box key="1">
      <Grid container spacing={13} alignItems={"center"}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
            Patient
          </Typography>
          <Typography>{data?.screening?.patient}</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
            Survey Conducted By
          </Typography>
          <Typography>{data?.screening?.surveyConductedBy}</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
            View Survey
          </Typography>
          {data?.screening?.surveyDetails?.status === "pending" ? (
            "Pending"
          ) : (
            <IconButton
              onClick={() =>
                handleOpenModal("survey", data?.screening?.surveyDetails)
              }
            >
              <RemoveRedEyeIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  ),

  isTitlePresent(timelineData, "Results") && (
    <Box key="2">
      <Grid container spacing={13}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
            Needs Identified
          </Typography>
          <Typography>
            {data?.results?.needsIdentified ? "Yes" : "No"}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
            Identified needs
          </Typography>
          <Box>
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "10px",
                }}
              >
                {data?.results?.identifiedNeeds &&
                data?.results?.identifiedNeeds?.length > 0
                  ? data?.results?.identifiedNeeds.map((icon, index) => {
                      const IconElement = iconMap[icon] || <BlockIcon />;
                      return (
                        <Tooltip
                          key={index}
                          title={icon.charAt(0).toUpperCase() + icon.slice(1)}
                        >
                          <div style={{ cursor: "pointer" }}>
                            {React.cloneElement(IconElement)}
                          </div>
                        </Tooltip>
                      );
                    })
                  : "N/A"}
              </div>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
            Consent Status
          </Typography>
          <Typography>{data?.results?.consentStatus ? "Yes" : "No"}</Typography>
        </Grid>
      </Grid>
    </Box>
  ),

  isTitlePresent(timelineData, "Consent") && (
    <Box key="3">
      <Grid container spacing={12}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
            Consent Form Signed
          </Typography>
          <Typography>
            {transformSocialReferralConsent(data?.consent?.consentFormSigned)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Typography variant="h6" fontSize={"16px"}>
            Details
          </Typography>
          <Typography style={{ maxWidth: 400, whiteSpace: "normal" }}>
            {data?.consent?.details}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  ),

  isTitlePresent(timelineData, "Online Referral Mode") && (
    <Box key="3">
      {data?.online_referral_mode?.referrals?.length === 0 ? (
      <Grid container spacing={12}>
      <Grid item xs={12} sm={4}>
        <Typography variant="h6" fontSize={"16px"}>
          Platform
        </Typography>
        <Typography sx={{textDecoration:'underline'}}>Find Help</Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="h6" fontSize={"16px"}>
        FindHelp Status
        </Typography>
        <Typography>No referrals have been made yet.</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="h6" fontSize={"16px"}>
        Loop Closed
        </Typography>
        <Typography>Referral Not Initiated</Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Button
          onClick={() => handleOpenModal("referToFindHelp")}
          variant="contained"
          sx={{ backgroundColor: "#0049C6", marginLeft: "15px" }}
        >
          <ReplyIcon sx={{ transform: "rotate(180deg) scaleY(-1)" }} /> Refer
        </Button>
      </Grid>
    </Grid>
      ):(
        <>
       { data?.online_referral_mode?.referrals?.map((item, index)=>(
<Grid key={index} container spacing={4}>
{index !== 0 && <Divider
          sx={{ background: "#ffffff", width: "100%", marginTop: "30px" }}
        />}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
            Platform
          </Typography>
          <Typography sx={{textDecoration:'underline'}}>Find Help</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
          FindHelp Status
          </Typography>
          <Typography sx={{textTransform:'capitalize'}}>{item?.findHelpStatus ? item?.findHelpStatus : 'No referrals have been made yet.'}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
          Loop Closed
          </Typography>
          <Typography>{item?.status ? getLoopClosedStatusChip(item?.status) : "Referral Not Initiated"}</Typography>
        </Grid>

        {/* <Divider
          sx={{ background: "#ffffff", width: "100%", marginTop: "30px" }}
        /> */}
        <Grid item xs={12} sm={12}>
          <ReferralModeAccordion title={item?.programDetails?.name || "N/A"} item={item} />
        </Grid>

{(data?.online_referral_mode?.referrals?.length - 1) !== index && (
        <Divider
          sx={{ background: "#ffffff", width: "100%", marginTop: "30px" }}
        />
)}

        {/* <Grid item xs={12} sm={12} sx={{paddingLeft: '80px', paddingTop: '30px'}}>
          {data?.online_referral_mode && Object?.keys(data?.online_referral_mode)?.length !== 0 && (
          <Button variant="outlined">More Info</Button>
          )} 
          <Button
            onClick={() => handleOpenModal("referToFindHelp")}
            variant="contained"
            sx={{ backgroundColor: "#0049C6", marginLeft: "15px" }}
          >
            <ReplyIcon sx={{ transform: "rotate(180deg) scaleY(-1)" }} /> Refer
          </Button>
        </Grid> */}
      </Grid>
        ))}
        <Grid container spacing={4} sx={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={12} sx={{paddingLeft: '80px', paddingTop: '30px'}}>
        <Button
          onClick={() => handleOpenModal("referToFindHelp")}
          variant="contained"
          sx={{ backgroundColor: "#0049C6", marginLeft: "15px" }}
        >
          <ReplyIcon sx={{ transform: "rotate(180deg) scaleY(-1)" }} /> Refer
        </Button>
      </Grid>
        </Grid>
        </>
      )}
    </Box>
  ),

  // Commenting due to V1
  // <Box key="5">
  // 	<Grid container spacing={!isReferralSubmitted ? 26 : 35}>
  // 		<Grid item xs={12} sm={6}>
  // 			<Typography variant="h6" fontSize={'16px'}>
  // 				Referral Initiation
  // 			</Typography>
  // 			<Typography>Manual</Typography>
  // 		</Grid>

  // 		{!isReferralSubmitted ? (
  // 			<Grid item xs={12} sm={6}>
  // 				<Button
  // 					onClick={() => handleOpenModal('referToCHW')}
  // 					endIcon={<ChevronRightIcon />}
  // 					variant="contained"
  // 					sx={{ backgroundColor: '#0049C6', width: '180px' }}
  // 				>
  // 					Refer to CHW
  // 				</Button>
  // 			</Grid>
  // 		) : (
  // 			<Grid item xs={12} sm={6}>
  // 				<Typography variant="h6" fontSize={'16px'}>
  // 					Referral Status
  // 				</Typography>
  // 				<Typography>
  // 					Sent <DoneIcon fontSize="small" />
  // 				</Typography>
  // 			</Grid>
  // 		)}
  // 	</Grid>
  // </Box>,

  // <Box key="6">
  // 	{!fifthItemContent ? (
  // 		<Box className="cursor-click" onClick={() => setFifthItemContent(true)}>
  // 			<Typography fontSize={'16px'}>
  // 				The Patient's details and Identified social needs are added to the CHW team's work queue for further assessment and action.
  // 			</Typography>
  // 		</Box>
  // 	) : (
  // 		<Box className="outer-container cursor-click" onClick={handleAddTimelineBilling}>
  // 			<Grid container spacing={2}>
  // 				<Grid item xs={12} sm={6}>
  // 					<Typography variant="h6" fontSize={'16px'}>
  // 						Assessment Conducted by
  // 					</Typography>
  // 					<Typography>Jane Doe, CHW</Typography>
  // 				</Grid>

  // 				<Grid item xs={12} sm={6}>
  // 					<Typography variant="h6" fontSize={'16px'}>
  // 						Assessment Summary
  // 					</Typography>
  // 					<Typography>Patient needs immediate housing and food support</Typography>
  // 				</Grid>

  // 				<Grid item xs={12} sm={6}>
  // 					<Typography variant="h6" fontSize={'16px'}>
  // 						Billable Time
  // 					</Typography>
  // 					<Typography>60 mins</Typography>
  // 				</Grid>

  // 				<Grid item xs={12} sm={6}>
  // 					<Typography variant="h6" fontSize={'16px'}>
  // 						Actions Taken
  // 					</Typography>
  // 					<Typography>Referred to local shelter</Typography>
  // 				</Grid>
  // 			</Grid>
  // 		</Box>
  // 	)}
  // </Box>,

  // <Box key="7">
  // 	{!isBiling ? (
  // 		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => setIsBiling(true)}>
  // 			<Typography>Not generated yet</Typography>
  // 		</Box>
  // 	) : (
  // 		<Grid container spacing={2}>
  // 			<Grid item xs={6}>
  // 				<Typography variant="h6" fontSize={'16px'}>
  // 					Primary Insurance
  // 				</Typography>
  // 				<Typography>HealthPlus</Typography>
  // 			</Grid>
  // 			<Grid item xs={6}>
  // 				<Typography variant="h6" fontSize={'16px'}>
  // 					Secondary Insurance
  // 				</Typography>
  // 				<Typography>Medicaid / WA Apple Care</Typography>
  // 			</Grid>
  // 			<Grid item xs={6}>
  // 				<Typography variant="h6" fontSize={'16px'}>
  // 					ICD-10 Codes
  // 				</Typography>
  // 				<Typography>Z59.4, Z59.1</Typography>
  // 			</Grid>
  // 			<Grid item xs={6}>
  // 				<Typography variant="h6" fontSize={'16px'}>
  // 					Billing Codes
  // 				</Typography>
  // 				<Typography>HSA01, FBA02</Typography>
  // 			</Grid>
  // 			<Grid item xs={6}>
  // 				<Typography variant="h6" fontSize={'16px'}>
  // 					Date
  // 				</Typography>
  // 				<Typography>25-07-2024</Typography>
  // 			</Grid>
  // 			<Grid item xs={6}>
  // 				<Typography variant="h6" fontSize={'16px'}>
  // 					CHI Billing History
  // 				</Typography>
  // 				<Typography>July CHI Clinical notes</Typography>
  // 			</Grid>
  // 			<Grid item xs={6}>
  // 				<Typography variant="h6" fontSize={'16px'}>
  // 					Notes
  // 				</Typography>
  // 				<Typography>
  // 					View Notes{' '}
  // 					<IconButton onClick={() => handleOpenModal('notes')}>
  // 						<RemoveRedEyeIcon fontSize="small" />
  // 					</IconButton>
  // 				</Typography>
  // 			</Grid>

  // 			<Grid item xs={12}>
  // 				<Button onClick={() => handleOpenModal('notes')} variant="contained" sx={{ backgroundColor: '#0049C6' }}>
  // 					Generate Clinical Notes
  // 				</Button>
  // 			</Grid>
  // 		</Grid>
  // 	)}
  // </Box>,

  // ********************************** Adding New Ones *********************************

  isTitlePresent(timelineData, "Communication") && (
    <Box key="5" sx={{width:'100%'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <CommunicationTable data={communicationData} />
        </Grid>
      </Grid>
    </Box>
  ),

  isTitlePresent(timelineData, "All Needs Met") && (
    <Box key="6">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" fontSize={"16px"}>
            Status
          </Typography>
          <Typography sx={{display:'flex'}}>
            <span style={{border: data?.closed_loop?.allNeedsMet ? "1px solid #28a745" : "1px solid #fabc03", borderRadius:'25px', height: data?.closed_loop?.allNeedsMet ? '25px' : '30px', width:data?.closed_loop?.allNeedsMet ? '25px' : '30px', background: data?.closed_loop?.allNeedsMet ? "#28a745" : '#fabc03', marginRight:'10px', marginTop:'5px'}}>
            {data?.closed_loop?.allNeedsMet ? (
              <DoneIcon fontSize={"small"} sx={{marginTop:'2px', marginLeft:'2px', color:'white'}} />
            ):(
              <HourglassBottomIcon fontSize={"small"} sx={{marginTop:'4px', marginLeft:'3px'}} />
            )}
              </span>
            {data?.closed_loop?.allNeedsMet ? 'Patient has been successfully connected with the appropriate Social Needs Resources.' : 'Patient is awaiting connection with the relevant Social Needs Resources.'}</Typography>
        </Grid>

        {/* <Grid item xs={12} sm={4}>
          <Typography variant="h6" fontSize={"16px"}>
            View Survey
          </Typography>
          {data?.closed_loop?.sdohSurveyDetails && data?.closed_loop?.sdohSurveyDetails?.status !== "pending" ? (
            <IconButton
            onClick={() =>
              handleOpenModal("survey", data?.closed_loop?.sdohSurveyDetails)
            }
          >
            <RemoveRedEyeIcon fontSize="small" />
          </IconButton>
          ) : (
            "Pending"
          )}
        </Grid> */}
        {/* <Grid item xs={12} sm={6}>
          <Typography variant="h6" fontSize={"16px"}>
            Notes
          </Typography>
          <Typography>{data?.closed_loop?.notes}</Typography>
        </Grid> */}
      </Grid>
    </Box>
  ),

  isTitlePresent(timelineData, "Assessment & Billing") && (
    <Box key="7">
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
          <Typography variant="h6" fontSize={"16px"} sx={{minWidth:'30%'}}>
            Assessment :
          </Typography>
          <Typography>{data["assessment_&_billing"]?.assessment}</Typography>
        </Grid>
        <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
          <Typography variant="h6" fontSize={"16px"} sx={{minWidth:'30%'}}>
            Billable Time :
          </Typography>
          <Typography>{data["assessment_&_billing"]?.billingTime}</Typography>
        </Grid>
        <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
          <Typography variant="h6" fontSize={"16px"} sx={{minWidth:'30%'}}>
            CHI Billing :
          </Typography>
          <Typography>{data["assessment_&_billing"]?.chiBilling}</Typography>
        </Grid>
        <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
          <Typography variant="h6" fontSize={"16px"} sx={{minWidth:'30%'}}>
            Billing Codes :
          </Typography>
          <Typography>{data["assessment_&_billing"]?.billingCodes}</Typography>
        </Grid>
        <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
          <Typography variant="h6" fontSize={"16px"} sx={{minWidth:'30%'}}>
            Notes :
          </Typography>
          <Typography>{data["assessment_&_billing"]?.notes}</Typography>
        </Grid>

        <Grid item xs={12} sx={{
          display:'flex',
    justifyContent: "flex-end",
  }}>
          <Button
            onClick={() => handleOpenModal("notes")}
            variant="contained"
            sx={{ backgroundColor: "#0049C6" }}
          >
            Generate Assessment
          </Button>
        </Grid>
      </Grid>
    </Box>
  ),
];

// Commenting due to V1
// export const timelineItems = [{ title: 'Screening' }, { title: 'Results' }, { title: 'Consent' }, { title: 'CHW Referral' }]

// Adding new
export const timelineItems = [
  { title: "Screening" },
  { title: "Results" },
  { title: "Consent" },
  { title: "Online Referral Mode" },
  { title: "Communication" },
  { title: "All Needs Met" },
  { title: "Assessment & Billing" },
];
