import React, { useState } from "react";
import { Box, Typography, Modal, Button, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useIsMobile } from "../../../contexts/isMobile";
import { useNavigate } from "react-router-dom";
import {
  useAddNewJourneyMutation,
} from "../../../store/journeysSlice";
import { SolidButton } from "../../../Components";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius: "10px",
};

export const CreateJourneyModal = ({ open, setOpen }) => {
    const[form,setForm]=useState({
        title:"",
        description:"",
    })
    const [addNewJourney, {isLoading: isAddJourneyLoading}] = useAddNewJourneyMutation();

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const createJourney = async (e) => {
    e.preventDefault();

    try {
        const journey = await addNewJourney(form);
        const journeyId = journey.data.data._id;
        localStorage.setItem("journeyId", JSON.stringify(journeyId));
        toast.success("Successfully created!");
        setOpen(false);

    } catch (error) {
      toast.error("Some error occurred!");
      console.log(error, "in addDrawer");
    }
  };

  const handleSaveJourney = async () => {
    try {
      if (form.title && form.description) {
        const journey = await addNewJourney(form);
        const journeyId = journey.data.data._id;
        localStorage.setItem("journeyId", JSON.stringify(journeyId));
        toast.success("Successfully created!");
        setOpen(false);
        navigate("/journeysflow");
      } else {
        toast.error("Title or description is missing");
      }
  } catch (error) {
    toast.error("Some error occurred!");
    console.log(error, "in addDrawer");
  }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create Journey
          </Typography>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Grid container spacing={2} justifyContent="center">
            <Grid item md={12} xs={12}>
                <TextField
                  required
                  fullWidth
                  id="outlined-required"
                  label="Title"
                  placeholder="Enter Title"
                  name="title"
                  value={form.title}
                  onChange={onChange}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                fullWidth
                  required
                  multiline
                  rows={4}
                  id="outlined-required"
                  label="Description"
                  placeholder="Enter Description"
                  name="description"
                  value={form.description}
                  onChange={onChange}
                />
              </Grid>
              <Grid item md={12} xs={12} textAlign="right">
              <SolidButton onClick={handleSaveJourney} label="Create" disabled={isAddJourneyLoading} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
