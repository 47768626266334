import React, { useState, useEffect } from 'react'
import { PatientSidebar } from './Sidebar'
import { Box, FormControlLabel, FormGroup, Grid, IconButton, Stack, Switch, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import ProviderInfoCard from './ProviderInfoCard'
import DemographicsData from './DemographicsData'
import AssignJourney from './AssignJourney'
import AssignSurgery from './AssignSurgery'
import { SolidButton, InactivityCheck, CreatePatientStepper } from '../../../Components'
import { useIsHovered } from '../../../contexts/isHovered'
import { drawerWidth } from '../../../Components/layout/constant'
import { createPatientSidebarData } from '../patientUtils'
import { useIsMobile } from '../../../contexts/isMobile'
import { useSelector } from 'react-redux'
import { useAddNewPatientMutation, useEditPatientMutation, useGetPatientByIDQuery, useUpdatePhoneMutation } from '../../../store/patientsSlice'
import { useAddNewCareAllyMutation } from '../../../store/careAllysSlice'
import { useAddAssignJourneyMutation } from '../../../store/assignJourneySlice'
import { useAddNewSurgeryMutation } from '../../../store/surgeriesSlice'
import { formatPhone, toSnakeCase, toTitleCase } from '../../../utils'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { careProgramsData } from '../../../careProgramUtils'
import SnackbarMsg from '../../../Components/SnackbarMsg'
import CreateCareAlly from './CreateCareAlly'

export default function CreatePatientIndex() {
	const localStorageStepperValue = localStorage.getItem('stepperValue') ? JSON?.parse(localStorage.getItem('stepperValue')) : null
	const localStoragePatientForm = localStorage.getItem('patientForm') ? JSON?.parse(localStorage.getItem('patientForm')) : null
	const localStorageAssignedJourney = localStorage.getItem('assignJourneyForm') ? JSON.parse(localStorage.getItem('assignJourneyForm')) : null
	const localStorageAssignedSurgery = localStorage.getItem('assignSurgeryForm') ? JSON.parse(localStorage.getItem('assignSurgeryForm')) : null
	const localStorageCareAlly = localStorage.getItem('createCareAllyForm') ? JSON.parse(localStorage.getItem('createCareAllyForm')) : null

	const { id } = useParams()
	const { data, isLoading: isEditDataLoading, isError } = useGetPatientByIDQuery(id)
	const { user } = useSelector((state) => state.user)
	const [oldPhone, setOldPhone] = useState(null)
	const [formPatientId, setFormPatientId] = useState('')
	const [language, setLanguage] = useState('')
	const [isValidNumber, setIsValidNumber] = useState(null)
	const [state, setState] = useState('')
	const [physician, setPhysician] = useState('')
	const [birthday, setBirthday] = useState('')
	const [mrn, setMrn] = useState('')
	const [team, setTeam] = useState('')
	const [selectedTeam, setSelectedTeam] = useState('')
	const [programTags, setProgramTags] = useState([])
	const [selectedProgramTags, setSelectedProgramTags] = useState('')
	const [selectedTags, setSelectedTags] = useState([])
	const [creatorData, setCreaterData] = useState('')
	const [membersData, setMembersData] = useState([])
	const [selectedPhysician, setSelectedPhysician] = useState(null)
	const [selectedEthnicity, setSelectedEthnicity] = useState('')
	const [selectedRace, setSelectedRace] = useState('')

	//   For Stepper
	const [activeStep, setActiveStep] = useState(0)
	const [skipped, setSkipped] = useState(new Set())

	// For Care Ally Creation
	const [languageCareAlly, setLanguageCareAlly] = useState('')
	const [formCareAlly, setFormCareAlly] = useState({
		firstName: '',
		lastName: '',
		relationship: '',
		phoneNumber: '',
	})
	const [isValidNumberCareAlly, setIsValidNumberCareAlly] = useState(null)

	//   For Assign Journey
	const [assign, setAssign] = useState('')
	const [selectedAssign, setSelectedAssign] = useState('')

	// For Assign Surgery
	const [surgeryDate, setSurgeryDate] = useState('')
	const [surgeon, setSurgeon] = useState('')
	const [selectedSurgeon, setSelectedSurgeon] = useState(null)
	const [triggerJourney, setTriggerJourney] = useState([])
  const [carePathway, setCarePathway] = useState('')
	const [selectCarePathway, setSelectCarePathway] = useState(null)
	const [surgeryForm, setSurgeryForm] = useState({
		description: '',
		location: '',
		surgeryType: '',
		patient: id,
		careTeamMember: user.id,
	})
	const [time, setTime] = useState('')
	const [surgeryTime, setSurgeryTime] = useState({})

	const [form, setForm] = useState({
		firstName: data?.firstName || '',
		lastName: data?.lastName || '',
		gender: '',
		patientSummary: '',
		email: '',
		phone: '',
		address1: '',
		address2: '',
		state: '',
		city: '',
		zip: '',
		tags: [],
		race: '',
		ethnicity: '',
	})

	const isMobile = useIsMobile()
	const navigate = useNavigate()
	const { hovered } = useIsHovered()
	const [addNewPatient, { isLoading }] = useAddNewPatientMutation()
	const [addAssignJourney] = useAddAssignJourneyMutation()
	const [addNewSurgery] = useAddNewSurgeryMutation()
	const [addNewCareAlly, { isLoading: isAddCareAllyLoading }] = useAddNewCareAllyMutation()
	const [editPatient, { isLoading: isEditLoading }] = useEditPatientMutation()
	const [updatePhone] = useUpdatePhoneMutation()
	const [openBar, setOpenBar] = useState(false)
	const handleClose = () => {
		// navigate("/patients", { replace: true });
		navigate(-1)
		setOpenBar(false)
	}

	const checkPhoneNumber = (newNumber, oldNumber) => {
		return newNumber !== oldNumber
	}

	const isStepSkipped = (step) => {
		return skipped?.has(step)
	}

	const handleCreate = () => {
		let newSkipped = skipped
		const requiredFields = ['firstName', 'lastName', 'phone']
		const fieldMessages = {
			firstName: 'First Name',
			lastName: 'Last Name',
			phone: 'Phone',
		}
		const missingField = requiredFields.find((field) => !form[field])

		if (missingField) {
			const friendlyFieldName = fieldMessages[missingField]
			toast.error(`${friendlyFieldName} is required!`)
			return
		}

		if (isValidNumber === true) {
			// Formatting phone number by replacing spaces and adding + sign for the number validator
			const formattedPhone = formatPhone(form?.phone)

			if (mrn?.length !== 0 && mrn.length > 20) {
				toast.error('MRN must not exceed 20 words')
				return
			}

			//   Commenting ref: Back Button of stepper
			//   if (!id && localStorageCreatedPatient === null) {
			localStorage.setItem(
				'patientForm',
				JSON.stringify({
					...form,
					phone: formattedPhone,
					birthday,
					language,
					state,
					race: selectedRace,
					ethnicity: selectedEthnicity,
					team,
					physician,
					mrn,
					tags: selectedTags,
					pathway: programTags,
					program: programTags,
					members: [user.id],
					creator: user.id,
				})
			)

			if (isStepSkipped(activeStep)) {
				newSkipped = new Set(newSkipped.values())
				newSkipped.delete(activeStep)
			}
			setActiveStep((prevActiveStep) => prevActiveStep + 1)
			setSkipped(newSkipped)
			localStorage.setItem('stepperValue', JSON.stringify({ activeStep: activeStep + 1, skipped: newSkipped }))
			// addNewPatient({
			//   ...form,
			//   phone: formattedPhone,
			//   birthday,
			//   language,
			//   state,
			//   race: selectedRace,
			//   ethnicity: selectedEthnicity,
			//   team,
			//   physician,
			//   tags: selectedTags,
			//   pathway: programTags,
			//   program: programTags,
			//   members: [user.id],
			//   creator: user.id,
			// })
			//   .unwrap()
			//   .then((data) => {
			//     // localStorage.setItem('createdPatient', JSON.stringify(data?.data)) //commenting ref: Back Button in Stepper
			//     localStorage.setItem("createdPatientId", data?.data?._id);
			//     setOpenBar(true);
			//     if (isStepSkipped(activeStep)) {
			//       newSkipped = new Set(newSkipped.values());
			//       newSkipped.delete(activeStep);
			//     }
			//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
			//     setSkipped(newSkipped);
			//     localStorage.setItem(
			//       "stepperValue",
			//       JSON.stringify({ activeStep: activeStep + 1, skipped: newSkipped })
			//     );
			//   })
			//   .catch((error) => {
			//     if (
			//       error.data ===
			//       'Cast to ObjectId failed for value "" (type string) at path "team" because of "BSONError"'
			//     ) {
			//       toast.error("Team is required");
			//     } else {
			//       toast.error(error.data?.message);
			//     }
			//   });
			//   } else {
			//     editPatient({
			//       id: id ? id : localStorageCreatedPatient?._id,
			//       body: {
			//         ...form,
			//         phone: formattedPhone,
			//         birthday,
			//         language,
			//         state,
			//         team,
			//         race: selectedRace,
			//         ethnicity: selectedEthnicity,
			//         physician,
			//         tags: selectedTags,
			//         pathway: programTags,
			//         program: programTags,
			//         members: membersData,
			//         creator: creatorData,
			//       },
			//     })
			//       .unwrap()
			//       .then(() => {
			// 		localStorage.setItem('createdPatient', JSON.stringify(
			// 			{
			// 				...form,
			// 				phone: formattedPhone,
			// 				birthday,
			// 				language,
			// 				state,
			// 				team,
			// 				race: selectedRace,
			// 				ethnicity: selectedEthnicity,
			// 				physician,
			// 				tags: selectedTags,
			// 				pathway: programTags,
			// 				program: programTags,
			// 				members: membersData,
			// 				creator: creatorData,
			// 				_id:localStorageCreatedPatient?._id
			// 			  }
			// 		))
			// 		setOpenBar(true);
			// 		if (isStepSkipped(activeStep)) {
			// 			newSkipped = new Set(newSkipped.values());
			// 			newSkipped.delete(activeStep);
			// 		}
			// 		setActiveStep((prevActiveStep) => prevActiveStep + 1);
			// 		setSkipped(newSkipped);
			//       })
			//       .catch((error) => {
			//         console.log("Error Log:", error);
			//         if (
			//           error.data ===
			//           'Cast to ObjectId failed for value "" (type string) at path "team" because of "BSONError"'
			//         ) {
			//           toast.error("Team is required");
			//         } else {
			//           toast.error(error.data?.message);
			//         }
			//       });
			//   }
		} else {
			toast.error('The number is not valid!')
		}
	}

	const handleAssign = async () => {
		if (!assign) {
			const formattedPhone = formatPhone(form?.phone)

			if (mrn?.length !== 0 && mrn?.length > 20) {
				toast.error('MRN must not exceed 20 words')
				return
			}

			if (formPatientId === '') {
				try {
					const data = await addNewPatient({
						...form,
						phone: formattedPhone,
						birthday,
						language,
						state,
						race: selectedRace,
						ethnicity: selectedEthnicity,
						team,
						physician,
						mrn,
						tags: selectedTags,
						pathway: programTags,
						program: programTags,
						members: [user.id],
						creator: user.id,
					}).unwrap()

					setFormPatientId(data?.data?._id)
				} catch (error) {
					console.log('Error Log:', error)
					if (error.data === 'Cast to ObjectId failed for value "" (type string) at path "team" because of "BSONError"') {
						toast.error('Team is required')
					} else {
						toast.error(error.data?.message)
					}
				}
				return
			}
		}

		if (formPatientId === '') {
			localStorage.setItem(
				'assignJourneyForm',
				JSON.stringify({
					journeyId: assign,
					hospital: user?.hospital || null,
					creator: user.id,
				})
			)
		}

		if (mrn?.length !== 0 && mrn?.length > 20) {
			toast.error('MRN must not exceed 20 words')
			return
		}

		const formattedPhone = formatPhone(form?.phone)

		if (formPatientId === '') {
			addNewPatient({
				...form,
				phone: formattedPhone,
				birthday,
				language,
				state,
				race: selectedRace,
				ethnicity: selectedEthnicity,
				team,
				physician,
				mrn,
				tags: selectedTags,
				pathway: programTags,
				program: programTags,
				members: [user.id],
				creator: user.id,
			})
				.unwrap()
				.then((data) => {
					setFormPatientId(data?.data?._id)
				})
				.catch((error) => {
					console.log('Error Log:', error)
					if (error.data === 'Cast to ObjectId failed for value "" (type string) at path "team" because of "BSONError"') {
						toast.error('Team is required')
					} else {
						toast.error(error.data?.message)
					}
				})
		}

		addPatientData()
	}

	const createSurgery = (e) => {
		let newSkipped = skipped
		if (surgeryDate === '' || surgeon === '' || surgeryForm.surgeryType === '') {
			toast.error('Please fill out the required fields')
			return
		}

		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values())
			newSkipped.delete(activeStep)
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
		setSkipped(newSkipped)
		localStorage.setItem('stepperValue', JSON.stringify({ activeStep: activeStep + 1, skipped }))

		localStorage.setItem(
			'assignSurgeryForm',
			JSON.stringify({
				...surgeryForm,
				surgeryType: surgeryForm?.surgeryType,
				surgeon,
				surgeryDate,
				surgeryTime,
        // carePathway
			})
		)
	}

	const createCareAlly = (e) => {
		let newSkipped = skipped
		// check if the any field is empty
		const emptyFields = Object.values(formCareAlly).some((value) => value === '')

		if (emptyFields || languageCareAlly === '') {
			toast.error('Please fill in all required fields!')
			return
		}

		if (!isValidNumberCareAlly) {
			toast.error('The number is not valid!')
			return
		}

		const formattedPhone = formatPhone(formCareAlly?.phoneNumber)

		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values())
			newSkipped.delete(activeStep)
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
		setSkipped(newSkipped)
		localStorage.setItem('stepperValue', JSON.stringify({ activeStep: activeStep + 1, skipped }))

		localStorage.setItem(
			'createCareAllyForm',
			JSON.stringify({
				...formCareAlly,
				phoneNumber: formattedPhone,
				language: languageCareAlly,
			})
		)
	}

	const handleNext = () => {
		if (activeStep === 0) {
			handleCreate()
		} else if (activeStep === 1) {
			createCareAlly()
		} else if (activeStep === 2) {
			createSurgery()
		} else {
			handleAssign()
		}
	}

	const handleSkip = () => {
		const newSkipped = new Set(skipped.values())
		newSkipped.add(activeStep)
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
		setSkipped(newSkipped)

		// Convert the Set to an array before saving
		const newSkippedArray = Array.from(skipped.values())
		newSkippedArray.push(activeStep) // Add the activeStep to the array

		localStorage.setItem('stepperValue', JSON.stringify({ activeStep: activeStep + 1, skipped: newSkippedArray }))

		if (newSkippedArray.includes(1)) {
			setLanguageCareAlly('')
			setFormCareAlly({
				firstName: '',
				lastName: '',
				relationship: '',
				phoneNumber: '',
			})
			setIsValidNumberCareAlly(null)
		} else if (newSkippedArray.includes(2)) {
			setSurgeryDate('')
			setSurgeon('')
			setSelectedSurgeon(null)
      setSelectCarePathway(null)
			setTriggerJourney([])
			setSurgeryForm({
				description: '',
				location: '',
				surgeryType: '',
				patient: id,
				careTeamMember: user.id,
			})
		}
	}

	const handleBack = () => {
		localStorage.setItem('stepperValue', JSON.stringify({ activeStep: activeStep - 1, skipped }))
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleEdit = () => {
		const requiredFields = ['firstName', 'lastName', 'phone']
		const fieldMessages = {
			firstName: 'First Name',
			lastName: 'Last Name',
			phone: 'Phone',
		}
		const missingField = requiredFields.find((field) => !form[field])

		if (missingField) {
			const friendlyFieldName = fieldMessages[missingField]
			toast.error(`${friendlyFieldName} is required!`)
			return
		}

		if (isValidNumber === true) {
			// Formatting phone number by replacing spaces and adding + sign for the number validator
			const formattedPhone = formatPhone(form?.phone)
			const isPhoneNumberChange = checkPhoneNumber(formattedPhone, formatPhone(oldPhone))

			if (isPhoneNumberChange) {
				updatePhone({ oldNumber: formatPhone(oldPhone), newNumber: formattedPhone })
					.then((res) => {
						console.log(res?.data?.message)
					})
					.catch((error) => {
						console.log('Error Log:', error)
					})
			}

			if (mrn?.length !== 0 && mrn?.length > 20) {
				toast.error('MRN must not exceed 20 words')
				return
			}

			editPatient({
				id,
				body: {
					...form,
					phone: formattedPhone,
					birthday,
					language,
					state,
					team,
					race: selectedRace,
					ethnicity: selectedEthnicity,
					physician,
					tags: selectedTags,
					mrn,
					pathway: programTags,
					program: programTags,
					members: membersData,
					creator: creatorData,
				},
			})
				.unwrap()
				.then(() => {
					setOpenBar(true)
				})
				.catch((error) => {
					console.log('Error Log:', error)
					if (error.data === 'Cast to ObjectId failed for value "" (type string) at path "team" because of "BSONError"') {
						toast.error('Team is required')
					} else {
						toast.error(error.data?.message)
					}
				})
		} else {
			toast.error('The number is not valid!')
		}
	}

	const addPatientData = async () => {
		let isSurgeryAdded = false
		let isJourneyAdded = false
		let isCareAllyAdded = false

		if (formPatientId) {
			if (formCareAlly?.firstName !== '') {
				let isError = false
				const formattedPhone = formatPhone(formCareAlly?.phoneNumber)
				const body = {
					...formCareAlly,
					phoneNumber: formattedPhone,
					language: languageCareAlly,
				}

				try {
					await addNewCareAlly({ id: formPatientId, body }).unwrap()
					isCareAllyAdded = true
				} catch (error) {
					isCareAllyAdded = null
					isError = true
					toast.error(`Care Ally: ${error?.data}`)
					console.log(error, 'error in addNewCareAlly')
				}

				if (isError) {
					return
				}
			}

			if (surgeryDate !== '' && surgeon !== '' && surgeryForm.surgeryType !== '') {
				try {
					await addNewSurgery({
						...surgeryForm,
						surgeryType: surgeryForm?.surgeryType,
						patient: formPatientId,
						surgeon,
						surgeryDate,
            // carePathway,
						journeyIds: triggerJourney?.length !== 0 && !skipped.has(2) && triggerJourney,
						surgeryTime,
					}).unwrap()
					isSurgeryAdded = true
				} catch (error) {
					isSurgeryAdded = null
					toast.error(error.data || error?.data?.error)
				}
			}

			if (localStorageAssignedJourney && surgeryDate === '') {
				try {
					await addAssignJourney({
						journeyId: assign,
						patientId: formPatientId,
						hospital: user?.hospital || null,
						creator: user.id,
					}).unwrap()
					isJourneyAdded = true
				} catch (error) {
					isJourneyAdded = null
					toast.error(error?.data?.error)
					console.log(error, 'error in addAssignJourney')
				}
			}

			if (formPatientId || isSurgeryAdded || isJourneyAdded || isCareAllyAdded) {
				toast.success('Patient has been created!')
				localStorage.removeItem('createCareAllyForm')
				localStorage.removeItem('stepperValue')
				localStorage.removeItem('patientForm')
				localStorage.removeItem('assignJourneyForm')
				localStorage.removeItem('assignSurgeryForm')
				handleClose()
			}
		}
	}

	const onChange = (e) => {
		setForm((form) => ({ ...form, [e.target.name]: e.target.value }))
	}

	useEffect(() => {
		if (data) {
			const findProgramObject = careProgramsData?.filter((obj) => data?.program?.includes(obj.value))

			setForm({
				firstName: data?.firstName || '',
				lastName: data?.lastName || '',
				gender: data?.gender || '',
				patientSummary: data?.patientSummary || '',
				email: data?.email || '',
				phone: data?.phone || '',
				address1: data?.address1 || '',
				address2: data?.address2 || '',
				state: data?.state || '',
				city: data?.city || '',
				zip: data?.zip || '',
				tags: data?.tags || [],
				race: data?.race || '',
				ethnicity: data?.ethnicity || '',
			})
			setState(data?.state || '')
			setLanguage(data?.language || '')
			setBirthday(data?.birthday ? new Date(data.birthday) : '')
			setTeam(data?.team?._id || '')
			setSelectedTeam(data?.team?.name || '')
			setProgramTags(data?.program || [])
			setSelectedProgramTags(findProgramObject)
			setCreaterData(data?.creator)
			setMembersData(data?.members)
			setPhysician(data?.physician?._id)
			setSelectedPhysician(data?.physician ? { _id: data?.physician?._id, name: data?.physician?.name } : null)
			setSelectedEthnicity(data?.ethnicity || '')
			setSelectedRace(data?.race || '')
			setMrn(data?.mrn)
			if (form?.phone?.length === 12) {
				setIsValidNumber(false)
			} else {
				setIsValidNumber(true)
			}

			setOldPhone(data?.phone)
		}
	}, [data])

	// This useEffect is commented because Back button is also commented.

	useEffect(() => {
		if (localStoragePatientForm && !id) {
			const findProgramObject = careProgramsData?.filter((obj) => localStoragePatientForm?.program?.includes(obj.value))

			setForm({
				firstName: localStoragePatientForm?.firstName || '',
				lastName: localStoragePatientForm?.lastName || '',
				gender: localStoragePatientForm?.gender || '',
				patientSummary: localStoragePatientForm?.patientSummary || '',
				email: localStoragePatientForm?.email || '',
				phone: localStoragePatientForm?.phone || '',
				address1: localStoragePatientForm?.address1 || '',
				address2: localStoragePatientForm?.address2 || '',
				state: localStoragePatientForm?.state || '',
				city: localStoragePatientForm?.city || '',
				zip: localStoragePatientForm?.zip || '',
				tags: localStoragePatientForm?.tags || [],
				race: localStoragePatientForm?.race || '',
				ethnicity: localStoragePatientForm?.ethnicity || '',
			})
			setState(localStoragePatientForm?.state || '')
			setLanguage(localStoragePatientForm?.language || '')
			setBirthday(new Date(localStoragePatientForm?.birthday) || '')
			setTeam(localStoragePatientForm?.team || '')
			setProgramTags(localStoragePatientForm?.program || [])
			setSelectedProgramTags(findProgramObject)
			setCreaterData(localStoragePatientForm?.creator)
			setMembersData(localStoragePatientForm?.members)
			setPhysician(localStoragePatientForm?.physician?._id)
			setSelectedPhysician(
				localStoragePatientForm?.physician
					? {
							_id: localStoragePatientForm?.physician?._id,
							name: localStoragePatientForm?.physician?.name,
					  }
					: null
			)
			setSelectedEthnicity(localStoragePatientForm?.ethnicity || '')
			setSelectedRace(localStoragePatientForm?.race || '')
			if (form?.phone?.length === 12) {
				setIsValidNumber(false)
			} else {
				setIsValidNumber(true)
			}
		}

		if (surgeryDate !== '' && !skipped?.has(2) && localStorageAssignedJourney) {
			setTriggerJourney(localStorageAssignedJourney?.journeyId)
		} else if (localStorageAssignedJourney) {
			setAssign(localStorageAssignedJourney?.journeyId)
		}

		if (localStorageAssignedSurgery) {
			setSurgeryDate(new Date(localStorageAssignedSurgery?.surgeryDate) || '')
			setSurgeon(localStorageAssignedSurgery?.surgeon || '')
			setSurgeryForm({
				description: localStorageAssignedSurgery?.description || '',
				location: localStorageAssignedSurgery?.location || '',
				surgeryType: localStorageAssignedSurgery?.surgeryType ? localStorageAssignedSurgery?.surgeryType : '',
				patient: id,
				careTeamMember: user.id,
			})

			setSurgeryTime(localStorageAssignedSurgery?.surgeryTime)

			if (localStorageAssignedSurgery?.surgeryTime?.hour) {
				const { hour, minute } = localStorageAssignedSurgery?.surgeryTime

				// Create a new date object
				const newDate = new Date()

				// Set the hours and minutes
				newDate.setHours(parseInt(hour, 10), parseInt(minute, 10), 0, 0)

				// Convert to string format like 'Thu Apr 04 2024 08:00:00 GMT+0500 (Pakistan Standard Time)'
				const formattedDate = newDate.toString()

				setTime(new Date(formattedDate))
			} else {
				setTime('')
			}
		}

		if (localStorageCareAlly) {
			setFormCareAlly({
				firstName: localStorageCareAlly?.firstName || '',
				lastName: localStorageCareAlly?.lastName || '',
				relationship: localStorageCareAlly?.relationship || '',
				phoneNumber: localStorageCareAlly?.phoneNumber || '',
			})
			setLanguageCareAlly(localStorageCareAlly?.language || '')
			if (localStorageCareAlly?.phoneNumber === 12) {
				setIsValidNumberCareAlly(false)
			} else {
				setIsValidNumberCareAlly(true)
			}
		}
	}, [])

	useEffect(() => {
		if (localStorageStepperValue) {
			setActiveStep(localStorageStepperValue?.activeStep)

			// Check if skipped exists and is an array before converting
			if (Array.isArray(localStorageStepperValue?.skipped)) {
				setSkipped(new Set(localStorageStepperValue?.skipped))
			} else {
				setSkipped(new Set())
			}
		}
	}, [])

	useEffect(() => {
		addPatientData()
	}, [formPatientId])

	if (id && isEditDataLoading && !isError) return <>{console.log('Loading')}</>
	if (isError) return <>{console.log('Error')}</>

	return (
		<>
			{/* <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: "fixed",
          top: "3.8rem",
          left: "2rem",
          width: "100%",
          height:"2.5rem",
          zIndex: 999,
          bgcolor: "white",
          px: "30px",
          pl,
        }}
      >
        <Box borderBottom={"2px solid"} display={"flex"} alignItems={"center"}>
          <Typography fontSize={"14px"} fontWeight={"bold"}>
            {form.firstName || form.lastName ? `${form.firstName} ${form.lastName}` : "Patient Name"}
          </Typography>
          {/* {!isMobile && (
            <IconButton>
              <Close />
            </IconButton>
          )} 
        </Box>
        {/* <FormGroup>
          <FormControlLabel
            label={
              <span style={{ display: "flex", alignItems: "center" }}>
                <span style={{ color: "red" }}>*</span>Show Mandatory Fields
                Only
              </span>
            }
            control={<Switch />}
          />
        </FormGroup> 
      </Stack> */}

			{!id && (
				<Box sx={{ marginTop: '50px', mx: '4rem' }}>
					<CreatePatientStepper
						{...{
							activeStep,
							isStepSkipped,
						}}
					>
						<Grid container justifyContent={'center'}>
							<Grid item md={11} my={3} sx={8} mx={isMobile && 2}>
								{activeStep === 0 ? (
									<DemographicsData
										{...{
											selectedPhysician,
											selectedEthnicity,
											setSelectedEthnicity,
											selectedRace,
											setSelectedRace,
											setSelectedPhysician,
											physician,
											setPhysician,
											form,
											onChange,
											setForm,
											selectedProgramTags,
											setSelectedProgramTags,
											isValidNumber,
											setIsValidNumber,
											birthday,
											setBirthday,
											language,
											setLanguage,
											state,
											setState,
											team,
											setTeam,
											selectedTeam,
											setSelectedTeam,
											programTags,
											setProgramTags,
											selectedTags,
											setSelectedTags,
											mrn,
											setMrn,
										}}
									/>
								) : activeStep === 3 ? (
									<AssignJourney
										{...{
											assign: surgeryDate !== '' && !skipped?.has(2) ? triggerJourney : assign,
											setAssign: surgeryDate !== '' && !skipped?.has(2) ? setTriggerJourney : setAssign,
											selectedAssign,
											setSelectedAssign,
											surgeryDate,
											skipped,
										}}
									/>
								) : activeStep === 1 ? (
									<CreateCareAlly
										{...{
											language: languageCareAlly,
											setLanguage: setLanguageCareAlly,
											form: formCareAlly,
											setForm: setFormCareAlly,
											isValidNumber: isValidNumberCareAlly,
											setIsValidNumber: setIsValidNumberCareAlly,
										}}
									/>
								) : activeStep === 2 ? (
									<AssignSurgery
										{...{
											surgeryDate,
											setSurgeryDate,
											triggerJourney,
											setTriggerJourney,
											form: surgeryForm,
											setForm: setSurgeryForm,
											surgeon,
											setSurgeon,
											selectedSurgeon,
											setSelectedSurgeon,
											time,
											setTime,
											setSurgeryTime,
											setCarePathway,
                      selectCarePathway,
                      setSelectCarePathway
										}}
									/>
								) : null}

								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										marginTop: '1.5rem',
									}}
								>
									<div>{activeStep !== 0 && <SolidButton label={'Back'} onClick={handleBack} />}</div>

									<div>
										{(activeStep === 1 || activeStep === 2) && <SolidButton label={'Skip'} onClick={handleSkip} isOutlined={true} />}

										<SolidButton label={activeStep === 3 ? 'Finish' : 'Next'} onClick={handleNext} disabled={isLoading || isEditLoading} />
									</div>
								</div>
							</Grid>
						</Grid>
					</CreatePatientStepper>
				</Box>
			)}

			<Box sx={{ height: '2rem' }} />
			{id && (
				<Grid container justifyContent={'center'}>
					{/* {!isMobile && (
          <Grid item md={2}>
            <PatientSidebar data={createPatientSidebarData} />
          </Grid>
        )} */}

					<Grid item md={11} my={3} sx={8} mx={isMobile && 2}>
						<DemographicsData
							{...{
								selectedPhysician,
								selectedEthnicity,
								setSelectedEthnicity,
								selectedRace,
								setSelectedRace,
								setSelectedPhysician,
								physician,
								setPhysician,
								form,
								onChange,
								setForm,
								selectedProgramTags,
								setSelectedProgramTags,
								isValidNumber,
								setIsValidNumber,
								birthday,
								setBirthday,
								language,
								setLanguage,
								state,
								setState,
								team,
								setTeam,
								selectedTeam,
								setSelectedTeam,
								programTags,
								setProgramTags,
								selectedTags,
								setSelectedTags,
								mrn,
								setMrn,
							}}
						/>

						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginTop: '1.5rem',
							}}
						>
							<SolidButton label={isLoading || isEditLoading ? 'Loading..' : 'Save'} onClick={handleEdit} disabled={isLoading || isEditLoading} />
						</div>
					</Grid>
				</Grid>
			)}
			<InactivityCheck />
			<SnackbarMsg msg={id ? 'Patient Updated Successfully' : 'Patient Created successfully'} handleClose={handleClose} open={openBar} width={'310px'} />
		</>
	)
}
