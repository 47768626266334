import React from "react";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import "survey-react/survey.css";
import { useLocation } from "react-router-dom";
// import { useGetSurveysByIDQuery } from "../store/suerveySlice";

export const ShowForm = () => {
  return <h1>Testing</h1>
  const location = useLocation();
  const id = location.pathname.split("/").reverse()[0];
  
  // const { data, isLoading, isSuccess, isError, error } =
  //   useGetSurveysByIDQuery(id);

  // let entityID, formSchema;
  // if (!isLoading && !isError) {
  //   entityID = data.ids[0];
  //   formSchema = data.entities[entityID].form;
  // }









  // console.log("formSchema", formSchema)
  // const handleComplete = (sender) => {
  //   console.log("=====================");
  //   const data = sender.data;
  //   fetch("process.env.REACT_APP_BASE_API_URLsurvey/survey-controller", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // survey.onComplete.add(handleComplete);









  // if (isLoading) return <p>"Loading..."</p>;
  // if (isError) return <p>{error}</p>;

  // if (isSuccess) {
  //   const survey = new Model(formSchema);
  //   survey.onComplete.add((sender, options) => {
  //     console.log(JSON.stringify(sender.data, null, 3));
  //   });
  //   return <Survey model={survey} />;
  // }
};
