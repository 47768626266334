import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssistants: builder.query({
      query: () => {
        return `/assistants/options`;
      }
    }),
    getAssistantById: builder.query({
      query: (id) => {
        return `/assistants/${id}`;
      }
    }),
    addNewAssistant: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/assistants",
          method: "POST",
          body: initialPost
        };
      },
      invalidatesTags: [{ type: "Assistant", id: "LIST" }],
    }),
    sendMessageByAssistantId: builder.mutation({
        query: (initialPost) => {
          return {
            url: "/assistants/assistant",
            method: "POST",
            body: initialPost
          };
        },
        invalidatesTags: [{ type: "Assistants", id: "LIST" }],
    }),
    editAssistants: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/assistants/${id}`,
          method: "PUT",
          body,
        };
      }
    }),
  }),
});

export const {
  useGetAssistantsQuery,
  useGetAssistantByIdQuery,
  useAddNewAssistantMutation,
  useSendMessageByAssistantIdMutation,
  useEditAssistantsMutation,
//   useDeletePhysicianMutation
} = extendedApiSlice;
