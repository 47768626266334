import axios from "axios";
import { getTokenFromLocalStroage } from "../../utils";

function extractMimeType(base64String) {
    const start = base64String.indexOf(':') + 1;
    const end = base64String.indexOf(';');
    return base64String.substring(start, end);
}

export default async function uploadSurveyLogo (base64String){
    const base64Content = base64String.split(',')[1];
  const binaryString = atob(base64Content);
  const uint8Array = new Uint8Array(binaryString.length);
  
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  const mimeType = extractMimeType(base64String);
  const blob = new Blob([uint8Array], { type: mimeType });
  const file = new File([blob], "filename.png", { type: mimeType });

  const body = new FormData();
  body.append("asset[]", file);

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API_URL}/v2/asset/upload`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${getTokenFromLocalStroage()}`,
      },
    }
  );

  return response?.data?.uploadedImages[0]?.imageUrl;
}