import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCallAgents: builder.query({
      query: () => `/call/agents`
    }),    
    getCallsByPatientID: builder.query({
      query: ({id,page, limit}) =>{
        const limit1 = limit ? limit : 10
        const finalQuery = { page, limit: limit1 };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/call/patient/${id}?${params.toString()}`}
    }),
    addNewCall: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/call",
          method: "POST",
          body: initialPost
        };
      },
      invalidatesTags: [{ type: "Note", id: "LIST" }],
    }),
    // editNote: builder.mutation({
    //   query: ({ id, body }) => {
    //     return {
    //       url: `/notes/${id}`,
    //       method: "PUT",
    //       body,
    //     };
    //   },
    //   invalidatesTags: ({ data }, error, { id }) => {
    //     return [
    //       { type: "Notes", id: "LIST" },
    //       { type: "Notes", id: data.id || id },
    //     ];
    //   },
    // }),
    // deleteNote: builder.mutation({
    //   query: (id) => {
    //     return {
    //       url: `/notes/${id}`,
    //       method: "DELETE",
    //     };
    //   },
    //   invalidatesTags: [{ type: "Notes", id: "LIST" }],
    // }),
  }),
});

export const {
  useGetCallAgentsQuery,
  useGetCallsByPatientIDQuery,
  useAddNewCallMutation,
//   useEditNoteMutation,
//   useDeleteNoteMutation,
} = extendedApiSlice;
