import React from 'react'
import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import { useSelector } from 'react-redux'
import { useAddNewSurveyMutation, useEditSurveyMutation } from '../../../store/suerveySlice'
import { useNavigate } from 'react-router-dom'
import { getCurrentSiteId, getTokenFromLocalStroage } from '../../../utils'
import { PATHS } from '../../../constants/routes'
import { useTimeField } from '@mui/x-date-pickers/TimeField/useTimeField'
import axios from 'axios'
import uploadSurveyLogo from '../HelperFunction'
import * as SurveyCore from 'survey-core'
import { nouislider } from 'surveyjs-widgets'
import 'nouislider/dist/nouislider.css'
nouislider(SurveyCore)

const defaultSchema = {
	title: 'nby user',
	logoPosition: 'right',
	pages: [
		{
			name: 'page1',
			elements: [
				{
					type: 'radiogroup',
					name: 'question1',
					choices: ['Item 1', 'Item 2', 'Item 3'],
				},
				{
					type: 'nouislider',
					name: 'range',
					title: 'Please range',
				},
			],
		},
	],
}

const creatorOptions = {
	showLogicTab: true,
	isAutoSave: false,
	haveCommercialLicense: true,
}

export function SurveyCreatorWidget() {
	const navigate = useNavigate()
	const [createPost, result] = useAddNewSurveyMutation()

	const { user } = useSelector((state) => state.user)

	const creator = new SurveyCreator(creatorOptions)
	creator.text = JSON.stringify(defaultSchema)
	creator.saveSurveyFunc = (saveNo, callback, createPost) => {
		callback(saveNo, true)
		saveSurveyJson(`${process.env.REACT_APP_BASE_API_URL}/surveys/`, creator.JSON, saveNo, callback, user)
	}

	async function saveSurveyJson(url, json, saveNo, callback) {
		let imageUrl = null

		if (json?.logo?.startsWith('data')) {
			imageUrl = await uploadSurveyLogo(json.logo)
			json.logo = imageUrl
		}

		const payload = {
			saveNo,
			form: json,
			createdBy: user?.email,
		}

		try {
			const response = await createPost(payload).unwrap()
			callback(saveNo, true)
			navigate(PATHS.formsIndexPage)
		} catch (e) {
			console.log(e)
			callback(saveNo, false)
		}
	}

	return <SurveyCreatorComponent creator={creator} />
}
