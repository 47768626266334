import React from "react";
import {
  Box,
  Typography,
  Modal,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { useIsMobile } from "../contexts/isMobile";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius:"10px"
};

export const CloneJourneyModal = ({ open, setOpen, handle, id }) => {
  const isMobile = useIsMobile();
  const [forms, setForms] = React.useState({
    title: "",
    description: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setForms((forms) => ({ ...forms, [e.target.name]: e.target.value }));
  };

  const handleClone = () => {
    if (forms.title && forms.description) {
      handle(id, forms);
      setOpen(false);
    }
    else {
      toast.error("Please fill the required fields");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign:"center"}}>
            Please provide name and description to clone this Journey
          </Typography>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ margin: "5px" }}>
              <Grid item md={12} xs={12}>
                <TextField
                  required
                  id="outlined-required"
                  label="Title"
                  placeholder="Enter Title"
                  name="title"
                  value={forms.title}
                  onChange={onChange}
                  sx={{ marginBottom: '1em', width: '33em' }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  required
                  multiline
                  rows={4}
                  id="outlined-required"
                  label="Description"
                  placeholder="Enter Description"
                  name="description"
                  value={forms.description}
                  onChange={onChange}
                  sx={{ width: '33em' }}
                />
              </Grid>
            </Box>
          </Box>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Box sx={{ margin: "5px" }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#816179",
                    padding: "1rem 2rem",
                    borderRadius: "0.5rem",
                    fontSize: "14px",
                    width: isMobile ? "100%" : "130px",
                    height: "35px",
                  }}
                  onClick={handleClone}
                >
                 Save
                </Button>
              </Box>
              <Box sx={{ margin: "5px" }}>
                <Button
                  size="small"
                  sx={{
                    color: "#816179",
                    border: "1px solid #816179",
                    padding: "1rem 2rem",
                    borderRadius: "0.5rem",
                    fontSize: "14px",
                    width: isMobile ? "100%" : "130px",
                    height: "35px",
                    ":hover": {
                      border: "1px solid black",
                      color: "black",
                    },
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
