import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  SolidButton,
  SeachField,
  TagsSelector,
  ViewDropdown,
  WeekDropdown,
} from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";

export function TableHeader({ view, week, setWeek, setView }) {
  const isMobile = useIsMobile();

  const gridMargin = {
    margin: isMobile && "10px 5px",
  };

  return (
    <>
      <Box sx={{ padding: isMobile ? "6px" : 3 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6} sx={gridMargin}>
            <Typography variant="h5">Patient Task</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container justifyContent="flex-end" alignItems="center">
              {/* <Grid item xs={12} sm={3} sx={gridMargin}>
                <SolidButton label="Create" onClick={()=>console.log("ok")} btnWidth="88%"/>
              </Grid>
              <Grid item xs={12} sm={5} sx={gridMargin}>
                <SeachField
                  {...{
                    searchTerm,
                    setSearchTerm,
                    options,
                    selectedOptions,
                    handleOptionSelect,
                  }}
                />
              </Grid> */}
              <Grid item xs={12} sm={3}>
                <Box sx={{ padding: !isMobile && "10px" }}>
                  <WeekDropdown {...{ week, setWeek }} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
