import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProcedures: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10, ...rest } = query;
        const finalQuery = { page, limit, ...rest };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/procedure?${params.toString()}`;
      }
    }),
    getProceduresOptions: builder.query({
      query: () => `/procedure/options`,
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Procedures", id: "LIST" },
          ...data.map((p) => ({ type: "Procedures", id: p._id })),
        ];
      },
    }),
    addNewProcedure: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/procedure",
          method: "POST",
          body: initialPost
        };
      },
      invalidatesTags: [{ type: "Procedure", id: "LIST" }],
    }),
    editProcedure: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/procedure/${id}`,
          method: "PUT",
          body,
        };
      }
    }),
    deleteProcedure: builder.mutation({
      query: (id) => {
        return {
          url: `/procedure/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetProceduresQuery,
  useGetProceduresOptionsQuery,
  useAddNewProcedureMutation,
  useEditProcedureMutation,
  useDeleteProcedureMutation
} = extendedApiSlice;
