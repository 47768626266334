import { Box, Chip, Grid, Paper, Typography, FormControlLabel, Switch, } from '@mui/material'
import { PieChart } from './PieChart'
import { PatientTable as Table } from '../Patient/PatientTable/PatientTable'
import { CircularLoader, MultiselectDropdown, SingleSelectDropdown } from '../../Components'
import { useState, useEffect } from 'react'
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded'
import { useGetProsFiltersQuery, useGetProsQuery } from '../../store/prosSlice'
import { ConsentDropdown } from './ConsentDropdown'
import PopoverUI from './PopoverUI'
import { format } from 'date-fns'
import { styled } from '@mui/material/styles'

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22,
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}))

export function ProsUI() {
	
	const pageName = 'pros'
	const [metric, setMetric] = useState('')
	const [key, setKey] = useState([])
	const [allMetric, setAllMetric] = useState(localStorage.getItem('allMetric') ? JSON.parse(localStorage.getItem('allMetric')) : [])
	const [selectedValue, setSelectedValue] = useState('pro')
	const [typeData, setTypeData] = useState([])
	const [keyData, setKeyData] = useState([])
	const [prosPage, setProsPage] = useState(localStorage.getItem('prosPage') ? parseInt(localStorage.getItem('prosPage')) : 1)
	const [queries, setQueries] = useState({})
	const [Consent, setConsent] = useState(null)
	const [consentData, setConsentData] = useState([])
	const [procedurePhase, setProcedurePhase] = useState(null)
	const [procedurePhaseData, setProcedurePhaseData] = useState([])
	const [proType, setProType] = useState(null)
	const [proTypeData, setProTypeData] = useState([])
	const [anchorEl, setAnchorEl] = useState(null)
	const [isOpen, setIsOpen] = useState(false)

	// For Popover
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	// For Filter By Date

	// Calculate today's date and the date two months ago
	const today = new Date()
	const twoMonthsAgo = new Date()
	twoMonthsAgo.setMonth(today.getMonth() - 2)

	const [startDate, setStartDate] = useState(twoMonthsAgo)
	const [endDate, setEndDate] = useState(today)

	//For Surgery Type Distribution
	const [surveyTypesDistribution, setSurgeryTypesDistribution] = useState([])
	const [formStatuses, setFormStatuses] = useState([])
	const [surgeriesStatuses, setSurgeriesStatuses] = useState([])

	const { data: filterData } = useGetProsFiltersQuery({proOnly: isOpen})
	const { data: prosData, isLoading, isError, isSuccess, refetch } = useGetProsQuery({ page: prosPage, proOnly: isOpen,  ...queries })

	const handleDelete = (name) => {
		const singleFields = ['Sms Consent', 'Pro Status', 'Procedure Phase']
		if (key.includes(name?.key)) {
			const filteredArray = key?.filter((item) => item !== name?.key)
			setKey(filteredArray)

			if(filteredArray.length === 0){
				setMetric('')
			}
		}

		const newArray = allMetric.filter((item) => item?.metric !== name?.metric || item?.key !== name?.key)
		setAllMetric(newArray)
		localStorage.setItem('allMetric', JSON.stringify(newArray));

		if (name?.metric === 'Pro Assigned Date' || name?.metric === 'Procedure Date') {
			setSelectedValue('pro')
			setStartDate(twoMonthsAgo)
			setEndDate(today)
		}

		if(singleFields.includes(metric) && name?.metric === metric){
			if(metric === 'Sms Consent'){
				setMetric('')
				setConsent(null);
			}else if(metric === 'Pro Status'){
				setMetric('')
				setProType(null);
			}else if(metric === 'Procedure Phase'){
				setMetric('')
				setProcedurePhase(null);
			}
		}
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleChangeValue = (event, newAlignment) => {
		setSelectedValue(newAlignment)
	}

	const refetchProsAgain = () => {
		refetch()
	}

	function formatDate(date) {
		const year = date.getFullYear()
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const day = String(date.getDate()).padStart(2, '0')
		return `${year}-${month}-${day}`
	}

	function generateColors(count) {
		const colors = []
		for (let i = 0; i < count; i++) {
			const color = `hsl(${Math.floor((360 / count) * i)}, 70%, 50%)`
			colors.push(color)
		}
		return colors
	}

	function isArrayEmpty(array) {
		return Array.isArray(array) && array.length === 0
	}

	function isObjectEmpty(obj) {
		return obj !== null && typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length === 0
	}

	function handleApplyChanges() {
		if (startDate && endDate && selectedValue === 'pro') {
			if (allMetric?.some((obj) => obj.metric === 'Pro Assigned Date')) {
				const newArray = allMetric.filter((item) => item?.metric !== 'Pro Assigned Date')
				const newArray2 = [
					...newArray,
					{
						metric: 'Pro Assigned Date',
						key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						startDate: formatDate(startDate),
						endDate: formatDate(endDate),
						values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						query: 'pro',
					},
				]
				setAllMetric(newArray2)
				setAnchorEl(null)
				localStorage.setItem('allMetric', JSON.stringify(newArray2));
			} else {
				const newArray = [
					...allMetric,
					{
						metric: 'Pro Assigned Date',
						key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						startDate: formatDate(startDate),
						endDate: formatDate(endDate),
						values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						query: 'pro',
					},
				]
				setAllMetric(newArray)
				setAnchorEl(null)
				localStorage.setItem('allMetric', JSON.stringify(newArray));
			}
		}

		if (startDate && endDate && selectedValue === 'procedure') {
			if (allMetric?.some((obj) => obj.metric === 'Procedure Date')) {
				const newArray = allMetric.filter((item) => item?.metric !== 'Procedure Date')
				const newArray2 = [
					...newArray,
					{
						metric: 'Procedure Date',
						key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						startDate: formatDate(startDate),
						endDate: formatDate(endDate),
						values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						query: 'procedure',
					},
				]
				setAllMetric(newArray2)
				setAnchorEl(null)
				localStorage.setItem('allMetric', JSON.stringify(newArray2));
			} else {
				const newArray = [
					...allMetric,
					{
						metric: 'Procedure Date',
						key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						startDate: formatDate(startDate),
						endDate: formatDate(endDate),
						values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						query: 'procedure',
					},
				]
				setAllMetric(newArray)
				setAnchorEl(null)
				localStorage.setItem('allMetric', JSON.stringify(newArray));
			}
		}

		if (startDate && endDate && selectedValue === 'dueDate') {
			if (allMetric?.some((obj) => obj.metric === 'PRO Due Date')) {
				const newArray = allMetric.filter((item) => item?.metric !== 'PRO Due Date')
				const newArray2 = [
					...newArray,
					{
						metric: 'PRO Due Date',
						key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						startDate: formatDate(startDate),
						endDate: formatDate(endDate),
						values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						query: 'dueDate',
					},
				]
				setAllMetric(newArray2)
				setAnchorEl(null)
				localStorage.setItem('allMetric', JSON.stringify(newArray2));
			} else {
				const newArray = [
					...allMetric,
					{
						metric: 'PRO Due Date',
						key: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						startDate: formatDate(startDate),
						endDate: formatDate(endDate),
						values: `${formatDate(startDate)} to ${formatDate(endDate)}`,
						query: 'dueDate',
					},
				]
				setAllMetric(newArray)
				setAnchorEl(null)
				localStorage.setItem('allMetric', JSON.stringify(newArray));
			}
		}
	}

	function handleProOnly(open){
		setKey([])
		setMetric('')
		setConsent(null)
		setProcedurePhase(null)
		setProType(null)
		setIsOpen(open);
	}

	useEffect(() => {
		if (filterData?.data) {
			const keys = Object.keys(filterData?.data).filter((key) => {
				const value = filterData?.data[key]
				return !(isArrayEmpty(value) || isObjectEmpty(value))
			})

			if (!isObjectEmpty(filterData?.data?.sms_consent)) {
				const consentDataOptions = Object.keys(filterData?.data?.sms_consent).map((key) => ({
					key: key,
					value: filterData?.data?.sms_consent[key],
				}))

				setConsentData(consentDataOptions)
			}

			if (!isObjectEmpty(filterData?.data?.procedure_phase)) {
				const procedurePhaseDataOptions = Object.keys(filterData?.data?.procedure_phase).map((key) => ({
					key: key,
					value: filterData?.data?.procedure_phase[key],
				}))

				setProcedurePhaseData(procedurePhaseDataOptions)
			}

			if (!isObjectEmpty(filterData?.data?.pro_status)) {
				const proTypeDataOptions = Object.keys(filterData?.data?.pro_status).map((key) => ({
					key: key,
					value: filterData?.data?.pro_status[key],
				}))

				setProTypeData(proTypeDataOptions)
			}

			setTypeData(keys)
		}
	}, [filterData])

	useEffect(() => {
		if (prosData?.data?.metrics?.formStatuses) {
			setFormStatuses(prosData?.data?.metrics?.formStatuses)
		}

		if (prosData?.data?.metrics?.surgeriesStatuses) {
			setSurgeriesStatuses(prosData?.data?.metrics?.surgeriesStatuses)
		}

		if (prosData?.data?.metrics?.distributionStatuses?.surveyTypesDistribution) {
			setSurgeryTypesDistribution(prosData?.data?.metrics?.distributionStatuses?.surveyTypesDistribution)
		}
	}, [prosData])

	useEffect(() => {
		const newQueries = allMetric.reduce((acc, item) => {
			if (item.query === 'pro' || item.query === 'procedure' || item.query === 'smsConsent' || item.query === 'procedurePhase' || item.query === 'proStatus' || item.query === "dueDate") {
				if (item.metric === 'Pro Assigned Date') {
					acc.proStartDate = item?.startDate
					acc.proEndDate = item?.endDate
				} else if (item.metric === 'Procedure Date') {
					acc.procedureStartDate = item?.startDate
					acc.procedureEndDate = item?.endDate
				} else if (item.metric === 'PRO Due Date') {
					acc.proStartDueDate = item?.startDate
					acc.proEndDueDate = item?.endDate
				} else if (item.metric === 'Sms Consent') {
					acc.smsConsent = item?.values
				} else if (item.metric === 'Procedure Phase' || item.metric === 'Procedure Status') {
					acc.procedurePhase = item?.values
				} else if (item.metric === 'PRO Status' || item.metric === 'Pro Status') {
					acc.proStatus = item?.values
				}
			} else {
				if (!acc[item.query]) {
					acc[item.query] = []
				}
				acc[item.query].push(item.values)
			}

			return acc
		}, {})

		// Update the queries state or pass newQueries where needed
		setQueries(newQueries)
		localStorage.setItem('prosPage', 1)
		setProsPage(1)
	}, [allMetric])

	if (isLoading) return <CircularLoader />
	const surveyTypesColors = generateColors(Object.keys(surveyTypesDistribution).length)
	return (
		<>
			<Paper
				sx={{
					border: '1px',
					width: '95%',
					borderRadius: '10px',
					// marginTop: '20px',
					margin: '45px'
				}}
			>
				<Typography sx={{ fontSize: '20px', paddingX: '20px', paddingY: '10px' }}>
					<b>Patient Reported Outcome (PRO) Insights</b>
				</Typography>
				<Grid container padding={'20px'}>
					<Grid item xs={4}>
						<PieChart
							{...{
								heading: 'PRO Types',
								query: 'proTypes',
								setAllMetric,
								allMetric,
								labels: surveyTypesDistribution?.map((item) => item.label),
								colors: surveyTypesColors,
								formData: surveyTypesDistribution?.map((item) => item.count),
								backendData: surveyTypesDistribution,
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<PieChart
							{...{
								heading: 'PRO Status',
								query: 'proStatus',
								setAllMetric,
								allMetric,
								labels: formStatuses?.map((item) => item.label),
								colors: ['#3CB371', '#DAA520', '#17a2b8'],
								formData: formStatuses?.map((item) => item.count),
								backendData: formStatuses,
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<PieChart
							{...{
								heading: 'Procedure Status',
								query: 'procedurePhase',
								setAllMetric,
								allMetric,
								labels: surgeriesStatuses?.map((item) => item?.label),
								colors: ['#3CB371', '#DAA520', '#F08080'],
								formData: surgeriesStatuses?.map((item) => item?.count),
								backendData: surgeriesStatuses,
							}}
						/>
					</Grid>
				</Grid>
			</Paper>

			<Paper
				sx={{
					border: '1px ',
					width: '95%',
					borderRadius: '10px',
					// marginTop: '20px',
					padding: '20px',
					margin:'45px'
				}}
			>
				<Typography sx={{ fontSize: '20px' }}>
					<b>Search Filter</b>
				</Typography>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box sx={{ display: 'flex' }}>
						<SingleSelectDropdown
							{...{ data: metric, setData: setMetric, allMetric, placeholder: 'Type', options: typeData, filterData: filterData?.data, setKeyData, setKey, setConsent, setProcedurePhase, setProType}} />
						{metric !== '' && metric !== 'Sms Consent' && metric !== 'Procedure Phase' && metric !== 'Pro Status' && (
							<MultiselectDropdown {...{ data: key, setData: setKey, placeholder: 'Value', metric, setAllMetric, allMetric, keyData }} />
						)}

						{metric !== '' && metric === 'Sms Consent' && (
							<ConsentDropdown {...{ data: Consent, setData: setConsent, allMetric, placeholder: 'Value', options: consentData, metric, setAllMetric }} />
						)}

						{metric !== '' && metric === 'Procedure Phase' && (
							<ConsentDropdown
								{...{ data: procedurePhase, setData: setProcedurePhase, allMetric, placeholder: 'Value', options: procedurePhaseData, metric, setAllMetric }}
							/>
						)}

						{metric !== '' && metric === 'Pro Status' && (
							<ConsentDropdown {...{ data: proType, setData: setProType, allMetric, placeholder: 'Value', options: proTypeData, metric, setAllMetric }} />
						)}
					</Box>

					<Box sx={{ padding: '10px', cursor: 'pointer', display:'flex', alignItems:'center' }}>
						<FormControlLabel
							control={<IOSSwitch sx={{ m: 1 }} checked={isOpen} onChange={()=> handleProOnly(!isOpen)} />}
							label='PROs Only'
						/>
						<DateRangeRoundedIcon sx={{marginRight:'5px'}} onClick={handleClick} />
					</Box>
				</Box>

				<Box sx={{ marginLeft: '8px', marginBottom: '5px' }}>
					{allMetric.length !== 0 && (
						<Typography
							fontSize={14}
							fontWeight={600}
							sx={{
								borderBottom: '1px solid red',
								display: 'initial',
								color: 'red',
								borderRadius: 0,
								cursor: 'pointer',
							}}
							onClick={() => {
								setAllMetric([])
								setKey([])
								setMetric('')
								setQueries({})
								setSelectedValue('pro')
								setStartDate(twoMonthsAgo)
								setEndDate(today)
								setConsent(null)
								setProcedurePhase(null)
								setProType(null)
								localStorage.removeItem('allMetric');
								localStorage.removeItem('prosPage');
							}}
						>
							Clear Filters
						</Typography>
					)}
				</Box>

				{allMetric.map((item, index) => (
					<Chip
						key={index}
						label={
							<>
								<b>{item?.metric}</b>: {item?.key}
							</>
						}
						onDelete={() => handleDelete(item)}
						sx={{ marginLeft: '5px', marginTop: '5px' }}
					/>
				))}
			</Paper>

			<Box sx={{
					width: '95%',
					margin:'45px'
				}}>
				<Table
					{...{
						page: prosPage,
						setPage: setProsPage,
						data: prosData?.data,
						isLoading,
						isError,
						isSuccess,
						refetchPatientAgain: refetchProsAgain,
						pageName,
					}}
				/>
			</Box>
			<PopoverUI {...{ id, open, anchorEl, handleClose, selectedValue, handleChangeValue, startDate, setStartDate, endDate, setEndDate, handleApplyChanges }} />
		</>
	)
}
