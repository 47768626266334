export const careProgramsData = [
  {
    id: "1",
    label: "Total Knee Replacement",
    value: "total_knee_replacement",
  },
  { id: "2", label: "Spine", value: "spine" },
  {
    id: "3",
    label: "Total Shoulder Replacement",
    value: "total_shoulder_replacement",
  },
  {
    id: "4",
    label: "Distal Radius Fracture Care Journey",
    value: "distal_radius_fracture",
  },
  {
    id: "5",
    label: "Total Hip Arthroplasty",
    value: "total_hip_replacement_pathway",
  },
  {
    id: "6",
    label: "Reverse Total Shoulder Arthroplasty",
    value: "reverse_total_shoulder_artroplasty_pathway",
  },
  {
    id: "7",
    label: "Bone Health and Fall Prevention",
    value: "bone_health_and_fall_prevention",
  },
  {
    id: "8",
    label: "Fracture Care Program",
    value: "fracture_care",
  },
  {
    id: "9",
    label: "Joint Health and Osteoarthritis",
    value: "joint_health_osteoarthritis",
  },
  {
    id: "10",
    label: "Spine Health",
    value: "spine_health",
  },
  { id: "11", label: "Sports Health", value: "sports_health" },
  {
    id: "12",
    label: "Total Joint Replacement",
    value: "total_joint_replacement",
  },
  {
    id: "13",
    label: "Hand, Wrist and Elbow",
    value: "hand_wrist_elbow",
  },
  {
    id: "14",
    label: "Obstetrics and Gynecology",
    value: "obstetrics_and_gynecology",
  },
  {
    id: "15",
    label: "Co-morbidities and Health Resources",
    value: "co-morbidities_and_health_resources",
  },
  {
    id: "16",
    label: "General Orthopaedic Care",
    value: "general_orthopaedic_care",
  },
  {
    id: "17",
    label: "EquityStream: Social Drivers and Health Related Social Needs",
    value: "social_drivers_and_health_related_social_needs",
  },
  {
    id: "18",
    label: "Diabetes",
    value: "diabetes_care",
  }
];
