import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetUser, setUser } from "./../store/userSlice";
import { setTokenInLocalStroage, getTokenFromLocalStroage, clearLocalStroage } from "../utils";
import { removePersistedData } from "../store";
const AuthenticatedUserContext = createContext();

const getMetaData = async (token, email) => {
  // http://localhost:5000/users/email
  const response = await fetch(process.env.REACT_APP_BASE_API_URL + "/users/email", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      email,
    }),
  });


  return await response.json();
};

export function IsAuthenticatedUserProvider({ children }) {
  const { isAuthenticated, getIdTokenClaims, user, logout } = useAuth0();
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  async function RedirectToLogin() {
    dispatch(resetUser());
    clearLocalStroage();
    await removePersistedData(); // Delete persisted data
    logout()
  }

  useEffect(() => {
    const CheckTokenExpiry = async () => {
      setInterval(() => {
        var IDTokenExpiry = localStorage.getItem("IDTokenExpiryTimeStamp");
        const dateTimeNow = Math.floor(Date.now() / 1000);

        if (IDTokenExpiry < dateTimeNow && dateTimeNow) {
          RedirectToLogin()
        }
      }, 5000)
    }

    CheckTokenExpiry()
  }, [])


  useEffect(() => {
    setLoading(true);
    const token = getTokenFromLocalStroage();
    const fetchData = async () => {
      const claim = await getIdTokenClaims();
      if (isAuthenticated) {
        setIsAuthenticatedUser(isAuthenticated);
        if (isAuthenticated) {
          try {
            setTokenInLocalStroage(claim.__raw);
            const data = await getMetaData(claim.__raw, claim.email);
            console.log(data)
            dispatch(
              setUser({
                email: claim.email,
                token: claim.__raw,
                role: data?.data?.role || 2615,
                id: data?.data?.id,
                hospital: data?.hospitalId,
                scopes: data?.scopes
              })
            );
            localStorage.setItem("IDTokenExpiryTimeStamp", claim.exp)
            setLoading(false);
          } catch (error) {
            // Handle API fetch errors here
            setLoading(false);
            console.error('API Fetch Error:', error);
          }
        } else {
          setLoading(false);
        }
      } else {
        return null
      }
    };

    fetchData();
  }, [isAuthenticated]);

  return (
    <AuthenticatedUserContext.Provider value={{ isAuthenticatedUser, user, loading }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
}



export function useIsAuthenticatedUser() {
  return useContext(AuthenticatedUserContext);
}
