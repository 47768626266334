import React from "react";
import { Survey } from "survey-react-ui";
import * as Sur from "survey-react"
import { Model } from "survey-core";
import "survey-react/survey.css";
import { useLocation, useParams } from "react-router-dom";
import { useGetSurveysByIDQuery } from "../store/suerveySlice";
import { useGetPatientsSurveyQuery, useGetPatientsSurveyResultByIDQuery, useUpdatePatientsSurveyMutation } from "../store/patientsSurveySlice";
import {CircularLoader} from "../Components/Loader"
import {InactivityCheck} from "../Components/InactivityCheck"

const ViewSolveSurvey = () => {
  const location = useLocation();
  const {sid,id} = useParams();

  const { data, isLoading, isSuccess, isError, error } =
    useGetSurveysByIDQuery(sid);
  const [updatePatientsSurvey]=useUpdatePatientsSurveyMutation();
  const {data:results,isSuccess:sucess}=useGetPatientsSurveyResultByIDQuery(id);
  
  let entityID, formSchema;
  if (!isLoading && !isError) {
    formSchema = data.form.form;
  }

  console.log("formSchema", formSchema)
  // const handleComplete = (sender) => {
  //   console.log("=====================");
  //   const data = sender.data;
  //   fetch("process.env.REACT_APP_BASE_API_URLsurvey/survey-controller", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // survey.onComplete.add(handleComplete);

  if (isLoading) return <p><CircularLoader/></p>;
  if (isError) return <p>{error}</p>;

  if (isSuccess) {
    const survey = new Model(formSchema);
    survey.onComplete.add((sender, options) => {
      console.log(JSON.stringify(sender.data,options,1000, null, 3));
    });
  survey.mode="display";
 
    const handleComplete = (sender,options) => {
      console.log(sender)
      console.log("=====================");
      const data = sender.data;
      updatePatientsSurvey({formResult:sender.data,id:id})
    
        .then((result) => {
        
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });
    };
  
  
    return sucess?
    <>
<Sur.Survey model={survey} data={results.data.formResult} onComplete={handleComplete}/>
      <InactivityCheck />
    </>
    :
    <h1>Loading</h1>;
  }
};

export default ViewSolveSurvey;
