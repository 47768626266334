import React, { useState } from 'react'
import { TextField, Button, IconButton, Box, Grid } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { SolidButton } from '../../../Components'
import { toast } from 'react-toastify'

function EmailNotificationUI({emails, setEmails}) {

	const handleAddMore = () => {
		// Check if all existing emails are filled
		const allEmailsFilled = emails.every((email) => email.email.trim() !== '' && !email.error)

		if (allEmailsFilled) {
			// Add a new email field if all existing ones are filled
			setEmails([...emails, { id: emails.length + 1, email: '', error: false }])
		} else {
			// Optionally, you can show a message or highlight the empty fields
			toast.warning('Please fill out all email fields before adding a new one.')
		}
	}

	const handleDelete = (id) => {
		setEmails(emails?.filter((email) => email.id !== id))
	}

	const handleEmailChange = (id, value) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // Simple regex for email validation
		const updatedEmails = emails?.map((email) => (email.id === id ? { ...email, email: value, error: !emailRegex.test(value) } : email))
		setEmails(updatedEmails)
	}

	return (
		<Box>
			{emails?.map((email, index) => (
				<Grid container spacing={2} alignItems="center" key={email.id} sx={{ mt: 1 }}>
					<Grid item xs={8}>
						<TextField
							fullWidth
							label={`Email ${index + 1}`}
							value={email.email}
							onChange={(e) => handleEmailChange(email.id, e.target.value)}
							error={email.error}
							helperText={email.error ? 'Invalid Email Format' : ''}
						/>
					</Grid>
					<Grid item>
						<IconButton
							color="secondary"
							onClick={() => handleDelete(email.id)}
							disabled={emails.length === 1} // Disable delete if only one email field
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
					{index === emails.length - 1 && (
						<Grid item>
							<SolidButton label={'Add More'} onClick={handleAddMore} />
						</Grid>
					)}
				</Grid>
			))}
		</Box>
	)
}

export default EmailNotificationUI