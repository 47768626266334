export const patientQueueFilterData = {
	acuity: {
		value: [
			{ id: 1, title: 'Low', value: 'low' },
			{ id: 2, title: 'High', value: 'high' },
			{ id: 3, title: 'Medium', value: 'medium' },
			{ id: 4, title: 'N/A', value: 'N/A' },
		],
	},
	taskCategory: {
		value: [
			{
				id: 1,
				title: 'Medication Refill Request',
				value: 'Medication Refill Request',
			},
			{
				id: 2,
				title: 'Administrative Paperwork',
				value: 'Administrative Paperwork',
			},
			{
				id: 3,
				title: 'Appointment Request',
				value: 'Appointment Request',
			},
			{
				id: 4,
				title: 'Mental & Emotional Support',
				value: 'Mental & Emotional Support',
			},
			{
				id: 5,
				title: 'Clinical Monitoring & Guidance',
				value: 'Clinical Monitoring & Guidance',
			},
			{
				id: 6,
				title: 'Rehabilitation & Physical Therapy Coordination',
				value: 'Rehabilitation & Physical Therapy Coordination',
			},
			{
				id: 7,
				title: 'Urgent/Emergent Clinician Review',
				value: 'Urgent/Emergent Clinician Review',
			},
			{
				id: 8,
				title: 'Case Management, Community & Social Needs',
				value: 'Case Management, Community & Social Needs',
			},
			{
				id: 9,
				title: 'Medication Guidance & Refills',
				value: 'Medication Guidance & Refills',
			},
			{
				id: 10,
				title: 'Appointment Scheduling',
				value: 'Appointment Scheduling',
			},
			{
				id: 11,
				title: 'Post-Operative Concern',
				value: 'Post-Operative Concern',
			},
			{
				id: 12,
				title: "Worker's Compensation & Med-Legal Support",
				value: "Worker's Compensation & Med-Legal Support",
			},
			{
				id: 13,
				title: 'Authorizations & Insurance Coordination',
				value: 'Authorizations & Insurance Coordination',
			},
			{
				id: 14,
				title: 'Surgery Coordination & Authorization',
				value: 'Surgery Coordination & Authorization',
			},
			{
				id: 15,
				title: 'Patient Complaints & Escalation Management',
				value: 'Patient Complaints & Escalation Management',
			},
			{
				id: 16,
				title: 'Medical Records & Documentation Requests',
				value: 'Medical Records & Documentation Requests',
			},
			{
				id: 17,
				title: 'Care Ally Communication',
				value: 'Care Ally Communication',
			},
			{
				id: 18,
				title: 'Wound Care & Concerns',
				value: 'Wound Care & Concerns',
			},
			{
				id: 19,
				title: 'New Task Assignment',
				value: 'New Task Assignment',
			},
			{
				id: 20,
				title: 'New Media Upload',
				value: 'New Media Upload',
			},
			{
				id: 21,
				title: 'New Form Submission',
				value: 'New Form Submission',
			},
			{
				id: 22,
				title: 'New Care Ally Message',
				value: 'New Care Ally Message',
			},
		],
	},
	status: {
		value: [
			{ id: 1, title: 'Pending', value: 'pending' },
			{ id: 2, title: 'Resolved', value: 'resolved' },
		],
	},
	program: {
		value: [
			{
				id: '1',
				title: 'Total Knee Replacement',
				value: 'total_knee_replacement',
			},
			{ id: '2', title: 'Spine', value: 'spine' },
			{
				id: '3',
				title: 'Total Shoulder Replacement',
				value: 'total_shoulder_replacement',
			},
			{
				id: '4',
				title: 'Distal Radius Fracture Care Journey',
				value: 'distal_radius_fracture',
			},
			{
				id: '5',
				title: 'Total Hip Arthroplasty',
				value: 'total_hip_replacement_pathway',
			},
			{
				id: '6',
				title: 'Reverse Total Shoulder Arthroplasty',
				value: 'reverse_total_shoulder_artroplasty_pathway',
			},
			{
				id: '7',
				title: 'Bone Health and Fall Prevention',
				value: 'bone_health_and_fall_prevention',
			},
			{
				id: '8',
				title: 'Fracture Care Program',
				value: 'fracture_care',
			},
			{
				id: '9',
				title: 'Joint Health and Osteoarthritis',
				value: 'joint_health_osteoarthritis',
			},
			{
				id: '10',
				title: 'Spine Health',
				value: 'spine_health',
			},
			{ id: '11', title: 'Sports Health', value: 'sports_health' },
			{
				id: '12',
				title: 'Total Joint Replacement',
				value: 'total_joint_replacement',
			},
			{
				id: '13',
				title: 'Hand, Wrist and Elbow',
				value: 'hand_wrist_elbow',
			},
			{
				id: '14',
				title: 'Obstetrics and Gynecology',
				value: 'obstetrics_and_gynecology',
			},
			{
				id: '15',
				title: 'Co-morbidities and Health Resources',
				value: 'co-morbidities_and_health_resources',
			},
			{
				id: '16',
				title: 'General Orthopaedic Care',
				value: 'general_orthopaedic_care',
			},
			{
				id: '17',
				title: 'EquityStream: Social Drivers and Health Related Social Needs',
				value: 'social_drivers_and_health_related_social_needs',
			},
			{
				id: '18',
				title: 'Diabetes',
				value: 'diabetes_care',
			},
		],
	},
}

export const patientProfileFilterData = {
	program: {
		value: [
			{
				id: '1',
				title: 'Total Knee Replacement',
				value: 'total_knee_replacement',
			},
			{ id: '2', title: 'Spine', value: 'spine' },
			{
				id: '3',
				title: 'Total Shoulder Replacement',
				value: 'total_shoulder_replacement',
			},
			{
				id: '4',
				title: 'Distal Radius Fracture Care Journey',
				value: 'distal_radius_fracture',
			},
			{
				id: '5',
				title: 'Total Hip Arthroplasty',
				value: 'total_hip_replacement_pathway',
			},
			{
				id: '6',
				title: 'Reverse Total Shoulder Arthroplasty',
				value: 'reverse_total_shoulder_artroplasty_pathway',
			},
			{
				id: '7',
				title: 'Bone Health and Fall Prevention',
				value: 'bone_health_and_fall_prevention',
			},
			{
				id: '8',
				title: 'Fracture Care Program',
				value: 'fracture_care',
			},
			{
				id: '9',
				title: 'Joint Health and Osteoarthritis',
				value: 'joint_health_osteoarthritis',
			},
			{
				id: '10',
				title: 'Spine Health',
				value: 'spine_health',
			},
			{ id: '11', title: 'Sports Health', value: 'sports_health' },
			{
				id: '12',
				title: 'Total Joint Replacement',
				value: 'total_joint_replacement',
			},
			{
				id: '13',
				title: 'Hand, Wrist and Elbow',
				value: 'hand_wrist_elbow',
			},
			{
				id: '14',
				title: 'Obstetrics and Gynecology',
				value: 'obstetrics_and_gynecology',
			},
			{
				id: '15',
				title: 'Co-morbidities and Health Resources',
				value: 'co-morbidities_and_health_resources',
			},
			{
				id: '16',
				title: 'General Orthopaedic Care',
				value: 'general_orthopaedic_care',
			},
			{
				id: '17',
				title: 'EquityStream: Social Drivers and Health Related Social Needs',
				value: 'social_drivers_and_health_related_social_needs',
			},
			{
				id: '18',
				title: 'Diabetes',
				value: 'diabetes_care',
			},
		],
	},
	patientStatus: {
		value: [
			{
				id: 1,
				title: 'Active Patient',
				value: 'active',
			},
			{ id: 2, title: 'Deactivated Patient', value: 'inactive' },
		],
	},
	consentApproved: {
		value: [
			{
				id: 1,
				title: 'Yes',
				value: 'yes',
			},
			{
				id: 2,
				title: 'No',
				value: 'no',
			},
			{
				id: 3,
				title: 'No Response',
				value: 'NO_RESPONSE',
			},
		],
	},
}
