import React, { useState } from "react";
import { Paper } from "@mui/material";
import { TaskTable } from "./TaskTable";
import { TableHeader } from "./TableHeader";
import { useGetTasksQuery } from "../../../store/tasksSlice";
import { CircularLoader, InactivityCheck } from "../../../Components";
import { useDebouncedValue } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useIsMobile } from "../../../contexts/isMobile";

export function TasksTable() {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const [selectedFields, setSelectedFields] = useState([]);
  const isMobile = useIsMobile();
  const { user } = useSelector((state) => state.user);

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};
  const searchAbleFields = ["title", "description"];
  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }

  const { isLoading, isSuccess, isError, error, data } = useGetTasksQuery({
    userID: user.id,
    page,
    ...queries,
  });

  if (isLoading) return <CircularLoader />;
  if (isError) return <div>{error}</div>;

  if (isSuccess) {
    return (
      <Paper
        sx={{
          width: "100%",
          boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
          border: "1px solid #D0EAE1",
          padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem",
        }}
        elevation={0}
      >
        <TableHeader
          {...{
            searchTerm,
            setSearchTerm,
            options: searchAbleFields,
            selectedOptions: selectedFields,
            handleOptionSelect: handleFieldSelect,
          }}
        />
        <TaskTable data={data.data} {...{ page, setPage }} />
        <InactivityCheck />
      </Paper>
    );
  }

  return <h1>Something went wrong!</h1>;
}
