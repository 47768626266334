import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Box,
  TableRow,
  Typography,
  Paper,
  Chip,
  Tooltip,
} from "@mui/material";
import { CircularLoader, PaginationBar } from "./../../../Components";
import { useGetTagsQuery } from "../../../store/tagsSlice";
import { useSelector } from "react-redux";
import CareProgram from "../../../Components/TableFields/CareProgram";
import { PatientInfo } from "../../../Components/TableFields/PatientInfo";
import { ActionCell } from "./ActionCell";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import ProsProgram from "../../ProsUI/ProsProgram";
import { useState } from "react";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import RightDrawer from "../../SocialNeedUI/RightDrawer";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import ShieldIcon from "@mui/icons-material/Shield";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import PaymentsIcon from "@mui/icons-material/Payments";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import BlockIcon from '@mui/icons-material/Block'

const columns = [
  { id: "patientInfo", label: "Patient Information", minWidth: 250 },
  // {
  //   id: "cptCode",
  //   label: "CPT Code",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "surgeryDate",
    label: "Procedure Date",
    minWidth: 170,
    align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "surgeryDescription",
    label: "Procedure Description",
    minWidth: 170,
    align: "right",
  },

  {
    id: "careProgram",
    label: "Care Program",
    minWidth: 10,
    align: "right",
  },
  {
    id: "consent",
    label: "SMS Consent",
  },
  {
    id: "status",
    label: "Patient Status",
  },
  {
    id: "action",
    label: "Action",
  },
];

const prosColumns = [
  { id: "patientInfo", label: "Patient Information", minWidth: 250 },
  {
    id: "surgeryDate",
    label: "Procedure Type / Procedure Date",
    minWidth: 170,
    align: "right",
  },
  { id: "physicianName", label: "Physician Name", minWidth: 200 },
  { id: "prosDue", label: "PROs Due", minWidth: 200 },
  {id: "riskCategory", label: "Risk Category", minWidth: 100},
  {
    id: "action",
    label: "Action",
  },
];

const socialColumns = [
  { id: "patientInfo", label: "Patient Information", minWidth: 250 },
  {id: 'socialNeeds', label:'Social Needs', minWidth: 170},
  // {id: 'adi', label:'ADI', minWidth: 100},
  {id: 'socialConsent', label:'Social Care Consent', minWidth: 100},
  {id: 'referralDate', label:'Date of Referral', minWidth: 170},
  // {id: 'assignedCHW', label:'Assigned CHW', minWidth: 170},
  {id: 'loopStatus', label:'All Needs Met', minWidth: 170},
  {id: 'referralStatus', label:'Referral Status', minWidth: 170},
  // {id: 'notes', label:'CHI Notes', minWidth: 170},
  {
    id: "action",
    label: "Action",
  },
];

const socialCareColumns = [
  { id: "patientInfo", label: "Patient Information", minWidth: 250 },
  {id: 'socialConsent', label:'SMS Consent', minWidth: 100},
  {id: 'contactAttempts', label:'No of Contact Attempts', minWidth: 80},
  {id: 'contactMethod', label:'Contact Method', minWidth: 150},
  {id: 'zip', label:'Zip Code', minWidth: 100},
  {id: 'ADI', label:'ADI', minWidth: 100},
  {id: 'socialNeeds', label:'Social Needs', minWidth: 170},
  {id: 'assignedTo', label:'Assigned To', minWidth: 170},
  {id: 'referringPhysician', label:'Referring Physician', minWidth: 170},
  // {
  //   id: "action",
  //   label: "Action",
  // },
]

const TagBadge = ({ text, color }) => {
  return (
    <Chip
      sx={{
        color: "#FFFFFF",
        backgroundColor: color ? `#${color.hex}` : "red",
      }}
      size="medium"
      label={text}
    />
  );
};

const MakeTags = (tagIDs) => {
  if (tagIDs?.length === 0) {
    return <p>No Tag Assigned</p>;
  }

  return tagIDs?.map((tagID, i) => {
    const tagText = tagID.tag;
    const color = tagID.color;
    return <TagBadge key={i} text={tagText} color={color} />;
  });
};

const getConsentColors = (consent) => {
  if (consent === "yes") {
    return {
      marginRight: "5px",
      color: "#63A02C",
    };
  } else if (consent === "no") {
    return {
      marginRight: "5px",
      color: "#DC362E",
    };
  } else {
    return {
      marginRight: "5px",
      color: "#FFB400",
    };
  }
};
const getStatusColors = (status) => {
  if (status === "active") {
    return {
      marginRight: "5px",
      color: "#63A02C",
    };
  } else if (status === "inactive") {
    return {
      marginRight: "5px",
      color: "#DC362E",
    };
  } else {
    return {
      marginRight: "5px",
      color: "#FFB400",
    };
  }
};

const getColorStyles = (risk) => {
  if (risk === "High Risk" || risk === "High risk") {
    return {
      marginRight: "5px",
      color: "#DC362E",
    };
  } else if (risk === "Moderate Risk" || risk === "Rising Risk" || risk === "Rising risk") {
    return {
      marginRight: "5px",
      color: "#FFB400",
    };
  }  else if (risk === "Low Risk" || risk === "Healthy") {
    return {
      marginRight: "5px",
      color: "#63A02C",
    };
  } else {
    return {
      marginRight: "5px",
      color: "black",
    };
  }
};

const calculateAge = (dob) => {
  if (dob) {
    // Check if the date is in ISO 8601 format
    let birthDate = dob.includes("T")
      ? new Date(dob)
      : new Date(`${dob}T00:00:00.000Z`);
    let today = new Date();
    let age_now = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return `${age_now}Y`;
  } else {
    return `N/A`;
  }
};

const createRows = (
  items,
  refetchPatientAgain,
  pageName,
  redirectToEditPage,
  toggleProsDrawer,
  openProsDrawer,
  openSocialDrawer,
  toggleSocialDrawer
) => {

  const iconMap = {
    food: <RestaurantIcon />,
    transportation: <DirectionsCarIcon  />,
    housing: <HouseOutlinedIcon />,
    safety: <ShieldIcon />,
    utility: <TipsAndUpdatesIcon />,
    financial: <PaymentsIcon />,
    urgent: <DirectionsRunIcon />,

  };

  return pageName === "pros"
    ? items?.map((item) => ({
        id: item?._id,
        patientInfo: (
          <>
            <Typography
              variant="p"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              <div style={{ display: "flex", flexDirection: 'column' }}>
                <div style={{display:'flex'}}>
                <Typography
                  onClick={() => redirectToEditPage(item?._id, pageName, item)}
                  variant="body2"
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    paddingRight: "5px",
                    fontWeight: 600,
                    color: "#000000",
                    "&:hover": {
                      color: "#816179",
                    },
                  }}
                >
                  {item?.firstName} {item?.lastName}
                </Typography>
                , {calculateAge(item.birthday)}
                </div>
                <div>
                DOB:{" "}{item?.birthday? moment(item?.birthday).format("MM-DD-YYYY"): "N/A"}
                </div>
              </div>
            </Typography>
          </>
        ),
        surgeryDate: (
          <Box>
            {item?.procedureType || item?.procedureDate ? (
              <>
                <Box>{item?.procedureType ? item?.procedureType : 'N/A'}</Box>
                <Box>{item?.procedureDate ? moment(item?.procedureDate).format("MM-DD-YYYY") : 'N/A'}</Box>
              </>
            ) : (
              "No data available"
            )}
          </Box>
        ),
        physicianName: item?.physicianName ? item?.physicianName : 'N/A',
        prosDue: item?.assignedSurveys?.length > 0 ? <ProsProgram program={item?.assignedSurveys} toggleProsDrawer={toggleProsDrawer} openProsDrawer={openProsDrawer} id={item._id} data={item} /> : 'No Data Available',
		riskCategory: 
		<>
		<Typography
          fontSize={14}
          color="black"
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
            <span style={{ ...getColorStyles(item?.riskCategory?.score ? item?.riskCategory?.score : 'N/A'), fontSize: "36px" }}>
              •
            </span>
          {item.riskCategory.score
            ? item?.riskCategory?.score?.charAt(0)?.toUpperCase() + item?.riskCategory?.score?.slice(1)
            : "N/A"}
        </Typography>
		</>,
        action: (
          <ActionCell
            id={item._id}
            path={item?.path}
            name={item?.firstName}
            consent={item?.consentResponse}
            patient={item}
            refetchPatientAgain={refetchPatientAgain}
            pageName={pageName}
            toggleProsDrawer={toggleProsDrawer}
            openProsDrawer={openProsDrawer}
            prosData={item}
          />
        ),
      }))
    : (pageName === "social") ? 
    items?.map((item) => ({
      id: item?._id,
      patientInfo: (
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            <div style={{ display: "flex", flexDirection: 'column' }}>
              <div style={{display:'flex'}}>
              <Typography
                onClick={() => redirectToEditPage(item?._id, pageName, item)}
                variant="body2"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  paddingRight: "5px",
                  fontWeight: 600,
                  color: "#000000",
                  "&:hover": {
                    color: "#816179",
                  },
                }}
              >
                {item?.patientDetails?.firstName} {item?.patientDetails?.lastName}
              </Typography>
              {/* , {calculateAge(item.birthday)} */}
              </div>
              <div>
              DOB:{" "}{item?.patientDetails?.birthday? moment(item?.patientDetails?.birthday).format("MM-DD-YYYY"): "N/A"}
              </div>
            </div>
          </Typography>
        </>
      ),
      socialNeeds: (
        <>
          <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '10px',
          }}
        >
          {(item?.patientDetails?.socialNeeds || item?.patientDetails?.socialRisks) && (item?.patientDetails?.socialNeeds?.length > 0 || item?.patientDetails?.socialRisks?.length > 0)
            ? [...(item?.patientDetails?.socialRisks || []), ...(item?.patientDetails?.socialNeeds || [])].map((icon, index) => {
                const isSocialNeed = item?.patientDetails?.socialNeeds?.includes(icon)
                const color = isSocialNeed ? '#ffb400' : '#DC362E'
                const IconElement = iconMap[icon] || <BlockIcon />
                return (
                  <Tooltip key={index} title={icon.charAt(0).toUpperCase() + icon.slice(1)}>
                    <div style={{ cursor: 'pointer' }}>{React.cloneElement(IconElement, { sx: { color } })}</div>
                  </Tooltip>
                )
              })
            : 'N/A'}
        </div>
        </>
      ),
      adi:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left"
            }}
          >
            {item?.patientDetails?.adi ? item?.patientDetails?.adi : 'N/A'}
          </Typography>
        </>
      ),
      socialConsent:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
              textTransform: 'capitalize'
            }}
          >
            {item?.patientDetails?.socialCareConsent ? item?.patientDetails?.socialCareConsent.replace("_", " ") : 'N/A'}
          </Typography>
        </>
      ),
      referralDate:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {item?.referrals?.createdAt ? moment(item?.referrals?.createdAt).format("MM-DD-YYYY") : 'N/A'}
          </Typography>
        </>
      ),
      assignedCHW:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {item?.patientDetails?.assignedCHW ? item?.patientDetails?.assignedCHW : 'N/A'}
          </Typography>
        </>
      ),
      loopStatus:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
              textTransform: 'capitalize'
            }}
          >
            {item?.allNeedsMet ? 'Yes' : item?.allNeedsMet === false ? "No" : 'N/A'}
          </Typography>
          {/* {item?.loopStatus === 'closed' && 
          <>
          <br />
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {moment(item?.loopStatusDate).format("MM-DD-YYYY")}
          </Typography>
          </>
          } */}
        </>
      ),
      referralStatus:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
              textTransform: 'capitalize'
            }}
          >
            {item?.status ? item?.status : 'N/A'} 
            {/* {item?.referralStatus === 'closed' && ','} */}
          </Typography>
          {/* {item?.referralStatus === 'closed' && 
          <>
          <br />
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {moment(item?.referralStatusDate).format("MM-DD-YYYY")}
          </Typography>
          </>
          } */}
        </>
      ),
      notes: (
        <Box sx={{display:'flex'}}>
        <StickyNote2Icon sx={{color:'black', cursor:'pointer', mr: '3px'}} onClick={toggleSocialDrawer({value:true,data:item?.patientDetails, allNeedsMet: item?.allNeedsMet})}/>
         <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            Notes
          </Typography>
        </Box>
      ),
      action: (
        <ActionCell
          id={item._id}
          path={item?.path}
          name={item?.patientDetails?.firstName}
          consent={item?.patientDetails?.consentResponse}
          patient={item?.patientDetails}
          refetchPatientAgain={refetchPatientAgain}
          pageName={pageName}
          toggleProsDrawer={toggleSocialDrawer}
          openProsDrawer={openSocialDrawer}
          prosData={item}
        />
      ),
    }))
    : pageName === 'socialqueue' ? 
    items?.map((item) => ({
      id: item?._id,
      patientInfo: (
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            <div style={{ display: "flex", flexDirection: 'column' }}>
              <div style={{display:'flex'}}>
              <Typography
                onClick={toggleSocialDrawer({value:true, data:item?.patientDetails, allNeedsMet:item?.allNeedsMet})}
                variant="body2"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  paddingRight: "5px",
                  fontWeight: 600,
                  color: "#000000",
                  "&:hover": {
                    color: "#816179",
                  },
                }}
              >
                {item?.firstName} {item?.lastName}
              </Typography>
              {/* , {calculateAge(item.birthday)} */}
              </div>
              <div>
              DOB:{" "}{item?.birthday? moment(item?.birthday).format("MM-DD-YYYY"): "N/A"}
              </div>
            </div>
          </Typography>
        </>
      ),
      socialNeeds: (
        <>
          <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '10px',
          }}
        >
          {(item?.patientDetails?.socialNeeds || item?.patientDetails?.socialRisks) && (item?.patientDetails?.socialNeeds?.length > 0 || item?.patientDetails?.socialRisks?.length > 0)
            ? [...(item?.patientDetails?.socialRisks || []), ...(item?.patientDetails?.socialNeeds || [])].map((icon, index) => {
                const isSocialNeed = item?.patientDetails?.socialNeeds?.includes(icon)
                const color = isSocialNeed ? '#ffb400' : '#DC362E'
                const IconElement = iconMap[icon] || <BlockIcon />
                return (
                  <Tooltip key={index} title={icon.charAt(0).toUpperCase() + icon.slice(1)}>
                    <div style={{ cursor: 'pointer' }}>{React.cloneElement(IconElement, { sx: { color } })}</div>
                  </Tooltip>
                )
              })
            : 'N/A'}
        </div>
        </>
      ),
      contactAttempts:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left"
            }}
          >
            {item?.contactAttempts ? item?.contactAttempts : 'N/A'}
          </Typography>
        </>
      ),
      contactMethod:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {item?.contactMethod ? item?.contactMethod : 'N/A'}
          </Typography>
        </>
      ),
      zip:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left"
            }}
          >
            {item?.zip ? item?.zip : 'N/A'}
          </Typography>
        </>
      ),
      ADI:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left"
            }}
          >
            {item?.ADI ? item?.ADI : 'N/A'}
          </Typography>
        </>
      ),
      socialConsent:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
              textTransform: 'capitalize'
            }}
          >
            {item?.socialConsent ? item?.socialConsent : 'N/A'}
          </Typography>
        </>
      ),
      assignedTo:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {item?.assignedTo ? item?.assignedTo : 'N/A'}
          </Typography>
        </>
      ),
      referringPhysician:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {item?.referringPhysician ? item?.referringPhysician : 'N/A'}
          </Typography>
        </>
      ),
      referralDate:(
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {item?.referralDate ? moment(item?.referralDate).format("MM-DD-YYYY") : 'N/A'}
          </Typography>
        </>
      ),
      // notes: (
      //   <Box sx={{display:'flex'}}>
      //   <StickyNote2Icon sx={{color:'black', cursor:'pointer', mr: '3px'}} onClick={toggleSocialDrawer(true)}/>
      //    <Typography
      //       variant="p"
      //       sx={{
      //         fontSize: 16,
      //         fontWeight: 400,
      //         textAlign: "left",
      //       }}
      //     >
      //       Notes
      //     </Typography>
      //   </Box>
      // ),
      // action: (
      //   <ActionCell
      //     id={item._id}
      //     path={item?.path}
      //     name={item?.firstName}
      //     consent={item?.consentResponse}
      //     patient={item}
      //     refetchPatientAgain={refetchPatientAgain}
      //     pageName={pageName}
      //     toggleProsDrawer={toggleSocialDrawer}
      //     openProsDrawer={openSocialDrawer}
      //     prosData={item}
      //   />
      // ),
    }))
    : items?.map((item) => ({
        id: item?._id,
        patientInfo: (
          <PatientInfo id={item?._id} patient={item} redirectUrl={"profile"} pageName={pageName} />
        ),
        tags: (
          <>
            <Box sx={{ display: "flex", justifyContent: "left" }}>
              {MakeTags(item?.tags)}
            </Box>
          </>
        ),
        cptCode: "787",
        surgeryDate: item?.surgeryDate
          ? moment(item?.surgeryDate)?.format("MMMM DD, YYYY")
          : "No data available",
        surgeryDescription: (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "200px",
            }}
          >
            {item?.surgeryName || "No data available"}
          </div>
        ),
        careProgram: <CareProgram program={item.program} />,
        consent: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <>
              <span
                style={{
                  ...getConsentColors(item?.consentResponse),
                  fontSize: "36px",
                }}
              >
                •
              </span>
              <Typography fontSize={14} color="black">
                {item?.consentResponse === "NO_RESPONSE"
                  ? "No Response"
                  : item?.consentResponse?.charAt(0).toUpperCase() +
                    item?.consentResponse?.slice(1)}
              </Typography>
            </>
          </div>
        ),
        status: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <>
              <span
                style={{ ...getStatusColors(item?.status), fontSize: "36px" }}
              >
                •
              </span>
              <Typography
                fontSize={14}
                color="black"
                textTransform={"capitalize"}
              >
                {item?.status}
              </Typography>
            </>
          </div>
        ),
        action: (
          <ActionCell
            id={item._id}
            path={item?.path}
            name={item?.firstName}
            consent={item?.consentResponse}
            patient={item}
            refetchPatientAgain={refetchPatientAgain}
            pageName={pageName}
          />
        ),
      }));
};

export function PatientTable({
  page,
  setPage,
  data,
  isLoading,
  isError,
  isSuccess,
  refetchPatientAgain,
  pageName,
}) {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [openProsDrawer, setOpenProsDrawer] = useState({value:false, data:{}});
  const [openSocialDrawer, setOpenSocialDrawer] = useState({value:false, data:null, allNeedsMet:null});
  const { data: tags, isSuccess: isSuccessTags } = useGetTagsQuery({
    userID: user.id,
  });
  const handleChangePage = (event, newPage) => {
    if(pageName === "pros"){
      localStorage.setItem("prosPage", newPage);
    }else{
      localStorage.setItem("page3", newPage);
    }
    setPage(newPage);
  };

  const redirectToEditPage = (id, name, data) => {
    localStorage.setItem('pageName2', JSON.stringify(name))
    if(pageName === 'pros'){
      setOpenProsDrawer({value:true, data});
    }else if(pageName === 'social' || pageName === 'socialqueue'){
      setOpenSocialDrawer({value:true, data: data?.patientDetails, allNeedsMet: data?.allNeedsMet});
    }else{
      navigate(`/profile/${id}`, { state: { action: "read-only", id } });
    }
    
  };

  const handleProsClose = () => {
    setOpenProsDrawer({value:false, data:{}});
  }

  const toggleProsDrawer = (open) => (event) => {
    handleProsClose();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    
    refetchPatientAgain()
    setOpenProsDrawer(open);
  };

  const toggleSocialDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    console.log(open, 'Open')
    setOpenSocialDrawer(open);
  };

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0 || data?.patients?.length === 0) {
    return pageName === "pros" ? (
      <Paper
        sx={{
          border: "1px ",
          width: "100%",
          padding: "15px",
        }}
      >
        No Patient found.
      </Paper>
    ) : (
      <Typography sx={{ margin: "10px 20px" }}>No Patient found.</Typography>
    );
  }

  return (
    <>
      <Paper
        sx={{
          border: "1px ",
          width: "100%",
          borderRadius: "10px",
        }}
      >
        <TableContainer sx={{ borderRadius: "4px" }} className="DialogContent">
          <Table>
            <TableHead style={{ backgroundColor: "rgba(0, 151, 236, 0.12)" }}>
              <TableRow>
                {pageName === "pros"
                  ? prosColumns?.map((column) => (
                      <TableCell
                        variant="head"
                        key={column.id}
                        align="left"
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#50607A",
                          paddingY: "5px",
                          minWidth: column.minWidth
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {column.label}
                        </div>
                      </TableCell>
                    ))
                  : pageName === "social" ? 
                  socialColumns?.map((column) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="left"
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#50607A",
                        paddingY: "5px",
                        minWidth: column.minWidth
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {column.label}
                      </div>
                    </TableCell>
                  ))
                  : pageName === 'socialqueue' ? 
                  socialCareColumns?.map((column) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="left"
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#50607A",
                        paddingY: "5px",
                        minWidth: column.minWidth
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {column.label}
                      </div>
                    </TableCell>
                  ))
                  : columns?.map((column) => (
                      <TableCell
                        variant="head"
                        key={column.id}
                        align="left"
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#50607A",
                          paddingY: "5px",
                          minWidth: column.minWidth
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {column.label}
                        </div>
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isSuccess &&
                isSuccessTags &&
                createRows(
                  pageName === "pros" ? data?.patients : (pageName === "social" || pageName === 'socialqueue') ? data?.data?.patients : data.data,
                  refetchPatientAgain,
                  pageName,
                  redirectToEditPage,
                  toggleProsDrawer,
                  openProsDrawer,
                  openSocialDrawer,
                  toggleSocialDrawer
                )?.map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {pageName === "pros"
                        ? prosColumns?.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align="left"
                                sx={{
                                  color: "text.secondary",
                                  minWidth: column.minWidth
                                }}
                              >
                                {value}
                              </TableCell>
                            );
                          })
                        : (pageName === "social" ) ? 
                          socialColumns?.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align="left"
                                sx={{
                                  color: "text.secondary",
                                  minWidth: column.minWidth
                                }}
                              >
                                {value}
                              </TableCell>
                            );
                          })
                        : pageName === 'socialqueue' ? 
                        socialCareColumns?.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align="left"
                              sx={{
                                color: "text.secondary",
                                minWidth: column.minWidth
                              }}
                            >
                              {value}
                            </TableCell>
                          );
                        })
                        : columns?.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align="left"
                                sx={{
                                  color: "text.secondary",
                                  minWidth: column.minWidth
                                }}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationBar
          {...{ page, count: (pageName === 'socialqueue' || pageName === 'social') ? data?.data?.totalPages : data.totalPages, handleChange: handleChangePage }}
        />
        <RightDrawer open={openSocialDrawer?.value} toggleDrawer={toggleSocialDrawer} isCHW={true} patient={openSocialDrawer?.data} />
      </Paper>
    </>
  );
}
