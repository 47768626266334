import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

// Replace with your ZIP code data
// import zipCodeData from "./ZipCodeData.json";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

export function ReactZipCodeMap({ zipCodeData, isError }) {
  if (isError) return <p>Some error</p>;
  return (
    <>
      <Box sx={{ width: "75%" }}>
        <ComposableMap projection="geoAlbersUsa">
          <Geographies geography={geoUrl}>
            {({ geographies }) => (
              <>
                {geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    stroke="#FFF"
                    geography={geo}
                    fill="#DDD"
                  />
                ))}
                {zipCodeData.map((zipData) => {
                  const centroid = [zipData.longitude, zipData.latitude]; // Replace with actual coordinates
                  return (
                    <Marker coordinates={centroid} key={zipData.zip_code}>
                      <circle r={5} fill="#816179" /> {/* Adjust circle size */}
                      <text
                        y="18"
                        fontSize={12}
                        textAnchor="top"
                        sx={{ fontWeight: "bold" }}
                      >
                        {zipData.zip_code}
                      </text>
                    </Marker>
                  );
                })}
              </>
            )}
          </Geographies>
        </ComposableMap>
      </Box>
      <Box sx={{ marginRight: "20px" }}>
        <Card
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "5px",
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              sx={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Most Representative ZIP Codes
            </Typography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {zipCodeData?.map((zipData) => (
                <li key={zipData.zip_code} style={{ marginBottom: "10px" }}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "bold", color: "gray" }}
                  >
                    {zipData.zip_code}: {zipData.count} patients
                  </Typography>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
