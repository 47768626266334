import React from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DeleteSvg, EditSvg } from "../../../assets/svgs";
import {
  CircularLoader,
  DeleteModal,
  PaginationBar,
} from "../../../Components";
import { hasScope } from "../../../utils";
import { PhysicianModal } from "./PhysicianModal";
import { useDeletePhysicianMutation } from "../../../store/physiciansSlice";
import { toast } from "react-toastify";

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "right",
  },
  {
    id: "speciality",
    label: "Speciality",
    minWidth: 170,
    align: "right",
  },
  {
    id: "type",
    label: "Type",
    minWidth: 170,
    align: "right",
  },
  {
    id: "consent",
    label: "Consent",
    minWidth: 170,
    align: "right",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

const createRows = (
  contents,
  handleOpenModal,
  handleDelete,
  openModal,
  setOpenModal,
  datas,
  handleCopyClick,
  scopes,
  openEditModal, 
  setOpenEditModal,
  refetchAgain
) => {
  const rows = contents && contents?.map(({ _id, name, speciality, type, consent }, index) => {
    return {
      name: (
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 14,
              color: "black",
              padding: "7px",
            }}
          >
            {name}
          </Typography>
        </>
      ),
      speciality: (
        <>
        <Typography
          variant="p"
          sx={{
            fontSize: 14,
            color: "black",
            padding: "7px",
          }}
        >
          {speciality}
        </Typography>
      </>
      ),
      type: (
        <>
        <Typography
          variant="p"
          sx={{
            fontSize: 14,
            color: "black",
            padding: "7px",
          }}
        >
          {type ? type : 'N/A'}
        </Typography>
      </>
      ),
      consent: (
        <>
        <Typography
          variant="p"
          sx={{
            fontSize: 14,
            color: "black",
            padding: "7px",
          }}
        >
          {consent ? consent : 'N/A'}
        </Typography>
      </>
      ),
      actions: (
        <>
              <Stack spacing={3} direction="row" justifyContent="center" alignItems="center" >
                
                <div
                  onClick={(event) => handleCopyClick(_id, name, speciality, type ? type : '')}
                  style={{ cursor: "pointer" }}
                >
                  <EditSvg />
                </div>

                {hasScope(scopes, 'settings/contentManagers:delete') && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(event) => handleOpenModal(event, name, _id)}
                >
                  <DeleteSvg />
                </div>
                )}
              </Stack>
            
          <DeleteModal
            open={openModal}
            setOpen={setOpenModal}
            id={datas}
            handle={handleDelete}
          />
          <PhysicianModal open={openEditModal.open} setOpen={setOpenEditModal} title="Edit Physician" refetchAgain={refetchAgain} data={{id: openEditModal.id, name: openEditModal.name, speciality: openEditModal.speciality, type: openEditModal.type}} />
        </>
      ),
    };
  });

  return rows;
};

export function PhysicianTable({
  page,
  setPage,
  data,
  isLoading,
  isError,
  searchTerm,
  scopes,
  refetchAgain
}) {
  // States
  const [openModal, setOpenModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState({ open: false, id: null, name: '', speciality: '', type: '' });
  const [datas, setData] = React.useState("");

  // Mutation:
  const [deletePhysician] = useDeletePhysicianMutation();

  // Functions:
  const handleOpenModal = (event, name, id) => {
    setData(id);
    setOpenModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (id) => {
    await deletePhysician(id).unwrap().then(res => {
      toast.success('Physician deleted successfully!');
      refetchAgain()
    }).catch((e) => {
      toast.error(e?.data?.error || e?.message || "some error occured!");
    });
  };

  const handleCopyClick = (id, name, speciality, type) => {
    setOpenEditModal({open: true, id, name, speciality, type})
  };

  const ProcedureType = data ? data?.data : [] ;
  const rows = createRows(
    ProcedureType,
    handleOpenModal,
    handleDelete,
    openModal,
    setOpenModal,
    datas,
    handleCopyClick,
    scopes,
    openEditModal, 
    setOpenEditModal,
    refetchAgain
  );

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return <Typography sx={{margin:"10px 20px"}}>No Physician found.</Typography>;
  }
  
  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  variant="head"
                  key={column.id}
                  align={"center"}
                  sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns?.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ color: "text.secondary", border: "none" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data?.totalPages, handleChange: handleChangePage }}
      />
    </>
  );
}
