import React, { useState } from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

import { useDeleteTaskMutation, useEditTaskMutation } from "../../../store/tasksSlice";
import { ActionButtons, PaginationBar } from "../../../Components";
import Status from "./Status";
import { EditTaskDrawer } from "./EditTaskDrawer";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ViewTableDrawer } from "./ViewTableDarwer";
import { useSelector } from "react-redux";
import { useNotAllowedRole } from "../../../hooks";


const createColumns = (isNotAllowed) => [
  { id: "Srno", label: "Index", minWidth: 170 },
  {
    id: "title",
    label: "Title",
    minWidth: 170,
    align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  // {
  //   id: "description",
  //   label: "Description",
  //   minWidth: 170,
  //   align: "right",
  // },
  {
    id: "duedate",
    label: "Due Date",
    minWidth: 170,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "right",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right"
  },
];

const createRows = ({ tasks, handleEdit, handleDelete, handleDetail, isNotAllowed }) => {
  const rows = tasks.map(
    ({ _id: id, title, description, date: duedate, status, members }, index) => {
      return {
        Srno: (
          <Typography
            variant="p"
            sx={{ fontSize: 16, fontWeight: 400, color: "text.secondary" }}
          >
            {index + 1}
          </Typography>
        ),
        title,
        description: description.slice(0, 28) + ".....",
        duedate: new Date(duedate)?.toDateString(),
        status: (
          <Status status={status} id={id} />
        ),
        actions: (
          <Stack spacing={3} direction="row" justifyContent="center" alignItems="center">
            {!isNotAllowed && <div>
              <ActionButtons
                id={id}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            </div>}
            <div style={{ cursor: "pointer", marginLeft: "10px" }} onClick={(e) => handleDetail(e, id)}>
              <RemoveRedEyeIcon />
            </div>
          </Stack>
        ),
      };
    }
  );

  return rows;
};


export function TaskTable({ page, setPage, data }) {
  const { user } = useSelector((state) => state.user);
  const [detailState, setDetailState] = useState({ detail: false, taskID: "" });
  const [editState, setEditState] = useState({ edit: false, taskID: "" });

  const isNotAllowed = useNotAllowedRole()
  const [deleteTask, { }] = useDeleteTaskMutation()


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };



  const toggleEditDrawer = (edit) => (event, taskID) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setEditState({ edit, taskID: taskID?.startsWith("back") ? "645cd291722a18db5c5b44c3" : taskID });
  };


  const toggleDetailDrawer = (detail) => (event, taskID) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDetailState({ detail, taskID: taskID?.startsWith("back") ? "645cd291722a18db5c5b44c3" : taskID });
  };

  const handleDetail = (e, id) => {
    toggleDetailDrawer(true)(e, id);
  }

  const handleEdit = (e, id) => {
    toggleEditDrawer(true)(e, id);
  };

  const handleDelete = async (id) => {
    deleteTask({ userID: user.id, taskID: id }).unwrap()
      .then(() => {
        toast.success("Deleted successfully!");
      })
      .catch((error) => {
        toast.error("some error occured!");
        console.error(error);
      });
  };


  const columns = createColumns(isNotAllowed)
  const rows = createRows({ tasks: data, handleEdit, handleDelete, handleDetail, isNotAllowed });
  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (column.hide) return null
                return (
                  <TableCell
                    variant="head"
                    key={column.id}
                    align={"center"}
                    sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                  >
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              if (row.hide) return null
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ color: "text.secondary", border: "none" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data.totalPages, handleChange: handleChangePage }}
      />

      {editState.edit && <EditTaskDrawer toggleDrawer={toggleEditDrawer} state={editState} />}
      {detailState.detail && <ViewTableDrawer toggleDrawer={toggleDetailDrawer} state={detailState} />}
    </>
  );
}
