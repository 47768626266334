import React, { createContext, useContext, useState } from "react";
import axios from "axios"

export const ChatContext = createContext();

export const AssistantChatProvider = ({ children }) => {
    // const [prompt, setPrompt] = useState("");
    // const [language, setLanguage] = useState("english");
    // const [languageModel, setLanguageModel] = useState('gpt-35');
    // const [pathway, setPathway] = useState([]);
    // const [platform, setPlatform] = useState("");
    const [assistantChatMessages, setAssistantChatMessages] = useState([]);
    const [assistantUserMessages, setAssistantUserMessages] = useState([])
    const [isAssistantLoading, setIsAssistantLoading] = useState(false);

    async function postMessage(inputMessage, body, config){
      const systemMessage = { content: body.prompt, role: "system" };
      const updatedassistantChatMessages = [...assistantChatMessages, { content: inputMessage, role: "user" }];
      const updatedassistantUserMessages = [...assistantUserMessages, { content: inputMessage, role: "user" } ]
      let messagesToSend = [...updatedassistantUserMessages];
      console.log(body)

      if (body.prompt !== "") {
          messagesToSend = [systemMessage, ...messagesToSend];
      }

      setAssistantChatMessages(updatedassistantChatMessages);
      setAssistantUserMessages(updatedassistantUserMessages)
      setIsAssistantLoading(true)
      const requestBody = {
        assistantId: body.assistantId,
        assistant_messages : messagesToSend,
        include_revel_content: body.include_revel_content === 'yes' ? true : false
      };

      try {
          const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/assistants/assistant`, requestBody, config);
          console.log(response)
          const finalassistantChatMessages = [...updatedassistantChatMessages, { content: response.data.answer, reference: response.data?.context_docs, role: 'system' }];
          const finalassistantUserMessages = [...updatedassistantUserMessages, { content: response.data.answer, role: 'assistant' }]
          setAssistantChatMessages(finalassistantChatMessages);
          setAssistantUserMessages(finalassistantUserMessages)
          setIsAssistantLoading(false);
          // Handle the response as needed, for example, updating chat messages with the response
      } catch (error) {
          console.error('Error posting message:', error);
          setIsAssistantLoading(false);
          // Handle the error as needed
      }
    }

  return (
    <ChatContext.Provider value={{ assistantChatMessages, setAssistantChatMessages, isAssistantLoading, postMessage }}>
      {children}
    </ChatContext.Provider>
  );
};

export function useAssistantChatValues() {
  return useContext(ChatContext);
}
