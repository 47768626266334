import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectField({label,showValue, setShowValue, data, onClick, fieldKey}) {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setShowValue((prevState) => {
        if (!prevState.some((item) => item.value === event.target.value.value)) {
          return [...prevState, event.target.value];
        }
        return prevState;
      });

    onClick(event, fieldKey)
      
    setAge('');
  };

  return (
    <FormControl sx={{ m: 1, width:'90%' }} size="small">
      <Select
        id="demo-select-small"
        value={age}
        onChange={handleChange}
        displayEmpty
        sx={{background:'#f9f9f9'}}
      >
        <MenuItem value="">
          {label}
        </MenuItem>
        {data && data?.map((item, index)=>(
           <MenuItem key={index} value={item}>{item?.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
