import React, { useEffect, useState } from "react";
import { Stack, Grid, Box, Typography, FormControl, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { SolidButton } from "../../Components";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEditUserMutation } from "../../store/userManagmentSlice";
import { toast } from "react-toastify";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import CloseIcon from "@mui/icons-material/Close";
import { formatPhone, getCurrentSiteId } from "../../utils";
import {useSelector} from "react-redux"

export function EditUser({ data, toggleEditDrawer }) {
  const {user} = useSelector(state => state.user)
  const { name, contact, role: roleData, creator: creatorData, hospital: hospitalData } = data;
  const [role, setRole] = useState(roleData);
  const [creator, setCreator] = useState(creatorData || "");
  const [isValidNumber, setIsValidNumber] = useState(null);
  const [form, setForm] = useState({
    name,
    contact,
  });

  const [editUser, { isSuccess, isLoading }] = useEditUserMutation();
  const handleChangeNumber = (newValue) => {
    matchIsValidTel(newValue); // boolean
    setIsValidNumber(newValue ? matchIsValidTel(newValue) : true);
  };
  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const onEdit = async () => {
    if (isValidNumber === true) {
      const formattedPhone = form?.contact ? formatPhone(form?.contact) : "";
      await editUser({
        id: data._id,
        body: { ...form, contact: formattedPhone, role, creator},
      })
        .unwrap()
        .then(() => toast.success("Successfully updated!"))
        .catch((error) => {
          toast.error(error?.message || "some error occured!");
        });
    } else {
      toast.error("Invalid phone number");
    }
  };

  if (isSuccess) {
    toggleEditDrawer(false, null);
  }

  useEffect(() => {
    async function updateData() {
      if (contact?.length === 12 || contact?.length === 0) {
        setIsValidNumber(true);
      } else {
        setIsValidNumber(false);
      }
    }
    updateData();
  }, []);
  return (
    <>
      <Box sx={{ padding: "2rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: "3rem" }}
        >
          <Typography variant="p" sx={{ fontSize: 30, fontWeight: 400 }}>
            Edit Role
          </Typography>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => toggleEditDrawer(false)}
          >
            <CloseIcon />
          </Box>
        </Stack>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold" }}
                gutterBottom
              >
                Enter Name
              </Typography>
              <TextField
                required
                id="outlined-required"
                name="name"
                value={form.name}
                onChange={onChange}
                placeholder="Enter Name"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold" }}
                gutterBottom
              >
                Contact Number
              </Typography>
              <MuiTelInput
                required
                id="outlined-required"
                name="contact"
                value={form.contact}
                onChange={(newValue) => {
                  const e = { target: { name: "contact", value: newValue } };
                  onChange(e);
                  handleChangeNumber(newValue);
                }}
                onlyCountries={["US"]}
                error={isValidNumber === false}
                helperText={isValidNumber === false && "Incorrect entry"}
                placeholder="Contact Number"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold" }}
                gutterBottom
              >
                User Role
              </Typography>
              <Select
                value={role}
                sx={{ width: "100%" }}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={2615}>Client Admin</MenuItem>
                <MenuItem value={1122}>Care team members</MenuItem>
                <MenuItem value={1111}>Reporting Users</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <SolidButton label="Update" onClick={onEdit} disabled={isLoading} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
