export function filterObject(originalObject, objectMethod) {
    let filteredObject = {};

    let labelMethod;

    switch (objectMethod) {
        case "sms":
            labelMethod = 'label';
            break;
        case "delay":
            labelMethod = 'value';
            break;
        case "task":
        case "trigger":
        case "form":
            labelMethod = 'extra';
            break;
        case "assign_journey":
            labelMethod = 'journeyId';
            break;
        default:
            break;
    }
  
    const methodSpecificFields = {
      delay: ['childNodeId', 'conditionOperator', 'delay', 'metric', 'value', 'method', 'parentNodeId', 'nextNodeId', 'prevNodeId', 'delayFor','timeRelation'],
      task: ['task', 'childNodeId', 'method', 'parentNodeId', 'nextNodeId', 'prevNodeId', 'isFilled'],
      sms: ['sms', 'childNodeId', 'method', 'parentNodeId', 'nextNodeId', 'prevNodeId', 'isFilled'],
      form: ['form', 'conditionOperator', 'checkCondition', 'surveyValues', 'childNodeId', 'method', 'parentNodeId', 'positiveChildNodeId', 'negativeChildNodeId', 'metric', 'delay', 'delayValue', 'nextNodeId', 'prevNodeId', 'isFilled'],
      trigger: ['triggerType', 'childNodeId', 'method', 'parentNodeId', 'nextNodeId', 'prevNodeId', 'isFilled'],
      assign_journey: ['childNodeId', 'method', 'parentNodeId', 'journeyId', 'journeyName', 'nextNodeId', 'prevNodeId', 'isFilled']
    };
  
    const allowedFields = methodSpecificFields[objectMethod] || [];
  
    allowedFields.forEach(field => {
        if (originalObject.hasOwnProperty(field)) {
          filteredObject[field] = originalObject[field];
        } else if (objectMethod === "assign_journey" && (field === "journeyId" || field === "journeyName")) {
          // If objectMethod is "assign_journey", include journeyId and journeyName fields
          filteredObject[field] = originalObject[field];
        } else {
          // If the field is not present, use objectMethod as a label
          filteredObject[objectMethod] = originalObject[labelMethod];
        }
      });
  
    return filteredObject;
  }
  