import React, { useState } from "react";
import { MenuItem, IconButton, Tooltip, Menu, Box } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { EditSvg } from "../../../assets/svgs";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { set } from "date-fns";
import { DeleteModal } from "../../../Components";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { DetailPageDrawer } from "../Profile/DetailPageDrawer";
import { useDeleteAppointmentMutation } from "../../../store/appointmentsSlice";
import { useDeleteSurgeryMutation } from "../../../store/surgeriesSlice";
import { useDeleteNoteMutation } from "../../../store/notesSlice";
import {useDeleteAssignJourneyMutation} from "../../../store/assignJourneySlice";
import { useDeleteTaskMutation} from "../../../store/tasksSlice";
import { useDeleteCareAllyMutation} from "../../../store/careAllysSlice";
import { ViewTableDrawer } from "../Profile/Task";
import {useSelector} from "react-redux"
import { SurgeryDetailDrawer } from "./SurgeryDetailDrawer";
import { NoteViewDrawer } from "./NoteViewDrawer";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';

export function ProfileActionCell({
  tableType,
  tag,
  data,
  setEditData,
  handleModalEdit,
  fetchAgain,
}) {
  const {user} = useSelector(state => state.user)
  const navigate = useNavigate();
  const {id} = useParams()
  const textColor = { color: "#979797" };

  const [openModal, setOpenModal] = useState(false);
  const [noteDetailOpen, setNoteDetailOpen] = useState(false)
  const [NoteData, setNoteData] = useState(null)
const[surgeryDetail,setSurgeryDetail]=useState(false)
  const [detail, setDetail] = React.useState(false);
  const [detailState, setDetailState] = useState({ detail: false, taskID: "" });
  const [deleteAppointment] = useDeleteAppointmentMutation();
  const [deleteSurgery] = useDeleteSurgeryMutation();
  const [deleteNote] = useDeleteNoteMutation();
  const [deleteAssignJourney] = useDeleteAssignJourneyMutation()
  const [deleteTask] = useDeleteTaskMutation()
  const [deletCareAlly] = useDeleteCareAllyMutation()

  const handleModal = () => {
    setOpenModal(true);
  };

  const handleDelete = async () => {
    if(tableType === 'journey'){
      deleteAssignJourney(data?._id).unwrap()
      .then(() => {
        fetchAgain()
        toast.success("Deleted successfully!");
      })
      .catch((error) => {
        toast.error("some error occured!");
        console.error(error);
      });
    }

    if (tableType === "appointment") {
      await deleteAppointment(data?._id)
        .unwrap()
        .then((res) => {
          fetchAgain();
          toast.success("Appointment is deleted");
          setOpenModal(false);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }

    if (tableType === "surgery") {
      await deleteSurgery(data?._id)
        .unwrap()
        .then((res) => {
          fetchAgain();
          toast.success("Procedure is deleted");
          setOpenModal(false);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }

    if (tableType === "note") {
      await deleteNote(data?._id)
        .unwrap()
        .then((res) => {
          fetchAgain();
          toast.success("Note is deleted");
          setOpenModal(false);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }

    if(tableType === "task"){
      await deleteTask({ userID:user.id, taskID: data?._id })
        .unwrap()
        .then((res) => {
          fetchAgain();
          toast.success("Task is deleted");
          setOpenModal(false);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }

    if(tableType === "careAlly"){
      await deletCareAlly(data?._id)
        .unwrap()
        .then((res) => {
          fetchAgain();
          toast.success("Care Ally is deleted");
          setOpenModal(false);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  };

  const toggleDetailDrawer = (detail) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDetail(detail);
  };
  
  const handleNavigate = () => {
    navigate(`/viewjourneysflow/${data?.journeyId?._id}/${id}/${data?._id}`)
  }

  const  toggleTaskDrawer = (detail) => (event, taskID) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDetailState({ detail, taskID: taskID?.startsWith("back") ? "645cd291722a18db5c5b44c3" : taskID });
  };

  const  toggleNoteDrawer = (detail) => (event, taskID) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const {value , data} = detail
    setNoteDetailOpen(value)
    setNoteData(data)
  };


  const handleDetail = (e) => {
    if(tableType==='journey'){
      handleNavigate()
    }
    if(tableType==="task"){
      toggleTaskDrawer(true)(e, data?._id);
    }
    if(tableType==='form'){
      toggleDetailDrawer(true)(e);

    }
  };

  const handleCopy = async () => {
    try {
      const pendingcopyurl =
        `${process.env.REACT_APP_FRONTEND_URI}/showPatientSurvey/` +
        data?.formId?._id +
        "/" +
        data?._id;
      await navigator.clipboard.writeText(pendingcopyurl);
      toast.success("Link Copied!");
    } catch (error) {
      toast.error("Failed to copy link. Please try again.");
    }
  };

  const handleLink = async () => {
    const pendingcopyurl =
      `/patientSurveys/` + data?.formId?._id + "/" + data?._id;
    navigate(pendingcopyurl);
  };

  const handleEdit = (dataToBeEdit) => {
    setEditData(dataToBeEdit);
    handleModalEdit();
  };

  const toggleSurgeryDrawer= (detail) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSurgeryDetail(detail);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
      { (tableType==="surgery" || tableType === "note" || tableType === 'pros') && <>
        <IconButton
            sx={{
              "&:hover .css-tksnbz-MuiSvgIcon-root": {
                visibility: "visible",
              },
              padding: "0px",
           mr:1.5
            }}
            onClick={(e)=>{tableType === 'surgery' ? toggleSurgeryDrawer(true)(e) : tableType === 'pros' ? console.log('View Survey') : toggleNoteDrawer({value:true , data})(e)}}
          >
            {tableType === 'pros' ? (
              <Tooltip title="View Survey" placement="top">
                  <VisibilityOutlinedIcon sx={{color:"#979797"}}/>
              </Tooltip>
            ): (
                  <VisibilityOutlinedIcon sx={{color:"#979797"}}/>
            )}
          </IconButton></>}

        {tableType === 'pros' && (
          <IconButton
          sx={{
            "&:hover .css-tksnbz-MuiSvgIcon-root": {
              visibility: "visible",
            },
            padding: "0px",
         mr:1.5
          }}
          onClick={(e)=>{tableType === 'surgery' ? toggleSurgeryDrawer(true)(e) : tableType === 'pros' ? console.log('Generate Note') : toggleNoteDrawer({value:true , data})(e)}}
        >
          <Tooltip title="Generate Note" placement="top">
          <AutoFixHighIcon sx={{color:"#979797"}}/>
          </Tooltip>
        </IconButton>
        )}
        {tableType === "journey" ||
        tableType === "task" || 
        (tableType === "form" && tag === "complete")  ? (
          <IconButton
            sx={{
              "&:hover .css-tksnbz-MuiSvgIcon-root": {
                visibility: "visible",
              },
              padding: "0px",
            }}
            onClick={handleDetail}
          >
            <VisibilityOutlinedIcon sx={{color:"#979797"}} />
          </IconButton>
        ) : tableType === "form" && (tag === "pending" || tag === "opened") ? (
          <>
            <IconButton
              sx={{
                "&:hover .css-tksnbz-MuiSvgIcon-root": {
                  visibility: "visible",
                },
                padding: "0px",
              }}
              onClick={handleLink}
            >
              <LinkOutlinedIcon />
            </IconButton>
            <IconButton
              sx={{
                "&:hover .css-tksnbz-MuiSvgIcon-root": {
                  visibility: "visible",
                },
                // padding: "0px",
              }}
              onClick={handleCopy}
            >
              <ContentCopyOutlinedIcon />
            </IconButton>
          </>
        ) : tableType !== 'pros' && (
          <>
            <IconButton
              sx={{
                "&:hover .css-tksnbz-MuiSvgIcon-root": {
                  visibility: "visible",
                },
                padding: "0px",
              }}
              onClick={() => {
                handleEdit(data);
              }}
            >
              <EditSvg sx={textColor} />
            </IconButton>
            <IconButton
              sx={{
                "&:hover .css-tksnbz-MuiSvgIcon-root": {
                  visibility: "visible",
                },
                // padding: "0px",
              }}
              onClick={handleModal}
            >
              <DeleteOutlineOutlinedIcon sx={textColor} />
            </IconButton>
          </>
        )}
      
        {tableType === "task" && (
          <>
            <IconButton
              sx={{
                "&:hover .css-tksnbz-MuiSvgIcon-root": {
                  visibility: "visible",
                },
                padding: "0px",
                ml: 1,
              }}
              onClick={() => {
                handleEdit(data);
              }}
            >
              <EditSvg sx={textColor} />
            </IconButton>
            <IconButton
              sx={{
                "&:hover .css-tksnbz-MuiSvgIcon-root": {
                  visibility: "visible",
                },
                // padding: "0px",
              }}
              onClick={handleModal}
            >
              <DeleteOutlineOutlinedIcon sx={textColor} />
            </IconButton>
          </>
        )}
        {tableType === "journey" && (
          <IconButton
            sx={{
              "&:hover .css-tksnbz-MuiSvgIcon-root": {
                visibility: "visible",
              },
              // padding: "0px",
            }}
            onClick={handleModal}
          >
            <DeleteOutlineOutlinedIcon sx={textColor} />
          </IconButton>
        )}
        {tableType === "pros" && (
          <>
          <IconButton
            sx={{
              "&:hover .css-tksnbz-MuiSvgIcon-root": {
                visibility: "visible",
              },
              padding: "0px",
              ml: 1,
            }}
            onClick={() => {
              console.log(data);
            }}
          >
            <Tooltip title="Text To Remind" placement="top">
            <MapsUgcIcon sx={textColor} />
            </Tooltip>
          </IconButton>
          <IconButton
            sx={{
              "&:hover .css-tksnbz-MuiSvgIcon-root": {
                visibility: "visible",
              },
              // padding: "0px",
            }}
            onClick={()=>{console.log('Call To Remind')}}
          >
            <Tooltip title="Call To Remind" placement="top">
            <PhoneForwardedIcon sx={textColor} />
            </Tooltip>
          </IconButton>
        </>
        )}
      </div>
      {detailState.detail && <ViewTableDrawer toggleDrawer={toggleTaskDrawer} state={detailState} />}
      <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        handle={handleDelete}
        id={data?._id}
        deleteText={tableType === 'journey' ? 'Deleting this assigned journey will also remove its association with any linked Procedures and Appointments for this patient. Are you sure you want to proceed?' : ''}
      />
      <NoteViewDrawer toggleDrawer={toggleNoteDrawer} state={noteDetailOpen} data={NoteData} />
      <SurgeryDetailDrawer toggleDrawer={toggleSurgeryDrawer} state={surgeryDetail} id={data?._id}/>
      <DetailPageDrawer
        toggleDrawer={toggleDetailDrawer}
        state={detail}
        pendingurl={
          `${process.env.REACT_APP_FRONTEND_URI}/patientSurveys/` +
          data?.formId?._id +
          "/" +
          data?._id
        }
      />
    </>
  );
}
