import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	LinearProgress,
	Stack,
	Button,
	IconButton,
	Chip,
} from '@mui/material'
import React, { useState } from 'react'
import moment from 'moment'
import { ProfileActionCell } from '../../PatientProfile/ActionsCell'
import { AudioPlayer, PaginationBar } from '../../../../Components'
import { Status } from '../../Profile/Task'
import { useEditAppointmentMutation } from '../../../../store/appointmentsSlice'
import { toast } from 'react-toastify'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { ViewDrawer } from '../../PatientProfile/CallDrawers/ViewDrawer'
import { useGetCallAgentsQuery } from '../../../../store/callSlice'
import { toTitleCase } from '../../../../utils'

const noteDescription = (description) => {
	return (
		<div
			style={{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: 2,
				lineHeight: '1.5em',
			}}
			dangerouslySetInnerHTML={{
				__html: description.split('\n').slice(0, 2).join('\n'),
			}}
		/>
	)
}

const AppointmentStatus = ({ data }) => {
	const [updateAppointment] = useEditAppointmentMutation()
	const updateAppointmentStatus = async (e) => {
		updateAppointment({
			id: e.target.value,
			body: {
				completed: true,
			},
		})
			.unwrap()
			.then(() => {
				// againRefetch()
				toast.success('Status updated successfully!')
			})
			.catch((error) => {
				toast.error('some error occured!')
				console.error(error)
			})
	}
	return (
		<Stack spacing={3} direction="row" justifyContent="left" alignItems="center">
			{data?.completed ? (
				'Completed'
			) : (
				<Box
					sx={{
						marginBottom: { xs: 1, sm: 0 },
						marginRight: { sm: 2 },
					}}
				>
					<Button
						size="small"
						variant="contained"
						value={data?._id}
						sx={{
							background: 'rgba(0, 151, 236, 0.12)',
							px: '16px',
							borderRadius: '30px',
							boxShadow: 'none',
							fontSize: '14px',
							fontWeight: 'bold',
							height: '35px',
							color: '#50607A',
							textTransform: 'capitalize',
						}}
						onClick={(e) => updateAppointmentStatus(e)}
					>
						Mark as Completed
					</Button>
				</Box>
			)}
		</Stack>
	)
}
export default function ProviderInfo({ data, title, tableType, team, columns, page, setPage, count, setEditData, handleModalEdit, fetchAgain }) {
	const [open, setOpen] = useState(false)
	const [tableData, setTableData] = useState(null)

	const { data: agentData } = useGetCallAgentsQuery()

	const toggleViewDrawer = (detail) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		const { value, data } = detail
		setTableData(data)
		setOpen(value)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const findFormattedKeyById = (data, id) => {
		if (data) {
			const key = Object?.keys(data)?.find((key) => data[key] === id)

			if (key) {
				const formattedKey = key?.replace(/_/g, ' ')?.replace(/\b\w/g, (char) => char?.toUpperCase())
				return formattedKey
			} else {
				return 'N/A'
			}
		} else {
			return 'N/A'
		}
	}

	const getConsentColors = (consent) => {
		if (consent === 'yes') {
			return {
				marginRight: '5px',
				color: '#63A02C',
			}
		} else if (consent === 'no') {
			return {
				marginRight: '5px',
				color: '#DC362E',
			}
		} else {
			return {
				marginRight: '5px',
				color: '#FFB400',
			}
		}
	}

	const textDecoration = { color: 'blue', textDecoration: 'underline', cursor: 'pointer' }

	function getArrayStatus(obj) {
		if (obj.forms.length === 0 && obj.calls.length === 0 && obj.chats.length === 0) {
			return 'Manually Entered'
		} else {
			let result = ''
			if (obj.forms.length > 0) {
				result += 'Form, '
			}
			if (obj.chats.length > 0) {
				result += 'SMS, '
			}
			if (obj.calls.length > 0) {
				result += 'Call, '
			}
			// Remove trailing comma at the end of result
			if (result.endsWith(', ')) {
				result = result.slice(0, -2)
			}
			return result
		}
	}

	const tagStatus = (status) => {
		if (status === 'published') {
			return <Chip label={status?.charAt(0)?.toUpperCase() + status?.slice(1)} size="small" color="success" />
		} else if (status === 'draft') {
			return <Chip label={status?.charAt(0)?.toUpperCase() + status?.slice(1)} size="small" color="warning" />
		} else {
			return 'N/A'
		}
	}

	return (
		<>
			<Typography fontSize={'18px'} fontWeight={'bold'} mb={2}>
				{title}
			</Typography>
			{data && data.length > 0 ? (
				<>
					<Box
						sx={{
							border: '1px solid #D6D6E1',
							width: '100%',
							borderRadius: '10px',
							mb: tableType === 'surgeon' ? '1rem' : '3rem',
						}}
					>
						<TableContainer sx={{ borderRadius: '10px' }} className="DialogContent">
							<Table>
								<TableHead style={{ backgroundColor: 'rgba(0, 151, 236, 0.12)' }}>
									<TableRow>
										{columns?.map((column) => (
											<TableCell
												variant="head"
												key={column.id}
												align="left"
												sx={{
													fontWeight: 600,
													fontSize: '14px',
													color: '#50607A',
													paddingY: '5px',
												}}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.map((tag, index) => (
										<TableRow hover role="checkbox" tabIndex={-1} key={index}>
											{columns?.map((column) => (
												<TableCell
													key={column.id}
													align="left"
													sx={{
														color: 'text.secondary',
													}}
												>
													<Typography
														sx={{
															textTransform: column.id === 'completed' && 'capitalize',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															textAlign: 'left',
															width: column.id === 'description' || column.id === 'title' ? '100px' : '100%',
														}}
													>
														{tableType === 'program' && tag && tag.length > 0
															? tag
																	.replace(/_/g, ' ')
																	.split(/\s+/)
																	.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
																	.join(' ')
															: column.id === 'program' && 'No Care Program Assigned'}
														{column.id === 'title' && tableType === 'form' ? (
															tag?.formId?.form?.title
														) : column.id === 'status' && tableType === 'form' ? (
															tag.status.charAt(0).toUpperCase() + tag.status.slice(1)
														) : column.id === 'agentId' && tableType === 'call' ? (
															findFormattedKeyById(agentData, tag?.agentId)
														) : column.id === 'call_status' && tableType === 'call' ? (
															tag?.callPicked === true ? (
																'Yes'
															) : (
																'No'
															)
														) : column.id === 'transcript' && tableType === 'call' ? (
															tag?.transcript ? (
																<p style={textDecoration} onClick={(e) => toggleViewDrawer({ value: true, column: column.id })(e)}>
																	View Transcript
																</p>
															) : (
																'N/A'
															)
														) : column.id === 'summary' && tableType === 'call' ? (
															tag?.summary ? (
																<p style={textDecoration} onClick={(e) => toggleViewDrawer({ value: true, column: column.id })(e)}>
																	View Summary
																</p>
															) : (
																'N/A'
															)
														) : column.id === 'note' && tableType === 'call' ? (
															tag?.note ? (
																<p style={textDecoration} onClick={(e) => toggleViewDrawer({ value: true, column: column.id })(e)}>
																	View Note
																</p>
															) : (
																'N/A'
															)
														) : column.id === 'status' && tableType === 'note' ? (
															tagStatus(tag?.status)
														) : column.id === 'metadata' && tableType === 'note' ? (
															getArrayStatus(tag?.metadata)
														) : column.id === 'recording' && tableType === 'call' ? (
															tag?.recording ? (
																<AudioPlayer url={tag.recording} />
															) : (
																'N/A'
															)
														) : column.id === 'name' && tableType === 'careAlly' ? (
															tag?.firstName || tag?.lastName ? (
																`${tag?.firstName} ${tag?.lastName}`
															) : (
																'N/A'
															)
														) : column.id === 'consentResponse' && tableType === 'careAlly' ? (
															<>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	<>
																		<span
																			style={{
																				...getConsentColors(tag?.consentResponse),
																				fontSize: '36px',
																			}}
																		>
																			•
																		</span>
																		<Typography fontSize={14} color="black">
																			{tag?.consentResponse === 'NO_RESPONSE'
																				? 'No Response'
																				: tag?.consentResponse?.charAt(0).toUpperCase() + tag?.consentResponse?.slice(1)}
																		</Typography>
																	</>
																</div>
															</>
														) : column.id === 'title' && tableType === 'task' ? (
															tag?.taskId?.title
														) : column.id === 'title' && tableType === 'journey' ? (
															tag?.journeyId?.title
														) : column.id === 'description' && tableType === 'task' ? (
															tag?.taskId?.description
														) : column.id === 'dueDate' && tableType === 'task' ? (
															moment(tag?.date)?.format('MMMM DD, YYYY')
														) : column.id === 'dateTime' ? (
															moment(tag?.dateTime)?.format('MMMM DD, YYYY')
														) : column.id === 'completed' ? (
															<>
																<AppointmentStatus data={tag} />
															</>
														) : column.id === 'triggerAutomatedAction' ? (
															tag?.triggerAutomatedAction === true ? (
																'Yes'
															) : (
																'No'
															)
														) : column.id === 'surgeryDate' ? (
															moment(tag?.surgeryDate)?.format('MMMM DD, YYYY')
														) : column.id === 'surgeonName' ? (
															tag.surgeon?.name ? (
																tag.surgeon?.name
															) : tag.surgeonName ? (
																tag.surgeonName
															) : (
																'N/A'
															)
														) : column.id === 'surgeryType' ? (
															tag?.surgeryType
																?.replace(/_/g, ' ')
																.split(/\s+/)
																.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
																.join(' ') || 'N/A'
														) : column.id === 'updatedAt' && tableType === 'form' && tag.status === 'complete' ? (
															moment(tag?.updatedAt)?.format('MMMM DD, YYYY')
														) : column.id === 'updatedAt' && tableType === 'form' && (tag.status === 'pending' || tag.status === 'opened') ? (
															'Pending update'
														) : column.id === 'date' ? (
															moment(tag?.date)?.format('MMMM DD, YYYY')
														) : column.id === 'description' && tableType === 'note' ? (
															noteDescription(tag?.description)
														) : column.id === 'assignedOn' && tag?.assignedOn && tag?.assignedOn !== 'N/A' ? (
															moment(tag[column.id])?.format('MMMM DD, YYYY')
														) : column.id === 'status' && tag?.status && tag?.status !== 'N/A' && tableType === 'journey' ? (
															<div>
																<LinearProgress
																	variant="determinate"
																	value={parseFloat(tag.status)}
																	sx={{
																		width: 'calc(100% - 4px)',
																		borderRadius: '10px',
																		height: '3.5px',
																		zIndex: 0,

																		backgroundColor: parseFloat(tag.status) > 0 ? '#CCF4FF' : '#0097EC',

																		'& .MuiLinearProgress-bar': {
																			backgroundColor: '#0097EC', // Desired colors
																		},
																	}}
																/>
																{parseFloat(tag[column.id])}%
															</div>
														) : column.id === 'status' && tableType === 'journey' ? (
															<div>{tag?.currentNodeCompleted ? 'Completed' : 'In Progress'}</div>
														) : column.id === 'createdAt' && tag?.createdAt ? (
															moment(tag[column.id])?.format('MMMM DD, YYYY')
														) : column.id === 'team' ? (
															team
														) : column.id === 'status' && tag?.status && tag?.status !== 'N/A' && tableType === 'task' ? (
															<Status status={tag?.status} id={tag?._id} />
														) : tag[column.id] ? (
															tag[column.id]
														) : (
															column.id !== 'action' && tableType !== 'program' && 'N/A'
														)}
													</Typography>
													{column.id === 'action' && tableType === 'call' && (
														<IconButton
															sx={{
																'&:hover .css-tksnbz-MuiSvgIcon-root': {
																	visibility: 'visible',
																},
																padding: '0px',
																mr: 1.5,
															}}
															onClick={(e) => toggleViewDrawer({ value: true, data: tag })(e)}
														>
															<VisibilityOutlinedIcon sx={{ color: '#979797' }} />
														</IconButton>
													)}
													{column.id === 'action' && tableType !== 'call' && (
														<>
															<ProfileActionCell
																tableType={tableType}
																tag={tag?.status}
																data={tag}
																setEditData={setEditData}
																handleModalEdit={handleModalEdit}
																fetchAgain={fetchAgain}
															/>
														</>
													)}
												</TableCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
							<ViewDrawer toggleDrawer={toggleViewDrawer} state={open} data={tableData} agentData={agentData} />
						</TableContainer>
						{tableType !== 'careAlly' && tableType !== 'physician' && tableType !== 'surgeon' && tableType !== 'pros' && (
							<PaginationBar {...{ page, count, handleChange: handleChangePage }} />
						)}
					</Box>
				</>
			) : (
				<Typography sx={{ fontSize: '16px', fontWeight: 600, textAlign: 'center', my: 6 }}>No Data Available</Typography>
			)}
		</>
	)
}
