import * as React from 'react'
import { Box, Popover, Button } from '@mui/material'
import { DateField as DatePicker } from '../../../Components'


export default function PopoverQueueUI({
	id,
	open,
	anchorEl,
	handleClose,
    startDate,
    setStartDate,
    endDate,
    setEndDate
}) {
	return (
		<div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
			>
				<Box sx={{ p: 2 }}>
					<Box sx={{ marginTop: '20px', marginBottom: '5px' }}>
						<DatePicker {...{ date: startDate, setDate: setStartDate, label: 'Start Date', isBorder: true }} />
					</Box>
					<DatePicker {...{ date: endDate, setDate: setEndDate, label: 'End Date', isBorder: true }} />
				</Box>
				<Box sx={{ textAlign: 'end', p: 2 }}>
					<Button
						variant="contained"
						size="medium"
						sx={{ background: '#0049C6', px: '16px', borderRadius: '30px', fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize' }}
						onClick={() => {
							handleClose()
						}}
					>
						Apply
					</Button>
				</Box>
			</Popover>
		</div>
	)
}
