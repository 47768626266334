import React, { useState } from "react";
import { Paper } from "@mui/material";
import { PatientTable as Table } from "./PatientTable";
import { TableHeader } from "./TableHeader";
import {
  useGetPatientsQuery,
  useGetCareProgramPatientsQuery,
} from "../../../store/patientsSlice";
import { useDebouncedValue } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useIsMobile } from "../../../contexts/isMobile";
import { useIsHovered } from '../../../contexts/isHovered'
import {InactivityCheck} from "../../../Components/InactivityCheck"
import { PatientSidebar } from "../CreatePatient/Sidebar";
import { patientTableSidebarData } from "../patientUtils";
import { PatientTableUI } from "./PatientTableUI";
import { ProsUI } from "../../ProsUI";
import {SocialNeedsUI} from "../../SocialNeedUI"

export function PatientTable() {
  // Using use Params
  let { programValue } = useParams();
  const isMobile = useIsMobile();
  const { hovered } = useIsHovered()
  const [pageName, setPageName] = useState(localStorage.getItem('pageName2') ? JSON.parse(localStorage.getItem('pageName2')) : 'patient')
  const [page, setPage] = useState(localStorage.getItem('page3') ? parseInt(localStorage.getItem('page3')) : 1);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["email", "firstName", "lastName", "phone"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
  const [queriesValue, setQueriesValue] = useState(localStorage.getItem('queriesValue2') ? JSON.parse(localStorage.getItem('queriesValue2')) : {})

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };
  const { user } = useSelector((state) => state.user);
  const queries = {};
  // for (const field of selectedFields) {
  //   queries[field] = debouncedValue;
  // }

  queries["email"] = debouncedValue
  queries["firstName"] = debouncedValue
  queries["lastName"] = debouncedValue
  queries["phone"] = debouncedValue
	queries['program'] = queriesValue?.program || []
  queries['status'] = queriesValue?.status || 'active'
  queries['consentApproved'] = queriesValue?.consentApproved || ''

  const { data, isLoading, isError, isSuccess, refetch } = useGetPatientsQuery(
    {
      userID: user.id,
      page,
      tags: selectedTags,
      ...queries,
    },
    { refetchOnMountOrArgChange: true }
  );

  // For Care Program Patient
  const {
    data: careProgramData,
    isLoading: careProgramLoading,
    isError: careProgramError,
    isSuccess: careProgramSuccess,
  } = useGetCareProgramPatientsQuery(
    {
      userID: user.id,
      page,
      programValue,
      tags: selectedTags,
      ...queries,
    },
    { refetchOnMountOrArgChange: true }
  );

  const refetchPatientAgain = ()=>{
    refetch()
  }

  React.useEffect(() => {
		if (page > data?.totalPages) {
			localStorage.setItem('page3', 1)
			setPage(1)
		}
	}, [data])

  React.useEffect(()=>{
    localStorage?.removeItem('createCareAllyForm');
    localStorage?.removeItem("stepperValue");
    localStorage?.removeItem("patientForm")
    localStorage?.removeItem("assignJourneyForm")
    localStorage?.removeItem("assignSurgeryForm")
  },[])

  // If Token is expire it navigate to Login page
  return (
    <>
        <Paper
      sx={{
        width: "95%",
        boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
        border: "1px solid #D0EAE1",
        margin: '45px',
        paddingX: '12px' 
      }}
      elevation={0}
    >
    {programValue !== undefined ? (
    <div style={{ padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem" }}>
      <TableHeader
        {...{
          selectedTags,
          setSelectedTags,
          searchTerm,
          setSearchTerm,
          setQueriesValue,
          queriesValue,
          options: searchAbleFields,
          selectedOptions: selectedFields,
          handleOptionSelect: handleFieldSelect,
        }}
      />
       <Table
          {...{
            selectedTags,
            setSelectedTags,
            page,
            setPage,
            data: careProgramData,
            isLoading: careProgramLoading,
            isError: careProgramError,
            isSuccess: careProgramSuccess,
            refetchPatientAgain
          }}
        />
    </div>
    ):(
      <PatientTableUI {...{
        selectedTags,
        setSelectedTags,
        searchTerm,
        setSearchTerm,
        setQueriesValue,
        queriesValue,
        searchAbleFields,
        selectedFields,
        handleFieldSelect,
        page,
        setPage,
        data,
        isLoading,
        isError,
        isSuccess,
        refetchPatientAgain,
        pageName
      }} />
    )}
    </Paper>
    <InactivityCheck />
    </>
  );
}
