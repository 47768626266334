import { useEffect, useRef, useState } from "react";
import { useReactFlow, useStore } from "reactflow";
import { stratify, tree } from "d3-hierarchy";
import { timer } from "d3-timer";
import { useChildNode } from "../../../../contexts/addChildNode";

const layout = tree()
  .nodeSize([700, 150])
  .separation(() => 1);

const options = { duration: 300 };

function layoutNodes(nodes, edges) {
  if (nodes.length === 0) {
    return [];
  }

  const hierarchy = stratify()
    .id((d) => d.id)
    .parentId((d) => edges.find((e) => e.target === d.id)?.source)(nodes);
  const root = layout(hierarchy);

  return root.descendants().map((d, index) => {
    return {
      ...d.data,
      position: { x: d.x, y: d.y },
    };
  });
  // return root.descendants().map((d) => {
  // 	let x = d.x;
  // 	if (d.parent) {
  // 		const parentX = d.parent.data.position.x;
  // 		const offset = 300;
  // 		if (d.data.data.label === 'yes') {
  // 			x = parentX - offset;
  // 		} else if (d.data.data.label === 'no') {

  // 			x = parentX + offset;
  // 		}
  // else if (d.parent && d.parent?.data.position.x > 0){
  // 	x = parentX + offset;
  // }
  // else if(d.parent && d.parent?.data.position.x < 0){
  // 	x = parentX - offset;
  // }

  // 	}
  // 	return {
  // 		...d.data,
  // 		position: {
  // 			x: x,
  // 			y: d.data.data.label === 'yes' || d.data.data.label === 'no' ? d.y + 30 : d.y,
  // 		},
  // 	};
  // });
}

const nodeCountSelector = (state) => state.nodeInternals.size;

function useLayout() {
	const { refreshworkflow } = useChildNode()
  const initial = useRef(true);
  const nodeCount = useStore(nodeCountSelector);

  const { getNodes, getNode, setNodes, setEdges, getEdges, fitView } =
    useReactFlow();
  useEffect(() => {
    let nodes = getNodes();
    const edges = getEdges();
    for (let k = 0; k < Array.from(nodes).length; k++) {
      if (
        (Array.from(nodes)[k].data.method === "form" &&
        (Array.from(nodes)[k].data.checkCondition === true || (Array.from(nodes)[k].data.waitUntilReminderAreSend === true && Array.from(nodes)[k].data.checkSubmission === true))) ||
        (Array.from(nodes)[k].data.method === "call" && Array.from(nodes)[k].data.isFilled === true)

      ) {
        let abc = nodes.findIndex(
          (n) =>
            n.id === Array.from(nodes)[k].data.positiveChildNodeId ||
            n.id === Array.from(nodes)[k].data.negativeChildNodeId
        );

        if (abc < 0) {
          nodes.push({
            width: undefined,
            height: undefined,
            id: Array.from(nodes)[k].data.positiveChildNodeId,
            position: {
              x: Array.from(nodes)[k].position.x,
              y: Array.from(nodes)[k].position.y,
            },
            data: {
              ...Array.from(nodes)[k].data,
              parentNodeId: Array.from(nodes)[k].id,
              label: Array.from(nodes)[k].data.method === "call" ? "attended" : "yes",
            },
            type: Array.from(nodes)[k].type,
          });

          nodes.push({
            width: undefined,
            height: undefined,
            id: Array.from(nodes)[k].data.negativeChildNodeId,
            position: {
              x: Array.from(nodes)[k].position.x,
              y: Array.from(nodes)[k].position.y,
            },
            data: {
              ...Array.from(nodes)[k].data,
              parentNodeId: Array.from(nodes)[k].id,
              label: Array.from(nodes)[k].data.method === "call" ? "not attended" : "no",
            },
            type: Array.from(nodes)[k].type,
          });
        }
      }
    }

    const targetNodes = layoutNodes(nodes, edges);
    const transitions = targetNodes.map((node) => {
      return {
        id: node.id,
        from: getNode(node.id)?.position || node.position,
        to: node.position,
        node,
      };
    });
    const t = timer((elapsed) => {
      const s = elapsed / options.duration;
      let currNodes = [];
      for (let j = 0; j < Array.from(transitions).length; j++) {
        currNodes.push({
          id: Array.from(transitions)[j].node.id,
          position: {
            x:
              Array.from(transitions)[j].from.x +
              (Array.from(transitions)[j].to.x -
                Array.from(transitions)[j].from.x) *
                s,
            y:
              Array.from(transitions)[j].from.y +
              (Array.from(transitions)[j].to.y -
                Array.from(transitions)[j].from.y) *
                s,
          },
          data: { ...Array.from(transitions)[j].node.data },
          type: Array.from(transitions)[j].node.type,
        });
      }
      setNodes(currNodes);

      if (elapsed > options.duration) {
        let finalNodes = [];
        for (let i = 0; i < Array.from(transitions).length; i++) {
          finalNodes.push({
            id: Array.from(transitions)[i].node.id,
            position: {
              x: Array.from(transitions)[i].to.x,
              y: Array.from(transitions)[i].to.y,
            },
            data: { ...Array.from(transitions)[i].node.data },
            type: Array.from(transitions)[i].node.type,
          });
        }
        setNodes(finalNodes);

        t.stop();

        if (!initial.current) {
        //   fitView({ duration: 200, padding: 0.2 });
        }
        initial.current = false;
      }
    });

    return () => {
      t.stop();
    };
  }, [nodeCount, getEdges, getNodes, getNode, setNodes, fitView, setEdges , refreshworkflow]);
}

export default useLayout;

// import { useEffect, useRef } from 'react';
// import { useReactFlow, useStore } from 'reactflow';
// import { stratify, tree } from 'd3-hierarchy';
// import { timer } from 'd3-timer';

// const layout = tree()
//   .nodeSize([200, 150])
//   .separation(() => 1);

// const options = { duration: 300 };

// function layoutNodes(nodes, edges) {
//   if (nodes.length === 0) {
//     return [];
//   }

//   const hierarchy = stratify()
//     .id((d) => d.id)
//     .parentId((d) => edges.find((e) => e.target === d.id)?.source)(nodes);

//   const root = layout(hierarchy);

//   return root.descendants().map((d) => ({ ...d.data, position: { x: d.x, y: d.y } }));
// }

// const nodeCountSelector = (state) => state.nodeInternals.size;

// function useLayout() {
//   const initial = useRef(true);

//   const nodeCount = useStore(nodeCountSelector);
//   const { getNodes, getNode, setNodes, setEdges, getEdges, fitView } = useReactFlow();

//   useEffect(() => {
//     const nodes = getNodes();
//     const edges = getEdges();

//     const targetNodes = layoutNodes(nodes, edges);

//     const transitions = targetNodes.map((node) => {
//       return {
//         id: node.id,
//         from: getNode(node.id)?.position || node.position,
//         to: node.position,
//         node,
//       };
//     });

//     const t = timer((elapsed) => {
//       const s = elapsed / options.duration;

//       const currNodes = transitions.map(({ node, from, to }) => {
//         return {
//           id: node.id,
//           position: {
//             x: from.x + (to.x - from.x) * s,
//             y: from.y + (to.y - from.y) * s,
//           },
//           data: { ...node.data },
//           type: node.type,
//         };
//       });

//       setNodes(currNodes);

//       if (elapsed > options.duration) {
//         const finalNodes = transitions.map(({ node, to }) => {
//           return {
//             id: node.id,
//             position: {
//               x: to.x,
//               y: to.y,
//             },
//             data: { ...node.data },
//             type: node.type,
//           };
//         });

//         setNodes(finalNodes);

//         t.stop();

//         if (!initial.current) {
//           fitView({ duration: 200, padding: 0.2 });
//         }
//         initial.current = false;
//       }
//     });

//     return () => {
//       t.stop();
//     };
//   }, [nodeCount, getEdges, getNodes, getNode, setNodes, fitView, setEdges]);
// }

// export default useLayout;
