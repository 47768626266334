import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSocialCare: builder.query({
      query: (query = {}) => {
				const { page = 1, limit = 10, ...rest } = query

				const finalQuery = {
					page,
					limit,
					...rest,
				}

        return {
          url: `/socialcare`,
          method: 'POST',
          body: finalQuery
        };
			},
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "SocialCare", id: "LIST" },
          ...data.map((p) => ({ type: "SocialCare" })),
        ];
      },
    }),
    getSocialCareFilter: builder.query({
      query: () => {
        return `/socialcare/filters`;
      }
    }),
    getSocialCareDetail: builder.query({
      query: (id) => {
        return `/socialcare/patient/${id}`;
      }
    }),
  }),
});

export const {
  useGetSocialCareQuery,
  useGetSocialCareFilterQuery,
  useGetSocialCareDetailQuery
} = extendedApiSlice;
