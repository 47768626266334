import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientSurgeries: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10, patientId, ...rest } = query;
        const finalQuery = { page, limit, ...rest };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/surgeries/patient/${patientId}?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Surgeries", id: "LIST" },
          ...data.map((p) => ({ type: "Surgeries", id: p._id })),
        ];
      },
    }),
    getSurgeryByID: builder.query({
      query: (id) => `/surgeries/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Surgeries", id: "LIST" },
          { type: "Surgeries", id: data._id || arg },
        ];
      },
    }),
    addNewSurgery: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/surgeries",
          method: "POST",
          body: initialPost
        };
      },
      invalidatesTags: [{ type: "Surgery", id: "LIST" }],
    }),
    editSurgery: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/surgeries/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ({ data }, error, { id }) => {
        return [
          { type: "Surgeries", id: "LIST" },
          { type: "Surgeries", id: data.id || id },
        ];
      },
    }),
    deleteSurgery: builder.mutation({
      query: (id) => {
        return {
          url: `/surgeries/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Surgeries", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPatientSurgeriesQuery,
  useGetSurgeryByIDQuery,
  useAddNewSurgeryMutation,
  useEditSurgeryMutation,
  useDeleteSurgeryMutation,
} = extendedApiSlice;
