import React from 'react';
import {Card, CardContent, Typography} from '@mui/material'


const delayReason = (reason, wait) => {
  if (wait) return 'Reminder';

  return reason.charAt(0).toUpperCase() + reason.slice(1);
};

export const PauseCard = ({data, isPublished}) => (
  <Card sx={{ minWidth: 206, border: 'solid 1px black' }}>
    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {!isPublished && <img src={require('../assets/Waiting-Sand-Transparent.gif')} style={{ width: '60px' }} alt="Pause Gif" />}
      <Typography sx={{ fontSize: 14, fontWeight: 800 }} gutterBottom>
        {isPublished ? `⚠️ Warning` : 'Journey Paused!'}
      </Typography>
      <Typography variant="body2">
        {!isPublished ? (
        `Due to ${delayReason(data?.waitingFor, data?.waitUntilReminderAreSend)}`
        ): <>
        Making edits to published <br/> journeys will change their <br/> status to draft.
        </>}
      </Typography>
    </CardContent>
  </Card>
);

export default PauseCard;
