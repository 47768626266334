import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { Menu, MenuItem } from "@mui/material";
import { useReactFlow, getOutgoers } from "reactflow";
import { uuid } from "../Pages/Journeys/Flow/utils";
import { useChildNode } from "../contexts/addChildNode";
import { toast } from "react-toastify";
import { AddDrawerV2 } from "./AddDrawerV2";

const MethodData = [
  { id: "form", label: "Form" },
  { id: "task", label: "Task" },
  { id: "sms", label: "SMS" },
  { id: "assign_journey", label: "Assign Journey" },
  { id: "deactivate_patient", label: "Deactivate Patient" },
  { id: "note", label: "Generate Note" },
  { id: "call", label: "Call Patient" },
  { id: "email", label: "Email Notification" },
];

export function AddButtonFlowV2({
  onClick,
  label,
  method,
  anchorEl,
  setAnchorEl,
  id,
  setChildNodeId,
  childDelayNodeId,
  setChildDelayNodeId,
  positiveChildNodeId,
  setPositiveChildNodeId,
  negativeChildNodeId,
  setNegativeChildNodeId,
  setAddChildNode,
  addChildNode,
  nodeInternals,
  data,
}) {
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();
  const { isChildNodeAdded, setForceRefreshChildNode, setOpenDrawer, openDrawer, setOpenMenu } = useChildNode();

  const [menuMethod, setMenuMethod] = useState(null)

  const open = Boolean(anchorEl);

  const checkNextNode = (idOfNode) => {
    const allEdges = getEdges();
    const filteredEdges = allEdges.find(item => item?.source === idOfNode)

    if(filteredEdges?.target === undefined && idOfNode === "1"){
      return true;
    }

    return filteredEdges?.target;
  };

  const handleClick = (event) => {
    // isChildNodeAdded && !checkNextNode(id)
    if (isChildNodeAdded && !checkNextNode(id)) {
      toast.warning(
        "Please Add Child Node or saved node to continue. The unsaved node has a red flashing sign."
      );
      return;
    }
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const getNextNodeId = (id) => {
		const allEdges = getEdges()
		const filteredEdges = allEdges.find((item) => item?.source === id)
		return filteredEdges?.target
  };

  const createNode = (
    id,
    name,
    delay,
    method,
    metric,
    childNodeId,
    positiveChildNodeId = null,
    negativeChildNodeId = null,
    checkCondition = null,
    data = null,
    selectForm = null,
    prevNodeId,
    nextNodeId,
    hasChildNode,
    isNotDelayNode
  ) => {
    let extra = "";
    let pages = [];
    let surveyValues = {};
    let conditionOperator = "";
    let form = {},
      task = {};
    let journeyId = "";
    let journeyName = "";
    if (data?.method === "form") {
      extra = "";
      pages = [];
      surveyValues = {};
      conditionOperator = "";
      form = {};
    }
    if (data?.method === "task") {
      extra = "";
      task = "";
    }
    if (data?.method === "assign_journey") {
      journeyId = "";
      journeyName = "";
    }

    const parentNode = getNode(id);

    if (!parentNode) {
      return;
    }

    let childNode;
    let childEdge;
    let childEdgeOne;

    const existingPlaceholders = getOutgoers(parentNode, getNodes(), getEdges())
      .filter((node) => node.type === "placeholder")
      .map((node) => node.id);

    if (hasChildNode) {
      childNode = {
        id: childNodeId,
        position: { x: parentNode.position.x, y: parentNode.position.y + 150 },
        type: "workflow",
        data: {
          task: task,
          form: form,
          extra: extra,
          pages: pages,
          prevNodeId: id,
          isFilled: false,
          label: name,
          metric,
          method,
          value: delay,
          parentNodeId: id,
          positiveChildNodeId,
          negativeChildNodeId,
          parentPositiveChildNodeId:
            parentNode?.data?.positiveChildNodeId &&
            (parentNode?.data?.label === "yes" ||
              parentNode?.data?.label === "attended")
              ? parentNode.id
              : parentNode?.data?.parentPositiveChildNodeId
              ? parentNode?.data?.parentPositiveChildNodeId
              : null,
          parentNegativeChildNodeId:
            parentNode?.data?.negativeChildNodeId &&
            (parentNode?.data?.label === "no" ||
              parentNode?.data?.label === "not attended")
              ? parentNode.id
              : parentNode?.data?.parentNegativeChildNodeId
              ? parentNode?.data?.parentNegativeChildNodeId
              : null,
          checkCondition,
          waitUntilReminderAreSend: method === "form" ? true : false,
          checkSubmission: false,
          surveyValues: surveyValues,
          conditionOperator: conditionOperator,
          journeyId,
          journeyName,
          nextNodeId: getNextNodeId(id),
          isChildNode: true,
          delayFor: "N/A",
          timeRelation: "before",
          hourToRunAt: {},
          skippable: true,
          smsOnFormAssignment: `Dear Patient,\nWe value you and would appreciate it if you could take a moment to complete a short survey.\nPlease click on the following link to access the survey:\n{{ surveyLink }}`,
        },
      };

      childEdgeOne = [
        {
          id: `${parentNode.id}=>${childNode.id}`,
          source: parentNode.id,
          target: childNode.id,
          type: "workflow",
        },
        {
          id: `${childNodeId}=>${
            getNextNodeId(id)
          }`,
          source: childNodeId,
          target: getNextNodeId(id),
          type: "workflow",
        },
      ];

      const targetValue = getNextNodeId(id);

      setEdges((edges) =>
        edges.filter((item) => item.target !== targetValue).concat(childEdgeOne)
      );
    } else {
      childNode = {
        id: childNodeId,
        position: { x: parentNode.position.x, y: parentNode.position.y + 150 },
        type: "workflow",
        data: {
          task: task,
          form: form,
          extra: extra,
          pages: pages,
          prevNodeId,
          isFilled: false,
          label: name,
          metric,
          method,
          value: delay,
          parentNodeId: id,
          positiveChildNodeId,
          negativeChildNodeId,
          parentPositiveChildNodeId:
            parentNode?.data?.positiveChildNodeId &&
            (parentNode?.data?.label === "yes" ||
              parentNode?.data?.label === "attended")
              ? parentNode.id
              : parentNode?.data?.parentPositiveChildNodeId
              ? parentNode?.data?.parentPositiveChildNodeId
              : null,
          parentNegativeChildNodeId:
            parentNode?.data?.negativeChildNodeId &&
            (parentNode?.data?.label === "no" ||
              parentNode?.data?.label === "not attended")
              ? parentNode.id
              : parentNode?.data?.parentNegativeChildNodeId
              ? parentNode?.data?.parentNegativeChildNodeId
              : null,
          checkCondition,
          waitUntilReminderAreSend: method === "form" ? true : false,
          surveyValues: surveyValues,
          conditionOperator: conditionOperator,
          journeyId,
          journeyName,
          nextNodeId,
          delayFor: "N/A",
          timeRelation: "before",
          hourToRunAt: {},
          skippable:true,
          smsOnFormAssignment: `Dear Patient,\nWe value you and would appreciate it if you could take a moment to complete a short survey.\nPlease click on the following link to access the survey:\n{{ surveyLink }}`,
        },
      };

      childEdgeOne = [
        {
          id: `${parentNode.id}=>${childNodeId}`,
          source: parentNode.id,
          target: childNodeId,
          type: "workflow",
        },
      ];

      let childEdges = [];
      if (positiveChildNodeId !== null && negativeChildNodeId !== null) {
        childEdges.push({
          id: `${childNodeId}=>${positiveChildNodeId}`,
          source: childNodeId,
          target: positiveChildNodeId,
          type: "workflow",
        });
        childEdges.push({
          id: `${childNodeId}=>${negativeChildNodeId}`,
          source: childNodeId,
          target: negativeChildNodeId,
          type: "workflow",
        });
      }
      if (childEdges.length > 0) {
        childEdge = childEdgeOne.concat(childEdges);
      } else {
        childEdge = childEdgeOne;
      }

      setEdges((edges) =>
        edges
          .filter((edge) => !existingPlaceholders.includes(edge.target))
          .concat(childEdge)
      );
    }

    setTimeout(() => {
      setNodes((nodes) =>
        nodes
          .filter((node) => !existingPlaceholders.includes(node.id))
          .concat([childNode])
      );
    }, 100);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleMenuClick = async (menuMethod) => {
    let childNodeId;
    const parentNode = getNode(id);

    if (
      parentNode?.data?.nextNodeId &&
      (parentNode?.data?.label === "no" ||
        parentNode?.data?.label === "not attended")
    ) {
      childNodeId = uuid();
    } else if (
      (parentNode?.data?.nextNodeId ||
        parentNode?.data?.label === "yes" ||
        parentNode?.data?.label === "attended") &&
        !getNextNodeId(parentNode?.data?.negativeChildNodeId) &&
      !addChildNode
    ) {
      childNodeId = parentNode?.data?.nextNodeId;
    } else if (
      (parentNode?.data?.nextNodeId ||
        parentNode?.data?.label === "yes" ||
        parentNode?.data?.label === "attended") &&
        getNextNodeId(parentNode?.data?.negativeChildNodeId) &&
      !addChildNode
    ) {
      childNodeId = uuid();
    } else {
      childNodeId = uuid();
    }

    // Journey Data
    const journeyData = {
      method: menuMethod,
    };

    if (menuMethod === "sms") {
      journeyData.sms = {
        sms: "SMS will be here",
        childNodeId: childDelayNodeId,
        method: menuMethod,
        parentNodeId: childNodeId,
        skippable:true
      };
      journeyData.delay = {
        childNodeId,
        conditionOperator: "and",
        delay: "1",
        metric: "minutes",
        value: 1,
        method: "delay",
        parentNodeId: id,
      };
    } else if (menuMethod === "task") {
      journeyData.task = {
        task: "Task Id will be here",
        childNodeId,
        method: menuMethod,
        parentNodeId: id,
        skippable:true
      };
      journeyData.delay = {
        childNodeId: childDelayNodeId,
        conditionOperator: "and",
        delay: "1",
        metric: "minutes",
        value: 1,
        method: "delay",
        parentNodeId: childNodeId,
        delayFor: "N/A",
        timeRelation: "before",
      };
    } else if (menuMethod === "trigger") {
      journeyData.trigger = {
        triggerType: "trigger",
        childNodeId,
        method: menuMethod,
        parentNodeId: id,
        skippable:true
      };
      journeyData.delay = {
        childNodeId: childDelayNodeId,
        conditionOperator: "and",
        delay: "1",
        metric: "minutes",
        value: 1,
        method: "delay",
        parentNodeId: childNodeId,
      };
    } else if (menuMethod === "assign_journey") {
      journeyData.assign_journey = {
        childNodeId,
        method: menuMethod,
        parentNodeId: id,
        journeyId: "triggerJourney.id",
        journeyName: "triggerJourney.name",
        skippable:true
      };
      journeyData.delay = {
        childNodeId: childDelayNodeId,
        conditionOperator: "and",
        delay: "1",
        metric: "minutes",
        value: 1,
        method: "delay",
        parentNodeId: childNodeId,
      };
    } else if (menuMethod === "form") {
      journeyData.form = {
        form: "selectForm.id",
        conditionOperator: "and",
        checkCondition: false,
        surveyValues: "noting",
        childNodeId,
        method,
        parentNodeId: id,
        positiveChildNodeId,
        negativeChildNodeId,
        waitUntilReminderAreSend: true,
        checkCondition: false,
        metric: "minutes",
        delay: "1",
        delayValue: "1",
        skippable:true
      };
      journeyData.delay = {
        childNodeId: childDelayNodeId,
        conditionOperator: "and",
        delay: "1",
        metric: "minutes",
        value: 1,
        method: "delay",
        parentNodeId: childNodeId,
        delayFor: "N/A",
        timeRelation: "before",
      };
    } else if (menuMethod === "deactivate_patient") {
      journeyData.deactivate_patient = {
        childNodeId,
        method: menuMethod,
        parentNodeId: id,
        isFilled: true,
        skippable:true
      };
      journeyData.delay = {
        childNodeId: childDelayNodeId,
        conditionOperator: "and",
        delay: "1",
        metric: "minutes",
        value: 1,
        method: "delay",
        parentNodeId: childNodeId,
        delayFor: "N/A",
        timeRelation: "before",
      };
    } else if (menuMethod === "note") {
      journeyData.note = {
        childNodeId,
        method: menuMethod,
        parentNodeId: id,
        isFilled: true,
        skippable:true
      };
      journeyData.delay = {
        childNodeId: childDelayNodeId,
        conditionOperator: "and",
        delay: "1",
        metric: "minutes",
        value: 1,
        method: "delay",
        parentNodeId: childNodeId,
        delayFor: "N/A",
        timeRelation: "before",
      };
    } else if (menuMethod === "call") {
      journeyData.call = {
        childNodeId,
        method: menuMethod,
        parentNodeId: id,
        isFilled: true,
        positiveChildNodeId,
        negativeChildNodeId,
        skippable:true
      };
      journeyData.delay = {
        childNodeId: childDelayNodeId,
        conditionOperator: "and",
        delay: "1",
        metric: "minutes",
        value: 1,
        method: "delay",
        parentNodeId: childNodeId,
        delayFor: "N/A",
        timeRelation: "before",
      };
    } else if (menuMethod === "email") {
      journeyData.email = {
        childNodeId,
        method: menuMethod,
        parentNodeId: id,
        isFilled: true,
        skippable:true
      };
      journeyData.delay = {
        childNodeId: childDelayNodeId,
        conditionOperator: "and",
        delay: "1",
        metric: "minutes",
        value: 1,
        method: "delay",
        parentNodeId: childNodeId,
        delayFor: "N/A",
        timeRelation: "before",
      };
    }

    let name = menuMethod;

    createNode(
      id,
      name,
      "1",
      "delay",
      "minutes",
      childNodeId,
      null,
      null,
      false,
      journeyData,
      null,
      id,
      childDelayNodeId,
      addChildNode,
      false
    );
    // createNode(id,'1','1','delay','minutes',childDelayNodeId, null, null, false,journeyData, null)

    setTimeout(() => {
      const newChildNodeId = uuid();
      setOpenMenu(childDelayNodeId)
      createNode(
        childNodeId,
        name,
        "1",
        menuMethod,
        "minutes",
        childDelayNodeId,
        null,
        null,
        false,
        journeyData,
        null,
        childNodeId,
        newChildNodeId,
        addChildNode,
        true
      );

      // createNode(childDelayNodeId, name, '1', menuMethod, 'minutes', childNodeId, null, null, false, journeyData, null);
      setChildNodeId(newChildNodeId);
      setChildDelayNodeId(uuid());
      setOpenDrawer(true)
    }, 600);

    setAddChildNode(false);
    setForceRefreshChildNode(false);

    // let edit;
    // if (paramsId) {
    //   edit = await editJourney({ id: paramsId, body: journeyData });
    // } else {
    //   const journeyIdString = JSON.parse(localStorage.getItem("journeyId"));
    //   edit = await editJourney({ id: journeyIdString, body: journeyData });
    // }

    // setPositiveChildNodeId(uuid())
    // setNegativeChildNodeId(uuid())
  };

  return (
    <>
      <IconButton
        aria-label="delete"
        size="small"
        sx={{
          background: "#816179",
          // px: method === "delay" ? "1px" : "8px",
          // py: "0px",
          borderRadius: "50%",
          height:
            (data?.positiveChildNodeId &&
              (label === "yes" ||
                label === "no" ||
                label === "attended" ||
                label === "not attended")) ||
            label === "Start Journey"
              ? "20px"
              : "30px",
          padding:
            data?.positiveChildNodeId &&
            (label === "yes" ||
              label === "no" ||
              label === "attended" ||
              label === "not attended")
              ? "10px"
              : "5px",
          width:
            data?.positiveChildNodeId &&
            (label === "yes" ||
              label === "no" ||
              label === "attended" ||
              label === "not attended") &&
            label !== "Start Journey"
              ? "5%"
              : label === "Start Journey"
              ? "20px"
              : "30px",
          marginBottom:
            data?.positiveChildNodeId &&
            (label === "yes" ||
              label === "no" ||
              label === "attended" ||
              label === "not attended")
              ? "-15px"
              : label === "Start Journey"
              ? "-23px"
              : "-28px",
          marginTop:
            (!data?.positiveChildNodeId &&
              (label !== "yes" ||
                label !== "no" ||
                label === "attended" ||
                label === "not attended")) ||
            label !== "Start Journey"
              ? "4px"
              : "0px",
          ":hover": { background: "#816179" },
        }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <AddIcon
          fontSize="inherit"
          sx={{
            color: "white",
            ":hover": { color: "black" },
            height:
              (data?.positiveChildNodeId &&
                (label === "yes" ||
                  label === "no" ||
                  label === "attended" ||
                  label === "not attended")) ||
              label === "Start Journey"
                ? "12px"
                : "20px",
            width:
              (data?.positiveChildNodeId &&
                (label === "yes" ||
                  label === "no" ||
                  label === "attended" ||
                  label === "not attended")) ||
              label === "Start Journey"
                ? "12px"
                : "20px",
          }}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        value={method}
      >
        {MethodData.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            onClick={() => {handleMenuClick(item.id)}}
          >
            {" "}
            {item.label}{" "}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
