import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useGetPatientByIDQuery } from '../../../store/patientsSlice'
import { useGetPatientQueueProfileByIDQuery } from '../../../store/patientQueuesSlice'
import { useGetProfileByPatientIDQuery } from '../../../store/riskProfilesSlice'
import { useNavigate } from 'react-router-dom'
import { useDeletePatientMutation } from '../../../store/patientsSlice'
import { PATHS } from '../../../constants/routes'
import { toast } from 'react-toastify'
import { CircularLoader, InactivityCheck } from '../../../Components'
import ProfileDetailSidebar from './ProfileDetailSidebar'
import { QueueProfileCard } from './QueueProfile'
import { useIsHovered } from '../../../contexts/isHovered'
import { useIsMobile } from '../../../contexts/isMobile'
import axios from 'axios'
import { getTokenFromLocalStroage } from '../../../utils'
import ProfileTabs from '../../../Components/MessageLayout/ProfileTab'
import { useGetChatQuery } from '../../../store/chatSlice'

export function QueueProfile() {
	const location = useLocation()
	const isMobile = useIsMobile()
	const tabValue = location.state?.value
	const [value, setValue] = useState(tabValue === 3 ? 2 : (tabValue !== 2 && tabValue) || 0)
	const [careAllyData, setCareAllyData] = useState(null)
  const [page, setPage] = useState(1)
	const id = location.pathname.split('/').reverse()[0]
	const { data, isError, isLoading, isSuccess, refetch } = useGetPatientQueueProfileByIDQuery(id, { refetchOnMountOrArgChange: true })
	const { data:chatData, refetch: chatRefetch, isLoading: isChatLoading } = useGetChatQuery({ id:data?.patient?._id, sid: '2', page })
	const [deletePatient] = useDeletePatientMutation()
	const navigate = useNavigate()

	const config = {
		headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
	}

	const fetchData = async (patientId) => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/v2/careAlly/patient/${patientId}`, config)
			setCareAllyData(response.data)
		} catch (error) {
			setCareAllyData(null)
			console.error('Error fetching data:', error)
		}
	}

	const refetchAgain = () => {
		refetch()
	}

	const onDelete = (id) => {
		deletePatient(id)
			.unwrap()
			.then(() => {
				refetch()
				toast.success('Successfully deleted!')
			})
			.catch((e) => toast.error(e.message || 'some error occured!'))
			.finally(() => navigate(PATHS.patientsIndexPage))
	}

	// Dummy Data for accordion
	const accordianData = [{ id: 1, title: 'Form' }]

	const refetchCareAllyAgain = () => {
		if (data) {
			fetchData(data?.patient?._id)
		}
	}

	const refetchChat = ()=>{
		chatRefetch()
	}

	useEffect(() => {
		if (data) {
			fetchData(data?.patient?._id)
		}
	}, [data])

	console.log(chatData)

	if (isError) return <div>some error</div>
	if (isLoading)
		return (
			<div>
				<CircularLoader />
			</div>
		)

	const name = `${data?.patient?.firstName} ${data?.patient?.lastName}`

	const { acuity, patient, taskCategory, originalMessage, chatbotReply, reasoning, createdAt, userFeedback, _id, status } = data

	return (
		<div
			style={{
				backgroundColor: 'white',
				padding: isMobile ? '1.5rem 1rem' : '1.5rem 2rem',
				overflow: 'hidden',
				height: '95vh',
			}}
		>
			{isSuccess && (
				<Grid container spacing={2} justifyContent={'space-around'}>
					<Grid item md={12} xs={12}>
						<QueueProfileCard
							id={patient?._id}
							name={name}
							email={patient?.email}
							path={null}
							acuity={acuity}
							taskCategory={taskCategory}
							patient={patient}
							value={value}
							careAllyData={careAllyData?.careAlly ? careAllyData?.careAlly : null}
						/>
					</Grid>
					<Grid item md={8.5} xs={12} mt={1}>
						<ProfileTabs
							isDrawer={false}
							refetchAgain={refetchAgain}
							queueId={_id}
							userFeedback={userFeedback}
							originalMessage={originalMessage}
							chatbotReply={chatbotReply}
							patient={patient}
							reasoning={reasoning}
							createdAt={createdAt}
							queueStatus={status}
							patientDrawer={false}
							acuity={acuity}
							taskCategory={taskCategory}
							value={value}
							setValue={setValue}
							careAllyData={careAllyData?.careAlly ? careAllyData?.careAlly : null}
							refetchCareAllyAgain={refetchCareAllyAgain}
							tabValue={tabValue}
							consent={patient?.consentResponse}
							status={patient?.status}

              page={page}
              setPage={setPage}
			  refetchPatientAgain={refetchAgain}
			  refetchChat={refetchChat}
			  chatData={chatData}
			  isChatLoading={isChatLoading}
						/>
					</Grid>
					{!isMobile && (
						<Grid item md={3} xs={3} mt={1}>
							<ProfileDetailSidebar name={name} path={null} patient={patient} />
						</Grid>
					)}
				</Grid>
			)}
			<InactivityCheck />
		</div>
	)
}
