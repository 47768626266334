import * as React from 'react'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export default function TimeFieldPicker({ isSurgery,label, date, setDate, setHourToRunAt, onChange }) {
	const handleDateChange = (newValue) => {
		if(newValue === null){
			setHourToRunAt({})
			return;
		}
		
		const originalDate = new Date(newValue)

		// Extract hour, minute
		const hours = String(originalDate.getHours()).padStart(2, '0')
		const minutes = String(originalDate.getMinutes()).padStart(2, '0')

		setHourToRunAt({
			hour: hours,
			minute: minutes,
		})

		setDate(originalDate)
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DemoContainer components={['TimePicker']}>
				<TimePicker
					label={!isSurgery && (label ? label : 'Specific Hours')}
					format="hh:mm a"
					value={date}
					onChange={onChange ? onChange :  (newValue) => handleDateChange(newValue)}
					sx={[
						{
							"& .MuiOutlinedInput-notchedOutline": {
							  border: isSurgery && "none",
							  borderBottom: isSurgery && "1px solid #979797 !important",
							  borderRadius:isSurgery && 0,
							},
						  },
						{ '&.MuiStack-root': { padding: '0px !important' } },
						{
							'& .MuiInputLabel-root.Mui-error': {
								fontSize: '1.2rem !important',
								color: '#979797',
							},
						},
						{ width: '100%' },
					]}
					InputLabelProps={{
						shrink: true,
					}}
					slotProps={{
						actionBar: {
							actions: ['clear', 'accept'],
						},
					}}
				/>
			</DemoContainer>
		</LocalizationProvider>
	)
}
