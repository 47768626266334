import { baseApiSlice } from './baseApiSlice'

export const extendedApiSlice = baseApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getPatientQueues: builder.query({
			query: (query = {}) => {
				const { page = 1, limit = 10, sortField, createdAfter, sortOrder,  status, userId, taskCategory, surgeryType, acuity, program, types, surgeons, ...rest } = query

				// Convert taskCategory, acuity, and program to arrays if they are not already
				const finalQuery = {
					page,
					limit,
					userId,
					sortField,
					sortOrder,
					createdAfter,
					...(status && { status }),
					...(Array.isArray(taskCategory) ? { taskCategory } : { taskCategory: [taskCategory] }),
					...(Array.isArray(acuity) ? { acuity } : { acuity: [acuity] }),
					...(Array.isArray(program) ? { program } : { program: [program] }),
					...(Array.isArray(surgeryType) ? { surgeryType } : { surgeryType: [surgeryType] }),
					...(Array.isArray(types) ? { types } : { types: [types] }),
					...(Array.isArray(surgeons) ? { surgeons } : { surgeons: [surgeons] }),
					...rest,
				}

				let params = new URLSearchParams()
				if (page) {
					for (const [key, value] of Object.entries(finalQuery)) {
						if (Array.isArray(value)) {
							// Handle array values
							value.forEach((item, index) => {
								let formattedKey
								let formattedItem
								if (key === 'taskCategory') {
									formattedKey = 'task category'
									formattedItem = item
								} else if(key==='acuity' && item==="N/A"){
									formattedKey = encodeURIComponent(key)
									formattedItem = item
								} else {
									formattedKey = encodeURIComponent(key)
									formattedItem = encodeURIComponent(item)
								}
								params.append(`${formattedKey}[${index}]`, formattedItem)
							})
						} else {
							// Handle non-array values
							params.append(key, value)
						}
					}
				}

				return `/patientQueues?${params.toString()}`
			},
		}),
		getPatientQueueFilter: builder.query({
			query: (id) => `/patientQueues/filter`
		}),
		getPatientQueueProfileByID: builder.query({
			query: (id) => `/v2/patientQueue/${id}`,
			providesTags: ({ data, ...rest }, error, arg) => {
				return [
					{ type: 'Patients', id: 'LIST' },
					{ type: 'Patient', id: data?._id || arg },
				]
			},
		}),
		getPatientQueueBadgeCount: builder.query({
			query: () => `/v2/patientQueue/badge/getCount`,
			providesTags: ({ data, ...rest }, error, arg) => {
				return [
					{ type: 'Patients', id: 'LIST' },
					{ type: 'Patient', id: data || arg },
				]
			},
		}),
		getPatientQueueCallDetail: builder.query({
			query: (id) => `/patientQueues/getCallDetails/${id}`
		}),
		getPatientQueueAnalytics: builder.query({
			query: () => `/patientQueues/analytics`
		}),
		updatePatientInPatientQueue: builder.mutation({
			query: ({ body }) => {
			  return {
				url: `/patientQueues/setPatientInPatientQueue`,
				method: "PUT",
				body,
			  };
			}
		  }),
	}),
})

export const { useGetPatientQueuesQuery, useGetPatientQueueProfileByIDQuery, useGetPatientQueueBadgeCountQuery, useGetPatientQueueCallDetailQuery, useGetPatientQueueAnalyticsQuery, useUpdatePatientInPatientQueueMutation, useGetPatientQueueFilterQuery } = extendedApiSlice
