import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export default function ReferenceAccordion({reference}) {
  return (
    <div>
      <Accordion elevation={0} sx={{background:'transparent'}}>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
                <Typography><strong><i>Source Data</i></strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{margin:'0px'}}>
            {reference?.map((item, index)=>(
            <li key={index}>
              <Typography>
              <ReactMarkdown
								remarkPlugins={[remarkGfm]}
								components={{
									a: (props) => (
										<a href={props.href} style={{ color: '#000' }} target="blank">
											{props.children}
										</a>
									),
								}}
							>
								{item}
							</ReactMarkdown>
          </Typography>
            </li>
            ))}
          </ol>
          
        </AccordionDetails>
      </Accordion>
    </div>
  );
}