import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10, userID, ...rest } = query;
        const finalQuery = { page, limit, userID,  ...rest };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        console.log({
          URL: `/surveys/?${params.toString()}`,
        });

        return `/surveys/?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        console.log("RESULT===>>>", data);
        return [
          { type: "Surveys", id: "LIST" },
          data.map(({ _id }) => ({ type: "Survey", id: _id })),
        ];
      },
    }),
    getSurveysByID: builder.query({
      query: (id) => `/surveys/${id}`,
      providesTags: ({ form }, error, arg) => {
        return [
          { type: "Surveys", id: "LIST" },
          { type: "Survey", id: form._id },
        ];
      },
    }),
    addNewSurvey: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/surveys",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Surveys", id: "LIST" }],
    }),
    editSurvey: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/surveys/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: [{ type: "Surveys", id: "LIST" }],
    }),
    publishSurvey: builder.mutation({
      query: (id) => {
        return {
          url: `/surveys/publishSurvey/${id}`,
          method: "PUT"
        };
      }
    }),
    deleteSurvey: builder.mutation({
      query: (id) => {
        return {
          url: `/surveys/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Surveys", id: "LIST" }],
    }),
    getAllSurveys: builder.query({
      query: (id) => `/surveys/survey/${id}`,
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Surveys", id: "LIST" },
          ...data.map((p) => ({ type: "Surveys", id: p._id })),
        ];
      },
    }),
  }),
});

export const {
  useGetSurveysQuery,
  useGetAllSurveysQuery,
  useGetSurveysByIDQuery,
  useAddNewSurveyMutation,
  useEditSurveyMutation,
  usePublishSurveyMutation,
  useDeleteSurveyMutation,
} = extendedApiSlice;
