import React from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
} from "@mui/material";
import { useIsMobile } from "../../../../contexts/isMobile";
import OutlinedButton from "../../../../Components/OutlinedButton";
import { SolidButton } from "../../../../Components";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F5F5",
  p: 4,
borderRadius:"10px"
};

export const ConfirmStatusModal = ({ open, setOpen, handle, id }) => {
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign:"center"}}>
          Has your task been completed?
          </Typography>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Box sx={{ margin: "5px" }}>
                <SolidButton onClick={handle} label={"Yes"}/>
               
              </Box>
              <Box sx={{ margin: "5px" }}>
                <OutlinedButton handleClick={handleClose} label={"No"}/>
              
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
