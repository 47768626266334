import { Paper, Typography } from "@mui/material";
import React from "react";

const CountGrid = ({ total, label }) => {
  return (
    <Paper
      sx={{
        border: "1px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px",
        padding: "35px",
      }}
    >
      <Typography sx={{ fontSize: "x-large", fontWeight: "bolder" }}>
        {total}
      </Typography>

      <Typography sx={{ fontSize: "large" }}>{label}</Typography>
    </Paper>
  );
};

export default CountGrid;
