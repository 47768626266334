import React from "react";
import { Stack, Box, Tooltip } from "@mui/material";
import { DeleteSvg, EditSvg } from "../assets/svgs";
import { DeleteModal } from "./DeleteModal";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { ConfirmationModal } from "./ConfirmationModal";

export function ActionButtons({ id, handleEdit, handleDelete, status, isStatus, openConfirmationModal, setOpenConfirmationModal, isLoadingPublishForm, handlePublishForm }) {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleOpenConfirmationModal = (id) => setOpenConfirmationModal({id, open:true})
  const handleCloseModal = () => setOpenModal(false);
  return (
    <>
    <Stack direction="row" justifyContent="left" spacing={2} >
    <Tooltip title="Edit"  placement="top" >
      <Box sx={{ cursor: "Pointer" }} onClick={(e) => handleEdit(e, id)}>
        <EditSvg />
      </Box>
    </Tooltip>
    <Tooltip title="Delete"  placement="top" >
      <Box sx={{ cursor: "Pointer",margin:"2px !important",marginLeft:"10px !important" }} onClick={handleOpenModal}>
        <DeleteSvg />
      </Box>
    </Tooltip>
      {isStatus && status === 'draft' && (
          <div style={{ cursor: isLoadingPublishForm ? 'wait' : "pointer" }} onClick={() => handleOpenConfirmationModal(id)}>
            <Tooltip title="Mark as Published"  placement="top" >
            <PublishedWithChangesIcon fontSize="small" />
            </Tooltip>
          </div>
          )}
    </Stack>
      <DeleteModal open={openModal} setOpen={setOpenModal} handle={handleDelete} id={id} />
      {openConfirmationModal && (
      <ConfirmationModal open={openConfirmationModal.open} setOpen={setOpenConfirmationModal} handle={handlePublishForm} id={openConfirmationModal.id} ConfirmationText={'Are you sure you want to publish this form?'} isLoading={isLoadingPublishForm} />
      )}
    </>
  );
}
