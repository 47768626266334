import { IconButton, Paper, Typography, Box } from '@mui/material'
import React from 'react'

export default function ChatCard({ title, data, icon: Icon }) {
	return (
		<Paper
			elevation={3}
			sx={{
				bgcolor: 'white',
				borderRadius: '30px',
				color: 'white',
				p: 3,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',
			}}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<IconButton sx={{ background: '#3a48c9', borderRadius: '12px' }}>
					<Icon sx={{ color: 'white' }} />
				</IconButton>
				<Typography variant="h5" p={1}>
					{title}
				</Typography>
			</div>
			{data.map((item, index) => (
				<Box
					key={index}
					sx={{
						bgcolor: '#f5f5f5',
						borderRadius: '10px',
						my: 1,
						color: 'white',
                        boxShadow: 2,
						p: 3,
					}}
				>
					<Typography sx={{ maxHeight: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>{item.value}</Typography>
				</Box>
			))}
		</Paper>
	)
}
