import { baseApiSlice } from "./baseApiSlice";


export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query({
      // query: () => "/Teams",
      query: (query = {}) => {
        const { page = 1, limit = 10, userID, ...rest } = query;
        const finalQuery = { page, limit, userID, ...rest };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/teams/?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Teams", id: "LIST" },
          ...data.map((p) => ({ type: "Teams", id: p._id })),
        ];
      },
    }),
    getAllTeams: builder.query({
      query: (id) => `/Teams/getAllTeam/${id}`,
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Teams", id: "LIST" },
          ...data.map((p) => ({ type: "Teams", id: p._id })),
        ];
      },
    }),
    getTeamByID: builder.query({
      query: (id) => `/teams/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Teams", id: "LIST" },
          { type: "Teams", id: data._id || arg },
        ];
      },
    }),
    addNewTeam: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/teams",
          method: "POST",
          body: initialPost
        };
      },
      invalidatesTags: [{ type: "Teams", id: "LIST" }],
    }),
    editTeam: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/teams/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ({ data }, error, { id }) => {
        return [
          { type: "Teams", id: "LIST" },
          { type: "Teams", id: data.id || id },
        ];
      },
    }),
    deleteTeam: builder.mutation({
      query: (id) => {
        return {
          url: `/teams/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Teams", id: "LIST" }],
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useGetAllTeamsQuery,
  useGetTeamByIDQuery,
  useAddNewTeamMutation,
  useEditTeamMutation,
  useDeleteTeamMutation,
} = extendedApiSlice;
