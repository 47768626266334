import React, { useEffect, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import "survey-react/survey.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetSurveysByIDQuery } from "../store/suerveySlice";
import {
  useGetPatientsSurveyByIDQuery,
  useGetPatientsSurveyQuery,
  useUpdatePatientsSurveyMutation,
} from "../store/patientsSurveySlice";
import { toast } from "react-toastify";
import {CircularLoader} from "../Components/Loader"
import {InactivityCheck} from "../Components/InactivityCheck"
import { Thankyou } from "../Components/Thankyou";

const ShowSurvey = () => {
  const location = useLocation();
  const { sid, id } = useParams();
  const navigate=useNavigate();
  const title = location.pathname.split('/')[1]

  const [isComplete, setIsComplete] = useState(false)

  const {data: surveyData} = useGetPatientsSurveyByIDQuery({id, title}) 
  const { data, isLoading, isSuccess, isError, error } = 
    useGetSurveysByIDQuery(sid);
  const [updatePatientsSurvey] = useUpdatePatientsSurveyMutation();
  let entityID, formSchema;

  useEffect(()=>{
    if(surveyData?.status === "complete"){
      setIsComplete(true)
    }
  },[surveyData]);

  if (!isLoading && !isError) {

    formSchema = data.form.form;
  }

  // const handleComplete = (sender) => {
  //   console.log("=====================");
  //   const data = sender.data;
  //   fetch("process.env.REACT_APP_BASE_API_URLsurvey/survey-controller", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // survey.onComplete.add(handleComplete);

  if (isLoading) return <p><CircularLoader/></p>;
  if (isError) return <p>{error}</p>;

  if (isSuccess) {
    const survey = new Model(formSchema);
    survey.onComplete.add((sender, options) => {
      console.log(JSON.stringify(sender.data, options, null, 3));
    });

    const handleComplete = (sender, options) => {
      const data = sender.data;
      updatePatientsSurvey({
        formResult: sender.data,
        status: "complete",
        id: id,
      })
        .unwrap()
        .then((result) => {
          if(title === "showPatientSurvey"){
            navigate("/thankyou")
          }
          else{
            navigate(-1);
          }
        })
        .catch((error) => {
          toast.error("some error occured!");
          console.error(error);
        });
    };

    return(
    <>
    {isComplete ? (
      <Thankyou heading={'Survey Already Completed'} paragraph={'It appears you have already filled out this survey'} />
    ):(
    <Survey model={survey} onComplete={handleComplete} />
    )}
      <InactivityCheck />
    </>)
  }
};


export default ShowSurvey;
