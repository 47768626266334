import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useGetPatientsByPatientsSurveyIDQuery } from '../store/patientsSurveySlice';

export  function Breadcrumb({surveyid}) {
    const {data , isLoading} = useGetPatientsByPatientsSurveyIDQuery(surveyid)
    if (isLoading) 
        return <>Loading..</>
    const patient = data?.data
    const form = data?.form
    const name = `${patient  ? patient.firstName : "Test"} ${patient  ?  patient.lastName : "Test"}`
    const title = form?.form?.title
    const patientid = patient?._id

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href={`${process.env.REACT_APP_FRONTEND_URI}/patients`}>
          Patient
        </Link>
        <Link underline="hover" color="inherit" href={`${process.env.REACT_APP_FRONTEND_URI}/profile/${patientid}`}>
         {name}
        </Link>
        <Typography color="text.primary">{title}</Typography>
      </Breadcrumbs>
    </div>
  );
}