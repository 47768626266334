import React, {useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import {
  Box,
  Chip,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { DoneAllRounded } from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { getTokenFromLocalStroage } from "../../utils";
import WhatwentwrongDialog from "../../Pages/patientQueue/Profile/WhatwentwrongDialog";
import SnackbarMsg from "../SnackbarMsg";

const CareProgramData = [
  {
    id: "1",
    title: "Total Knee Replacement",
    value: "total_knee_replacement",
  },
  { id: "2", title: "Spine", value: "spine" },
  {
    id: "3",
    title: "Total Shoulder Replacement",
    value: "total_shoulder_replacement",
  },
  {
    id: "4",
    title: "Distal Radius Fracture Care Journey",
    value: "distal_radius_fracture",
  },
  {
    id: "5",
    title: "Total Hip Arthroplasty",
    value: "total_hip_replacement_pathway",
  },
  {
    id: "6",
    title: "Reverse Total Shoulder Arthroplasty",
    value: "reverse_total_shoulder_artroplasty_pathway",
  },
  {
    id: "7",
    title: "Bone Health and Fall Prevention",
    value: "bone_health_and_fall_prevention",
  },
  {
    id: "8",
    title: "Fracture Care Program",
    value: "fracture_care",
  },
  {
    id: "9",
    title: "Joint Health and Osteoarthritis",
    value: "joint_health_osteoarthritis",
  },
  {
    id: "10",
    title: "Spine Health",
    value: "spine_health",
  },
  { id: "11", title: "Sports Health", value: "sports_health" },
  {
    id: "12",
    title: "Total Joint Replacement",
    value: "total_joint_replacement",
  },
  {
    id: "13",
    title: "Hand, Wrist and Elbow",
    value: "hand_wrist_elbow",
  },
  {
    id: "14",
    title: "Obstetrics and Gynecology",
    value: "obstetrics_and_gynecology",
  },
  {
    id: "15",
    title: "Co-morbidities and Health Resources",
    value: "co-morbidities_and_health_resources",
  },
  {
    id: "16",
    title: "General Orthopaedic Care",
    value: "general_orthopaedic_care",
  },
  {
    id: "17",
    title: "EquityStream: Social Drivers and Health Related Social Needs",
    value: "social_drivers_and_health_related_social_needs",
  },
  {
    id: "18",
    title: "Diabetes",
    value: "diabetes_care",
  },
];

export default function MessageInterventionAccordion({
  item,
  queueData,
  patient,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [chatbotResponseCritique, setChatbotResponseCritique] = useState("");
  const [userFeedback2, setUserFeedback2] = useState([]);
  const [selectedAcuity, setSelectedAcuity] = useState("");
  const [proposedChatbotResponse, setProposedChatbotResponse] = useState("");
  const [selectedTaskLabel, setSelectedTaskLabel] = useState("");
  const [currentItemId, setCurrentItemId] = useState("");
  const [openDislikeModal, setOpenDislikeModal] = useState(false);
  const [isTaskCategorySelected, setIsTaskCategorySelected] = useState(true);
  const [isChatbotResponse, setIsChatbotResponse] = useState(true);
  const [isAcuitySelected, setIsAcuitySelected] = useState(true);
  const [itemStatus, setItemStatus] = useState('queueStatus');
  const [chatbotResponse, setChatbotResponse] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleHideDetailsClick = () => {
    setExpanded(false); // This will close the accordion
  };

  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };

  const addReaction = async (queueID, status) => {
    if (status === "dislike") {
      if (!selectedAcuity || !selectedTaskLabel || !chatbotResponseCritique) {
        toast.error("Please fill out the form in-order to submit the feedback");
        return;
      }
    }

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/addReaction/${queueID}`,
          {
            status: status,
            userId: user.id,
            correctTaskLabel: selectedTaskLabel,
            correctAcuityLabel: selectedAcuity,
            proposedChatbotResponse: proposedChatbotResponse,
            chatbotResponseCritique: chatbotResponseCritique,
          },
          config
        )
        .then((res) => {
          setOpen(true);
          // if(patientDrawer){
          // 	setUserFeedback2([res?.data?.feedback])
          // }
        });
    } catch (error) {
      toast.error("Error:" + error);
    }
    setCurrentItemId("");
    setSelectedTaskLabel("");
    setSelectedAcuity("");
    setProposedChatbotResponse("");
    setChatbotResponseCritique("");
    setIsAcuitySelected(false);
    setIsChatbotResponse(false);
    setIsTaskCategorySelected(false);
    setOpenDislikeModal(false);
  };

  const setCurrentItem = async (itemId) => {
    setOpenDislikeModal(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function renderFeedbackIcons(itemId, userFeedbackData) {
    const iconSkeleton = {
      color: "#979797",
      cursor: "pointer",
    };

    const dislikeIcon = {
      cursor: "pointer",
      color: "red",
    };

    const feedbackIcons = {
      like: <ThumbUpOffAltIcon fontSize="medium" style={{ color: "green" }} />,
      dislike: (
        <ThumbDownOffAltIcon
          fontSize="medium"
          style={dislikeIcon}
          onClick={() => setCurrentItem(itemId)}
        />
      ),
    };

    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "start" }}
      >
        <>
          {userFeedbackData?.some((item) => item?.status === "like") &&
            feedbackIcons.like}
          {!userFeedbackData?.some((item) => item?.status === "like") && (
            <ThumbUpOffAltIcon
              fontSize="medium"
              onClick={() => addReaction('queueId', "like")}
              style={iconSkeleton}
            />
          )}
          &ensp;
        </>
        {userFeedbackData?.some((item) => item?.status === "dislike") &&
          feedbackIcons.dislike}
        {!userFeedbackData?.some((item) => item?.status === "dislike") && (
          <ThumbDownOffAltIcon
            fontSize="medium"
            onClick={() => setCurrentItem(itemId)}
            style={iconSkeleton}
          />
        )}
      </div>
    );
  }

  const formatDate = (createdAt) => {
    const createdAtMoment = moment(createdAt);
    const today = moment().startOf("day");
    const tomorrow = moment().startOf("day").add(1, "day");

    if (createdAtMoment.isSame(today, "day")) {
      return "Today " + createdAtMoment.format("hh:mm A");
    } else if (createdAtMoment.isSame(tomorrow, "day")) {
      return "Tomorrow " + createdAtMoment.format("hh:mm A");
    } else {
      return createdAtMoment.format("MMMM DD, YYYY hh:mm A");
    }
  };

  return (
    <Accordion
      elevation={0}
      sx={{ padding: "0px" }}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      {expanded !== "panel1" && (
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px !important",
          },
          padding: "0px !important",
          margin: "0px !important",
        }}
      >
        <Typography
                  sx={{
                    textAlign: "left",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Show Details
                </Typography>
                <KeyboardArrowDownIcon fontSize="small" sx={{marginTop:'2px'}} />
      </AccordionSummary>
      )}
      <AccordionDetails sx={{padding:' 8px 8px 16px'}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "40%",
              textAlign: "left",
              marginRight: "auto",
            }}
          >
            <Grid spacing={2}>
              <Grid item xs={12} sx={{ mb: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    mb: "10px",
                  }}
                >
                  <Typography>
                    <strong>Care Program</strong>
                  </Typography>
                </Box>
                <div style={{ display: "flex" }}>
                  {patient?.program && patient?.program.length !== 0 ? (
                    patient?.program?.map((item, index) => (
                      <Chip
                        key={index}
                        label={
                          CareProgramData?.find(
                            (value) => value?.value === item
                          )?.title
                        }
                        sx={{
                          backgroundColor: "rgba(0, 151, 236, 0.12)",
                          mr: "5px",
                          borderRadius: "5px",
                        }}
                      />
                    ))
                  ) : (
                    <p>No Care program found!</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sx={{ mt: "10px" }}>
                <Typography sx={{ textAlign: "left", mb: "10px" }}>
                  <strong>Prediction Reasoning:</strong>
                </Typography>

                <Box
                  sx={{
                    backgroundColor: "rgba(255, 31, 0, 0.08)",
                    padding: "10px",
                  }}
                >
                  {queueData?.reasoning && queueData?.reasoning?.length !== 0 ? (
                    queueData?.reasoning?.map((item, index) => (
                      <Typography
                        key={index}
                        sx={{ textAlign: "left", mb: "10px" }}
                      >
                        {item}
                      </Typography>
                    ))
                  ) : (
                    <Typography sx={{ textAlign: "left", mb: "10px" }}>
                      Not Available
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ mt: "10px", display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleHideDetailsClick}
                >
                  Hide Details
                </Typography>
                <KeyboardArrowUpIcon fontSize="small" />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </AccordionDetails>

      <SnackbarMsg
        msg={"Thank you for giving us feedback!"}
        handleClose={handleClose}
        open={open}
        width={"310px"}
      />

      <WhatwentwrongDialog
        open={openDislikeModal}
        setOpen={setOpenDislikeModal}
        addReaction={addReaction}
        queueId={'queueId'}
        originalMessage={'originalMessage'}
        isAcuitySelected={isAcuitySelected}
        setIsAcuitySelected={setIsAcuitySelected}
        isChatbotResponse={isChatbotResponse}
        setIsChatbotResponse={setIsChatbotResponse}
        isTaskCategorySelected={isTaskCategorySelected}
        setIsTaskCategorySelected={setIsTaskCategorySelected}
        proposedChatbotResponse={proposedChatbotResponse}
        setProposedChatbotResponse={setProposedChatbotResponse}
        chatbotResponseCritique={chatbotResponseCritique}
        setChatbotResponseCritique={setChatbotResponseCritique}
        selectedAcuity={selectedAcuity}
        setSelectedAcuity={setSelectedAcuity}
        selectedTaskLabel={selectedTaskLabel}
        setSelectedTaskLabel={setSelectedTaskLabel}
        chatbotResponse={chatbotResponse}
      />
    </Accordion>
  );
}
