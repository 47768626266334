import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, ListItemIcon, ListItemText } from '@mui/material';

const FilterDropdown = ({ label, data, selectedValue, setSelectedValue }) => {

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const renderValue = (value) => {
    const item = data.find((item) => item.value === value);
    return item ? item.label : '';
  };

  return (
    <Box sx={{ minWidth: 220, marginLeft: '10px', marginRight: '10px' }}>
      <FormControl fullWidth>
        <InputLabel id="filter-select-label">{label}</InputLabel>
        <Select
          labelId="filter-select-label"
          id="filter-select"
          value={selectedValue}
          label={label}
          onChange={handleChange}
          renderValue={renderValue}
        >
          {data?.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterDropdown;
