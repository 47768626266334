import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Call, Email } from "@mui/icons-material";

export default function ReferralModeAccordion({title, item}){
    return(
        <Accordion sx={{background: 'aliceblue'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
            <Box sx={{display:'flex', flexDirection:'column'}}>
          <Typography variant="h6" fontSize={"20px"}>
            {title}
          </Typography>
          <Typography sx={{textDecoration:'underline'}}>By Findhelp</Typography>
            </Box>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={4}>
        <Grid item xs={12} sm={5}>
          <Typography variant="h6" fontSize={"14px"} sx={{ marginTop: "10px" }}>
            Main Services
          </Typography>
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
              {
                item?.programDetails?.service_tags &&
                item?.programDetails?.service_tags.length > 0 ? (
                  <ul>
                    {item.programDetails.service_tags.map((icon, index) => (
                      <li key={index} style={{ textTransform: "capitalize" }}>
                        {icon}
                      </li>
                    ))}
                  </ul>
                ) : (
                  "N/A"
                )
              }
          </div>
        </Grid>
        <Grid item xs={12} sm={1}>
        <Divider
        orientation="vertical"
          sx={{ background: 'aliceblue', width: "100%" }}
        />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" fontSize={"16px"}>
            Next Steps
          </Typography>
          <Box
            // sx={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   alignItems: "center",
            // }}
          >
            {item?.programDetails?.next_steps?.map((items, index)=>(
            <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
             {items.channel === "phone" && <Call fontSize="6px" />}
             {items.channel === "email" && <Email fontSize="6px" />}
              <Typography sx={{ marginLeft: "5px", fontSize: "13px" }}>
                {items?.contact}
              </Typography>
            </Box>
            ))}
          </Box>
          {/* <Typography variant="h6" fontSize={"16px"} sx={{ marginTop: "15px" }}>
            Serves your state
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", color: "red" }}>
              <AccessTimeIcon fontSize="small" />
              <Typography sx={{ color: "red", marginLeft:'5px', marginTop:'2px' }}>Closed Now</Typography>
            </Box>

            <Box>
              <Typography sx={{textDecoration:'underline', marginTop:'2px'}}>See Open Hours</Typography>
            </Box>
          </Box> */}

<Typography variant="h6" fontSize={"16px"} sx={{ marginTop: "15px" }}>
            Description
          </Typography>

            <Box>
              <Typography>{item?.programDetails?.description || "N/A"}</Typography>
            </Box>
        </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>
    )
}