import { Grid, Typography, Box, TextField, FormControlLabel, Checkbox, Stack, Switch, } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { NodeFormInput } from '../NodeFormInput'
import { NodeFormDropdown } from '../NodeFormDropdown'
import { NodeBranchTags } from '../NodeBranchTags'
import NodeTabs from '../NodeTabs'
import { CreateForm } from '../../Pages/JourneysV2/Methods/CreateForm'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useAddNewTaskMutation, useGetAllTasksQuery } from '../../store/tasksSlice'
import { useSelector } from 'react-redux'
import { CreateTask } from '../../Pages/Journeys/Methods/CreateTask'
import { SolidButton } from '../SolidButton'
import { toast } from 'react-toastify'
import {
	useAddNewJourneyMutation,
	useEditJourneyMutation,
	useGetAllJourneysQuery,
	useEditJourneyNodeMutation,
	useAddJourneyNodeMutation,
} from '../../store/journeysSlice'
import { useGetAllContentsQuery } from '../../store/contentsSlice'
import { CreateTrigger } from '../../Pages/Journeys/Methods/CreateTrigger'
import { ContentModal } from '../../Pages/Journeys/Drawers/ContentModal'
import { useParams } from 'react-router-dom'
import CreateAssignJourney from '../../Pages/Journeys/Methods/CreateAssignJourney'
import { useReactFlow, getOutgoers } from 'reactflow'
import { uuid } from '../../Pages/Journeys/Flow/utils'
import { useChildNode } from '../../contexts/addChildNode'
import { filterObject } from './function'
import EditDropdown from './EditDropdown'
import NoteForm from './NoteForm'
import CallPatientForm from './CallPatientForm'
import { useGetNoteTypesQuery } from '../../store/notesSlice'
import EmailNotificationForm from './EmailNotificationForm'
import { PermissionModal } from './PermissionModal'
import { styled } from "@mui/material/styles";
import { useGetMessagePlaceholdersQuery } from '../../store/chatSlice'
import PlaceholderMenu from './PlaceholderMenu'

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
	  padding: 0,
	  margin: 2,
	  transitionDuration: "300ms",
	  "&.Mui-checked": {
		transform: "translateX(16px)",
		color: "#fff",
		"& + .MuiSwitch-track": {
		  backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
		  opacity: 1,
		  border: 0,
		},
		"&.Mui-disabled + .MuiSwitch-track": {
		  opacity: 0.5,
		},
	  },
	  "&.Mui-focusVisible .MuiSwitch-thumb": {
		color: "#33cf4d",
		border: "6px solid #fff",
	  },
	  "&.Mui-disabled .MuiSwitch-thumb": {
		color:
		  theme.palette.mode === "light"
			? theme.palette.grey[100]
			: theme.palette.grey[600],
	  },
	  "&.Mui-disabled + .MuiSwitch-track": {
		opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
	  },
	},
	"& .MuiSwitch-thumb": {
	  boxSizing: "border-box",
	  width: 22,
	  height: 22,
	},
	"& .MuiSwitch-track": {
	  borderRadius: 26 / 2,
	  backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
	  opacity: 1,
	  transition: theme.transitions.create(["background-color"], {
		duration: 500,
	  }),
	},
  }));
  
export function SmsForm({
	method,
	data,
	id,
	nodeID,
	nodeInternals,
	setForceRenderNode,
	isEdit,
	setIsEdit,
	setExpanded,
	childNodeId,
	setChildNodeId,
	childDelayNodeId,
	setChildDelayNodeId,
	positiveChildNodeId,
	setPositiveChildNodeId,
	negativeChildNodeId,
	setNegativeChildNodeId,
	addChildNode,
	setAnchorEl,
	checkConditionRender,
	setCheckConditionRender,
	selectedQuestionsRender,
	setSelectedQuestionsRender,
	selectedRemindersRender,
	setSelectedRemindersRender,
}) {
	const { id: paramsId } = useParams()
	const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow()
	const { isChildNodeAdded, setIsChildNodeAdded, setEditMenu, refreshworkflow, setRefreshworkflow, setOpenDrawer, setOpenMenu } = useChildNode()
	const inputRef = useRef(null);

	const [openPermissionModal, setOpenPermissionModal] = useState(false)
	const [permissionValues, setPermissionValues] = useState(null)
	const currentNode = getNode(nodeID ? nodeID : id)
	const [selectMethod, setSelectMethod] = useState(method)
	const [delay, setDelay] = useState(1)
	const [metric, setMetric] = useState('minutes')
	const [task, setTask] = useState({ id: null, task: '' })
	const [trigger, setTrigger] = useState({ id: null, trigger: '' })
	const [newTask, setNewTask] = useState(false)
	const [selectForm, setSelectForm] = useState({ id: null, form: '' })
	const [sms, setSms] = useState('')
	const [components, setComponents] = useState([{ id: 0 }]) // Initial state with one component
	const [formSubmissionSmsReminder, setFormSubmissionSmsReminder] = useState([
		{
			value: 1,
			metric: 'minutes',
			message: `Please complete the survey at the following link:\n{{ surveyLink }}`,
			reminderNodeId: uuid(),
		},
	])
	const [waitUntilReminderAreSend, setWaitUntilReminderAreSend] = useState(false)
	const [smsOnFormAssignment, setSmsOnFormAssignment] = useState('')
	const [surveyValues, setSurveyValues] = useState({})
	const [checkCondition, setCheckCondition] = useState(false)
	const [initialCheckCondition, setInitialCheckCondition] = useState(null)
	const [selectedQuestions, setSelectedQuestions] = useState([])
	const [formQuestions, setFormQuestions] = useState([])
	const [addQuestion, setAddQuestion] = useState(false)
	const [status, setStatus] = useState('assigned')
	const [date, setDate] = useState('')
	const [url, setUrl] = useState('')
	const [title, setTitle] = useState(data?.title || '')
	const [note, setNote] = useState('')
	const [agent, setAgent] = useState('')
	const [agentShow, setAgentShow] = useState(null)
	const [subject, setSubject] = useState('')
	const [messageBody, setMessageBody] = useState('')
	// const [attempt, setAttempt] = useState(null);
	const [selectedMembers, setSelectedMembers] = useState([])
	const [open, setOpen] = React.useState(false)
	const [forceRender, setForceRender] = useState(false)
	const [isCallNodeAdded, setIsCallNodeAdded] = useState([])
	const [conditionOperator, setConditionOperator] = useState('and')
	const [responseData, setResponseData] = useState({
		id: null,
		task: 'Create New Task',
	})
	const [taskForms, setTaskForms] = useState({
		title: '',
		description: '',
	})
	const [triggerJourney, setTriggerJourney] = useState({
		id: null,
		name: '',
	})
	const [skippable, setSkippable] = useState(false)

	// For Placeholder Menu
	const [anchorElPlaceholder, setAnchorElPlaceholder] = React.useState(null);
	const openPlaceholder = Boolean(anchorElPlaceholder);


	// Mutations and Redux:
	const { user } = useSelector((state) => state.user)

	const { data: taskData, refetch } = useGetAllTasksQuery(user.id, {
		skip: false,
	})
	const [addNewJourney] = useAddNewJourneyMutation()
	const [editJourneyNode, { isLoading: isLoadingEdit }] = useEditJourneyNodeMutation()
	const [addJourneyNode, { isLoading: isLoadingNode }] = useAddJourneyNodeMutation()
	const { data: journeyListData } = useGetAllJourneysQuery({
		id: user.id,
		params: 'exclude=appointment,surgery',
	})
	const [addNewTask, { isLoading: isAddTaskLoading }] = useAddNewTaskMutation()
	const [editJourney, { isLoading }] = useEditJourneyMutation()
	const { data: contentData } = useGetAllContentsQuery(user.id)
	const { data: noteData } = useGetNoteTypesQuery()
	const {data: placeholderData} = useGetMessagePlaceholdersQuery()
	const currentJourneyId = JSON.parse(localStorage.getItem('journeyId'))

	// Utils:
	const triggerData = {
		data: [
			{ _id: 'pre_surgery', title: 'Pre Procedure' },
			{ _id: 'post_surgery', title: 'Post Procedure' },
			{ _id: 'pre_appointment', title: 'Pre Appointment' },
			{ _id: 'post_appointment', title: 'Post Appointment' },
		],
	}

	// Functions:
	const handleCheckConditionUpdate = (e) => {
		setCheckCondition((checkCondition) => !checkCondition)
		setSurveyValues({})
		setSelectedQuestions([])
	}

	const fetchTaskAgain = () => {
		refetch()
	}

	const handleOpenCreateModal = () => {
		setOpen(true)
	}

	const handleskippableChange = () => {
		setSkippable(!skippable)
	}

	function convertToTitleCase(inputString) {
		// Split the string into words
		const words = inputString.split('_')

		// Capitalize the first letter of each word
		const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))

		// Join the words back together
		const titleCaseString = titleCaseWords.join(' ')

		return titleCaseString
	}

	const getChildNode = getNode(nodeID)

	const getNextNodeId = (id) => {
		const allEdges = getEdges()
		const filteredEdges = allEdges.find((item) => item?.source === id)
		return filteredEdges?.target
	}

	const getDescendantNodeIds = (idToFind, otherIdToDelete, value) => {
		const allNodes = getNodes() // Assuming getNodes returns the current list of nodes
		const otherIdNodeData = getNode(otherIdToDelete)
		const nextNodeId = getNextNodeId(otherIdToDelete)

		// Helper function to recursively collect all descendants
		const collectDescendants = (parentId) => {
			let descendants = []
			for (const node of allNodes) {
				if (node.data.parentNodeId === parentId) {
					descendants.push(node.id) // Add child node to descendants
					descendants = descendants.concat(collectDescendants(node.id)) // Recursively collect descendants of this child node
				}
			}
			return descendants
		}

		const newEdge = {
			id: `${otherIdNodeData?.data?.parentNodeId}=>${nextNodeId}`,
			source: otherIdNodeData?.data?.parentNodeId,
			target: nextNodeId,
			type: 'workflow',
		}

		setEdges((edges) => edges.filter((item) => item.source !== otherIdToDelete).concat(newEdge))

		// Collect all descendants of the node with idToFind (excluding the idToFind itself)
		const descendants = collectDescendants(idToFind)
		// Combine all IDs to be removed in one step
		const idsToRemove = [idToFind, otherIdToDelete].concat(descendants)

		// Filter out the nodes to remove from the allNodes array
		const updatedNodes = allNodes.filter((node) => !idsToRemove.includes(node.id))

		// Update the nodes (excluding the descendants)
		setNodes(updatedNodes)
	}

	const handleOpenPermissionModal = () => {
		setPermissionValues(null)
		setOpenPermissionModal(true)
	}

	const createConditionNode = (
		id,
		name,
		delay,
		method,
		metric,
		childNodeId,
		positiveChildNodeId = null,
		negativeChildNodeId = null,
		checkCondition = null,
		data = null,
		selectForm = null,
		prevNodeId,
		nextNodeId,
		label,
		title
	) => {
		let extra = ''
		let pages = []
		let surveyValues = {}
		let conditionOperator = ''
		let form = {},
			task = {}
		let journeyId = ''
		let journeyName = ''
		if (data?.method === 'form') {
			extra = data.form.form
			pages = typeof selectForm.pages !== 'undefined' ? selectForm.pages : []
			surveyValues = data.form.surveyValues
			conditionOperator = data.form.conditionOperator
			form = data.form
		}
		if (data?.method === 'task') {
			extra = data.task.task
			task = data.task
		}
		if (data?.method === 'assign_journey') {
			journeyId = data.assign_journey.journeyId
			journeyName = data.assign_journey.journeyName
		}

		const parentNode = getNode(id)
		const childNodeData = getNode(childNodeId)
		const allNodes = getNodes()
		const keysArray = Array.from(nodeInternals?.keys())
		const index = keysArray.indexOf(nodeID)

		if (!parentNode) {
			return
		}

		const childNode = {
			id: childNodeId,
			position: { x: parentNode.position.x, y: parentNode.position.y + 150 },
			type: 'workflow',
			data: {
				task: task,
				form: form,
				extra: extra,
				pages: pages,
				prevNodeId,
				isFilled: data?.form?.isFilled ? data?.form?.isFilled : data?.call?.isFilled,
				label: method === 'call' ? label : name,
				title,
				metric,
				method,
				value: delay,
				parentNodeId: id,
				positiveChildNodeId: getChildNode?.data?.positiveChildNodeId ? getChildNode?.data?.positiveChildNodeId : positiveChildNodeId,
				negativeChildNodeId: getChildNode?.data?.negativeChildNodeId ? getChildNode?.data?.negativeChildNodeId : negativeChildNodeId,
				checkCondition,
				surveyValues: surveyValues,
				conditionOperator: conditionOperator,
				journeyId,
				journeyName,
				nextNodeId,
				waitUntilReminderAreSend: checkCondition ? waitUntilReminderAreSend : false,
				checkSubmission: checkCondition,
			},
		}

		let childEdge
		let childEdgeOne = [
			{
				id: `${parentNode.id}=>${childNodeId}`,
				source: parentNode.id,
				target: childNodeId,
				type: 'workflow',
			},
		]
		let childEdges = []
		if (positiveChildNodeId !== null && negativeChildNodeId !== null) {
			childEdges.push({
				id: `${childNodeId}=>${positiveChildNodeId}`,
				source: childNodeId,
				target: positiveChildNodeId,
				type: 'workflow',
			})
			childEdges.push({
				id: `${childNodeId}=>${negativeChildNodeId}`,
				source: childNodeId,
				target: negativeChildNodeId,
				type: 'workflow',
			})
		}
		if ((isChildNodeAdded || isEdit) && permissionValues?.id === null && ['yes'].includes(permissionValues?.value)) {
			childEdges.push({
				id: `${positiveChildNodeId}=>${getNextNodeId(childNodeData?.id)}`,
				source: positiveChildNodeId,
				target: getNextNodeId(childNodeData?.id),
				type: 'workflow',
			})
		} else if ((isChildNodeAdded || isEdit) && permissionValues?.id === null && ['no'].includes(permissionValues?.value)) {
			childEdges.push({
				id: `${negativeChildNodeId}=>${getNextNodeId(childNodeData?.id)}`,
				source: negativeChildNodeId,
				target: getNextNodeId(childNodeData?.id),
				type: 'workflow',
			})
			console.log({
				id: `${negativeChildNodeId}=>${getNextNodeId(childNodeData?.id)}`,
				source: getNextNodeId(childNodeData?.id),
				target: childNodeData?.data?.nextNodeId,
				type: 'workflow',
			})
			console.log(negativeChildNodeId, childNodeData)
		}

		if (childEdges.length > 0 && checkCondition) {
			childEdge = childEdgeOne.concat(childEdges)
		} else {
			childEdge = childEdgeOne
		}
		let updatedNodes

		let updatedEdges

		if ((isChildNodeAdded || isEdit) && permissionValues?.id === null && ['yes', 'no'].includes(permissionValues?.value)) {
			updatedEdges = getEdges()
				.filter((item) => item.target !== childNodeData?.data?.nextNodeId)
				.filter((item) => item.source !== childNodeData?.id)
				.concat(childEdge)
		} else {
			updatedEdges = getEdges().concat(childEdge)
		}

		// // Filter out duplicates based on 'id'
		const uniqueEdges = updatedEdges.filter((edge, index, self) => index === self.findIndex((e) => e.id === edge.id))

		if (allNodes.length === index + 1) {
			updatedNodes = getNodes().concat([childNode])
		} else {
			// updatedNodes = allNodes.slice(0,index).concat([childNode])
			updatedNodes = allNodes.concat([childNode])
		}

		setEdges(uniqueEdges)
		setNodes(updatedNodes)
	}

	// This function is getting all ids by checking idToDelete linked with those nodes and then we are eliminating it
	const removeConditionNode = (idToDelete) => {
		const allNodes = getNodes() // Assuming getNodes returns the current list of nodes

		// Helper function to recursively collect all descendants
		const collectDescendants = (parentId) => {
			let descendants = []
			for (const node of allNodes) {
				if (node.data.parentNodeId === parentId) {
					descendants.push(node.id)
					descendants = descendants.concat(collectDescendants(node.id)) // Recursively collect descendants
				}
			}
			return descendants
		}

		// Collect all descendants of the node with idToDelete
		const nodesToRemoveIds = collectDescendants(idToDelete)

		// Filter out the nodes to remove from the allNodes array
		const updatedNodes = allNodes.filter((node) => !nodesToRemoveIds.includes(node.id))

		// Update the nodes (excluding the descendants)
		setNodes(updatedNodes)
	}

	function checkUndefinedOrEmpty(array) {
		return array.some((obj) => !obj.value || obj.message === '' || obj.message?.trim() === '{{ surveyLink }}')
	}

	function isConditionalNodeChange(selectedMethod, method, isEdit) {
		// Define the conditional methods
		const conditionalMethods = ['form', 'call']

		// Check if both the selectedMethod and the previous method are conditional
		const isSelectedConditional = conditionalMethods.includes(selectedMethod)
		const isPreviousConditional = conditionalMethods.includes(method)

		// Return true if both are conditional, otherwise return false
		return isSelectedConditional && isPreviousConditional && isEdit
	}

	function updateConditionalNodes(selectedMethod, nodeData) {
		const positiveId = nodeData?.data?.positiveChildNodeId
		const negativeId = nodeData?.data?.negativeChildNodeId

		const positiveIdData = getNode(positiveId)
		const negativeIdData = getNode(negativeId)
		const allNodes = getNodes()

		const newConditionalNodes = [
			{
				...positiveIdData,
				id: positiveId,
				position: positiveIdData?.position,
				type: 'workflow',
				data: {
					...positiveIdData?.data,
					method: selectedMethod,
					label: selectedMethod === 'call' ? 'attended' : 'yes',
				},
			},
			{
				...negativeIdData,
				id: negativeId,
				position: negativeIdData?.position,
				type: 'workflow',
				data: {
					...negativeIdData?.data,
					method: selectedMethod,
					label: selectedMethod === 'call' ? 'not attended' : 'no',
				},
			},
		]

		const idsToRemove = [positiveId, negativeId]

		const updatedNodes = allNodes.filter((node) => !idsToRemove.includes(node.id)).concat(newConditionalNodes)

		setNodes(updatedNodes)
	}

	function shouldSendReminder(waitUntilReminderAreSend, previousState, checkConditionLogic) {
		// If the previous state is true and the new state is false, return false
		if (previousState === true && waitUntilReminderAreSend === false) {
			return false
		}

		if (!checkConditionLogic && selectMethod === 'form') {
			return false
		}

		// Otherwise, return the new state
		return true
	}

	function removeEmptyConditionalNodes(positiveId, negativeId) {
		const allNodes = getNodes()

		const idsToRemove = [positiveId, negativeId]

		const updatedNodes = allNodes.filter((node) => !idsToRemove.includes(node.id))
		const updatedEdges = getEdges().filter((edges) => !idsToRemove.includes(edges.target))

		setEdges(updatedEdges)
		setNodes(updatedNodes)
	}

	const checkIsConditionalMethod = (nodeId, method, isEdit) => {
		const nodeData = getNode(nodeId)
		let isPostiveHaveChild
		let isNegativeHaveChild

		if (
			(!(method === 'form' && (waitUntilReminderAreSend || nodeData?.data?.waitUntilReminderAreSend) && (checkCondition || nodeData?.data?.checkSubmission)) ||
				method === 'call') &&
			nodeData?.data?.positiveChildNodeId &&
			nodeData?.data?.positiveChildNodeId &&
			isEdit
		) {
			isPostiveHaveChild = getNextNodeId(nodeData?.data?.positiveChildNodeId)
			isNegativeHaveChild = getNextNodeId(nodeData?.data?.negativeChildNodeId)

			return isPostiveHaveChild || isNegativeHaveChild ? false : true
		}

		return false
	}

	const createJourney = async (e, taskObject) => {
		e?.preventDefault()

		const nodeData = nodeInternals?.get(nodeID)
		const prevNodeData = nodeInternals?.get(nodeData?.data?.prevNodeId)
		const checkConditionLogic = checkCondition && waitUntilReminderAreSend
		const isConditionalMethod = checkIsConditionalMethod(nodeID, selectMethod, isEdit)
		const nodeCheckConditionLogic = nodeData?.data?.waitUntilReminderAreSend && nodeData?.data?.checkSubmission

		const errorMessage = 'Please fill in all fields!'
		if (
			(selectMethod === 'sms' && !sms) ||
			(selectMethod === 'task' && (taskObject?.id ? false : !task.id)) ||
			(selectMethod === 'trigger' && typeof trigger === 'object') ||
			(selectMethod === 'note' && note === '') ||
			(selectMethod === 'email' && (subject === '' || messageBody === '')) ||
			(selectMethod === 'call' && agent === '') ||
			(selectMethod === 'assign_journey' && !triggerJourney.id) ||
			(selectMethod === 'form' && selectForm?.id === null) ||
			(selectMethod === 'form' && checkCondition && checkUndefinedOrEmpty(formSubmissionSmsReminder)) ||
			(selectMethod === 'form' && (smsOnFormAssignment === '' || smsOnFormAssignment === '{{ surveyLink }}'))
		) {
			toast.error(errorMessage)
			return
		}

		const isConditionalMethod2 = (method) => {
			if (method === 'form' && (nodeCheckConditionLogic || checkConditionLogic) && selectMethod === 'call') {
				return true
			}

			// Check if the method is 'form' and checkConditionLogic is true, or the method is 'call'
			return (
				(method === 'form' && (nodeCheckConditionLogic || checkConditionLogic) && !isConditionalNodeChange(selectMethod, method, isEdit)) || method === 'call'
			)
		}

		// Journey Data
		const journeyData = {
			method: selectMethod,
		}

		const setLabelAndFilled = (title, label, newMethod, isFilled, id, name, waitUntilReminderAreSend, checkSubmissionValue, isCall, isNote, messageBody, skippable, agent) => {
			if (nodeData?.data) {
				nodeData.data.title = title
				nodeData.data.label = isCall
					? `Call from ${label?.title}`
					: isNote && Object?.keys(noteData?.data)?.find((key) => noteData?.data[key] === label)
					? Object?.keys(noteData?.data)?.find((key) => noteData?.data[key] === label)
					: label
				nodeData.data.isFilled = isFilled
				nodeData.data.method = newMethod
				nodeData.data.checkSubmission = checkSubmissionValue
				nodeData.data.checkCondition = false
				nodeData.data.waitUntilReminderAreSend = waitUntilReminderAreSend ? waitUntilReminderAreSend : false
				nodeData.data.skippable = skippable
				nodeData.data.agentId = isCall && agent

				if (messageBody !== '') {
					nodeData.data.body = messageBody
					nodeData.data.subject = label
				}

				if (id && !name) {
					nodeData.data.extra = id
				} else if (id && name) {
					nodeData.data.journeyId = id
					nodeData.data.journeyName = name
				}
			}
		}

		// Set up common delay object
		journeyData.delay = {
			childNodeId: nodeData?.data?.prevNodeId,
			conditionOperator: 'and',
			delay: prevNodeData?.data?.value,
			metric: prevNodeData?.data?.metric,
			value: parseInt(prevNodeData?.data?.value),
			method: 'delay',
			parentNodeId: prevNodeData?.data?.prevNodeId || '1',
			nextNodeId: nodeID,
			// nextNodeId: prevNodeData?.data?.nextNodeId,
			prevNodeId: prevNodeData?.data?.prevNodeId || '1',
			delayFor: prevNodeData?.data?.delayFor || 'N/A',
			timeRelation: prevNodeData?.data?.timeRelation || 'before',
			hourToRunAt: prevNodeData?.data?.hourToRunAt || {},
			runAtSpecificHour: prevNodeData?.data?.runAtSpecificHour || false,
		}

		const conditionalNextNodeId = prevNodeData?.data?.nextNodeId
		// nodeData?.data?.nextNodeId === "1"
		//   ? prevNodeData?.data?.nextNodeId
		//   : nodeData?.data?.nextNodeId;

		switch (selectMethod) {
			case 'sms':
				journeyData.sms = {
					title,
					sms,
					childNodeId: nodeID,
					method: selectMethod,
					parentNodeId: nodeData?.data?.parentNodeId,
					nextNodeId: nodeData?.data?.nextNodeId,
					prevNodeId: nodeData?.data?.prevNodeId,
					isFilled: true,
					skippable
				}
				setLabelAndFilled(title, sms, selectMethod, true, null, null, null, null, null, null ,null, skippable)
				break

			case 'task':
				journeyData.task = {
					title,
					task: taskObject?.id ? taskObject?.id : task?.id,
					childNodeId: nodeID,
					method: selectMethod,
					parentNodeId: nodeData?.data?.parentNodeId,
					nextNodeId: nodeData?.data?.nextNodeId,
					prevNodeId: nodeData?.data?.prevNodeId,
					isFilled: true,
					skippable
				}
				setLabelAndFilled(title, taskObject?.task ? taskObject?.task : task.task, selectMethod, true, task?.id, null, null, null, null,null, null, skippable)
				break

			case 'form':
				journeyData.form = {
					title,
					form: selectForm.id,
					conditionOperator,
					checkCondition: false,
					checkSubmission: checkCondition,
					formSubmissionSmsReminder: checkCondition ? formSubmissionSmsReminder : [],
					waitUntilReminderAreSend: checkCondition ? waitUntilReminderAreSend : false,
					smsOnFormAssignment,
					surveyValues,
					childNodeId: nodeID,
					method: selectMethod,
					parentNodeId: nodeData?.data?.parentNodeId,
					positiveChildNodeId: getChildNode?.data?.positiveChildNodeId ? getChildNode?.data?.positiveChildNodeId : positiveChildNodeId,
					negativeChildNodeId: getChildNode?.data?.negativeChildNodeId ? getChildNode?.data?.negativeChildNodeId : negativeChildNodeId,
					metric,
					delay,
					delayValue: delay,
					nextNodeId: nodeData?.data?.nextNodeId,
					prevNodeId: nodeData?.data?.prevNodeId,
					isFilled: true,
					skippable
				}
				setLabelAndFilled(title, selectForm.form, selectMethod, true, selectForm.id, undefined, waitUntilReminderAreSend, checkCondition, null, null, null, skippable)
				break

			case 'trigger':
				journeyData.trigger = {
					title,
					triggerType: trigger,
					childNodeId: nodeID,
					method: selectMethod,
					parentNodeId: nodeData?.data?.parentNodeId,
					nextNodeId: nodeData?.data?.nextNodeId,
					prevNodeId: nodeData?.data?.prevNodeId,
					isFilled: true,
					skippable
				}
				setLabelAndFilled(title, convertToTitleCase(trigger), selectMethod, true, trigger, null, null, null, null, null, null, skippable)
				break

			case 'assign_journey':
				journeyData.assign_journey = {
					title,
					childNodeId: nodeID,
					method: selectMethod,
					parentNodeId: nodeData?.data?.parentNodeId,
					journeyId: triggerJourney.id,
					journeyName: triggerJourney.name,
					nextNodeId: nodeData?.data?.nextNodeId,
					prevNodeId: nodeData?.data?.prevNodeId,
					isFilled: true,
					skippable
				}
				setLabelAndFilled(title, triggerJourney.name, selectMethod, true, triggerJourney.id, triggerJourney.name, null, null, null, null, null, skippable)
				break

			case 'deactivate_patient':
				journeyData.deactivate_patient = {
					title,
					childNodeId: nodeID,
					method: selectMethod,
					parentNodeId: nodeData?.data?.parentNodeId,
					nextNodeId: nodeData?.data?.nextNodeId,
					prevNodeId: nodeData?.data?.prevNodeId,
					isFilled: true,
					skippable
				}
				setLabelAndFilled(title, 'Deactivate Patient', selectMethod, true, null, null, null, null, null, null, null, skippable)
				break

			case 'note':
				journeyData.note = {
					title,
					noteType: note,
					childNodeId: nodeID,
					method: selectMethod,
					parentNodeId: nodeData?.data?.parentNodeId,
					nextNodeId: nodeData?.data?.nextNodeId,
					prevNodeId: nodeData?.data?.prevNodeId,
					isFilled: true,
					skippable
				}
				setLabelAndFilled(title, note, selectMethod, true, undefined, undefined, undefined, undefined, false, true, null, skippable)
				break

			case 'email':
				journeyData.email = {
					title,
					subject,
					body: messageBody,
					childNodeId: nodeID,
					method: selectMethod,
					parentNodeId: nodeData?.data?.parentNodeId,
					nextNodeId: nodeData?.data?.nextNodeId,
					prevNodeId: nodeData?.data?.prevNodeId,
					isFilled: true,
					skippable
				}
				setLabelAndFilled(title, subject, selectMethod, true, undefined, undefined, undefined, undefined, false, false, messageBody, skippable)
				break

			case 'call':
				journeyData.call = {
					title,
					agentId: agent,
					// callAttemptsUntilPhonePicked:attempt,
					childNodeId: nodeID,
					method: selectMethod,
					parentNodeId: nodeData?.data?.parentNodeId,
					nextNodeId: nodeData?.data?.nextNodeId,
					prevNodeId: nodeData?.data?.prevNodeId,
					isFilled: true,
					positiveChildNodeId: getChildNode?.data?.positiveChildNodeId ? getChildNode?.data?.positiveChildNodeId : positiveChildNodeId,
					negativeChildNodeId: getChildNode?.data?.negativeChildNodeId ? getChildNode?.data?.negativeChildNodeId : negativeChildNodeId,
					skippable
				}
				setLabelAndFilled(title, agentShow, selectMethod, true, undefined, undefined, undefined, undefined, true, null, null, skippable, agent)
				break

			default:
				break
		}

		let edit
		const journeyIdString = JSON.parse(localStorage.getItem('journeyId'))
		const redirectId = paramsId ? paramsId : journeyIdString

		if (isChildNodeAdded && ['yes', 'no'].includes(permissionValues?.value) && permissionValues?.id === null) {
			const nextNodeData = nodeInternals?.get(nodeData?.data?.nextNodeId)
			const prevNodeId = nextNodeData?.data?.parentNodeId
			const prevNode = nodeInternals?.get(prevNodeId)

			const prevObject = {
				...prevNode?.data,
				childNodeId: prevNode?.id,
				nextNodeId: journeyData?.delay?.childNodeId,
			}

			const nextObject = {
				...nextNodeData?.data,
				childNodeId: nextNodeData?.id,
				parentNodeId: journeyData[journeyData?.method]?.childNodeId,
				prevNodeId: journeyData[journeyData?.method]?.childNodeId,
			}

			const responsePrevObject = filterObject(prevObject, prevObject?.method)
			const responseNextObject = filterObject(nextObject, nextObject?.method)

			const addJourneyNodeBody = {
				...journeyData,
				prevNode: responsePrevObject,
				nextNode: responseNextObject,
			}

			// Initialize an empty result object
			const transformedData = {}

			// Iterate through the keys of addJourneyNodeBody
			for (const key in addJourneyNodeBody) {
				// Extract the 'method' property from each sub-object
				const method = addJourneyNodeBody[key]?.method

				// Check if the 'method' property exists
				if (method) {
					// Check if this is a 'prevNode' and whether its childNodeId matches the 'form' childNodeId
					if (key === 'prevNode' && addJourneyNodeBody[key]?.childNodeId === addJourneyNodeBody?.form?.childNodeId) {
						// Skip adding prevNode if the childNodeIds match
						continue
					}

					// If the key does not exist in transformedData, create an array for it
					transformedData[method] = transformedData[method] || []

					// Push the current sub-object to the array under the corresponding method
					transformedData[method].push(addJourneyNodeBody[key])
				}
			}

			if ((isChildNodeAdded && permissionValues?.id === null && ['yes', 'no'].includes(permissionValues?.value)) || isEdit) {
				// Add moveBranch to journeyData here
				transformedData.moveBranch = permissionValues?.value
			}

			// Use a ternary operator for concise assignment
			edit = paramsId ? await addJourneyNode({ id: paramsId, body: transformedData }) : await addJourneyNode({ id: journeyIdString, body: transformedData })
		} else if (isChildNodeAdded && permissionValues === null) {
			const nextNodeData = nodeInternals?.get(nodeData?.data?.nextNodeId)
			const prevNodeId = nextNodeData?.data?.parentNodeId
			const prevNode = nodeInternals?.get(prevNodeId)

			const prevObject = {
				...prevNode?.data,
				childNodeId: prevNode?.id,
				nextNodeId: journeyData?.delay?.childNodeId,
			}

			const nextObject = {
				...nextNodeData?.data,
				childNodeId: nextNodeData?.id,
				parentNodeId: journeyData[journeyData?.method]?.childNodeId,
				prevNodeId: journeyData[journeyData?.method]?.childNodeId,
			}

			const responsePrevObject = filterObject(prevObject, prevObject?.method)
			const responseNextObject = filterObject(nextObject, nextObject?.method)

			const addJourneyNodeBody = {
				...journeyData,
				prevNode: responsePrevObject,
				nextNode: responseNextObject,
			}

			// Initialize an empty result object
			const transformedData = {}

			// Iterate through the keys of addJourneyNodeBody
			for (const key in addJourneyNodeBody) {
				// Extract the 'method' property from each sub-object
				const method = addJourneyNodeBody[key]?.method

				// Check if the 'method' property exists
				if (method) {
					// If the key does not exist in transformedData, create an array for it
					transformedData[method] = transformedData[method] || []

					// Push the current sub-object to the array under the corresponding method
					transformedData[method].push(addJourneyNodeBody[key])
				}
			}

			// Use a ternary operator for concise assignment
			edit = paramsId ? await addJourneyNode({ id: paramsId, body: transformedData }) : await addJourneyNode({ id: journeyIdString, body: transformedData })
		} else {
			if (permissionValues !== null && permissionValues?.id !== null) {
				getDescendantNodeIds(permissionValues?.id, permissionValues?.otherId, permissionValues?.value)

				// Add keepBranch to journeyData here
				journeyData.keepBranch = permissionValues?.value
			}

			if (isEdit && ['yes', 'no'].includes(permissionValues?.value) && permissionValues?.id === null) {
				// Add moveBranch to journeyData here
				journeyData.moveBranch = permissionValues?.value
			}

			if (paramsId) {
				edit = isEdit
					? await editJourneyNode({
							journeyID: paramsId,
							nodeID: nodeID,
							body: { ...journeyData },
					  })
					: await editJourney({ id: paramsId, body: journeyData })
			} else {
				edit = isEdit
					? await editJourneyNode({
							journeyID: journeyIdString,
							nodeID: nodeID,
							body: { ...journeyData },
					  })
					: await editJourney({ id: journeyIdString, body: journeyData })
			}
		}

		if (edit?.data?.data) {
			if (
				((waitUntilReminderAreSend && checkCondition && selectMethod === 'form') || selectMethod === 'call') &&
				(!isCallNodeAdded?.find((obj) => obj?.id === nodeID && obj?.added === true)?.id || selectMethod === 'form')
				// &&
				// !isConditionalNodeChange(selectMethod, method, isEdit)
			) {
				createConditionNode(
					nodeData?.data?.parentNodeId,
					selectMethod,
					delay,
					selectMethod,
					metric,
					nodeID,
					nodeData?.data?.positiveChildNodeId ? nodeData?.data?.positiveChildNodeId : positiveChildNodeId,
					nodeData?.data?.negativeChildNodeId ? nodeData?.data?.negativeChildNodeId : negativeChildNodeId,
					true,
					journeyData,
					selectForm,
					nodeData?.data?.parentNodeId,
					childDelayNodeId,
					nodeData?.data?.label,
					nodeData?.data?.title
				)

				let newArrayOfObjects = [...isCallNodeAdded, { id: nodeID, added: true }]

				setPositiveChildNodeId(uuid())
				setNegativeChildNodeId(uuid())
				setIsCallNodeAdded(newArrayOfObjects)
			}

			if (
				isConditionalMethod &&
				!(
					isConditionalNodeChange(selectMethod, method, isEdit) &&
					shouldSendReminder(waitUntilReminderAreSend, nodeData?.data?.waitUntilReminderAreSend, checkConditionLogic)
				)
			) {
				removeEmptyConditionalNodes(nodeData?.data?.positiveChildNodeId, nodeData?.data?.negativeChildNodeId)
			}

			// if (isConditionalNodeChange(selectMethod, method, isEdit) && shouldSendReminder(waitUntilReminderAreSend, nodeData?.data?.waitUntilReminderAreSend, checkConditionLogic)) {
			// 	updateConditionalNodes(selectMethod, nodeData)
			// }

			console.log(
				!(
					((waitUntilReminderAreSend && checkCondition && selectMethod === 'form') || selectMethod === 'call') &&
					(!isCallNodeAdded?.find((obj) => obj?.id === nodeID && obj?.added === true)?.id || selectMethod === 'form')
				) ||
					(method === 'form' && (nodeCheckConditionLogic || checkConditionLogic) && selectMethod === 'call') ||
					(method === 'call' && selectMethod === 'form' && (nodeCheckConditionLogic || checkConditionLogic)),
				'********'
			)

			if (
				isConditionalMethod2(nodeData?.data?.method) &&
				(!(
					((waitUntilReminderAreSend && checkCondition && selectMethod === 'form') || selectMethod === 'call') &&
					(!isCallNodeAdded?.find((obj) => obj?.id === nodeID && obj?.added === true)?.id || selectMethod === 'form')
				) ||
					(method === 'form' && (nodeCheckConditionLogic || checkConditionLogic) && selectMethod === 'call') ||
					(method === 'call' && selectMethod === 'form' && (nodeCheckConditionLogic || checkConditionLogic)))
			) {
				updateConditionalNodes(selectMethod, nodeData)
			}

			setIsEdit(false)
			setEditMenu(null)
			setForceRender(true)
			setForceRenderNode(true)
			setRefreshworkflow(!refreshworkflow)
			setExpanded(false)
			setIsChildNodeAdded(false)

			toast.success('Saved Successfully')
			setOpenDrawer(false)
			setOpenMenu(null)
		}

		setInitialCheckCondition(null)
		setForceRenderNode(true)
		setAnchorEl(null)
	}
	const checkMethodChange = () => {
		let hasChildNodes
		const nodeData = nodeInternals?.get(nodeID)

		// Handle checkCondition and waitUntilReminderAreSend interactions
		const checkConditionLogic = checkCondition && waitUntilReminderAreSend // If both are true, return true (conditional nodes)
		const nodeCheckConditionLogic = nodeData?.data?.waitUntilReminderAreSend && nodeData?.data?.checkSubmission

		const isConditionalMethod = (method) => {
			if (method === 'form' && (nodeCheckConditionLogic || checkConditionLogic) && selectMethod === 'call') {
				return true
			}
			if (method === 'form' && (nodeCheckConditionLogic || checkConditionLogic) && (selectMethod !== 'call' || selectMethod !== 'form')) {
				return true
			}
			if (method === 'form' && !nodeCheckConditionLogic && checkConditionLogic && selectMethod === 'form') {
				return true
			}
			// Check if the method is 'form' and checkConditionLogic is true, or the method is 'call'
			return (
				(method === 'form' && (nodeCheckConditionLogic || checkConditionLogic) && !isConditionalNodeChange(selectMethod, method, isEdit)) || method === 'call'
			)
		}
		const isNonConditionalMethod = (method) => !isConditionalMethod(method)
		// Check if `waitUntilReminderAreSend` has changed from true to false
		const reminderChangedToFalse = nodeData?.data?.waitUntilReminderAreSend === true && waitUntilReminderAreSend === false

		// Check if `waitUntilReminderAreSend` has changed from false to true
		const reminderChangedToTrue = nodeData?.data?.waitUntilReminderAreSend === false && waitUntilReminderAreSend === true

		// Function to check if a node ID is referenced as a parentNodeId by any node

		// Determine if the node has child nodes
		if (isChildNodeAdded && ((selectMethod === 'form' && checkConditionLogic) || selectMethod === 'call')) {
			hasChildNodes = true
		} else if (method === 'form' && !nodeCheckConditionLogic && checkConditionLogic && selectMethod === 'form') {
			hasChildNodes = getNextNodeId(nodeID)
		} else if (isConditionalMethod(nodeData?.data?.method)) {
			hasChildNodes = getNextNodeId(nodeData?.data?.positiveChildNodeId) || getNextNodeId(nodeData?.data?.negativeChildNodeId)
		} else {
			hasChildNodes = getNextNodeId(nodeID)
		}

		// Scenario logs
		console.log('sConditionalMethod(nodeData?.data?.method):', isConditionalMethod(nodeData?.data?.method))
		console.log('Node Method:', nodeData?.data?.method)
		console.log('Has Child Nodes:', hasChildNodes)
		console.log('Is Child Node Added:', isChildNodeAdded)
		console.log('Apply Reminders Check (current):', checkCondition)
		console.log('Apply Reminders Check (node):', nodeData?.data?.checkSubmission)
		console.log('Wait Until Reminder Are Send (current):', waitUntilReminderAreSend)
		console.log('Wait Until Reminder Are Send (node):', nodeData?.data?.waitUntilReminderAreSend)
		console.log('Reminder Changed To False:', reminderChangedToFalse)
		console.log('Reminder Changed To True:', reminderChangedToTrue)
		console.log('Selected Method:', selectMethod)
		console.log('Is Edit?:', isEdit)

		if (nodeData?.data?.method === 'form') {
			if (!hasChildNodes) {
				if (checkConditionLogic) {
					console.log('Scenario 1: Form Node, WaitUntilRemindersAreSent true, no children - Return false')
					return false
				} else {
					console.log('Scenario 2: Form Node, WaitUntilRemindersAreSent false, no children - Return false')
					return false
				}
			} else if (
				selectMethod !== 'call' &&
				nodeData?.data?.waitUntilReminderAreSend &&
				nodeData?.data?.checkSubmission &&
				hasChildNodes &&
				nodeData?.data?.method !== selectMethod
			) {
				console.log('Scenario 3: Form Node, WaitUntilRemindersAreSent true, has children - Return true')
				return true
			} else if (hasChildNodes && nodeData?.data?.method === selectMethod && checkConditionLogic && !nodeCheckConditionLogic) {
				console.log('Scenario 3.1: Form Node, WaitUntilRemindersAreSent true, has children - Return true')
				return true
			} else if (!checkConditionLogic && hasChildNodes && !reminderChangedToFalse && selectMethod === 'form') {
				console.log('Scenario 4: Form Node, WaitUntilRemindersAreSent false during creation, has children - Return false')
				return false
			} else if (reminderChangedToFalse && hasChildNodes && !isChildNodeAdded) {
				console.log('Scenario 5: Form Node, WaitUntilRemindersAreSent changed true to false, has children - Return true')
				return true
			} else if (reminderChangedToTrue && hasChildNodes) {
				console.log('Scenario 6: Form Node, WaitUntilRemindersAreSent changed false to true, has children - Return true')
				return true
			}
		}

		if (nodeData?.data?.method === 'call' && selectMethod != 'form') {
			if (!hasChildNodes) {
				console.log('Scenario 7: Call Node, no children - Return false')
				return false
			} else if (nodeData?.data?.method !== selectMethod && hasChildNodes) {
				console.log('Scenario 8: Call Node, has children - Return true')
				return true
			} else if (nodeData?.data?.method === selectMethod && hasChildNodes && !isEdit) {
				console.log('****************************************')
				return true
			}
		}

		if (isNonConditionalMethod(nodeData?.data?.method) && isConditionalMethod(selectMethod)) {
			if (!hasChildNodes) {
				console.log('Scenario 10: Non-conditional to Conditional with no child nodes - Result: false')
				return false
			} else if (nodeData?.data?.method !== selectMethod && hasChildNodes) {
				console.log('Scenario 11: Non-conditional to Conditional with child nodes - Result: true')
				return true
			}
		} else if (
			nodeData?.data?.method !== 'form' &&
			isNonConditionalMethod(nodeData?.data?.method) &&
			isConditionalMethod(selectMethod, checkConditionLogic) &&
			hasChildNodes
		) {
			console.log('Scenario 12: Non-conditional to Conditional with child nodes - Result: true')
			return true
		} else if (nodeData?.data?.method === 'form' && selectMethod === 'call' && nodeData?.data?.waitUntilReminderAreSend) {
			console.log('Scenario 13: Conditional form to Conditional call with WaitUntilRemindersAreSent true - Result: false')
			return false
		} else if (nodeData?.data?.method === 'form' && selectMethod === 'call' && !nodeData?.data?.waitUntilReminderAreSend && hasChildNodes) {
			console.log('Scenario 14: Conditional form to Conditional call with WaitUntilRemindersAreSent false and has child nodes - Result: true')
			return true
		} else if (nodeData?.data?.method === 'call' && selectMethod === 'form' && checkConditionLogic && hasChildNodes) {
			console.log('Scenario 15: Conditional call to Conditional form with WaitUntilRemindersAreSent true and has child nodes - Result: false')
			return false
		} else if (nodeData?.data?.method === 'call' && selectMethod === 'form' && !checkConditionLogic && hasChildNodes) {
			console.log('Scenario 16: Conditional call to Conditional form with WaitUntilRemindersAreSent false and has child nodes - Result: true')
			return true
		}
	}

	const createTask = async (e) => {
		e.preventDefault()
		try {
			const taskData = await addNewTask({
				...taskForms,
				date,
				status,
				team: null,
				createdBy: user.id,
				members: [...selectedMembers, user.id],
			})
			if (taskData?.data) {
				refetch()
				setResponseData({
					id: taskData?.data?.data?._id,
					task: taskData?.data?.data?.title,
				})
				setTask({
					id: taskData?.data?.data?._id,
					task: taskData?.data?.data?.title,
				})
				setNewTask(false)
				createJourney(e, {
					id: taskData?.data?.data?._id,
					task: taskData?.data?.data?.title,
				})
			} else {
				toast.error('Please fill in all required fields!')
			}
		} catch (error) {
			toast.error('Some error occurred!')
		}
	}

	function isMoveBranch(selectedMethod, nodeId) {
		const nodeData = getNode(nodeId)
		const nodeMethod = nodeData?.data?.method
		const waitUntilReminderAreSend = nodeData?.data?.waitUntilReminderAreSend
		const checkSubmission = nodeData?.data?.checkSubmission
		const checkConditionLogic = waitUntilReminderAreSend === true && checkSubmission === true
		const IsNotConditionalForm = selectMethod === 'form' && nodeMethod === 'form' && !checkConditionLogic
		// Define the conditional methods
		const conditionalMethods = ['call', 'form']
		// Check if selectedMethod is conditional and nodeMethod is not conditional
		if (conditionalMethods.includes(selectedMethod) && !conditionalMethods.includes(nodeMethod)) {
			return true
		} else if (IsNotConditionalForm) {
			return true
		}

		return false
	}
	
	const handleMenuClickPlaceholder = (value) => {
		if (inputRef.current) {
			const cursorPosition = inputRef.current.selectionStart;
			const newSms = sms.substring(0, cursorPosition) + value + sms.substring(cursorPosition);
			setSms(newSms); // Update SMS with the new value at cursor position
		}

		setAnchorElPlaceholder(null);
	}

	useEffect(() => {
		// You can perform any side effect you want to happen after the render here.
		// For example, fetching data, updating the DOM, etc.

		// In this case, if you have some logic that should happen after the render,
		// you can place it here.

		// Ensure to clean up the forceRender state afterward.
		setForceRender(false)
	}, [forceRender])

	useEffect(() => {
		const dataMethod = data?.method

		if (dataMethod === 'form') {
			setSmsOnFormAssignment(data?.smsOnFormAssignment)
		}

		if (dataMethod === 'call' && data?.agentId) {
			setAgent(data?.agentId)
			// setAttempt(data?.callAttemptsUntilPhonePicked)
		}

		if (dataMethod === 'email' && data?.subject && data?.body) {
			setSubject(data?.subject)
			setMessageBody(data?.body)
		}

		if (dataMethod === 'sms' && isEdit) {
			setSms(data?.label)
		} else if (dataMethod === 'trigger' && isEdit) {
			const filteredTriggerData = triggerData.data?.find((item) => item._id === data?.triggerType)
			setTrigger({
				id: filteredTriggerData?._id,
				trigger: filteredTriggerData?.title,
			})
		}

		if (data?.title) {
			setTitle(data?.title)
		}

		if(data?.skippable){
			setSkippable(true)
		}else{
			setSkippable(false)
		}
	}, [data, isEdit])

	useEffect(() => {
		if (checkCondition && selectMethod !== 'form') {
			setCheckCondition(false)
			setCheckConditionRender(false)
		}
	}, [checkCondition, selectMethod])

	useEffect(() => {
		if (selectForm?.form !== '' && selectMethod === 'form') {
			if(selectForm?.form === data?.label){
				setTitle(data?.title ? data?.title : selectForm?.form)
			}else{
				setTitle(selectForm?.form)
			}
		} else if (data?.method === 'form' && selectMethod !== 'form') {
			setTitle('')
		}
	}, [selectForm, selectMethod])

	useEffect(() => {
		if (currentNode) {
			setInitialCheckCondition(currentNode?.data?.checkSubmission)
		}
	}, [nodeID, currentNode])

	useEffect(() => {
		let newArray = []
if(nodeInternals){
	for (const [key, value] of nodeInternals?.entries()) {
		if ((value?.data?.method === 'call' || value?.data?.method === 'form') && value?.data?.isFilled) {
			newArray.push({ id: value?.id, added: true })
		}
	}
}
		setIsCallNodeAdded(newArray)
	}, [])

	return (
		<Grid container spacing={2} >
			<Grid item md={12} xs={12}>
				<TextField
					fullWidth
					id="outlined-textarea"
					label="Title"
					placeholder="Enter Title"
					className="nopan"
					name="title"
					value={title}
					onChange={(e) => {
						setTitle(e.target.value)
					}}
				/>
			</Grid>
			{isEdit && (
				<Grid item md={12} xs={12}>
					<EditDropdown {...{ selectMethod, setSelectMethod, isFormMethod: getNode(nodeID)?.data?.method === 'form' ? true : false }} />
				</Grid>
			)}

			{selectMethod === 'deactivate_patient' && (
				<Typography sx={{ padding: '15px 0px', fontSize: 15, fontWeight: 'bolder' }}>
					This node, when triggered, will deactivate the patient. Press Save to proceed.
				</Typography>
			)}

			{selectMethod === 'sms' && (
				<>
				<Grid item md={12} xs={12}>
						<PlaceholderMenu data={placeholderData?.messagePlaceHolders} open={openPlaceholder} anchorEl={anchorElPlaceholder} setAnchorEl={setAnchorElPlaceholder} handleMenuClick={handleMenuClickPlaceholder} />
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							required
							multiline
							fullWidth
							minRows={4}
							maxRows={20}
							className="nopan"
							id="outlined-textarea"
							label="SMS"
							placeholder="Enter SMS"
							name="sms"
							value={sms}
							inputRef={inputRef}
							onChange={(e) => {
								setSms(e.target.value)
							}}
						/>
					</Grid>

					<Grid item md={12} xs={12}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box sx={{ padding: '15px 10px', marginTop: '7px' }}>
								<Box sx={{ cursor: 'pointer' }} onClick={() => handleOpenCreateModal()}>
									<AddCircleOutlineIcon />
								</Box>
							</Box>
							<Typography sx={{ padding: '15px 0px', fontSize: 15, fontWeight: 'bolder' }}>Add Content in sms</Typography>
						</Box>
					</Grid>
				</>
			)}
			{selectMethod === 'assign_journey' && (
				<Grid item md={12} xs={12}>
					<CreateAssignJourney
						{...{
							triggerJourney,
							setTriggerJourney,
							journeyListData,
							user,
							currentJourneyId,
							data,
						}}
					/>
				</Grid>
			)}
			{selectMethod === 'task' && (
				<Grid item md={12} xs={12}>
					<CreateTask
						{...{
							taskData,
							task,
							setTask,
							newTask,
							setNewTask,
							responseData,
							date,
							setDate,
							status,
							setStatus,
							taskForms,
							setTaskForms,
							data,
						}}
					/>
				</Grid>
			)}

			{selectMethod === 'trigger' && (
				<Grid item md={12} xs={12}>
					<CreateTrigger {...{ trigger, setTrigger, triggerData }} />
				</Grid>
			)}

			{selectMethod === 'note' && (
				<Grid item md={12} xs={12}>
					<NoteForm {...{ note, setNote, noteData, data }} />
				</Grid>
			)}

			{selectMethod === 'email' && (
				<Grid item md={12} xs={12}>
					<EmailNotificationForm {...{ subject, setSubject, messageBody, setMessageBody }} />
				</Grid>
			)}

			{selectMethod === 'call' && (
				<Grid item md={12} xs={12}>
					<CallPatientForm {...{ agent, setAgent, agentShow, setAgentShow, label: data?.label }} />
				</Grid>
			)}
			<Grid item md={12} xs={12}>
				{selectMethod === 'form' && (
					<>
						<CreateForm
							{...{
								selectForm,
								setSelectForm,
								setTitle,
								setCheckCondition,
								setFormQuestions,
								setSelectedQuestions,
								setSurveyValues,
								surveyValues,
								checkCondition,
								checkConditionRender,
								setCheckConditionRender,
								selectedQuestionsRender,
								setSelectedQuestionsRender,
								conditionOperator,
								setConditionOperator,
								selectedQuestions,
								metric,
								setMetric,
								delay,
								setDelay,
								sms,
								setSms,
								components,
								setComponents,
								formSubmissionSmsReminder,
								setFormSubmissionSmsReminder,
								waitUntilReminderAreSend,
								setWaitUntilReminderAreSend,
								addQuestion,
								setAddQuestion,
								formQuestions,
								data,
								nodeID,
								nodeInternals,
								selectedRemindersRender,
								setSelectedRemindersRender,
								smsOnFormAssignment,
								setSmsOnFormAssignment,
								placeholderData
							}}
						/>
					</>
				)}
			</Grid>

			<Grid item md={12} xs={12}>
			<Box sx={{ mt: "10px", display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IOSSwitch
                sx={{ mr: 1 }}
                checked={skippable}
                onChange={() => handleskippableChange()}
              />
              <Typography>Skip Node if Delay is in the Past</Typography>
            </Box>
            </Box>
			</Grid>

			<Grid item md={12} xs={12} textAlign={'right'}>
				{!newTask ? (
					<SolidButton
						label={isLoading || isLoadingEdit || isLoadingNode ? 'Loading' : isEdit ? 'Edit' : 'Save'}
						onClick={() => {
							checkMethodChange() ? handleOpenPermissionModal() : createJourney()
						}}
						disabled={isLoading || isLoadingEdit || isLoadingNode}
					/>
				) : (
					<SolidButton label="Create Task" onClick={createTask} disabled={isAddTaskLoading} />
				)}
			</Grid>

			{/* Modal Button */}
			<ContentModal
				open={open}
				setOpen={setOpen}
				setUrl={setUrl}
				url={url}
				message={sms}
				setMessage={setSms}
				title={'Choose Content'}
				data={contentData?.data || []}
			/>

			<PermissionModal
				open={openPermissionModal}
				setOpen={setOpenPermissionModal}
				permissionValues={permissionValues}
				setPermissionValues={setPermissionValues}
				handleSave={createJourney}
				nodeData={nodeInternals?.get(nodeID)}
				isMoveBranch={isMoveBranch(selectMethod, nodeID) || isChildNodeAdded}
			/>
		</Grid>
	)
}
