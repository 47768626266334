import React, { useState, useRef } from "react";
import { TextField, Box, Typography, Modal, Stack, Button } from "@mui/material";
import {
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  OutlinedInput,
  ListItemText,
  InputLabel
} from '@mui/material';
import { CircularLoader, SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useAddNewContentMutation } from "../../../store/contentsSlice";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import {useSelector} from "react-redux"
import { Buffer } from "buffer";
import AWS from "aws-sdk";
import { getCurrentSiteId } from "../../../utils";
import { careProgramsData } from "../../../careProgramUtils";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

export function ProgramSelect({ program, setProgram }) {
  const data = careProgramsData;
  const isMobile = useIsMobile();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setProgram(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log(program)
  };

  const porgramTagsText = (selected) =>
  selected?.map((tagID) => {
    const tag = data?.find((tag) => tag.value === tagID);
    return tag ? tag.label : "";
    });

  return (
    <FormControl
      sx={{
        paddingTop: "3px",
        "& .MuiInputLabel-root": {
          paddingTop: "3px",
        },
      }}
      fullWidth
    >
      <InputLabel id="demo-multiple-checkbox-label">Care Programs</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        label="Care Programs"
        InputLabelProps={{ shrink: true }}
        multiple
        value={program || []}
        onChange={handleChange}
        input={<OutlinedInput sx={{ height: "2.4em" }} label="Care Programs" />}
        renderValue={(selected) => porgramTagsText(selected).join(", ")}
        style={{ borderColor: '#dad4d4', height: "3.4em" }}
        MenuProps={MenuProps}
      >
        {data?.map((item) => (
          <MenuItem key={item.id} value={item.value}>
            <Checkbox checked={program.indexOf(item.value) > -1} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export const CreateContentModal = ({ open, setOpen, title }) => {
  const fileRef = useRef()
  const {user} = useSelector(state => state.user)
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [key, setKey] = useState("");
  const [showLoader, setLoader] = useState(false);
  const isMobile = useIsMobile();
  const [program, setProgram] = useState([]);
  const LoadingMessage = "File is uploading, please don’t close your window it might take couple of minutes";

  // For File upload in s3 bucket
  const config = new AWS.S3({
    accessKeyId:'AKIATHS3YSRQQZ3VKUGG',
    secretAccessKey:'Vs78iVXr//6ngycHrpeuW/3jYBd72bkrC23SLMFp',
  })

  const handleClose = () => {
    setOpen(false);
    setName(""); // Clear the tag input field
    setFile("");
    setKey("");
    setType("");
    setProgram([]);
  };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
        let uploadFile = e.target.files[0];
        const fileType = uploadFile.name.split(".").pop();
        setType(fileType);
        setFile(uploadFile);
        setKey(uploadFile.name);
    }
}

  const [addNewContent, {isLoading: isAddContentLoading}] = useAddNewContentMutation();

  const onSave = async () => {
    setLoader(true);

    try {
      const params={
        Bucket: 'azra-content-manager',
        Key:file.name,
        Body:file,
          ContentType:file?.type
      }
      const s3File = await config.upload(params).promise();
  
      if (!s3File || !s3File?.Location) {
        toast.error("Failed to upload the file.");
        return;
      }

      await addNewContent({
        name,
        type,
        key,
        program,
        url: s3File?.Location,
        creator: user.id,
        s3Uri: `s3://${s3File?.Bucket}/${s3File?.Key}`
      })
      
        .unwrap()
        .then((res) => {
          setLoader(false);
          toast.success("Successfully created!");
          handleClose();
        })
        .catch((e) => {
          setLoader(false);
          toast.error(e?.data?.error || e?.message || "Some error occurred!");
        });
    } catch (e) {
      setLoader(false);
      console.error("Error uploading file:", e);
      toast.error("Some error occurred while uploading the file.");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 600,
    bgcolor: "#F5F5F5",
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
          {showLoader ? (
            <div style={{ textAlign: "center" }}>
              <CircularLoader message={LoadingMessage} modalHeight="30vh" />
            </div>
          ) : (
            <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
              <TextField
                required
                id="outlined-required"
                label="Name"
                name="name"
                placeholder="Enter Hospital Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                sx={{ width: "100%", mt: 2, marginBottom: '0.5em' }}
              />
              <ProgramSelect
                program={program}
                setProgram={setProgram}
              />
              <Box sx={{display:"flex", alignItems:"center" , margin: "10px 0px"}}>
                <Button
                        size="small"
                        variant="contained"
                        sx={{
                          background: "#816179",
                          padding: "5px",
                          height: isMobile ? "30px" : "35px",
                          width: isMobile ? "100%" : "150px",
                          borderRadius: "0.5rem",
                          fontSize: "14px",
                        }}
                        onClick={() => fileRef.current.click()}
                      >
                        Upload File
                      </Button>
                      {file && <Typography sx={{marginLeft:"5px"}}> : {file.name ? file.name : "No File Selected"} </Typography> }
                <input hidden type="file" name="myFile" ref={fileRef} onChange={onFileChange} />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Box sx={{ ml: "auto" }}>
                  <SolidButton label="Save" onClick={onSave} disabled={isAddContentLoading} />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};
