import React, { useState, useRef, useEffect } from "react";
import {
  Portal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const EditDropdown = ({ selectMethod, setSelectMethod, isFormMethod }) => {
  const container = useRef(null);
  const [methodShow, setMethodShow] = useState(null);
  
  const nodeData = [
    { id: "form", title: "Form" },
    { id: "sms", title: "Sms" },
    { id: "assign_journey", title: "Assign Journey" },
    { id: "task", title: "Task" },
    { id: "deactivate_patient", title: "Deactivate Patient" },
    { id: "note", title: "Generate Note"},
    {id: "call", title:"Call Patient"},
    {id: "email", title:"Email Notification"}
  ];

  const handleChange = (e) => {
    const findNMethodObject = nodeData?.find(
      (item) => item.title === e.target.value
    );
    setSelectMethod(findNMethodObject?.id);
  };

  useEffect(() => {
    if (selectMethod) {
      setMethodShow(nodeData.find((item) => item.id === selectMethod));
    }
  }, [selectMethod]);

  return (
    <FormControl fullWidth ref={container} sx={{
      textAlign: "left",
    }}>
      <InputLabel id="demo-simple-form-label">Node Method</InputLabel>
      <Portal container={() => container.current}>
        <Select
        fullWidth
          labelId="demo-simple-form-label"
          id="demo-simple-form"
          value={methodShow?.title}
          label="Node Method"
          onChange={handleChange}
        >
          {isFormMethod ? nodeData?.filter((data)=> data?.id !== 'call')?.map((item) => (
            <MenuItem key={item.id} value={item.title} sx={{
                "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                  width: "100%",
                },
                width: "590px",
              }}
            >
              {item?.title}
            </MenuItem>
          )) : nodeData?.map((item) => (
            <MenuItem key={item.id} value={item.title} sx={{
                "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                  width: "100%",
                },
                width: "590px",
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Select>
      </Portal>
    </FormControl>
  );
};

export default EditDropdown;
