import { Grid } from "@mui/material";
import React from "react";
import { PatientAttention } from "./PatientAttention/PatientAttention";
import { FormChart } from "./FormChart/FormChart";
import { useIsMobile } from "../../../contexts/isMobile";

export function AttentionAndForm({ setFormChartLoader }) {
  const isMobile = useIsMobile();
  return (
    <>
      <Grid container justifyContent="space-between" sx={{ marginTop: "25px" }}>
        <Grid sx={{ padding: "10px 10px 10px 0px" }} item xs={12} sm={6}>
          <PatientAttention />
        </Grid>
        <Grid sx={{ padding: "10px 0px" }} item xs={12} sm={6}>
          <FormChart {...{ setFormChartLoader }} />
        </Grid>
      </Grid>
    </>
  );
}
