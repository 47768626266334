import { Box, Grid, Paper, Typography } from '@mui/material'
import { TotallPatientSvg } from '../../../assets/svgs'
import { useIsMobile } from '../../../contexts/isMobile'
import { TotalPatientsSvg } from '../../../assets/svgs/TotalPatients'
import { useGetPatientQueueAnalyticsQuery } from '../../../store/patientQueuesSlice'
import { useEffect } from 'react'

const StatCard = ({ title, value, icon, sx = {} }) => (
	<Paper
		sx={{
			display: 'flex',
			alignItems: 'center',
			background: '#F7F7F7',
			borderRadius: '10px',
			padding: '10px',
			margin: '10px 0',
			boxShadow: '1px 2px 1px 1px rgba(52, 58, 64, 0.2)',
			...sx,
		}}
	>
		{icon && <Box sx={{ marginRight: '10px' }}>{icon}</Box>}
		<Box>
			<Typography sx={{ color: '#87888A', fontSize: '14px' }}>{title}</Typography>
			<Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>{value}</Typography>
		</Box>
	</Paper>
)

const InfoBox = ({ title, value }) => (
	<Paper
		sx={{
			background: '#f9f9f9',
			padding: '12px',
			borderRadius: '10px',
			height: '100%',
			boxShadow: '1px 2px 1px 1px rgba(52, 58, 64, 0.2)',
		}}
	>
		<Typography sx={{ color: '#87888A', fontSize: '13px', marginBottom: '10px' }}>{title}</Typography>
		<Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>{value}</Typography>
	</Paper>
)

export function LeftPart({data}) {
	const isMobile = useIsMobile()

	const {data: analyticsData, refetch} = useGetPatientQueueAnalyticsQuery()

	useEffect(()=>{
		const intervalId = setInterval(() => {
			refetch()
		  }, 10000);
	  
		  return () => clearInterval(intervalId);
	},[])

	return (
		<Grid container spacing={0.5} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ margin: '10px' }}>
			<Grid item xs={12}>
				<StatCard
					title="Total Patients"
					value={analyticsData?.data?.analytics?.totalPatients ? analyticsData?.data?.analytics?.totalPatients :  0}
					icon={<TotalPatientsSvg />}
					sx={{
						justifyContent: isMobile ? 'space-between' : 'flex-start',
						padding: isMobile ? '10px 10px' : '10px 20px',
					}}
				/>
			</Grid>

			<Grid item xs={6}>
				<InfoBox title="Total Calls" value={analyticsData?.data?.analytics?.totalCalls ? analyticsData?.data?.analytics?.totalCalls : 0} />
			</Grid>

			<Grid item xs={6}>
				<InfoBox title="Total Chats" value={analyticsData?.data?.analytics?.totalChats ? analyticsData?.data?.analytics?.totalChats : 0} />
			</Grid>
		</Grid>
	)
}
