import React, { useState } from "react";
import {
  Drawer,
  TextField,
  Box,
  Typography,
  Stack,
  Tabs,
  Tab,
  Switch,
} from "@mui/material";
import { SolidButton, CircularLoader } from "../../../Components";
import { useEditHospitalMutation } from "../../../store/hospitalsSlice";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import BusinessHoursUI from "./BusinessHourUi";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import EmailNotificationUI from "./EmailNotificationUI";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BasicTabs({
  getConsentMessage,
  setGetConsentMessage,
  consentApprovedMessage,
  setConsentApprovedMessage,
  messageAfterConsentDeclined,
  setMessageDeclined,
  message,
  setMessage,
  messageToDeactivatedPatient,
  setMessageToDeactivatedPatient,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetConsentMessageChange = (input) => {
    setGetConsentMessage((getConsentMessage) =>
      getConsentMessage.map((item, index) =>
        index === value ? { ...item, message: input } : item
      )
    );
  };

  const handleConsentApprovedMessageChange = (input) => {
    setConsentApprovedMessage((consentApprovedMessage) =>
      consentApprovedMessage.map((item, index) =>
        index === value ? { ...item, message: input } : item
      )
    );
  };

  const handleMessageAfterConsentDeclinedChange = (input) => {
    setMessageDeclined((messageAfterConsentDeclined) =>
      messageAfterConsentDeclined.map((item, index) =>
        index === value ? { ...item, message: input } : item
      )
    );
  };

  const handleMessageChange = (input) => {
    setMessage((message) =>
      message.map((item, index) =>
        index === value ? { ...item, message: input } : item
      )
    );
  };

  const handleMessageToDeactivatedPatientChange = (input) => {
    setMessageToDeactivatedPatient((messageToDeactivatedPatient) =>
      messageToDeactivatedPatient.map((item, index) =>
        index === value ? { ...item, message: input } : item
      )
    );
  };

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="English" {...a11yProps(0)} />
          <Tab label="Spanish" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <EnglishTab
          {...{
            getConsentMessage,
            handleGetConsentMessageChange,
            consentApprovedMessage,
            handleConsentApprovedMessageChange,
            messageAfterConsentDeclined,
            handleMessageAfterConsentDeclinedChange,
            message,
            handleMessageChange,
            value,
            messageToDeactivatedPatient,
            handleMessageToDeactivatedPatientChange,
          }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SpanishTab
          {...{
            getConsentMessage,
            handleGetConsentMessageChange,
            consentApprovedMessage,
            handleConsentApprovedMessageChange,
            messageAfterConsentDeclined,
            handleMessageAfterConsentDeclinedChange,
            message,
            handleMessageChange,
            value,
            messageToDeactivatedPatient,
            handleMessageToDeactivatedPatientChange,
          }}
        />
      </CustomTabPanel>
    </Box>
  );
}

function EnglishTab({
  getConsentMessage,
  handleGetConsentMessageChange,
  consentApprovedMessage,
  handleConsentApprovedMessageChange,
  messageAfterConsentDeclined,
  handleMessageAfterConsentDeclinedChange,
  message,
  handleMessageChange,
  value,
  messageToDeactivatedPatient,
  handleMessageToDeactivatedPatientChange,
}) {
  return (
    <>
      <TextField
        id="outlined-required"
        label="Consent message to be sent to patients and care allies upon their creation"
        name="getConsentMessage"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={getConsentMessage[value]?.message}
        onChange={(e) => {
          handleGetConsentMessageChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
      <TextField
        id="outlined-required"
        label="Message to send after consent is taken"
        name="consentApprovedMessage"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={consentApprovedMessage[value]?.message}
        onChange={(e) => {
          handleConsentApprovedMessageChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
      <TextField
        id="outlined-required"
        label="Notification to be sent to patients who have chosen not to provide consent"
        name="message"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={messageAfterConsentDeclined[value]?.message}
        onChange={(e) => {
          handleMessageAfterConsentDeclinedChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
      <TextField
        id="outlined-required"
        label="Message to send during off-hours to patients"
        name="message"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={message[value]?.message}
        onChange={(e) => {
          handleMessageChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
      <TextField
        id="outlined-required"
        label="Message to send after patient deactivation"
        name="message"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={messageToDeactivatedPatient[value]?.message}
        onChange={(e) => {
          handleMessageToDeactivatedPatientChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
    </>
  );
}

function SpanishTab({
  getConsentMessage,
  handleGetConsentMessageChange,
  consentApprovedMessage,
  handleConsentApprovedMessageChange,
  messageAfterConsentDeclined,
  handleMessageAfterConsentDeclinedChange,
  message,
  handleMessageChange,
  value,
  messageToDeactivatedPatient,
  handleMessageToDeactivatedPatientChange,
}) {
  return (
    <>
      <TextField
        id="outlined-required"
        label="Mensaje de consentimiento que se enviará a pacientes y aliados de atención al momento de su creación"
        name="getConsentMessage"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={getConsentMessage[value]?.message}
        onChange={(e) => {
          handleGetConsentMessageChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
      <TextField
        id="outlined-required"
        label="Mensaje para enviar después de obtener el consentimiento"
        name="consentApprovedMessage"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={consentApprovedMessage[value]?.message}
        onChange={(e) => {
          handleConsentApprovedMessageChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
      <TextField
        id="outlined-required"
        label="Notificación que se enviará a los pacientes que hayan optado por no dar su consentimiento."
        name="message"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={messageAfterConsentDeclined[value]?.message}
        onChange={(e) => {
          handleMessageAfterConsentDeclinedChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
      <TextField
        id="outlined-required"
        label="Mensaje para enviar fuera de horario a los pacientes."
        name="message"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={message[value]?.message}
        onChange={(e) => {
          handleMessageChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
      <TextField
        id="outlined-required"
        label="Mensaje a enviar tras la desactivación del paciente"
        name="message"
        placeholder="Enter Message"
        multiline
        rows={4}
        value={messageToDeactivatedPatient[value]?.message}
        onChange={(e) => {
          handleMessageToDeactivatedPatientChange(e.target.value);
        }}
        sx={{ width: "100%", mt: 2 }}
      />
    </>
  );
}

export function EditHospitalDrawer({
  open,
  setOpen,
  toggleDrawer,
  names,
  consentMessage,
  messageDeclined,
  approvedMessage,
  id,
  messages,
  activeHour,
  featureFlags,
  refetchAgain,
  replyToDeactivatedPatients,
  messageToDeactivatedPatients,
  executePastNodess,
  replyOnlyOnLowAcuitys,
  disableDialogues,
  imageUploadNotifierss
}) {
  const currentYear = new Date().getFullYear();
  const DAYS = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  // States
  const [emails, setEmails] = useState([{ id: 1, email: '' }])
  const [name, setName] = useState(`${names}`);
  const [getConsentMessage, setGetConsentMessage] = useState(
    `${consentMessage}`
  );
  const [consentApprovedMessage, setConsentApprovedMessage] = useState(
    `${approvedMessage}`
  );
  const [messageAfterConsentDeclined, setMessageDeclined] = useState(
    `${messageDeclined}`
  );
  const [message, setMessage] = useState("");
  const [activeHours, setActiveHours] = useState([]);
  const [featureFlagsOption, setFeatureFlagsOption] = useState(false);
  const [replyOnlyOnLowAcuity, setReplyOnlyOnLowAcuity] = useState(false);
  const [disableDialogue, setDisableDialogue] = useState(false)
  const [loading, setLoading] = useState(true);
  const [businessHours, setBusinessHours] = useState({
    sunday: {
      isOpen: false,
      from: new Date(currentYear, 0, 1, 7, 30, 0),
      to: new Date(currentYear, 0, 1, 17, 30, 0),
    },
    monday: {
      isOpen: false,
      from: new Date(currentYear, 0, 1, 7, 30, 0),
      to: new Date(currentYear, 0, 1, 17, 30, 0),
    },
    tuesday: {
      isOpen: false,
      from: new Date(currentYear, 0, 1, 7, 30, 0),
      to: new Date(currentYear, 0, 1, 17, 30, 0),
    },
    wednesday: {
      isOpen: false,
      from: new Date(currentYear, 0, 1, 7, 30, 0),
      to: new Date(currentYear, 0, 1, 17, 30, 0),
    },
    thursday: {
      isOpen: false,
      from: new Date(currentYear, 0, 1, 7, 30, 0),
      to: new Date(currentYear, 0, 1, 17, 30, 0),
    },
    friday: {
      isOpen: false,
      from: new Date(currentYear, 0, 1, 7, 30, 0),
      to: new Date(currentYear, 0, 1, 17, 30, 0),
    },
    saturday: {
      isOpen: false,
      from: new Date(currentYear, 0, 1, 7, 30, 0),
      to: new Date(currentYear, 0, 1, 17, 30, 0),
    },
  });
  const [replyToDeactivatedPatient, setReplyToDeactivatedPatient] =
    useState(false);
    const [executePastNodes, setExecutePastNodes] = useState(false)
  const [messageToDeactivatedPatient, setMessageToDeactivatedPatient] =
    useState([
      { language: "en", message: "" },
      { language: "es", message: "" },
    ]);

  // Mutation:
  const [editHospital, {isLoading: isEditHospitalLoading}] = useEditHospitalMutation();

  // Function:
  const handleClose = () =>
    setOpen({
      open: false,
      id: null,
      names: "",
      message: "",
      activeHour: "",
      featureFlags: [],
      replyToDeactivatedPatients: "",
      messageToDeactivatedPatients: "",
      executePastNodess:false,
      replyOnlyOnLowAcuitys: false,
      disableDialogues: false,
      imageUploadNotifierss: []
    });

  const handleFeatureFlagsChange = () => {
    setFeatureFlagsOption(!featureFlagsOption);
  };

  const handleReplyOnlyOnLowAcuityChange = () => {
    setReplyOnlyOnLowAcuity(!replyOnlyOnLowAcuity)
  };

  const handleDisableDialogueChange = () => {
    setDisableDialogue(!disableDialogue)
  };


  const changeEmailObjectIntoArray = (emailsObject) => {
    const returnArray = emailsObject?.map(item => item.email).filter(email => email !== '');
    return returnArray;
  };

  const convertEmailsToObjectArray = (emailsArray) => {
    return emailsArray.map((email, index) => ({
      id: index + 1,
      email: email
    }));
  };

  const onSave = async () => {
    const imageUploadNotifiers = changeEmailObjectIntoArray(emails)

    await editHospital({
      id,
      body: {
        name,
        config: {
          getConsentMessage,
          consentApprovedMessage,
          messageAfterConsentDeclined,
          offHourMessage: message,
          activeHours,
          replyToDeactivatedPatient,
          messageToDeactivatedPatient,
          executePastNodes,
          featureFlags: featureFlagsOption ? ["disableAutopilot"] : [],
          replyOnLowAcuity: replyOnlyOnLowAcuity,
          disableDialogue: disableDialogue,
          imageUploadNotifiers
        },
      },
    })
      .unwrap()
      .then((res) => {
        toast.success("Successfully Edited!");
        setBusinessHours({
          sunday: {
            isOpen: false,
            from: new Date(currentYear, 0, 1, 7, 30, 0),
            to: new Date(currentYear, 0, 1, 17, 30, 0),
          },
          monday: {
            isOpen: false,
            from: new Date(currentYear, 0, 1, 7, 30, 0),
            to: new Date(currentYear, 0, 1, 17, 30, 0),
          },
          tuesday: {
            isOpen: false,
            from: new Date(currentYear, 0, 1, 7, 30, 0),
            to: new Date(currentYear, 0, 1, 17, 30, 0),
          },
          wednesday: {
            isOpen: false,
            from: new Date(currentYear, 0, 1, 7, 30, 0),
            to: new Date(currentYear, 0, 1, 17, 30, 0),
          },
          thursday: {
            isOpen: false,
            from: new Date(currentYear, 0, 1, 7, 30, 0),
            to: new Date(currentYear, 0, 1, 17, 30, 0),
          },
          friday: {
            isOpen: false,
            from: new Date(currentYear, 0, 1, 7, 30, 0),
            to: new Date(currentYear, 0, 1, 17, 30, 0),
          },
          saturday: {
            isOpen: false,
            from: new Date(currentYear, 0, 1, 7, 30, 0),
            to: new Date(currentYear, 0, 1, 17, 30, 0),
          },
        });
        setReplyToDeactivatedPatient(false);
        setMessageToDeactivatedPatient([
          { language: "en", message: "" },
          { language: "es", message: "" },
        ]);
        setEmails([{id:1, email:''}])
        refetchAgain();
        setOpen({
          open: false,
          id: null,
          names: "",
          message: "",
          activeHour: "",
          featureFlags: [],
          replyToDeactivatedPatients: "",
          messageToDeactivatedPatients: "",
          executePastNodess:false,
          replyOnlyOnLowAcuitys: false,
          disableDialogues: false,
          imageUploadNotifierss: []
        });
      })
      .catch((e) => {
        toast.error(e?.data?.error || e?.message || "some error occured!");
      });
  };

  // UseEffect:
  React.useEffect(() => {
    setName(names);

    // Function to ensure both 'en' and 'es' languages are present
    const ensureLanguages = (messages) => {
      const hasEnglish = messages.some((item) => item.language === "en");
      const hasSpanish = messages.some((item) => item.language === "es");

      let updatedMessages = messages.slice(); // Create a copy of the messages array
      if (!hasEnglish) {
        updatedMessages = updatedMessages.concat({
          language: "en",
          message: "",
        });
      }

      if (!hasSpanish) {
        updatedMessages = updatedMessages.concat({
          language: "es",
          message: "",
        });
      }

      return updatedMessages;
    };

    // Update consentMessage to include both 'en' and 'es' if necessary
    const newConsentMessageValue = ensureLanguages(consentMessage);
    setGetConsentMessage(newConsentMessageValue);

    // Update messageDeclined to include both 'en' and 'es' if necessary
    const newMessageDeclinedValue = ensureLanguages(messageDeclined);
    setMessageDeclined(newMessageDeclinedValue);

    // Update approvedMessage to include both 'en' and 'es' if necessary
    const newApprovedMessageValue = ensureLanguages(approvedMessage);
    setConsentApprovedMessage(newApprovedMessageValue);

    const newMessageToDeactivatedPatientValue = ensureLanguages(
      messageToDeactivatedPatients
    );
    setMessageToDeactivatedPatient(newMessageToDeactivatedPatientValue);

    setReplyToDeactivatedPatient(replyToDeactivatedPatients);

    setExecutePastNodes(executePastNodess)
    setReplyOnlyOnLowAcuity(replyOnlyOnLowAcuitys)
    setDisableDialogue(disableDialogues)
  }, [
    names,
    consentMessage,
    messageDeclined,
    approvedMessage,
    messageToDeactivatedPatients,
    replyToDeactivatedPatients,
    executePastNodess,
    replyOnlyOnLowAcuitys,
    disableDialogues
  ]);

  React.useEffect(() => {
    if (messages) {
      // Function to ensure both 'en' and 'es' languages are present
      const ensureLanguages = (messages) => {
        const hasEnglish = messages.some((item) => item.language === "en");
        const hasSpanish = messages.some((item) => item.language === "es");

        let updatedMessages = messages.slice(); // Create a copy of the messages array
        if (!hasEnglish) {
          updatedMessages = updatedMessages.concat({
            language: "en",
            message: "",
          });
        }

        if (!hasSpanish) {
          updatedMessages = updatedMessages.concat({
            language: "es",
            message: "",
          });
        }

        return updatedMessages;
      };

      const newMessageValue = ensureLanguages(messages);
      setMessage(newMessageValue);
    } else {
      setMessage("");
    }

    if (activeHour) {
      const updatedBusinessHours = { ...businessHours };

      activeHour.forEach((activeTime) => {
        const { startHour, endHour, day, enabled } = activeTime;

        // Create new Date objects for each active hour
        const startDate = new Date();
        const endDate = new Date();

        // Set start and end hours and minutes
        startDate.setHours(startHour?.hour, startHour?.minute, 0, 0);
        endDate.setHours(endHour?.hour, endHour?.minute, 0, 0);

        // Convert day number to corresponding day name
        const dayName = DAYS[day];
        if (dayName !== undefined) {
          // Check if dayName is defined
          // Update business hours for the corresponding day
          updatedBusinessHours[dayName] = {
            isOpen: enabled,
            from: startDate,
            to: endDate,
          };
        }
      });

      setActiveHours(activeHour);
      // Update state with the new businessHours
      setBusinessHours(updatedBusinessHours);
    } else {
      setActiveHours([]);
    }

    if (featureFlags[0] === "disableAutopilot") {
      setFeatureFlagsOption(true);
    } else {
      setFeatureFlagsOption(false);
    }

    if(imageUploadNotifierss?.length !== 0){
      setEmails(convertEmailsToObjectArray(imageUploadNotifierss))
    }else{
      setEmails([{id:1, email:''}])
    }
  }, [activeHour, messages, featureFlags, imageUploadNotifierss]);

  React.useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [open]);

  React.useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
  }, [id]);

  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "80%",
            boxShadow: "none",
          },

          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            boxShadow: "none",
          },
        }}
        anchor="right"
        open={open}
        onClose={() => handleClose()}
      >
        <Box sx={{ padding: "2rem" }}>
          {loading ? (
            <CircularLoader />
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography id="modal-modal-title" variant="h6">
                  Edit Site
                </Typography>
                <Box sx={{ cursor: "pointer" }} onClick={() => handleClose()}>
                  <CloseIcon />
                </Box>
              </Stack>
              <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Name"
                  name="name"
                  placeholder="Enter Hospital Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  sx={{ width: "100%", mt: 2 }}
                />
                <Typography
                  variant="h6"
                  component="h1"
                  gutterBottom
                  sx={{ mt: "20px" }}
                >
                  Chat Settings
                </Typography>
                <Box sx={{ mt: "10px", display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IOSSwitch
                sx={{ mr: 1 }}
                checked={featureFlagsOption}
                onChange={() => handleFeatureFlagsChange()}
              />
              <Typography>Disable Autopilot Mode</Typography>
            </Box>
              <Box sx={{ ml:'20px', display: "flex", alignItems: "center" }}>
              <IOSSwitch
                sx={{ mr: 1 }}
                checked={replyOnlyOnLowAcuity}
                onChange={() => handleReplyOnlyOnLowAcuityChange()}
              />
              <Typography>Enable Low Acuity Autopilot</Typography>
              </Box>
              <Box sx={{ ml:'20px', display: "flex", alignItems: "center" }}>
              <IOSSwitch
                sx={{ mr: 1 }}
                checked={disableDialogue}
                onChange={() => handleDisableDialogueChange()}
              />
              <Typography>Disable Dialogue Agent</Typography>
              </Box>
            </Box>
            <Box>
            <Typography
              variant="h6"
              component="h1"
              gutterBottom
              sx={{ mt: "20px" }}
            >
              Photo Upload Notification Recipients
            </Typography>
              <EmailNotificationUI {...{emails, setEmails}} />
            </Box>
                <Box sx={{ mt: "5px" }}>
                  <BusinessHoursUI
                    {...{
                      activeHours,
                      setActiveHours,
                      businessHours,
                      setBusinessHours,
                    }}
                  />
                </Box>
                <Typography
                  variant="h6"
                  component="h1"
                  gutterBottom
                  sx={{ mt: "20px" }}
                >
                  Message Templates
                </Typography>
                <BasicTabs
                  {...{
                    getConsentMessage,
                    setGetConsentMessage,
                    consentApprovedMessage,
                    setConsentApprovedMessage,
                    messageAfterConsentDeclined,
                    setMessageDeclined,
                    message,
                    setMessage,
                    messageToDeactivatedPatient,
                    setMessageToDeactivatedPatient,
                  }}
                />

                <Typography
                  variant="h6"
                  component="h1"
                  gutterBottom
                  sx={{ mt: "20px" }}
                >
                  Advanced Settings
                </Typography>
                <Box sx={{ mt: "10px", display: "flex", alignItems: "center" }}>
                  <IOSSwitch
                    sx={{ mr: 1 }}
                    checked={replyToDeactivatedPatient}
                    onChange={() =>
                      setReplyToDeactivatedPatient(!replyToDeactivatedPatient)
                    }
                  />
                  <Typography>
                    Enable Auto Response for Deactivated Patients
                  </Typography>
                </Box>

                {/* <Box sx={{ mt: "10px", display: "flex", alignItems: "center" }}>
                <IOSSwitch
                  sx={{ mr: 1 }}
                  checked={executePastNodes}
                  onChange={() => setExecutePastNodes(!executePastNodes)}
                />
                <Typography>Enable Execution of Past Nodes</Typography>
              </Box> */}

                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Box sx={{ ml: "auto" }}>
                    <SolidButton label="Save" onClick={onSave} disabled={isEditHospitalLoading} />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
