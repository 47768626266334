import React from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DeleteSvg, EditSvg } from "../../../assets/svgs";
import {
  CircularLoader,
  DeleteModal,
  PaginationBar,
} from "../../../Components";
import { useDeleteContentMutation } from "../../../store/contentsSlice";
import { toast } from "react-toastify";
import { hasScope } from "../../../utils";
import { ProcedureTypeModal } from "./ProcedureTypeModal";
import { useDeleteProcedureMutation } from "../../../store/proceduresSlice";

const columns = [
  // { id: "Srno", label: "Index", minWidth: 170 },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "right",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

const createRows = (
  contents,
  handleOpenModal,
  handleDelete,
  openModal,
  setOpenModal,
  datas,
  scopes,
  refetchAgain,
  openModalEdit, 
  setOpenModalEdit
) => {
  const rows = contents && contents?.map(({ _id, name, platform }, index) => {
    return {
      name: (
        <>
          <Typography
            variant="p"
            sx={{
              fontSize: 14,
              color: "black",
              padding: "7px",
            }}
          >
            {name}
          </Typography>
        </>
      ),
      actions: (
        <>
              <Stack spacing={3} direction="row" justifyContent="center" alignItems="center" >
                {!platform ? (
                  <>
                <div
                  onClick={() => setOpenModalEdit({id: _id, name, open:true})}
                  style={{ cursor: "pointer" }}
                >
                  <EditSvg />
                </div>

                {hasScope(scopes, 'settings/contentManagers:delete') && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal(_id)}
                >
                  <DeleteSvg />
                </div>
                )}
                  </>
                ): (
                  <>
                  Not Editable
                  </>
                )}
              </Stack>
            
          <DeleteModal
            open={openModal}
            setOpen={setOpenModal}
            id={datas}
            handle={handleDelete}
          />
          <ProcedureTypeModal open={openModalEdit.open} setOpen={setOpenModalEdit} title="Edit Procedure" refetchAgain={refetchAgain} data={{id: openModalEdit.id, name: openModalEdit.name}} />
        </>
      ),
    };
  });

  return rows;
};

export function ProcedureTypeTable({
  page,
  setPage,
  data,
  isLoading,
  isError,
  searchTerm,
  scopes,
  refetchAgain
}) {
  // States
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalEdit, setOpenModalEdit] = React.useState({id: null, name: '', open:false});
  const [datas, setData] = React.useState('');

  const [deleteProcedure] = useDeleteProcedureMutation()

  // Functions:
  const handleOpenModal = (id) => {
    setData(id);
    setOpenModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (id) => {
    await deleteProcedure(id).unwrap().then(res => {
      toast.success('Procedure deleted successfully!');
      refetchAgain()
    }).catch((e) => {
      toast.error(e?.data?.error || e?.message || "some error occured!");
    });
  };

  const ProcedureType = data ? data?.data : [] ;

  const rows = createRows(
    ProcedureType,
    handleOpenModal,
    handleDelete,
    openModal,
    setOpenModal,
    datas,
    scopes,
    refetchAgain,
    openModalEdit, 
    setOpenModalEdit
  );

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return <Typography sx={{margin:"10px 20px"}}>No Procedure Type found.</Typography>;
  }
  
  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  variant="head"
                  key={column.id}
                  align={"center"}
                  sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ color: "text.secondary", border: "none" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data?.totalPages, handleChange: handleChangePage }}
      />
    </>
  );
}
