import { Box, Typography } from "@mui/material";
import React  from "react";
import { useIsMobile } from "../../../../contexts/isMobile";

export function FormStatus() {
    const isMobile = useIsMobile();

  return (
    <>
      <Box sx={{display:"flex", flexDirection: isMobile ? "column" : "row", justifyContent:"center"}}>
      <Box sx={{ display:"flex",alignItems:"center", marginRight: !isMobile && "20px", marginLeft: isMobile && "20px"}}>
        <Box sx={{background: "#816179", width:"13px", height:"13px",borderRadius:"7px", marginRight:"10px"}} />
        <Typography>Form Completed</Typography>
      </Box>
      <Box sx={{display:"flex", alignItems:"center", marginLeft: isMobile && "20px"}}>
        <Box sx={{background: "#D0EAE1", width:"13px", height:"13px",borderRadius:"7px", marginRight:"10px"}} />
        <Typography>Form Not Complete</Typography>
      </Box>
      </Box>
    </>
  );
}
