import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,Paper
} from "@mui/material";
import { CircularLoader, PaginationBar,ActionButtons } from "../../Components";
import { useDeleteUserMutation } from "../../store/userManagmentSlice";
import { useGetAllHospitalsQuery } from "../../store/hospitalsSlice";
import { EditDrawer } from "./EditDrawer";
import { toast } from "react-toastify";
import {useSelector} from "react-redux";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "role",
    label: "Role",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

const ROLES = {
  1526: "Admin",
  2615: "ClientAdmin",
  1122: "CareTeamMember",
  1111: "ReportingUser",
};

const hospitalName = (id, teams) => {
  // Filter the teams array for the hospital with the ID `id`.
  const hospital = teams?.filter(item => item._id === id);

  // If a hospital is found, return its name.
  if (hospital.length > 0) {
    const name = hospital[0].name;
    return name;
  }

  // If no hospital is found, return the String.
  return "Azra Health Care";
}

const createRows = ({ items, teams, handleEdit, handleDelete }) => {
  return (items && items.length > 0) ? items.map(({ _id: id, name, email, role, hospital }) => ({
    name,
    email,
    role: ROLES[String(role)],
    site: hospitalName(hospital, teams),
    actions: <ActionButtons id={id} handleEdit={handleEdit} handleDelete={handleDelete} />,
  })):[];
};

export function UserTable({
  page,
  setPage,
  data,
  isLoading,
  isError,
  isSuccess,
  toggleDrawer,
  searchTerm
}) {
  const {user} = useSelector(state => state.user)
  const [drawerState, setDrawerState] = useState({open: false, id: null});
  const { data : team } = useGetAllHospitalsQuery()
  const [deleteUser, {}] = useDeleteUserMutation();
//   const filteredData = data ? data?.data?.filter((item) =>
//   item.tag.toLowerCase().includes(searchTerm.toLowerCase())
// ) : [];
  const toggleEditDrawer = (open) => (event, id) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(prev => ({ ...prev, open, id}));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEdit = (e, id) => {
    toggleEditDrawer(true)(e, id)
    console.log(id)
  };

  const handleDelete = async (id) => {
    await deleteUser(id).unwrap()
    .then(() => {
      toast.success("Deleted successfully!");
    })
    .catch((error) => {
      toast.error("some error occured!");
    });
  };

  if (isLoading) return <div><CircularLoader/></div>;
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return <Typography sx={{margin:"10px 20px"}}>No User found.</Typography>;
  }

  return (
    <>
     <Paper
        sx={{
          border: "1px ",
          width: "100%",
          borderRadius: "10px",
        }}
      >
      <TableContainer sx={{ borderRadius: "4px" }}>
        <Table >
          <TableHead style={{ backgroundColor: "rgba(0, 151, 236, 0.12)" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  variant="head"
                  key={column.id}
                  align="left"
											sx={{
												fontWeight: 600,
												fontSize: '14px',
												color: '#50607A',
												paddingY: '5px',
											}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              createRows({
                items: data ? data.data : [],
                teams: team ? team.data : [],
                handleEdit,
                handleDelete,
              }).map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align="left"
															sx={{
																color: 'text.secondary',
															}}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data.totalPages, handleChange: handleChangePage }}
      />
      </Paper>
      { drawerState.open && <EditDrawer drawerState={drawerState} toggleEditDrawer={toggleEditDrawer}/>}

    </>
  );
}
