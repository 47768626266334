import { Grid, Paper, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchField from "../../../Components/SearchField";
import OutlinedButton from "../../../Components/OutlinedButton";
import { JourneyAccordianModal } from "../Profile/Journey";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import { journeyData } from "./ProfileUtils";
import { useGetAssignJourneyQuery } from "../../../store/assignJourneySlice";
import {  CircularLoader } from "../../../Components";
import { useDebouncedValue } from "@mantine/hooks";
import { useChildNode } from "../../../contexts/addChildNode";

const journeyDetailsData=[{
    'name':'N/A',
    'base':'N/A',
    'assignedOn':'N/A',
    'status':'N/A',
    'action':'N/A'
  }]
export default function JourneyTable({id:patientId, user, status}) {
  const { procedureReferesh, setProcedureReferesh, appointmentReferesh, setAppointmentReferesh } = useChildNode();

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const [page, setPage] = useState(1);
  const searchAbleFields = ["title","description"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
const [open,setOpen]=useState(false)
  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };
  const queries = {};

     for (const field of selectedFields) {
       queries[field] = debouncedValue;
     }

const handleModal=()=>{
    setOpen(true)
}

const { isLoading, isSuccess, isError, error, data,refetch } = useGetAssignJourneyQuery({userID: user.id ,page,patientId, ...queries},{ 
  refetchOnMountOrArgChange: true });

  console.log('journey',data)

  const fetchAgain=()=>{
    refetch()
  }
 
  useEffect(()=>{
    if(data && (procedureReferesh !== 0 || appointmentReferesh !== 0)){
      refetch();
    }

    setProcedureReferesh(0);
    setAppointmentReferesh(0);
  },[appointmentReferesh, procedureReferesh])

  if (isLoading) return  <CircularLoader />;
  if (isError) return <div>{error}</div>;

  return (
    <div id="journeys">
      <Paper
        elevation={3}
        sx={{ height: "100%", p: "2rem", borderRadius: "10px", mt: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Journeys
        </Typography>
        <Grid container justifyContent={"space-between"} sx={{paddingY: "12px"}}>
            <Grid item md={4} xs={6} >
          <SearchField
            {...{
              searchTerm,
              setSearchTerm,
              options: searchAbleFields,
              selectedOptions: selectedFields,
              handleOptionSelect: handleFieldSelect,
            }}
          />
          </Grid>
          <Grid item xs={3} textAlign={"right"}>
            <OutlinedButton label={"Assign"} handleClick={handleModal}/>
          </Grid>
        </Grid>
        <ProviderInfo data={data?.data || []} tableType={'journey'}  columns={journeyData} count={data?.totalPages} {...{page,setPage, fetchAgain}} />
      </Paper>
      <JourneyAccordianModal {...{open,setOpen,pid:patientId, fetchAgain, status}}/>
    </div>
  );
}
