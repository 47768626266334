import { Bar } from "react-chartjs-2";
import { useIsMobile } from "../../../contexts/isMobile";
import { careProgramsData } from "../../../careProgramUtils";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

export function BarChart({ chartData, chartDataError }) {
  const isMobile = useIsMobile();

  const [labels, setLabels] = useState([]);
  const [countValues, setCountValues] = useState([]);

  const options = {
    responsive: true,
    aspectRatio: isMobile ? 1 : 2,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        padding: {
          top: 30,
          bottom: 30,
        },
        maintainAspectRatio: false, // Fix typo here
        animation: {
          animateScale: true,
        },
      },
      // scales: {
      //   x: {
      //     ticks: {
      //       callback: function (value, index, values) {
      //         const words = value.split(" ");
      //         const maxWordsPerLine = 1;
      //         const lines = [];

      //         for (let i = 0; i < words.length; i += maxWordsPerLine) {
      //           lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
      //         }

      //         return lines;
      //       },
      //     },
      //   },
      // },
    },
  };

    // Calculate the barThickness by the total labels and values
    const calculateBarThickness = () => {
      const totalLabels = labels?.length;
      const totalValues = countValues?.length;
      
      const maxBarThickness = 150; // Maximum desired bar thickness
      const minBarThickness = 10; // Minimum desired bar thickness
      
      const calculatedThickness = Math.max(
        minBarThickness,
        maxBarThickness / Math.max(totalLabels, totalValues)
      );
      
      return calculatedThickness;
    };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "ICD10 Code",
        data: countValues,
        backgroundColor: "#816179",
        barThickness: calculateBarThickness(),
      },
    ],
  };

  useEffect(() => {
    console.log('see icd10 chart data ', chartData)
    const programData = chartData
      .map((c) => {
        return {
          label: c?.sdohICD10Code,
          value: c?.sdohICD10Code,
          count: c?.count,
        }
      })

    const labelsArray = programData?.map((item) => item.label);
    const countValuesArray = programData?.map((item) => item.count);

    setLabels(labelsArray);
    setCountValues(countValuesArray);
  }, [chartData]);

  if (chartDataError) return <p>Some Error</p>;
  return (
    <Box
      sx={{ width: isMobile ? "100%" : "60%", height: "50%", margin: "auto", padding: "20px 0px" }}
    >
      <Bar data={data} options={options} />
    </Box>
  );
}
