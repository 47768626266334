import React from 'react'
import { Drawer, Grid, Stack, Box, Typography, Divider } from '@mui/material'
import { CircularLoader, SolidButton } from '../../../../Components'
import { useIsMobile } from '../../../../contexts/isMobile'
import { useSelector } from 'react-redux'
import { useGetTaskByIDQuery } from '../../../../store/tasksSlice'
import { useGetAllUserQuery } from '../../../../store/userManagmentSlice'
import { useGetTeamByIDQuery } from '../../../../store/teamsSlice'

const Content = ({ data, membersName, teams, taskID, toggleDrawer }) => {
	const date = data?.data?.date
	const taskId = data?.data?.taskId
	const status = data?.data?.status
	const title = taskId?.title
	const description = taskId?.description
	const teamName = teams?.data?.name

	return (
		<Box sx={{ padding: '2rem' }}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '3rem' }}>
				<Typography variant="p" sx={{ fontSize: '28px', fontWeight: '400' }}>
					Task Detail
				</Typography>
			</Stack>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12}>
						<Typography variant="h2" sx={{ fontSize: '15px' }}>
							Assigned To:
						</Typography>
						<Typography
							sx={{
								wordWrap: 'break-word',
								overflowWrap: 'break-word',
								hyphens: 'auto',
							}}
							variant="body1"
						>
							{membersName?.join(', ')} {teamName && `, ${teamName}`}
						</Typography>
					</Grid>
					<Grid item md={6} xs={12}>
						<Typography variant="h2" sx={{ fontSize: '15px' }}>
							Due Date:
						</Typography>
						<Typography variant="body1">{new Date(date).toDateString()}</Typography>
					</Grid>
					<Grid item md={12} xs={12}>
						<Divider />
						<Box sx={{ marginTop: '20px' }}>
							<Typography variant="h2" sx={{ fontSize: '15px' }}>
								Title:
							</Typography>
							<Typography variant="body1">{title}</Typography>
						</Box>
					</Grid>
					<Grid item md={12} xs={12}>
						<Divider />
						<Box sx={{ marginTop: '20px' }}>
							<Typography variant="h2" sx={{ fontSize: '15px' }}>
								Description:
							</Typography>
							<Typography variant="body1">{description}</Typography>
						</Box>
					</Grid>
					<Grid item md={12} xs={12}>
						<Divider />
						<Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
							<Typography variant="h2" sx={{ fontSize: '15px' }}>
								Status:
							</Typography>
							<Typography sx={{ textTransform: 'capitalize' }} variant="body1">
								{status}
							</Typography>
						</Box>
						<Divider />
					</Grid>
				</Grid>
				<Stack direction="row" justifyContent={'flex-end'} sx={{ marginTop: '3rem' }}>
					<SolidButton
						label="Close"
						onClick={(e) => {
							toggleDrawer(false)
						}}
					/>
				</Stack>
			</Box>
		</Box>
	)
}

export function ViewTableDrawer({ state, toggleDrawer, dataId }) {
	const { user } = useSelector((state) => state.user)
	const { isLoading, isError, error, data } = useGetTaskByIDQuery({ userID: user.id, taskID: state?.taskID })
	// Not deleting useGetAllUserQuery and membersName because old data have members array and assign to is showing their care team members
	const { data: users } = useGetAllUserQuery(user.id)
	const membersName = users?.data?.filter((user) => data?.data?.members?.includes(user._id))?.map((CareTeamMember) => CareTeamMember.name)

	const { data: teams } = useGetTeamByIDQuery(data?.data?.team)

	const isMobile = useIsMobile()
	return (
		<React.Fragment key={'anchor'}>
			<Drawer
				key={state.taskID}
				sx={{
					display: { sm: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						overflowX: 'hidden',
						width: !isMobile ? '45%' : '80%',
					},
				}}
				anchor="right"
				open={state.detail}
				onClose={() => toggleDrawer(false)}
			>
				{isLoading && <CircularLoader/>}
				{isError && <div>{error}</div>}
				{!isLoading && (
					<Content key={state.taskID} membersName={membersName} teams={teams && teams} data={data} taskID={state.taskID} toggleDrawer={toggleDrawer(false)} />
				)}
			</Drawer>
		</React.Fragment>
	)
}
