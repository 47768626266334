import React, { useState, useEffect, useRef } from "react";
import { useIsMobile } from "../../contexts/isMobile";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { SolidButton } from "../../Components";
import { CircularLoader } from "../../Components/Loader";
import { InactivityCheck } from "../../Components/InactivityCheck";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useParams, useLocation } from "react-router-dom";
import { useUploadGalleryMutation } from "../../store/patientsSlice";
import UploadImageModal from "./UploadImageModal";
import { toast } from "react-toastify";
import axios from "axios";
import { ThankyouMsg } from "./ThankYouMsg";

export default function UploadImageIndex() {
  const { id } = useParams();
  const location = useLocation();
  const scrollableRef = useRef();
  const uploadBy = location?.search?.split('=')[1]
  const isMobile = useIsMobile();
  const [patientImages, setPatientImages] = useState([]);
  const [patientImagesBody, setPatientImagesBody] = useState([]);
  const [reasonBool, setReasonBool] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [uploadGallery, { isSuccess: uploadSuccess }] =
    useUploadGalleryMutation();

  const handleFinish = async () => {
    try {
      const body = { patientImages: patientImagesBody, uploadBy };
      if (patientImagesBody?.length !== 0) {
        const response = await uploadGallery({ id, body });
        if (response?.data?.patientImages) {
          toast.success("Images has been posted");
        }else{
          toast.error("Something went wrong!");
        }
      }
    } catch (error) {
      toast.error("Error:", error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/v2/patient/${id}/checkPatientLink`
        );
        if (response.status === 200) {
          setIsSuccess(true);
        } else {
          setIsError(true);
          setIsSuccess(false);
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const scrollDown = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current?.scrollHeight + 200;
    }
  };

  useEffect(() => {
    if (uploadSuccess) {
      setInterval(() => {
        window.close(); // Assigning an empty function to clear the onunload handler
      }, 5000);
    }
  }, [uploadSuccess]);

  useEffect(()=>{
    scrollDown()
  },[reasonBool])

  if (isLoading) return <CircularLoader />;

  return (
    <>
      {uploadSuccess ? (
        <>
          <ThankyouMsg />
        </>
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#9797971a",
            flexDirection: "column",
            height: "100vh",
          }}
        className="DialogContent"
        >
          <Card
            elevation={3}
            ref={scrollableRef}
            sx={{
              width: isMobile ? "70%" : "50%",
              p: 3,
              display: "flex",
              flexDirection: "column",
              my: "2rem",
              alignItems: "center",
              overflowY: 'auto'
            }}
          >
            {isSuccess ? (
              <>
                <div>
                  <img
                    src={require("../../assets/RevelAi.png")}
                    width={isMobile ? 100 : 150}
                    height={isMobile ? 100 : 120}
                  />
                  <Typography
                    fontWeight={600}
                    textAlign={isMobile? "justify": "center"}
                    sx={{fontSize:'medium'}}
                  >
                    Please provide a reason for each photo. Click “Finish” to upload all photos.
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <UploadImageModal
                    {...{ patientImages, setPatientImages, patientImagesBody, setPatientImagesBody, reasonBool, setReasonBool,scrollDown, handleFinish }}
                  />
                </div>
              </>
            ) : (
              <Typography fontSize={"18px"} fontWeight={500} mb={2}>
                Link has been expired.
              </Typography>
            )}
          </Card>
          <InactivityCheck />
        </Box>
      )}
    </>
  );
}
