import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientMap: builder.query({
      query: (id) => `/analytics/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Analytics", id: "LIST" },
          { type: "Analytics", id: data?._id || arg },
        ];
      },
    }),
    getPatientMapByZipCode: builder.query({
      query: (id) => `/analytics/zipcode/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Analytics", id: "LIST" },
          { type: "Analytics", id: data?._id || arg },
        ];
      },
    }),
  }),
});

export const { useGetPatientMapQuery, useGetPatientMapByZipCodeQuery } =
  extendedApiSlice;
