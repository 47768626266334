import React, { useState } from "react";
import { Paper, Box } from "@mui/material";
import { PatientCountHeader } from "./PatientCoundHeader";
import { CountChart } from "./CountChart";
import { useGetPatientCountQuery } from "../../../store/dashboardsSlice";
import { useSelector } from "react-redux";
import { useIsMobile } from "../../../contexts/isMobile";

export function PatientCount() {
  const [view, setView] = useState("");
  const isMobile = useIsMobile();
  const { user } = useSelector((state) => state.user);
  const { data, isLoading, isError, isSuccess } = useGetPatientCountQuery(
    user.id
  );

  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
        border: "1px solid #D0EAE1",
        marginTop: "25px",
      }}
      elevation={0}
    >
      <PatientCountHeader {...{ view, setView }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            marginBottom: "10px",
            width: isMobile ? "90%" : "70%",
            height: isMobile ? "90%" : "50%",
          }}
        >
          <CountChart {...{ chartData: data, isLoading, isError }} />
        </Box>
      </div>
    </Paper>
  );
}
