import React, { useState } from 'react'
import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material'
import { useIsMobile } from '../../../contexts/isMobile'
import { useIsHovered } from '../../../contexts/isHovered'
import { useActivatePatientMutation, useDeactivatePatientMutation } from '../../../store/patientsSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { DeleteModal } from '../../../Components'
import SnackbarMsg from '../../../Components/SnackbarMsg'
import { ConfirmationModal } from '../../../Components/MessageLayout/ConfirmationModal'

export function PatientSidebar({ data, isProfile, id, status, refetch, isPatientTable, setPageName, pageName }) {
	const isMobile = useIsMobile()
	const navigate = useNavigate()
	const { hovered } = useIsHovered()
	const [pathName, setPathName] = useState(isPatientTable && pageName ? pageName : !isPatientTable ? 'overview' : 'patient')
	const [open, setOpen] = useState(false)
	const [openBar, setOpenBar] = useState(false)
	const [activatePatient] = useActivatePatientMutation()
	const [deactivatePatient] = useDeactivatePatientMutation()
	const handleModal = () => {
		setOpen(true)
	}
	React.useEffect(() => {
		// Add event listener for scroll
		window.addEventListener('scroll', handleScroll)
		return () => {
			// Clean up event listener when component unmounts
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const handleScroll = () => {
		// Find which heading is currently visible in the viewport
		const headings = data.map((item) => ({
			path: item?.path,
			element: document?.getElementById(item?.path === 'careAlly' || item?.path === 'providerInfo' ? item?.path : item?.path?.toLowerCase()),
		}))

		const windowHeight = window?.innerHeight

		let maxVisibleArea = 0
		let visibleHeadingPath = null

		headings.forEach(({ path, element }) => {
			const rect = element?.getBoundingClientRect()
			let visibleHeight = Math?.min(rect?.bottom, windowHeight) - Math?.max(rect?.top, 0)
			let visibleArea = visibleHeight * rect?.width // Calculate visible area

			if (visibleArea > maxVisibleArea) {
				maxVisibleArea = visibleArea
				visibleHeadingPath = path
			}
		})
		const scrollTolerance = 5; // Adjust as needed (in pixels)
		const isScrolledToBottom = window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - scrollTolerance;
		
		if (visibleHeadingPath) {
			setPathName(isScrolledToBottom ? 'careAlly' : visibleHeadingPath)
		}
	}
	// console.log(pathName)

	const handleListItemClick = (path) => {
		// Extract the element ID from the path
		const elementId = path === 'careAlly' || path === 'providerInfo' ? path : path.toLowerCase()
		setPathName(path)

		if(isPatientTable){
			setPageName(path)
		}
		// Scroll to the corresponding heading
		const heading = document.getElementById(elementId)
		if (heading) {
			const additionalScrollSpace = path === 'providerInfo' ? 250 : 80
			const scrollPosition = heading.offsetTop - additionalScrollSpace
			window.scrollTo({
				top: scrollPosition,
				behavior: 'smooth',
			})
		}
	}

	const onDelete = (id) => {
		if (status === 'inactive') {
			activatePatient({ id })
				.unwrap()
				.then(() => {
					toast.success('Patient Activated Successfully!')
					setOpen(false)
					refetch()
				})
				.catch((error) => {
					toast.error(error.data.error)
				})
		}

		if (status === 'active') {
			deactivatePatient({ id })
				.unwrap()
				.then(() => {
					toast.success('Patient Deactivated Successfully!')
					setOpen(false)
					navigate('/patients')
					refetch()
				})
				.catch((error) => {
					toast.error(error.data.error)
				})
		}
	}

	const handleClose = () => {
		navigate('/patients')
		setOpenBar(false)
	}

	console.log(data)
	return (
		<>
			<Stack
				direction="column"
				justifyContent="left"
				sx={{
					height: '100%',
					alignItems: isMobile ? 'baseline' : 'left',
					position: 'fixed',
					bgcolor: 'white',
					paddingLeft: !hovered && '12px',
				}}
			>
				<List>
					{data.map(({ text, path }) => {
						return (
							<ListItem key={text} disablePadding onClick={() => handleListItemClick(path)}>
								<ListItemButton
									sx={{
										display: 'flex',
										':hover': {
											backgroundColor: '#CCF4FF',
											borderRight: '2px solid #0097EC',
										},
										width: isMobile ? 46 : 200,
										backgroundColor: `${path}` === pathName ? '#CCF4FF' : 'inherit',
										borderRight: `${path}` === pathName ? '2px solid #0097EC' : 'none',
									}}
								>
									<ListItemText primary={text} sx={{ fontSize: '14px', fontWeight: 500 }} />
								</ListItemButton>
							</ListItem>
						)
					})}
				</List>

				{isProfile && (
					<div style={{ position: 'absolute', bottom: '4rem' }}>
						<Box>
							<Button
								variant="primary"
								size="medium"
								sx={{
									borderRadius: '30px',
									mr: 1,
									color: '14px',
									color: 'red',
									fontWeight: 600,
									textTransform: 'capitalize',
								}}
								onClick={() => navigate(`/editProfile/${id}`)}
							>
								Edit Patient
							</Button>
						</Box>
						<Box>
							<Button
								variant="primary"
								size="medium"
								sx={{
									borderRadius: '30px',
									mr: 1,
									color: '14px',
									color: 'red',
									fontWeight: 600,
									textTransform: 'capitalize',
								}}
								onClick={handleModal}
							>
								{status === 'active' ? 'Deactivate' : 'Activate'} Patient
							</Button>
						</Box>
					</div>
				)}
			</Stack>
			<ConfirmationModal
				open={open}
				setOpen={setOpen}
				handle={onDelete}
				id={id}
				txt={status === 'active' ? 'Are you sure you want to deactivate the patient?' : 'Are you sure you want to activate the patient?'}
			/>
			<SnackbarMsg msg={'Patient Deleted successfully'} handleClose={handleClose} open={openBar} width={'310px'} />
		</>
	)
}
