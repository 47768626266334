import { Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchField from "../../../Components/SearchField";
import OutlinedButton from "../../../Components/OutlinedButton";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import { SurgeryData, formsData, } from "./ProfileUtils";
import { AssignFormsModal } from "./ProfileModals/FormsModal";
import { SurgeryModal } from "./ProfileModals/SurgeryModal";
import { useGetPatientSurgeriesQuery } from "../../../store/surgeriesSlice";
import { useDebouncedValue } from "@mantine/hooks";
import {  CircularLoader } from "../../../Components";



export default function SurgeryTable({id, user, status}) {
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["location", "description"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
const [open,setOpen]=useState(false)
  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};
  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }
  
const handleModal=()=>{
    setOpen(true)
}

const { isLoading, isSuccess, isError, error, data, refetch } = useGetPatientSurgeriesQuery({
  userID: user.id,
  page,
  patientId: id,
  ...queries,
});

const fetechAgain = () =>{
  refetch()
}

if (isLoading) return  <CircularLoader />;
if (isError) return <div>{error}</div>;

  return (
    <div id="surgery">
      <Paper
        elevation={3}
        sx={{ height: "100%", p: "2rem", borderRadius: "10px", mt: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Procedures
        </Typography>
        <Grid container justifyContent={"space-between"} sx={{paddingY: "12px"}}>
            <Grid item md={4} xs={6}>
          <SearchField
            {...{
              searchTerm,
              setSearchTerm,
              options: searchAbleFields,
              selectedOptions: selectedFields,
              handleOptionSelect: handleFieldSelect,
            }}
          />
          </Grid>
          <Grid item xs={3} textAlign={"right"}>
            <OutlinedButton label={"Create"} handleClick={handleModal}/>
          </Grid>
        </Grid>
        <ProviderInfo data={data?.data || []}  columns={SurgeryData} tableType="surgery" count={data?.totalPages} {...{page,setPage, setEditData, handleModalEdit:handleModal, fetchAgain:fetechAgain}} />
      </Paper>
      <SurgeryModal {...{open,setOpen,fetechAgain, setEditData, editData, status}}/>
    </div>
  );
}
