import React from "react";
import {
  Drawer,
  Grid,
  Stack,
  Paper,
  Box,
  Typography,
  Button,
} from "@mui/material";
import moment from "moment"
import { useIsMobile } from "../contexts/isMobile";
import { useNavigate } from "react-router-dom";
import { useReadNotificationMutation } from "../store/notificationsSlice";
import TimeAgo from "react-timeago"
import CloseIcon from '@mui/icons-material/Close';

export function NotificationDrawer({ state, toggleDrawer, data, refetchAgain }) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [readNotification] = useReadNotificationMutation()

  const formatDate = (createdAt) => {
    const createdAtMoment = moment(createdAt);
    const today = moment().startOf("day");
    const tomorrow = moment().startOf("day").add(1, "day");

    if (createdAtMoment.isSame(today, "day")) {
      return  createdAtMoment.format("hh:mm A");
    } else if (createdAtMoment.isSame(tomorrow, "day")) {
      return "Tomorrow " + createdAtMoment.format("hh:mm A");
    } else {
      return createdAtMoment.format("MM-DD-YYYY hh:mm A");
    }
  };

  async function view(url,id){
  const seenNotification = await readNotification(id)

  if(seenNotification){
     refetchAgain()
     // Wait for the navigation to complete
     navigate(url);
  }
  };

  const handleViewButtonClick = (url, id) => {
    view(url, id); // Call the view function with the provided parameters
    toggleDrawer(false); // Call the toggleDrawer function here
  };

  const today = new Date()
  const twentyFourHourAgo = new Date(today.getTime() - 24 * 60 *60 *1000)

  // For recent and earlier notification
  const recentNotifications = data?.filter((notification) => {
    const notificationDate = new Date(notification?.createdAt);
    return notificationDate >= twentyFourHourAgo && notificationDate <= today;
  });

  const earlierNotifications = data?.filter((notification) => {
    const notificationDate = new Date(notification?.createdAt);
    return notificationDate < twentyFourHourAgo ;
  });

  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: isMobile ? "80%" : "50%",
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
      >
            <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{marginLeft: "25px",marginRight: "25px",}}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: "400",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          Notifications
        </Typography>
        <Box sx={{cursor:"pointer",maringRight:"1rem"}} onClick={toggleDrawer(false)}>
        <CloseIcon/>
        </Box>
        </Stack>
        {data.length === 0 ? (
          // Display message when there are no notifications
          <Box
            sx={{
              textAlign: "center",
              marginTop: "20px",
              fontSize: "18px",
              color: "#777",
            }}
          >
            No new notifications.
          </Box>
        ) : (
          <>
          {recentNotifications.length > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ marginBottom: "10px", marginLeft: "25px", }}
              >
                <Typography sx={{ fontWeight: "bold",color:'#757575'  }}>
                  Recent
                </Typography>
              </Grid>
            ) : null}

          {recentNotifications.map((items)=>(
              <Paper
            elevation={3}
            sx={{
              backgroundColor: items?.isRead === false && '#cfd6ff',
              padding: "12px",
              marginLeft: "25px",
              marginRight: "25px",
              marginBottom: "20px",
            }}
            key={items?._id}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ marginBottom: "10px" }}
            >
              <Typography sx={{fontWeight : items?.isRead === false && 'bold'}}>{items?.notificationText}</Typography>
              {!isMobile && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                {/* <Typography >{formatDate(items?.createdAt)}</Typography> */}
                  <TimeAgo date={items?.createdAt} />
                </Box>
              )}
            </Grid>
            <Stack direction="row" justifyContent="space-between">
              <Button
                size="small"
                variant="contained"
                sx={{
                  background: "#816179",
                  borderRadius: "8px",
                  fontSize: "10px",
                  width: "100px",
                  height: "30px",
                }}
                // onClick={toggleDrawer(false)}
                onClick={() => {
                  view(items?.url, items?._id);
                }}
              >
                View
              </Button>
              {isMobile && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                {/* <Typography >{formatDate(items?.createdAt)}</Typography> */}
                <TimeAgo date={items?.createdAt} />
                </Box>
              )}
            </Stack>
          </Paper>
            ))}

            {earlierNotifications.length > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ marginBottom: "10px", marginLeft: "25px", }}
              >
                <Typography sx={{ fontWeight: "bold", color:'#757575' }}>
                  Earlier
                </Typography>
              </Grid>
            ) : null}
            
          {earlierNotifications.map((items)=>(
              <Paper
            elevation={3}
            sx={{
              backgroundColor: items?.isRead === false && '#cfd6ff',
              padding: "12px",
              marginLeft: "25px",
              marginRight: "25px",
              marginBottom: "20px",
            }}
            key={items?._id}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ marginBottom: "10px" }}
            >
              <Typography sx={{fontWeight : items?.isRead === false && 'bold'}}>{items?.notificationText}</Typography>
              {!isMobile && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                {/* <Typography >{formatDate(items?.createdAt)}</Typography> */}
                <TimeAgo date={items?.createdAt} />
                </Box>
              )}
            </Grid>
            <Stack direction="row" justifyContent="space-between">
              <Button
                size="small"
                variant="contained"
                sx={{
                  background: "#816179",
                  borderRadius: "8px",
                  fontSize: "10px",
                  width: "100px",
                  height: "30px",
                }}
                // onClick={handleViewButtonClick(items?.url, items?._id)}
                 onClick={() => {
                   toggleDrawer(false);
                  view(items?.url, items?._id);
                }}
              >
                View
              </Button>
              {isMobile && (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                {/* <Typography >{formatDate(items?.createdAt)}</Typography> */}
                <TimeAgo date={items?.createdAt} />
                </Box>
              )}
            </Stack>
          </Paper>
            ))}
          </>
        )}
      </Drawer>
    </React.Fragment>
  );
}
