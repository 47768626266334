import React, { useEffect, useState } from 'react'
import { Drawer, Box, Typography, TextField, Stack, ToggleButton, ToggleButtonGroup, InputAdornment, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import AiConversationTab from '../MessageLayout/AiConversationTab'
import AiAssistantDropdownButton from '../MessageLayout/AiAssistantDropdownButton'
import { ContentsAccordian } from '../../Pages/Settings/ContentManager/ContentsAccordian'
import { useSelector } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save'
import CheckIcon from '@mui/icons-material/Check'
import { styled } from '@mui/system'
import { useGetAgentsFilterQuery, useGetAgentsQuery } from '../../store/agentsSlice'
import EditIcon from '@mui/icons-material/Edit'
import { useEditAssistantsMutation, useGetAssistantsQuery } from '../../store/assistantsSlice'
import { SolidButton } from '../SolidButton'
import { toast } from 'react-toastify'

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'& .MuiToggleButton-root': {
		borderRadius: '0',
		padding: '4px 20px',
		fontSize: '12px',
		minHeight: 'auto',
		'&:first-of-type': {
			borderTopLeftRadius: '20px',
			borderBottomLeftRadius: '20px',
		},
		'&:last-of-type': {
			borderTopRightRadius: '20px',
			borderBottomRightRadius: '20px',
		},
		'&.Mui-selected': {
			backgroundColor: '#0049C6',
			color: 'white',
			fontWeight: 'bold',
			'&:hover': {
				backgroundColor: '#003d6a !important',
				color: 'white !important',
			},
		},
		'&:not(.Mui-selected)': {
			backgroundColor: 'white',
			color: '#0049C6',
			fontWeight: 'bold',
			borderColor: 'blue',
		},
	},
}))

const LanguageModelDropdown = ({ model, setModel, setLanguageModel, dataLanguageModel, isDisabled, agent }) => {
	const handleChange = (event) => {
		const selectedModel = event.target.value;
		setModel(selectedModel);
		setLanguageModel(selectedModel?.value);
	};

	useEffect(()=>{
		if(agent?.language_model){
			const filteredModel = dataLanguageModel.find(item => item.value === agent?.language_model);
			setModel(filteredModel || null);
		}
	},[dataLanguageModel, agent])

	return (
		<div>
			<FormControl fullWidth sx={{background:'white'}}>
				<InputLabel id="language-model-select-label">Language Model</InputLabel>
				<Select
					labelId="language-model-select-label"
					id="language-model-select"
					label="Language Model"
					onChange={handleChange}
					required
					value={model || ''} // Ensure value is never undefined/null
					disabled={isDisabled}
				>
					{dataLanguageModel.map((item, index) => (
						<MenuItem key={index} value={item}>
							{item.title}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};


export function ExternalAIAssistant({ state, toggleDrawer }) {
	const { user } = useSelector((state) => state.user)
	const [isSaved, setIsSaved] = useState(false)
	const [selectedValue, setSelectedValue] = useState('yes')
	const [selectedAiValue, setSelectedAiValue] = useState(null)
	const [agentId, setAgentId] = useState(null)
	const [prompt, setPrompt] = useState(null)
	const [languageModel, setLanguageModel] = useState(null)
	const [model, setModel] = useState(null)
	const [dataLanguageModel, setDataLanguageModel] = useState([])
	const [isEditing, setIsEditing] = useState(false)
	const [isAIAssistantEdited, setIsAIAssistantEdited] = useState(false)
	
	const { data } = useGetAssistantsQuery();
	const {data: filterData} = useGetAgentsFilterQuery()
	const [editAssistants, {isLoading}] = useEditAssistantsMutation()
	const agent = data?.find((agent) => agent._id === agentId)
	
	const [title, setTitle] = useState(agent?.title || 'AI Assistant')

	const handleChangeValue = (event, newAlignment) => {
		console.log(agent)
		setSelectedValue(newAlignment)
	}

	const handleEditClick = () => {
		setIsEditing(true)
	}

	const handleTitleChange = (event) => {
		setTitle(event.target.value)
	}

	const handleBlur = () => {
		setIsEditing(false)
	}

	const handleSaveClick = () => {
		setIsSaved(true)
		// Simulate a delay of 2 seconds before resetting the icon back to Save
		setTimeout(() => {
			setIsSaved(false)
		}, 2000)
	}

	const handleSave =async () => {
		try {
		
		if ((title === "" || prompt === "" || languageModel === null)) {
		  toast.error("Please fill in all the fields!");
		  return;
		}

		const body = {
			title,
			prompt,
			language_model: languageModel,
			createdBy: user.id,
			// hospitalId : user.hospital
		}


		let response = await editAssistants({ id: agent?._id, body });

		if (response?.data) {
		  const responseToast = "Assistant Updated Successfully!"
		  toast.success(responseToast);
		  setIsAIAssistantEdited(true);
		  setIsEditing(false);
		} else {
		  toast.error(response.error?.data?.error || response.error?.data);
		}
	  } catch (err) {
		console.log("Error:", err);
		toast.error("Something went wrong!");
	  }
	}

	const handleClose = () => {
		setIsEditing(false);
		setTitle(agent?.title || 'AI Assistant')
		setPrompt(agent?.prompt)

		const filteredModel = dataLanguageModel?.find(item => item?.value === agent?.language_model);
		setModel(filteredModel);
		setLanguageModel(agent?.language_model)
	}

	const truncateTitle = (title) => {
		return title.length > 12 ? `${title.substring(0, 12)}...` : title
	}

	useEffect(()=>{
		if(filterData?.filters){
			const languageModelFilters = Object.entries(filterData?.filters?.languages_model)?.map(([key, val])=>{
				return {title:key, value:val}
			})


			setDataLanguageModel(languageModelFilters)
		}
	},[filterData])

	useEffect(() => {
		if (agent && dataLanguageModel.length) {
			setTitle(agent?.title || 'AI Assistant');
			setPrompt(agent?.prompt);

			setLanguageModel(agent?.language_model);
		}
	}, [agent, dataLanguageModel]);

	// This useEffect help to clear the selected assistant when Drawer is open or close
	useEffect(()=>{
		if(agentId){
			setTitle('AI Assistant');
			setPrompt(null);
			setLanguageModel(null);
			setModel(null)
			setAgentId(null);
			setSelectedAiValue('Available Assistants')
		}
	},[state])
	
	return (
		<React.Fragment key={'anchor'}>
			<Drawer
				sx={{
					display: { sm: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						overflow: 'hidden',
						width: '95%',
					},
				}}
				anchor="right"
				open={state}
				onClose={toggleDrawer(false)}
			>
				<Box
					sx={{
						display: 'flex',
						height: '100%', // Ensures full height for both sections
					}}
				>
					{/* Left Section */}
					<Box
						sx={{
							width: '30%',
							backgroundColor: '#f5f5f5', // Optional: Background color for the left section
							padding: 2,
							overflowY: 'auto'
						}}
					className="DialogContent"
					>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: '16px' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{isEditing ? (
									<TextField value={title} onChange={handleTitleChange} autoFocus size="small" variant="outlined" sx={{ width: '200px', background:'white' }}/>
								) : (
									<>
										<Tooltip title={title.length > 12 ? title : ''} sx={{ cursor: 'pointer' }}>
											<Typography fontSize={'20px'} variant="h6">
												{truncateTitle(title)}
											</Typography>
										</Tooltip>
										{agent && (
											<IconButton onClick={handleEditClick} size="small">
												<EditIcon fontSize="14px" />
											</IconButton>
										)}
									</>
								)}
							</div>
							<AiAssistantDropdownButton selectedValue={selectedAiValue} setSelectedValue={setSelectedAiValue} setAgentId={setAgentId} isAIAssistant={true} isAIAssistantEdited={isAIAssistantEdited} setIsAIAssistantEdited={setIsAIAssistantEdited} title={title} />
						</Box>
						<Box sx={{ my: '20px' }}>
						<LanguageModelDropdown {...{ model, setModel, setLanguageModel, dataLanguageModel, isDisabled: !isEditing, agent }} />
						</Box>
						<Box sx={{ my: '20px' }}>
							<TextField
								required
								label="Prompt"
								variant="outlined"
								fullWidth
								name="prompt"
								value={prompt}
								onChange={(e) => setPrompt(e.target.value)}
								multiline
								disabled={!isEditing}
								minRows={6}
								maxRows={20}
								sx={{
									backgroundColor: 'white', // Set the background color to white
								}}
								InputLabelProps={{
									shrink: agent?.prompt && true, // Ensures the label is always floating above the text
								}}
							/>
						</Box>
						{isEditing && (
						<Box
							sx={{
								display: 'flex',
								my: '12px',
								justifyContent:'end',
								alignItems: 'center', // Vertically center the items
								gap: 1, // Optional: Add some space between the label and the toggle buttons
							}}
							>
							<SolidButton label={'Save'} onClick={handleSave} disabled={isLoading} />
							<SolidButton label={'Cancel'} isOutlined={true} onClick={handleClose} disabled={isLoading} />
						</Box>
						)}
						{!isEditing && (
						<Box
							sx={{
								display: 'flex',
								my: '12px',
								alignItems: 'center', // Vertically center the items
								gap: 2, // Optional: Add some space between the label and the toggle buttons
							}}
						>
							<Typography variant="h6" fontSize={'18px'}>
								Include RevelAi Content
							</Typography>
							<CustomToggleButtonGroup color="primary" value={selectedValue} onChange={handleChangeValue} exclusive aria-label="Platform">
								<ToggleButton value="yes">Yes</ToggleButton>
								<ToggleButton value="no">No</ToggleButton>
							</CustomToggleButtonGroup>
						</Box>
						)}
						{!isEditing && (
						<ContentsAccordian scopes={user.scopes} isAIAssistant={true} />
						)}
					</Box>

					{/* Right Section */}
					<Box
						sx={{
							width: '70%',
							backgroundColor: '#ffffff', // Optional: Background color for the right section
							padding: 2,
						}}
					>
						<AiConversationTab isAIAssistant={true} agentId={agentId} id={'65e06d1d23b2833249ff4bd0'} include_revel_content={selectedValue} assistantPrompt={prompt} />
					</Box>
				</Box>
			</Drawer>
		</React.Fragment>
	)
}
