import { Grid, Typography, Box, Paper } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { addInParams } from '../../../utils';

const DoughnutChart = ({ chartData, title, backendData, queriesValue, setQueriesValue }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverOpen = (event) => {
	  setAnchorEl(event.currentTarget);
	};
  
	const handlePopoverClose = () => {
	  setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	function populateQueries(array, label, key1) {
		const newQueriesValue = {...queriesValue, acuity: queriesValue?.acuity ? queriesValue?.acuity : []}
		let filteredObject ;
		if(key1 === 'acuity'){
			filteredObject = array?.find(item => item.value === label);
			if (filteredObject) {
				// Only add label if it’s not already in the acuity array
				if (!newQueriesValue?.acuity?.includes(label)) {
					const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1)
					newQueriesValue?.acuity?.push(label);

					addInParams('acuity', newQueriesValue.acuity?.map(item => item));

					const oldLocalStorage = localStorage.getItem('checkboxStates') ? JSON.parse(localStorage.getItem('checkboxStates')) : {};
					localStorage.setItem('checkboxStates', JSON.stringify({ acuity: {...oldLocalStorage?.acuity, [capitalizedLabel]: true} }));
				}
			}
		}else if(key1 === 'surgeon'){
			filteredObject = array?.find(item => item.title === label);
			if (filteredObject) {
				// Only add label if it’s not already in the acuity array
				if (!newQueriesValue?.surgeon?.find(item => item.title === label)) {
					newQueriesValue?.surgeon?.push(filteredObject);
					addInParams('surgeon', [...newQueriesValue?.surgeon?.map(item => item?.value)])
					const oldLocalStorage = localStorage.getItem('surgeon') ? JSON.parse(localStorage.getItem('surgeon')) : []
					localStorage.setItem('surgeon', JSON.stringify([...oldLocalStorage, filteredObject]))
				}
			}
		}else if(key1 === 'taskCategory'){
			filteredObject = array?.find(item => item.value === label);
			if (filteredObject) {
				// Only add label if it’s not already in the acuity array
				if (!newQueriesValue?.taskCategory?.find(item => item?.value === label)) {
					newQueriesValue?.taskCategory?.push(filteredObject);
					addInParams('taskCategory', [...newQueriesValue?.taskCategory?.map(item => item?.value)])
					const oldLocalStorage = localStorage.getItem('taskCategory') ? JSON.parse(localStorage.getItem('taskCategory')) : []
					localStorage.setItem('taskCategory', JSON.stringify([...oldLocalStorage, filteredObject]))
				}
			}
		}

		setQueriesValue(newQueriesValue)
		localStorage.setItem('queriesValue', JSON.stringify(newQueriesValue))
		console.log(filteredObject, newQueriesValue, label,array, key1, 'In func')
	  }

	// Data for doughnut
	const data = {
		labels: chartData?.map((item) => item.label || 'N/A'),
		datasets: [
			{
				data: chartData?.map((item) => item.count || 0),
				borderColor: ['#FFFFFF'],
				backgroundColor:
					title === 'Active Triage Needs'
						? chartData?.map((item) => {
								if (item.label === 'low') return '#75D3C8'
								if (item.label === 'medium') return '#FFB546'
								if (item.label === 'high') return '#EF5F5F'
								return '#CCCCCC' // default color if none match
						  })
						: ['#75D3C8', '#FFB546', '#EF5F5F'],
				pointBackgroundColor: 'rgba(255,206,86,0.2)',
			},
		],
	}

	// Options for doughnut
	const options = {
		plugins: {
			title: {
				display: true,
				font: {
					size: 16,
					weight: 'bold',
				},
			    padding: {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				  },
				align: 'start',
			},
			legend: {
				display: true,
				position: 'right',
				align: 'end',
				labels: {
					boxWidth: 20,
					padding: 10,
					usePointStyle: true,
					generateLabels: (chart) => {
						//For Showing Percentage in front of labels
						const dataset = chart?.data?.datasets[0]
						const total = dataset?.data?.reduce((sum, value) => sum + value, 0)

						return chart?.data?.labels?.map((label, index) => {
							const value = dataset?.data[index]
							const percentage = Math?.floor((value / total) * 100)
							const capitalizedLabel = label?.charAt(0)?.toUpperCase() + label?.slice(1)

							return {
								text: `	${capitalizedLabel} (${percentage}%)`,
								fillStyle: dataset?.backgroundColor[index],
								lineWidth: 1,
								hidden: chart?.getDatasetMeta(0)?.data[index]?.hidden,
								index: index,
							}
						})
					},
				},
			},
			datalabels: {
				color: (ctx) => {
					const value = ctx?.dataset?.data[ctx?.dataIndex]
					return value < 100 ? 'black' : 'white' // Customize color based on value
				},
				formatter: (value, ctx) => {
					const dataset = ctx?.chart?.data?.datasets[ctx?.datasetIndex]
					const total = dataset?.data?.reduce((sum, current) => sum + current, 0)
					const percentage = Math?.floor((value * 100) / total)
					return percentage > 0 ? `${percentage}%` : '' // Display percentage if > 0
				},
				font: {
					size: 12,
					weight: 'bold',
				},
				display: false, // Set to true if you want labels inside the doughnut segments
			},
			afterDraw: (chart) => {
				if (chart?.data?.datasets[0]?.data?.length < 1) {
					const ctx = chart?.ctx
					const width = chart?.width
					const height = chart?.height
					ctx.textAlign = 'center'
					ctx.textBaseline = 'middle'
					ctx.font = '30px Arial'
					ctx.fillText('No data to display', width / 2, height / 2)
					ctx.restore()
				}
			},
		},
		maintainAspectRatio: false, // Disable aspect ratio to control size manually
		responsive: true,
		onHover: (event, chartElement) => {
			event.native.target.style.cursor = chartElement?.length ? 'pointer' : 'default'
		},
		onClick: (evt, element) => {
			if (element.length > 0) {
				const chart = element[0].element.$context.chart
				const datasetIndex = element[0].datasetIndex
				const index = element[0].index
				const label = chart.data.labels[index]
				const backendValue = backendData.find(item => item.title === label)
				const keyValue = title === 'Outstanding Inquiries' ? 'taskCategory' : title === 'Active Triage Needs' ? 'acuity' : 'surgeon'
				populateQueries(backendData, label, keyValue)
				// if (!allMetric?.some((obj) => obj.query === query && obj.values === backendValue?.value)) {
				// 	const newMetric = [...allMetric, { metric: heading, key: label, values: backendValue?.value, query }]
				// 	setAllMetric(newMetric)
				// 	localStorage.setItem('allMetric', JSON.stringify(newMetric));
				// }
			}
		},
	}

	const generatePopoverLabels = () => {
		const dataset = data.datasets[0];
		const total = dataset?.data?.reduce((sum, value) => sum + value, 0);
	  
		return data.labels?.map((label, index) => {
		  const value = dataset?.data[index];
		  const percentage = Math.floor((value / total) * 100);
	  
		  // Use the same logic for background colors as in the chart
		  let backgroundColor;
		  if (title === 'Active Triage Needs') {
			if (label === 'low') backgroundColor = '#75D3C8';
			else if (label === 'medium') backgroundColor = '#FFB546';
			else if (label === 'high') backgroundColor = '#EF5F5F';
			else backgroundColor = '#CCCCCC'; // default color
		  } else {
			// Fallback for other titles or conditions
			const colors = ['#75D3C8', '#FFB546', '#EF5F5F'];
			backgroundColor = colors[index % colors.length]; // Cycle through colors
		  }
	  
		  const capitalizedLabel = label?.charAt(0)?.toUpperCase() + label?.slice(1);
	  
		  return (
			<div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
			  <div
				style={{
				  width: '12px',
				  height: '12px',
				  backgroundColor,
				  marginRight: '8px',
				  borderRadius: '50%',
				}}
			  ></div>
			  <Typography>{`${capitalizedLabel} (${percentage}%)`}</Typography>
			</div>
		  );
		});
	  };
	  

	return (
		<>
		<div style={{ width: '100%', height: '100px' }}>
			<Doughnut data={data} options={options} />
		</div>
		<Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
		<Typography sx={{p: 1}}>
		{generatePopoverLabels()}
		</Typography>
      </Popover>
		</>
	)
}

const StatCard = ({ title, value, isGraph, data, backendData, queriesValue, setQueriesValue }) => {
	return (
		<Paper
			sx={{
				background: '#fff',
				margin: '10px',
				padding: '12px',
				borderRadius: '20px',
				height: '93%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				boxShadow: '0px 0px 15px rgba(52, 58, 64, 0.2)',
				border: '1px solid #D0EAE1',
			}}
		>
			<Box>
				<Typography sx={{ fontSize: '18px', color: '#4B4D4F', fontWeight: 400 }}>{title}</Typography>
				<Typography sx={{ fontSize: '26px', fontWeight: 600, color: '#05080B' }}>{value}</Typography>
			</Box>
			{isGraph ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<DoughnutChart chartData={data} title={title} backendData={backendData} queriesValue={queriesValue} setQueriesValue={setQueriesValue} />
				</Box>
			) : (
				<Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', overflow: 'hidden' }}>
					{[
						{ label: 'Low', value: 20, bg: '#A2F2EE' },
						{ label: 'Medium', value: 40, bg: 'rgba(255, 181, 70, 0.40)' },
						{ label: 'High', value: 40, bg: '#FFE1E1' },
					].map((item, index) => (
						<p
							style={{
								background: item?.bg,
								padding: '5px',
								margin: '4px',
								textAlign: 'center',
								flex: 1,
								boxSizing: 'border-box',
								borderRadius: '5px',
								fontWeight: 500,
								fontSize: '14px',
								color: '#05080B',
							}}
							key={index}
						>
							{item?.label}:{item?.value}
						</p>
					))}
				</Box>
			)}
		</Paper>
	)
}

export function RightSide({ data, backendData, queriesValue, setQueriesValue }) {
	return (
		<>
			<Grid container spacing={0.5}>
				<Grid item xs={4}>
					<StatCard
						title="Active Triage Needs"
						value={data?.activeTriageNeeds?.[0]?.totalCount}
						data={data?.activeTriageNeeds?.[0]?.acuityBreakdown}
						isGraph={true}
						backendData={backendData?.acuity?.value}
						queriesValue={queriesValue}
						setQueriesValue={setQueriesValue}
					/>
				</Grid>

				{/* <Grid item xs={3}>
					<StatCard title="Tasks By Acuity" value="120" isGraph={false} />
				</Grid> */}

				<Grid item xs={4}>
					<StatCard
						title="Surgeon's Patients"
						value={data?.surgeonPatientCounts?.[0]?.totalCount}
						data={data?.surgeonPatientCounts?.[0]?.surgeonBreakdown}
						isGraph={true}
						backendData={backendData?.surgeon?.value}
						queriesValue={queriesValue}
						setQueriesValue={setQueriesValue}
					/>
				</Grid>

				<Grid item xs={4}>
					<StatCard
						title="Outstanding Inquiries"
						value={data?.outstandingInquires?.[0]?.totalCount}
						data={data?.outstandingInquires?.[0]?.categoryBreakdown}
						isGraph={true}
						backendData={backendData?.taskCategory?.value}
						queriesValue={queriesValue}
						setQueriesValue={setQueriesValue}
					/>
				</Grid>
			</Grid>
		</>
	)
}
