import React, { useState, useEffect } from 'react'
import { Grid, Stack, Box, Typography, TextField, Modal, OutlinedInput, InputLabel, MenuItem, FormControl, ListItemText, Select, Checkbox } from '@mui/material'
import { SolidButton } from '../../Components'
import CloseIcon from '@mui/icons-material/Close'
import { useIsMobile } from '../../contexts/isMobile'
import { toast } from 'react-toastify'
import { useAddNewAgentMutation, useEditAgentsMutation, useGetAgentsFilterQuery } from '../../store/agentsSlice'
import { useSelector } from 'react-redux'
import { useAddNewAssistantMutation } from '../../store/assistantsSlice'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
}



  const SourceDropdown = ({ source, setSource, dataSources }) => {
	const handleChange = (event) => {
	  const {
		target: { value },
	  } = event;
  
	  setSource(
		typeof value === 'string' ? value.split(',') : value
	  );
	};
  
	return (
	  <div>
		<FormControl fullWidth>
		  <InputLabel id="demo-multiple-dataSource-label">Data Sources</InputLabel>
		  <Select
			required
			labelId="demo-multiple-dataSource-label"
			id="demo-multiple-checkbox"
			multiple
			value={source}
			onChange={handleChange}
			input={<OutlinedInput label="Data Sources" />}
			renderValue={(selected) =>
			  selected
				.map((val) => dataSources.find((src) => src.value === val)?.title)
				.join(', ')
			}
			MenuProps={MenuProps}
		  >
			{dataSources.map((src) => (
			  <MenuItem key={src.value} value={src.value}>
				<Checkbox checked={source.indexOf(src.value) > -1} />
				<ListItemText primary={src.title} />
			  </MenuItem>
			))}
		  </Select>
		</FormControl>
	  </div>
	);
  };

const LanguageModelDropdown = ({ model, setModel, setLanguageModel, dataLanguageModel }) => {
	const handleChange = (event) => {
		setModel(event.target.value)
		setLanguageModel(event.target.value?.value)
	  };

	return (
		<div>
		<FormControl fullWidth>
		  <InputLabel id="demo-simple-select-label">Language Model</InputLabel>
		  <Select
			labelId="demo-simple-select-label"
			id="demo-simple-select"
			label="Language Model"
			onChange={handleChange}
			required
			value={model}
		  >
			{dataLanguageModel.map((item, index) => (
			  <MenuItem key={index} value={item}>
				{item.title}
			  </MenuItem>
			))}
		  </Select>
		</FormControl>
	  </div>
	)
}

export default function AddAiAssistantModal({ open, setOpen, editData, setEditData, refetchAgain, isAIAssistant }) {
	const { user } = useSelector((state) => state.user)
	const [form, setForm] = useState({
		title: '',
		description: '',
		prompt: '',
	})
	const [modalHeight, setModalHeight] = useState(null)
	const [source, setSource] = useState([])
	const [languageModel, setLanguageModel] = useState(null)
	const [model, setModel] = useState(null)
	const [dataLanguageModel, setDataLanguageModel] = useState([])
	const [dataSources, setDataSources] = useState([])
	const isMobile = useIsMobile()

	const {data} = useGetAgentsFilterQuery()
	const [addNewAgent, {isLoading}] = useAddNewAgentMutation()
	const [addNewAssistant, {isLoading: isLoadingAssitant}] = useAddNewAssistantMutation()
	const [editAgent, {isLoading: isEditLoading}] = useEditAgentsMutation()
	const onChange = (e) => {
		setForm((form) => ({ ...form, [e.target.name]: e.target.value }))
	}

	const resetAllValues = () => {
		setForm({
			title: '',
			description: '',
			prompt: '',
		})
		setSource([])
		setLanguageModel(null)
		setModel(null)
		setEditData(null)

	}

	const createCareAlly = async (e) => {
		try {
			// check if the any field is empty
			const emptyFields = Object.values(form).some((value) => value === "") || source.length === 0 || languageModel === null;
	
			if ((emptyFields && !isAIAssistant) || ((form.title === "" || form.prompt === "" || languageModel === null) && isAIAssistant)) {
			  toast.error("Please fill in all the fields!");
			  return;
			}

			const body = {
			  ...form,  
			sourceTypes: source,
			language_model: languageModel
			};

			const AiAssistantBody = {
				title: form.title,
				prompt: form.prompt,
				language_model: languageModel,
				createdBy: user.id,
				// hospitalId : user.hospital
			}

			let response;
			if (!editData?._id && !isAIAssistant) {
			  response = await addNewAgent(body);
			} else if (!editData?._id && isAIAssistant) {
				response = await addNewAssistant(AiAssistantBody);
			} else {
			  response = await editAgent({ id: editData?._id, body });
			}
	
			if (response?.data) {
			  const responseToast = editData?._id && !isAIAssistant
				? "Agent Updated Successfully!"
				: !editData?._id && isAIAssistant 
				? "Assistant Created Successfully!"
				: "Agent Created Successfully!";
			  toast.success(responseToast);
			  refetchAgain();
			  resetAllValues();
			  setOpen(false);
			} else {
			  toast.error(response.error?.data?.error || response.error?.data);
			}
		  } catch (err) {
			console.log("Error:", err);
			toast.error("Something went wrong!");
		  }
	  };

	const handleClose = () => {
		resetAllValues()
		setOpen(false)
	}

	useEffect(() => {
		if (editData) {
			setForm({
				title: editData?.title || '',
				description: editData?.description || '',
				prompt: editData?.prompt || '',
			})

			setSource(editData?.sourceTypes)
			const filteredModel = dataLanguageModel?.find(item => item?.value === editData?.language_model);
			setModel(filteredModel);
			setLanguageModel(editData?.language_model)
		}
	}, [editData])

	useEffect(() => {
		if (form.prompt) {
			const lineCount = (form?.prompt || '').split('\n').length
			const adjustedLineCount = Math.min(lineCount, 8)
			const newHeight = 400 + adjustedLineCount * 20
			setModalHeight(newHeight)
		} else {
			setModalHeight(400)
		}
	}, [form])

	useEffect(()=>{
		if(data?.filters){
			const languageModelFilters = Object.entries(data?.filters?.languages_model)?.map(([key, val])=>{
				return {title:key, value:val}
			})

			const sourceFilters = Object.entries(data?.filters?.source)?.map(([key, val])=>{
				return {title:key, value:val}
			})

			setDataSources(sourceFilters)
			setDataLanguageModel(languageModelFilters)
		}
	},[data])

	return (
		<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: isMobile ? '80%' : 700,
					bgcolor: '#F5F5F5',
					p: 4,
					borderRadius: '10px',
					overflowY: 'auto',
					maxHeight: '90vh',
				}}
				className="DialogContent"
			>
				<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '16px' }}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
					{isAIAssistant ? "AI Assistant" : "AI Agent"}
					</Typography>
					<Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
						<CloseIcon />
					</Box>
				</Stack>

				<Grid container spacing={2}>
					<Grid item md={6} xs={12}>
						<TextField required label="Title" variant="outlined" fullWidth name="title" value={form.title} onChange={onChange} />
					</Grid>

					<Grid item md={6} xs={12}>
						<LanguageModelDropdown {...{ model, setModel, setLanguageModel, dataLanguageModel }} />
					</Grid>

{!isAIAssistant && (
					<Grid item md={12} xs={12}>
						<SourceDropdown {...{ source, setSource, dataSources }} />
					</Grid>
)}

{!isAIAssistant && (
					<Grid item md={12} xs={12}>
						<TextField
							required
							label="Description"
							variant="outlined"
							fullWidth
							name="description"
							value={form.description}
							onChange={onChange}
							multiline
							minRows={4}
							maxRows={12}
						/>
					</Grid>
)}

					<Grid item md={12} xs={12}>
						<TextField
							required
							label="Prompt"
							variant="outlined"
							fullWidth
							name="prompt"
							value={form.prompt}
							onChange={onChange}
							multiline
							minRows={6}
							maxRows={20}
						/>
					</Grid>
				</Grid>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '16px' }}>
					<SolidButton label={editData?._id ? 'Save' : 'Create'} onClick={createCareAlly} disabled={isLoading || isEditLoading || isLoadingAssitant} />
				</Box>
			</Box>
		</Modal>
	)
}
