import React, { useState } from "react";
import { Paper } from "@mui/material";
import { UserTable } from "./UserTable";
import { TableHead } from "./TableHead";
import { useGetUsersQuery } from "../../store/userManagmentSlice";
import { useDebouncedValue } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useIsMobile } from "../../contexts/isMobile";
import {InactivityCheck} from "../../Components/InactivityCheck"

export function RolePage() {
  const isMobile = useIsMobile();
  const { user } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["name", "email"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};

  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }
  const { data, isLoading, isError, isSuccess } = useGetUsersQuery({
    userID: user.id,
    page,
    ...queries,
  });

  // If Token is expire it navigate to Login page
  return (
    <div style={{ padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem" }}>
      <TableHead
        {...{
          searchTerm,
          setSearchTerm,
          options: searchAbleFields,
          selectedOptions: selectedFields,
          handleOptionSelect: handleFieldSelect,
        }}
      />
      <UserTable
        {...{
          page,
          setPage,
          data,
          isLoading,
          isError,
          isSuccess,
        }}
      />
      <InactivityCheck />
    </div>
  );
}
