import React, {useState, useEffect}  from "react";
import { useIsMobile } from "../../../contexts/isMobile";
import { Line } from "react-chartjs-2";
import { CircularLoader } from "../../../Components";

export function CountChart({chartData, isLoading, isError,}) {
    const isMobile = useIsMobile();

    // useStates for Male and female
    const [male, setMale] = useState(Array(12).fill(0))
    const [female, setFemale] = useState(Array(12).fill(0))

    // Data for the Line Chart
    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","July","Aug", "Sept", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Male",
            data: male,
            fill: false,
            borderColor: "#816179"
          },
          {
            label: "Female",
            // data: [0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0],
            data: female,
            fill: false,
            borderColor: "#D0EAE1"
          }
        ]
    };

  // Disabling the label
  const options = {
    responsive: true,
    aspectRatio: isMobile ? 1 : 2, // Adjust the aspect ratio based on the device
    plugins: {
      legend: {
        display: false,
      },
      title: {
        padding: {
          top: 30,
          bottom: 30,
        },
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
        },
      },
    },
  };

    // useEffect to initialize the male and female data
    useEffect(() => {
      if(chartData){
        setMale(chartData?.male);
        setFemale(chartData?.female);
      }
    }, [chartData])
    
if(isLoading) return <>
  <CircularLoader />
</>

if(isError) return <p>Some error</p>
  return (
    <>
      <Line data={data} options={options} />
    </>
  );
}
