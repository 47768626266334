import React, { memo, useState } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import cx from 'classnames';

import styles from './NodeTypes.module.css';
import usePlaceholderClick from '../hooks/usePlaceholderClick';
import { AddDrawer } from '../../Drawers';

const PlaceholderNode = ({ id, data }) => {
  // see the hook implementation for details of the click handler
  // calling onClick turns this node and the connecting edge into a workflow node
  // const onClick = usePlaceholderClick(id);

      // State and function for Start Journey
      const [openJourney, setOpenJourney] = useState(false);

      const toggleJourenyDrawer = (openJourney) => (event) => {
        if (
          event.type === "keydown" &&
          (event.key === "Tab" || event.key === "Shift")
        ) {
          return;
        }
        setOpenJourney(openJourney);
      };

  const nodeClasses = cx(styles.node, styles.placeholder);

  return (
    <>
    <div onClick={toggleJourenyDrawer(true)} className={nodeClasses} title="click to add a node">
      {data.label}
      <Handle className={styles.handle} type="target" position={Position.Top} isConnectable={false} />
      <Handle className={styles.handle} type="source" position={Position.Bottom} isConnectable={false} />
      <AddDrawer toggleDrawer={toggleJourenyDrawer} id={id} state={openJourney} title={"Start Journey"} choice={"placeholder"} />
    </div>
    </>
  );
};

export default memo(PlaceholderNode);
