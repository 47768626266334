import { Pagination, Box } from "@mui/material";
import React from "react";

export const PaginationBar = ({ page, count, handleChange }) => {
  return (
    <Box sx={{ paddingTop: "2.5rem", paddingBottom: "2rem" }}>
      <Pagination
        sx={{
          display: "flex",
          justifyContent: "center",
          "& .MuiPaginationItem-icon": {
            backgroundColor: "rgba(0, 151, 236, 0.12)",
            color: "black",
          },
          "& .Mui-selected": {
            backgroundColor: "rgba(0, 151, 236, 0.12) !important",
            color: "black !important",
          },
        }}
        count={count}
        page={page}
        onChange={handleChange}
      />
    </Box>
  );
};
