import "./App.css";
import { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RoutersProvider } from "./router";
import { HoveredProvider } from "./contexts/isHovered";
import { ChildNodeProvider } from "./contexts/addChildNode";
import {ChatProvider} from "./contexts/chatValue";
import { AssistantChatProvider } from "./contexts/assistantChatValue";
import { socket } from "./socket";

function App() {
  const [socketio, setSocket] = useState(socket.connected);

  useEffect(() => {
    function onConnect() {
      setSocket(true);
    }

    function onDisconnect() {
      setSocket(false);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  return (
    <>
      {/* <Container sx={{ minWidth: "100%" }} maxWidth={false}> */}
      <HoveredProvider>
        <ChildNodeProvider>
          <ChatProvider>
            <AssistantChatProvider>
          <RoutersProvider />
          <ToastContainer />
            </AssistantChatProvider>
          </ChatProvider>
        </ChildNodeProvider>
      </HoveredProvider>
      {/* </Container> */}
    </>
  );
}

export default App;
