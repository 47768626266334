import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIsMobile } from '../../contexts/isMobile'

export function ThankyouMsg() {
  const isMobile=useIsMobile()
  return (
    <Stack direction="row" justifyContent="center" sx={{height:'100vh'}} >
      <Box  sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems:"center",
            textAlign:"center",width:isMobile?"80%":"100%"
          }}>
        <Typography variant="h1" sx={{marginBottom:"5px"}}>Thank you for uploading your images!</Typography>
        <Typography variant="body1">
        Our team will review them promptly and get back to you as soon as possible.
        </Typography>
      </Box>
      </Stack>
  )
}