import React, { useState } from "react";
import { Stack, Box, Grid, IconButton } from "@mui/material";
import { SideBar } from "./SideBar";
import { SolidButton } from "./../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import SearchField from "../../../Components/SearchField";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FiltersDialog from "../../../Components/FiltersDialog";
import { patientProfileFilterData } from "../../../FilterUtils";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useNavigate } from "react-router-dom";

export function TableHeader({
  searchTerm,
  setSearchTerm,
  options,
  selectedOptions,
  handleOptionSelect,
  setQueriesValue,
  queriesValue
}) {
  const isMobile = useIsMobile();
  const [openFilter, setOpenFilter] = useState(false);
  const navigate = useNavigate();

  const handleRedirect = () => {
    localStorage.removeItem('patientForm')
    localStorage.removeItem('assignJourneyForm')
    localStorage.removeItem('assignSurgeryForm')
    localStorage.removeItem('stepperValue')
    navigate("/createProfile/");
  };

  const handleOpenFilter = (e) => {
    setOpenFilter(true);
  };

  console.log(queriesValue)
  return (
    <>
      <Box sx={{ padding: isMobile ? "6px" : 3 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={3} textAlign="left">
                <SearchField
                  {...{
                    searchTerm,
                    setSearchTerm,
                    options,
                    selectedOptions,
                    handleOptionSelect,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={9} textAlign="right" mt={isMobile&& 2}>
                {/* <IconButton>
										<FileDownloadOutlinedIcon sx={{ color: '#536878' }} />
									</IconButton> */}
                <IconButton onClick={(e) => handleOpenFilter(e)}>
                  <FilterAltOutlinedIcon sx={{ color: "#536878" }} />
                </IconButton>
                <SolidButton
                  label="Create"
                  onClick={handleRedirect}
                  btnWidth={"10%"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <FiltersDialog
        open={openFilter}
        setOpen={setOpenFilter}
        queriesValue={queriesValue}
        setQueriesValue={setQueriesValue}
        data={patientProfileFilterData}
      />
      {/* <SideBar toggleDrawer={toggleDrawer} state={open} /> */}
    </>
  );
}
