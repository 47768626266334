import { Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const renderValue = (item, data) => {
  if (item.value === "tags" && data.tags) {
    return data.tags.length === 0
      ? "N/A"
      : data.tags.map((tagItem) => tagItem.tag).join(", ");
  } else if (
    (item.value === "birthday" || item.value === "procedureDate") &&
    data[item.value]
  ) {
    return moment(data[item.value]).format("MM-DD-YYYY");
  } else {
    return data[item.value] || "N/A";
  }
};

export default function DemographicsData({ data, TitleData, title }) {
  return (
    <div key={data?._id}>
      <Typography fontSize={"1.1rem"} fontWeight={"bold"}>
        {title}
      </Typography>
      <Grid container spacing={2} p={1} mb={3}>
        {TitleData?.map((item) => (
          <Grid item md={item.value === "patientSummary" ? 12 :item.value==="email"?6: 4} xs={item.value === "patientSummary"||item.value==="email" ? 12 :6 }>
            <Typography fontSize="1rem" color="#979797">
              {item.title}
            </Typography>
            <Typography
              fontSize="1rem"
              textTransform={item.value === "gender" ?"capitalize":"none"}
            >
              {renderValue(item, data)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
