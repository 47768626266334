import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContents: builder.query({
      // query: () => "/hospitals",
      query: (query = {}) => {
        const { page = 1, limit = 10, userID, ...rest } = query;
        const finalQuery = { page, limit, userID, ...rest };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/contents/?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Contents", id: "LIST" },
          ...data.map((p) => ({ type: "Content", id: p._id })),
        ];
      },
    }),
    getAllContents: builder.query({
      query: (id) => `/contents/all/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Contents", id: "LIST" },
          { type: "Content", id: data._id || arg },
        ];
      },
    }),
    getContentByID: builder.query({
      query: (id) => `/contents/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Contents", id: "LIST" },
          { type: "Content", id: data._id || arg },
        ];
      },
    }),
    addNewContent: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/contents",
          method: "POST",
          body: initialPost
        };
      },
      invalidatesTags: [{ type: "Contents", id: "LIST" }],
    }),
    deleteContent: builder.mutation({
      query: (id) => {
        return {
          url: `/contents/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Contents", id: "LIST" }],
    }),
  }),
});

export const {
  useGetContentsQuery,
  useGetAllContentsQuery,
  useGetContentByIDQuery,
  useAddNewContentMutation,
  useDeleteContentMutation,
} = extendedApiSlice;
