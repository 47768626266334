import React from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
} from "@mui/material";
import { useIsMobile } from "../../contexts/isMobile";
import { SolidButton } from "../SolidButton";
import OutlinedButton from "../OutlinedButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius:"10px"
};

export const ConfirmationModal = ({ open, setOpen, handle,txt ,id }) => {
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    handle(id)
  };
  

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" sx={{textAlign:"center"}}>
            {typeof txt !== 'undefined' && txt !==''  ? txt : 'Are you Sure You Want to Delete' }
          </Typography>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Box sx={{ margin: "5px" }}>
                <SolidButton label={"Yes"} onClick={handleConfirmation} btnWidth={isMobile ? "100%" : "90px"} />
                
              </Box>
              <Box sx={{ margin: "5px" }}>
                <OutlinedButton label={"Cancel"} handleClick={handleClose} />
                
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
