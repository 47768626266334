import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useIsMobile } from "../../../contexts/isMobile";

export function MapHeader() {
  const isMobile = useIsMobile();

  const gridMargin = {
    margin: isMobile && "10px 5px",
  };

  return (
    <>
      <Box sx={{ padding: isMobile ? "6px" : 3, paddingBottom: "0px !important" }}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item xs={12} sm={8} sx={gridMargin}>
            <Typography variant="h5">State-wise Patient Counts Map</Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}