import React from "react";

export function PendingSvg() {
    return (
        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 21C12.6167 21 11.4373 20.5123 10.462 19.537C9.48667 18.5617 8.99934 17.3827 9 16C9 14.6167 9.48767 13.4373 10.463 12.462C11.4383 11.4867 12.6173 10.9993 14 11C15.3833 11 16.5627 11.4877 17.538 12.463C18.5133 13.4383 19.0007 14.6173 19 16C19 17.3833 18.5123 18.5627 17.537 19.538C16.5617 20.5133 15.3827 21.0007 14 21ZM15.675 18.375L16.375 17.675L14.5 15.8V13H13.5V16.2L15.675 18.375ZM2 20C1.45 20 0.979002 19.804 0.587002 19.412C0.195002 19.02 -0.000664969 18.5493 1.69779e-06 18V4C1.69779e-06 3.45 0.196002 2.979 0.588002 2.587C0.980002 2.195 1.45067 1.99934 2 2H6.175C6.35834 1.41667 6.71667 0.937335 7.25 0.562002C7.78334 0.186668 8.36667 -0.000664894 9 1.77305e-06C9.66667 1.77305e-06 10.2627 0.187668 10.788 0.563002C11.3133 0.938335 11.6673 1.41734 11.85 2H16C16.55 2 17.021 2.196 17.413 2.588C17.805 2.98 18.0007 3.45067 18 4V10.25C17.7 10.0333 17.3833 9.85 17.05 9.7C16.7167 9.55 16.3667 9.41667 16 9.3V4H14V7H4V4H2V18H7.3C7.41667 18.3667 7.55 18.7167 7.7 19.05C7.85 19.3833 8.03334 19.7 8.25 20H2ZM9 4C9.28334 4 9.521 3.904 9.713 3.712C9.905 3.52 10.0007 3.28267 10 3C10 2.71667 9.904 2.479 9.712 2.287C9.52 2.095 9.28267 1.99934 9 2C8.71667 2 8.479 2.096 8.287 2.288C8.095 2.48 7.99934 2.71733 8 3C8 3.28333 8.096 3.521 8.288 3.713C8.48 3.905 8.71734 4.00067 9 4Z" fill="#50607A" />
        </svg>

    );
}
