import { Grid, Box, Typography, Avatar } from "@mui/material";
import { useIsAuthenticatedUser } from "./../../../contexts/isAuthenticatedUser";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import React from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

export default function ProfileDetailSidebar({ path, name, patient }) {
  const { user } = useIsAuthenticatedUser();

  const getAge = (birthDate) => {
    if (birthDate) {
      const age = Math.floor(
        (new Date() - new Date(birthDate).getTime()) / 3.15576e10
      );
      return `${age}Y`;
    } else {
      return "No DOB Available";
    }
  };

  const renderContactInfo = (value, IconComponent) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "20px",
        alignItems: "center",
        marginBottom: "5px",
      }}
    >
      <IconComponent sx={{ color: "#50607A", fontSize: "16px" }} />
      <Typography
        sx={{ fontSize: "12px", marginLeft: "8px", color: "#50607A" }}
      >
        {value}
      </Typography>
    </Box>
  );

  return (
    <div
      style={{
        borderLeft: "1px solid #979797",
        height: "67vh",
        paddingLeft: "1rem",
      }}
    >
      {/* Image section */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ padding: "10px" }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Avatar
            alt="Remy Sharp"
            src={
              path
                ? `${process.env.REACT_APP_BASE_API_URL}/${path}`
                : user?.picture
            }
            sx={{ width: "40px", height: "40px" }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "15px",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>{name}</Typography>
            <Typography sx={{ fontWeight: 400 }}>{getAge(patient?.birthday)}, {patient.gender?.charAt(0)?.toUpperCase() || 'N/A'}</Typography>
          </Box>
        </Box>
      </Grid>

      {/* Contact Info section */}
      <Box
        sx={{
          marginLeft: "10px",
          background: "#f7f8fb",
          borderRadius: "10px",
          my: "15px",
          p: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bolder",
            marginLeft: "10px",
            fontSize: "14px",
            marginBottom: "10px",
          }}
        >
          Contact Info
        </Typography>
        {renderContactInfo(patient?.phone, CallOutlinedIcon)}
        {renderContactInfo(patient?.email, EmailOutlinedIcon)}
        {renderContactInfo("123 Street, City", LocationOnOutlinedIcon)}
      </Box>

      {/* Treatment section */}
      {/* <Box
        sx={{
          marginLeft: "13px",
          marginTop: "20px",
          borderBottom: "1px solid #816179",
          pb: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "0.5",
            marginBottom: "15px",
          }}
        >
          Treatment
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ marginRight: "2rem" }}>
              <Typography sx={{ fontSize: "12px", color: "#979797" }}>
                Date of Last Encounter
              </Typography>
              <Typography
                sx={{
                  lineHeight: "1.2",
                  fontSize: "14px",
                }}
              >
                01/20/20
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "12px", color: "#979797" }}>
                Primary Provider
              </Typography>
              <Typography
                sx={{
                  lineHeight: "1.2",
                  fontSize: "14px",
                }}
              >
                Dr something
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography sx={{ fontSize: "12px", color: "#979797" }}>
          Surgery
        </Typography>
        <Typography
          sx={{
            lineHeight: "1.2",
            fontSize: "14px",
            marginBottom: "5px",
          }}
        >
          Dummy surgery
        </Typography>
      </Box> */}

      {/* <Box
        sx={{
          marginLeft: "13px",
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "0.5",
            marginBottom: "10px",
          }}
        >
          Last Note
        </Typography>

        <Typography sx={{ fontSize: "12px", color: "#50607A" }}>
          Generated By Jennings, Hayden at 03:15 PM
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineClamp: 2,
          }}
        >
          Subject: Patient has post-operative pain and stiffness after total hip
          replacement surgery two days ago. Pain is 8/10, localized to the
          surgical site. Patient is taking pain medications and following
          post-operative instructions.
        </Typography>
      </Box> */}
    </div>
  );
}
