import React from 'react'
import { Modal, Box, Typography, IconButton, Divider, Select, MenuItem, FormControl, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SolidButton } from '../../Components'

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	maxHeight: '80vh',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	overflowY: 'auto',
	borderRadius: '12px',
}

const dropdownContainerStyle = {
	mb: 2,
	display: 'flex',
	alignItems: 'center',
	gap: 2,
}

const dropdownStyle = {
	minWidth: 250,
	width: 250,
}

const dropdownSelectStyle = {
	height: 36,
	'& .MuiSelect-select': {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
	},
}

const dropdownLabelStyle = {
	fontWeight: 500,
	fontSize: '1rem',
	marginRight: 2,
	width: 100, // Fixed width for labels
	display: 'flex',
	alignItems: 'center', // Align items vertically
}

const additionalTextStyle = {
	mt: 1,
	fontSize: '0.875rem',
	color: 'text.secondary',
}

const textareaContainerStyle = {
	mt: 2,
}

const textareaStyle = {
	width: '100%',
	marginTop: 1,
	padding: 1,
	borderRadius: 1,
	border: '1px solid',
}

const buttonContainerStyle = {
	display: 'flex',
	justifyContent: 'flex-end',
	gap: 1,
	marginTop: 3,
}

const dropdownOptions = [
	{ value: 'medium', label: 'Medium' },
	{ value: 'low', label: 'Low' },
	{ value: 'high', label: 'High' },
]

const Dropdown = ({ label }) => (
	<Box sx={dropdownContainerStyle}>
		<Typography sx={dropdownLabelStyle}>{label}:</Typography>
		<FormControl sx={dropdownStyle}>
			<Select
				displayEmpty
				defaultValue=""
				renderValue={(selected) => {
					if (selected === '') {
						return <em style={{ fontStyle: 'normal' }}>Priority Level</em>
					}
					return selected
				}}
				sx={dropdownSelectStyle}
			>
				{dropdownOptions.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	</Box>
)

const ReferModal = ({ open, handleClose, onSubmit }) => {
	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={modalStyle}>
				<Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 400, fontSize: '1.5rem' }}>
					Reason for Referral
				</Typography>
				<Divider sx={{ mt: 2, mb: 2 }} />

				<Dropdown label="Food" />
				<Dropdown label="Electricity" />
				<Dropdown label="Security" />

				<Box sx={textareaContainerStyle}>
					<TextField multiline minRows={4} placeholder="Enter your comments here..." fullWidth variant="outlined" label="Additional Notes" sx={{ mt: 1 }} />
				</Box>

				<Box sx={buttonContainerStyle}>
					<SolidButton isOutlined={true} label={'Cancel'} onClick={handleClose} />
          <SolidButton
						label={'Submit Referral'}
						onClick={() => {
							onSubmit() // Call the function to add the timeline item
							handleClose()
						}}
					/>
				</Box>
			</Box>
		</Modal>
	)
}

export default ReferModal
