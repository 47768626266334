import React, { useState } from "react";
import { TextField, Box, Typography, Modal, Stack } from "@mui/material";
import { SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useEditTagMutation } from "../../../store/tagsSlice";
import { toast } from "react-toastify";
import { ColorPicker } from "material-ui-color";
import CloseIcon from '@mui/icons-material/Close';

export const EditTagModal = ({ open, setOpen, title, tags, color,creators,hospitals,  id }) => {
  const isMobile = useIsMobile();
  const [tag, setTag] = useState(`${tags}`);
  const [colors, setcolors] = useState(color);
  const [creator, setCreator] = useState(creators || "");
  const [hospital, setHospital] = useState(hospitals || "");

  React.useEffect(() => {
    setTag(tags);
    setcolors(color);
    setCreator(creators);
    setHospital(hospitals)
  }, [id,color, creators, hospitals]);
  const handleClose = () =>
    setOpen({ open: false, id: null, tags: "", color: {} });
  const [editTag, { isSuccess, isLoading: isEditTagLoading }] = useEditTagMutation();
  const handleColorChange = (newColor) => {
    setcolors(newColor);
    console.log(newColor);
  };
  const onSave = async (id) => {
    await editTag({ id, body: { tag: tag, color: colors, creator, hospital } })
      .unwrap()
      .then((res) => {
        toast.success("Successfully created!");
        console.log(res,"res")
        setOpen({ open: false, id: null, tags: "",color:{} });
      })
      .catch((e) => {
        toast.error(e?.data?.error || e?.message || "some error occured!");
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 400,
    bgcolor: "#F5F5F5",
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Box sx={{cursor:"pointer"}} onClick={()=>setOpen(false)}>
        <CloseIcon/>
        </Box>
      </Stack>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              required
              id="outlined-required"
              label="Name"
              name="tag"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
              }}
              sx={{ width: "100%", mt: 2 }}
            />
            <Box sx={{ width: "100%", mt: 2 }}>
              <ColorPicker value={colors} onChange={handleColorChange} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Box sx={{ ml: "auto" }}>
                <SolidButton
                  label="Save"
                  onClick={() => {
                    onSave(id);
                  }}
                  disabled={isEditTagLoading}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
