import { PatientTable as Table } from "./PatientTable";
import { TableHeader } from "./TableHeader";

export function PatientTableUI({selectedTags,
    setSelectedTags,
    searchTerm,
    setSearchTerm,
    setQueriesValue,
    queriesValue,
    searchAbleFields,
    selectedFields,
    handleFieldSelect,
    page,
    setPage,
    data,
    isLoading,
    isError,
    isSuccess,
    refetchPatientAgain,
  pageName}){
    return(
        <>
          <TableHeader
        {...{
          selectedTags,
          setSelectedTags,
          searchTerm,
          setSearchTerm,
          setQueriesValue,
          queriesValue,
          options: searchAbleFields,
          selectedOptions: selectedFields,
          handleOptionSelect: handleFieldSelect,
        }}
      />
      <Table
          {...{
            selectedTags, 
            setSelectedTags,
            page,
            setPage,
            data,
            isLoading,
            isError,
            isSuccess,
            refetchPatientAgain,
            pageName
          }}
        />
        </>
    )
}