import React, { useState } from 'react'
import { Paper, Grid, Typography, Stack, Box, Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { SeachField } from '../../../Components'
import { useIsMobile } from '../../../contexts/isMobile'
import { ContentsTable } from './ContentsTable'
import { CreateContentModal } from './CreateContentModal'
import { useGetContentsQuery } from '../../../store/contentsSlice'
import { useDebouncedValue } from '@mantine/hooks'
import { useSelector } from 'react-redux'
import { hasScope } from '../../../utils'

export function ContentsAccordian({ scopes, isAIAssistant }) {
	const isMobile = useIsMobile()
	const { user } = useSelector((state) => state.user)
	const [expanded, setExpanded] = useState(false)
	const [changeText, setChangeText] = useState(false)
	const [page, setPage] = useState(1)
	const [searchTerm, setSearchTerm] = useState('')
	const [debouncedValue] = useDebouncedValue(searchTerm, 200)
	const searchAbleFields = ['name', 'type']
	const [selectedFields, setSelectedFields] = useState([...searchAbleFields])

	const handleFieldSelect = (option, checked) => {
		if (checked) {
			setSelectedFields([...selectedFields, option])
		} else {
			setSelectedFields(selectedFields.filter((o) => o !== option))
		}
	}

	const queries = {}

	for (const field of selectedFields) {
		queries[field] = debouncedValue
	}

	const { data, isLoading, isError } = useGetContentsQuery({
		userID: user.id,
		page,
		...queries,
	})

	// States for CreateContentModal
	const [open, setOpen] = React.useState(false)
	const handleOpenCreateModal = (event) => {
		event.stopPropagation()
		setOpen(true)
	}

	const direction = isMobile ? 'column' : 'row'

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	const handleSearchFieldClick = (event) => {
		event.stopPropagation()
	}

	return (
		<Grid item xs={12} md={12} lg={12}>
			<Paper elevation={3} sx={{ marginTop: '20px', marginBottom: '20px' }}>
				<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
					<AccordionSummary
						aria-controls="panel1bh-content"
						id="panel1bh-header"
						onClick={() => setChangeText((prev) => !prev)}
						// direction="column" justifyContent="space-between"
					>
						<Stack
							sx={{
								display: 'flex',
								flexDirection: direction,
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							<Typography
								sx={{
									fontSize: isAIAssistant ? '16px' : '20px',
									fontWeight: isAIAssistant ? 700 : 500,
									margin: '8px 2px',
									width: isMobile ? '100%' : '34%',
								}}
							>
								<Box
									sx={{
										borderBottom: !isAIAssistant && changeText ? '5px solid #816179' : isAIAssistant && changeText ? '5px solid #0049C6' : 'none',
										display: 'initial',
										paddingBottom: isAIAssistant && '5px',
									}}
								>
									Content Manager
								</Box>
							</Typography>
							<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" alignItems={{ xs: 'flex-start', sm: 'center' }} sx={{ flexGrow: 1 }}>
								{hasScope(scopes, 'settings/contentManagers:create') && (
									<Box
										sx={{
											marginBottom: { xs: 1, sm: 0 },
											marginRight: { sm: 2 },
											width: isMobile ? '100%' : isAIAssistant ? '100px' : '150px',
										}}
									>
										<Button
											size="small"
											variant="contained"
											sx={{
												background: isAIAssistant ? '#0049C6' : '#816179',
												padding: '5px',
												height: isMobile ? '30px' : isAIAssistant ? '28px' : '35px',
												width: isMobile ? '100%' : isAIAssistant ? '100px' : '150px',
												borderRadius: isAIAssistant ? '1.5rem' : '0.5rem',
												fontSize: isAIAssistant ? '12px' : '14px',
											}}
											onClick={handleOpenCreateModal}
										>
											Create
										</Button>
									</Box>
								)}
								{!isAIAssistant && (
									<Box
										onClick={handleSearchFieldClick}
										sx={{
											width: isMobile ? '100%' : '20%',
											marginRight: { xs: 0, sm: 2 },
											marginBottom: isMobile && '8px',
											'.MuiAutocomplete-inputRoot': { padding: '1px' },
										}}
									>
										<SeachField
											{...{
												searchTerm,
												setSearchTerm,
												options: searchAbleFields,
												selectedOptions: selectedFields,
												handleOptionSelect: handleFieldSelect,
											}}
										/>
									</Box>
								)}
								{!isAIAssistant && (
									<Typography
										sx={{
											padding: '5px',
											fontSize: '14px',
											fontWeight: 500,
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											width: isMobile ? '100%' : '15%',
											border: '1px solid #d0d0d0',
											borderRadius: '5px',
										}}
									>
										{changeText ? 'Reduce' : 'Expand'} {changeText ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</Typography>
								)}
							</Stack>
						</Stack>
					</AccordionSummary>
					<AccordionDetails>
						<ContentsTable {...{ data, isLoading, isError, page, setPage, searchTerm, scopes, isAIAssistant }} />
					</AccordionDetails>
				</Accordion>

				{/* Modal Button */}
				<CreateContentModal open={open} setOpen={setOpen} title={'Create Content'} />
			</Paper>
		</Grid>
	)
}
