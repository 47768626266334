import React from 'react';
import { Modal, Box, Typography, IconButton, Divider, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Questions } from './Utils/Questions';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '80vh',  
  bgcolor: 'background.paper',
  
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',  
  borderRadius: '12px',  
  width: 800, 
};

const questionStyle = {
  mt: 2,
  mb: 1,
  fontWeight: 500, 
  fontSize: '1rem',
};

const numberStyle = {
  fontWeight: 800,
  marginRight: 1,
};

const SurveyModal = ({ open, handleClose, data}) => {
  const renderResult = (item) => {
		if (item?.type === 'signaturepad' && item?.result) {
			return <img src={item.result} alt="Signature" />
		} else if (item?.type === 'multipletext' && typeof item?.result === 'object') {
			return (
				<Box>
					{Object.entries(item.result).map(([key, value], index) => (
						<Typography key={index} sx={{ marginTop: '10px', fontSize: '14px' }}>
							{key}: {value}
						</Typography>
					))}
				</Box>
			)
		} else {
			return item?.result
		}
	}
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 16 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 500, fontSize: '2rem' }} >
          {data?.title}
        </Typography>
        <Box>
        <Box  sx={{ mb: 2 }}>
              <Typography variant="body2" sx={questionStyle}>
                
                {"Description"}
              </Typography>
              <Typography variant="body2" sx={{ }}>
              {data?.description}
              </Typography>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
            {data?.formResult
							.filter((items) => items?.type != 'panel')
							.map((item, index) => (
								<Grid item md={12} xs={12}>
									<Divider />
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
										}}
									>
										<Box
											sx={{
												marginRight: '20px',
												marginBottom: '12px',
												padding: '6px',
											}}
										>
											<Typography variant="h2" sx={{ fontSize: '16px' }}>
												Q{index + 1}
											</Typography>
										</Box>
										<Box sx={{ marginTop: '20px' }}>
											<Typography variant="h2" sx={{ fontSize: '14px' }}>
												{item?.title}
											</Typography>
											<Typography sx={{ marginTop: '10px', fontSize: '14px' }}>
												{Array.isArray(item?.result)
													? item?.result.map((val, i) =>
															item.type == 'checkbox' ? (
																<Grid item md={12} xs={12}>
																	<Divider />
																	<Box
																		sx={{
																			display: 'flex',
																			justifyContent: 'flex-start',
																			alignItems: 'center',
																		}}
																	>
																		<Box
																			sx={{
																				marginRight: '20px',
																				marginBottom: '12px',
																				padding: '6px',
																			}}
																		></Box>
																		<Box sx={{ marginTop: '20px' }}>
																			<Typography sx={{ marginTop: '10px', fontSize: '14px' }}>
																				{i + 1}. {val}
																			</Typography>
																		</Box>
																	</Box>
																</Grid>
															) : (
																Object.keys(val).map((key, indexes) => (
																	<Grid item md={12} xs={12}>
																		<Divider />
																		<Box
																			sx={{
																				display: 'flex',
																				justifyContent: 'flex-start',
																				alignItems: 'center',
																			}}
																		>
																			<Box
																				sx={{
																					marginRight: '20px',
																					marginBottom: '12px',
																					padding: '6px',
																				}}
																			>
																				<Typography variant="h2" sx={{ fontSize: '16px' }}>
																					Q{indexes + 1}
																				</Typography>
																			</Box>
																			<Box sx={{ marginTop: '20px' }}>
																				<Typography variant="h2" sx={{ fontSize: '14px' }}>
																					{key}
																				</Typography>
																				<Typography sx={{ marginTop: '10px', fontSize: '14px' }}>{val[key]}</Typography>
																			</Box>
																		</Box>
																	</Grid>
																))
															)
													  )
													: renderResult(item)}
											</Typography>
										</Box>
									</Box>
								</Grid>
							))}
        </Box>
      </Box>
    </Modal>
  );
};

export default SurveyModal;
