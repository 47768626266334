import React, { useState, useEffect } from 'react'
import { Grid, TextField, Box, Typography, Modal, MenuItem, Select, Stack, Autocomplete } from '@mui/material'
import { CircularLoader, SolidButton } from '../../../../Components'
import { useAddAssignJourneyMutation } from '../../../../store/assignJourneySlice'
import { useGetAllJourneysQuery } from '../../../../store/journeysSlice'
import { toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'
import OutlinedButton from '../../../../Components/OutlinedButton'
import { useIsMobile } from '../../../../contexts/isMobile'

export const JourneyAccordianModal = ({ open, setOpen, pid, fetchAgain, status }) => {
	const { user } = useSelector((state) => state.user)
	const isMobile = useIsMobile()

	const { isLoading, isSuccess, isError, error, data, refetch } = useGetAllJourneysQuery({ id: user.id, params: 'exclude=appointment,surgery' })
	const [addAssignJourney, { isLoading: isLoadingAssignJoourney }] = useAddAssignJourneyMutation()

	const [assign, setAssign] = useState('')
	const [selectedAssign, setSelectedAssign] = useState('')

	const resetValues = () => {
		setAssign('')
		setSelectedAssign('')
	}
	useEffect(() => {
		refetch()
	}, [])
	if (isLoading)
		return (
			<div>
				<CircularLoader />
			</div>
		)
	if (isError) return <div>some error</div>

	const handleChange = (event, newValue) => {
		const journeyId = data?.data?.find((item) => item?.title === newValue)
		setSelectedAssign(newValue)
		setAssign(journeyId?._id)
	}

	const handleAssign = () => {
		if (status === 'active') {
			if (selectedAssign === '' || assign === '') {
				toast.error('Please pick a journey from the dropdown menu first.')
				return
			}

			addAssignJourney({
				journeyId: assign,
				patientId: pid,
				hospital: user?.hospital || null,
				creator: user.id,
			})
				.unwrap()
				.then(() => {
					fetchAgain()
					resetValues()
					setOpen(false)
					toast.success('Successfully assigned!')
				})

				.catch((error) => {
					toast.error(error?.data?.error)
					console.log(error, 'error in addnewPatientSurvey')
					setOpen(true)
				})
		} else {
			toast.error('The patient status is currently inactive.')
			setOpen(false)
		}
	}

	const handleClose = () => {
		resetValues()
		setOpen(false)
	}

	return (
		<div>
			<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: isMobile ? '80%' : 700,
						height: isMobile ? 300 : 400,
						bgcolor: '#F5F5F5',
						p: 4,
						borderRadius: '10px',
					}}
				>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Journeys
						</Typography>
						<Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
							<CloseIcon />
						</Box>
					</Stack>
					<Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
						<Grid item md={6} xs={12}>
							<Typography fontSize={12} mt={2}>
								Assign Journey <span style={{ color: 'red' }}>*</span>
							</Typography>
							<Autocomplete
								value={selectedAssign}
								onChange={(event, newValue) => handleChange(event, newValue)}
								options={data?.data?.map((item) => item?.title)}
								renderInput={(params) => <TextField {...params} variant="standard" />}
							/>
						</Grid>
						{/* <Grid item md={6} xs={12}>
              <Typography fontSize={12} mt={2}>
                Journey Base
              </Typography>
              <Autocomplete
                value={selectPhysician}
                onChange={(event, newValue) => {
                  setSelectedPhysician(newValue);
                }}
                options={data.map((item)=>item.name)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid> */}
					</Grid>
					<Box
						sx={{
							display: 'flex',
							mt: isMobile ? '2rem' : '12rem',
							justifyContent: 'flex-end',
						}}
					>
						<OutlinedButton label="Cancel" handleClick={handleClose} />
						<SolidButton label="Assign" onClick={handleAssign} disabled={isLoadingAssignJoourney} />
					</Box>
				</Box>
			</Modal>
		</div>
	)
}
