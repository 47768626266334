import { useState } from 'react'
import { Box, Button, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import EnrollScreen from './EnrollScreen'
import PatientDetailScreen from './PatientDetailScreen'
import ThankyouScreen from './ThankyouScreen'
import PatientCheck from './PatientCheck'
import { useGetHospitalDetailForOnboardingQuery } from '../../store/hospitalsSlice'
import { useParams } from 'react-router-dom'

export function PatientOnboarding() {
	const { id } = useParams()
	const { data: hospitalDetails, error: hospitalDetailsError, isLoading: hospitalDetailsLoading } = useGetHospitalDetailForOnboardingQuery(id)
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
	const [isEnrolledClicked, setIsEnrolledClicked] = useState(false)
	const [activeStep, setActiveStep] = useState(null)
	const [patientCheck, setPatientCheck] = useState('')

	return (
		<Paper
			sx={{
				margin: 'auto',
				maxWidth: 500,
				flexGrow: 1,
				p: 2,
				height: '90vh',
				marginTop: '20px',
			}}
			elevation={0}
		>
			{!isEnrolledClicked && activeStep === null ? (
				<EnrollScreen
					isSmallScreen={isSmallScreen}
					setIsEnrolledClicked={setIsEnrolledClicked}
					setActiveStep={setActiveStep}
					hospitalDetails={hospitalDetails?.data}
					hospitalDetailsLoading={hospitalDetailsLoading}
				/>
			) : isEnrolledClicked && activeStep === 0 ? (
				<PatientCheck isSmallScreen={isSmallScreen} setActiveStep={setActiveStep} setPatientCheck={setPatientCheck} />
			) : activeStep === 1 ? (
				<PatientDetailScreen
					{...{ activeStep, setActiveStep, patientCheck, isSmallScreen }}
					termsAndConditions={hospitalDetails?.data?.tandcUrl}
					hospitalId={id}
					isPatientScreen={hospitalDetails?.data?.isPatientScreen}
					setIsEnrolledClicked={setIsEnrolledClicked}
				/>
			) : (
				<ThankyouScreen {...{ activeStep, setActiveStep, isSmallScreen, setIsEnrolledClicked, setPatientCheck }} hospitalDetails={hospitalDetails?.data} />
			)}
		</Paper>
	)
}
