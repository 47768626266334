import * as React from 'react'
import { Avatar, IconButton, Tooltip, Box, Typography, Stack } from '@mui/material'
import { ConfirmationModal } from '../../../Components/MessageLayout/ConfirmationModal'
import ReplayIcon from '@mui/icons-material/Replay'
import { LaunchRounded } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetPatientByIDQuery } from '../../../store/patientsSlice'
import { useResendConsentMutation } from '../../../store/chatSlice'
import { toast } from 'react-toastify'
const calculateAge = (dob, gender) => {
	const genderShortForm = gender === 'male' ? 'M' : gender === 'female' ? 'F' : 'N/A'
	if (dob) {
		// Check if the date is in ISO 8601 format
		let birthDate = dob.includes('T') ? new Date(dob) : new Date(`${dob}T00:00:00.000Z`)
		let today = new Date()
		let age_now = today.getFullYear() - birthDate.getFullYear()
		let m = today.getMonth() - birthDate.getMonth()
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age_now--
		}
		return `${age_now}Y, ${genderShortForm}`
	} else {
		return `${genderShortForm}`
	}
}
function formatPhoneNumber(phoneNumber) {
	// Remove any non-digit characters
	const cleaned = ('' + phoneNumber).replace(/\D/g, '')

	// Check if the input is valid (should be 11 digits with country code)
	if (cleaned.length === 11 && cleaned[0] === '1') {
		const country = cleaned[0] // Country code
		const area = cleaned.slice(1, 4) // Area code
		const local = cleaned.slice(4, 7) // First 3 digits
		const number = cleaned.slice(7, 11) // Last 4 digits

		return `+${country} (${area}) ${local}-${number}`
	}

	return phoneNumber // Return original if not valid
}
const getConsentColors = (consent) => {
	if (consent === 'yes') {
		return {
			marginRight: '5px',
			color: '#63A02C',
		}
	} else if (consent === 'no') {
		return {
			marginRight: '5px',
			color: '#DC362E',
		}
	} else {
		return {
			marginRight: '5px',
			color: '#FFB400',
		}
	}
}

const getSmsConsent = (data) => {
	return data?.consentResponse === 'NO_RESPONSE' ? 'No Response' : data?.consentResponse?.charAt(0).toUpperCase() + data?.consentResponse?.slice(1)
}

const getColorStyles = (acuity) => {
	if (acuity === 'high') {
		return {
			marginRight: '5px',
			color: '#DC362E',
		}
	} else if (acuity === 'medium') {
		return {
			marginRight: '5px',
			color: '#FFB400',
		}
	} else if (acuity === 'N/A') {
		return {
			marginRight: '5px',
			color: 'black',
		}
	} else {
		return {
			marginRight: '5px',
			color: '#63A02C',
		}
	}
}

export function DrawerHeaderV2({ patientDetails, acuity, careAllyData, value, status, refetchChat, id }) {
	const [openResend, setOpenResend] = React.useState(false)

	const location = useLocation()

	// Create a URLSearchParams object from the location search string
	const queryParams = new URLSearchParams(location.search)
	const [resendConsent] = useResendConsentMutation();
	const queryPatientId = queryParams?.get('patientId')

	const isQueryPatientId = !patientDetails && queryParams?.get('patientId') ? true : false

	const { data: patientData } = useGetPatientByIDQuery(queryPatientId)

	const handleResend = () => {
		setOpenResend(true)
	}
	const navigate = useNavigate()

	const goToProfile = (id, name) => {
		localStorage.setItem('pageName', JSON.stringify(name))
		navigate(`/profile/${id}`, { state: { action: 'read-only', id } })
	}

	const handleDataResend = async () => {
		if (status === 'active') {
			const response = await resendConsent(id)
			if (response?.data?.message) {
				toast.success('The SMS consent has been resent.')
				refetchChat()
				setOpenResend(false)
			} else {
				toast.error('Something went wrong!')
			}
		} else {
			toast.error('The patient status is currently inactive.')
		}
	}
	return (
		<Box
			sx={(theme) => ({
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-around',
				gap: 2,
				m: '0px 16px',
				mt: '10px',
				p: 1,
				bgcolor: 'rgba(0,151,236,0.12)',
				color: '#50607A',
				border: '1px solid rgba(0,151,236,0.12)',
				borderRadius: 3,
				fontSize: '10px',
				fontWeight: '600',
			})}
		>
			<Avatar
				sx={{ width: 40, height: 40 }}
				alt="Profile Image"
				//   src={
				//     path
				//       ? `${process.env.REACT_APP_BASE_API_URL}/${path}`
				//       : user?.picture
				//   }
			/>

			<Stack direction="column" spacing={0.5}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="body2">
						{' '}
						{careAllyData && value === 4
							? careAllyData?.firstName || careAllyData?.lastName
								? `${careAllyData?.firstName} ${careAllyData?.lastName}`
								: 'N/A'
							: isQueryPatientId
							? `${patientData?.firstName} ${patientData?.lastName}`
							: `${patientDetails?.firstName} ${patientDetails?.lastName}`}
					</Typography>
					<Tooltip title="Go to Profile">
						<IconButton
							sx={{ ml: '3px' }}
							size="small"
							onClick={() => {
								goToProfile(isQueryPatientId ? patientData?._id : patientDetails?._id, 'patient')
							}}
						>
							<LaunchRounded fontSize="small" />
						</IconButton>
					</Tooltip>
				</Box>
				<Typography variant="caption">
					{' '}
					{careAllyData && value === 4
						? careAllyData?.relationship || 'N/A'
						: isQueryPatientId
						? calculateAge(patientData?.birthday, patientData?.gender)
						: calculateAge(patientDetails?.birthday, patientDetails?.gender)}
				</Typography>
			</Stack>

			<Stack direction="column" justifyContent="center" spacing={0.5}>
				<Typography
					variant="body2"
					sx={{
						marginTop: acuity && '15px !important',
					}}
				>
					Acuity
				</Typography>
				{acuity ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							marginTop: '-10px !important',
						}}
					>
						<span
							style={{
								...getColorStyles(acuity),
								fontSize: '30px',
							}}
						>
							•
						</span>
						<Typography variant="caption">{acuity?.charAt(0).toUpperCase() + acuity?.slice(1)}</Typography>
					</Box>
				) : (
					<Typography variant="caption">N/A</Typography>
				)}
			</Stack>

			<Stack direction="column" spacing={0.5}>
				<Typography variant="body2">Phone</Typography>
				<Typography variant="caption">
					{careAllyData && value === 4
						? formatPhoneNumber(careAllyData?.phoneNumber)
						: isQueryPatientId
						? formatPhoneNumber(patientData?.phone)
						: formatPhoneNumber(patientDetails?.phone)}
				</Typography>
			</Stack>

			<Stack direction="column" spacing={0.5}>
				<Typography variant="body2">Email</Typography>
				<Typography variant="caption">{patientDetails?.email && value != 4 ? patientDetails.email : isQueryPatientId ? patientData?.email : 'N/A'}</Typography>
			</Stack>

			<Stack direction="column" justifyContent="center" spacing={0.5}>
				<Typography variant="body2" sx={{ marginTop: '15px !important' }}>
					SMS Consent
					{value !== 4 && (patientDetails?.consentResponse !== 'yes' || (isQueryPatientId && patientData?.consentResponse !== 'yes')) && (
						<Tooltip title="Resend SMS Consent">
							<IconButton onClick={handleResend}>
								<ReplayIcon sx={{ width: '20px', height: '20px' }} />
							</IconButton>
						</Tooltip>
					)}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						marginTop: '-10px !important',
					}}
				>
					<span
						style={{
							...(careAllyData && value === 4
								? getConsentColors(careAllyData?.consentResponse)
								: isQueryPatientId
								? getConsentColors(patientData?.consentResponse)
								: getConsentColors(patientDetails?.consentResponse)),
							fontSize: '30px',
						}}
					>
						•
					</span>
					<Typography variant="caption" color="#50607A">
						{careAllyData && value === 4 ? getSmsConsent(careAllyData) : isQueryPatientId ? getSmsConsent(patientData) : getSmsConsent(patientDetails)}
					</Typography>
				</Box>
			</Stack>

			<ConfirmationModal open={openResend} setOpen={setOpenResend} txt="Are you sure you want to resend the SMS consent?" handle={handleDataResend} />
		</Box>
	)
}
