import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientAppointments: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10, patientId, ...rest } = query;
        const finalQuery = { page, limit, ...rest };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/appointments/patient/${patientId}?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Appointments", id: "LIST" },
          ...data.map((p) => ({ type: "Appointments", id: p._id })),
        ];
      },
    }),
    getAppointmentByID: builder.query({
      query: (id) => `/appointments/${id}`,
      providesTags: ({ data, ...rest }, error, arg) => {
        return [
          { type: "Appointments", id: "LIST" },
          { type: "Appointments", id: data._id || arg },
        ];
      },
    }),
    addNewAppointment: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/appointments",
          method: "POST",
          body: initialPost
        };
      },
      invalidatesTags: [{ type: "Appointment", id: "LIST" }],
    }),
    editAppointment: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/appointments/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ({ data }, error, { id }) => {
        return [
          { type: "Appointments", id: "LIST" },
          { type: "Appointments", id: data.id || id },
        ];
      },
    }),
    deleteAppointment: builder.mutation({
      query: (id) => {
        return {
          url: `/appointments/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Appointments", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPatientAppointmentsQuery,
  useGetAppointmentByIDQuery,
  useAddNewAppointmentMutation,
  useEditAppointmentMutation,
  useDeleteAppointmentMutation,
} = extendedApiSlice;
