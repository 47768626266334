import React, { useState, useEffect } from "react";
import {
  Drawer,
  Grid,
  Stack,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  ListItemText,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CircularLoader, SolidButton } from "./../../../Components";
import { useGetAllUserQuery } from "../../../store/userManagmentSlice";
import { toast } from "react-toastify";
import { ROLES } from "../../../constants/routes";
import {
  useEditTeamMutation,
  useGetTeamByIDQuery,
} from "../../../store/teamsSlice";

export function MembersSelect({
  selectedTags,
  setSelectedTags,
  selectName,
  setSelectedName,
}) {
  const { data: users, isLoading, isError } = useGetAllUserQuery();

  const data = users?.data
    ?.filter((user) => user.role === ROLES.CareTeamMember)
    ?.map((CareTeamMember) => ({
      _id: CareTeamMember._id,
      tag: CareTeamMember.name,
      name: CareTeamMember.name,
    }));

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  
    // Splitting the stringified value into an array if it's a string
    const selectedIds = typeof value === "string" ? value.split(",") : value;
  
    // Finding the corresponding name for each id and creating an object with id and name
    const selectedNames = selectedIds.map((id) => {
      const tag = data?.find((tag) => tag._id === id);
      const existingMember = selectName.find((member) => member.id === id);
  
      return {
        id,
        name: tag?.tag || "",
        role: existingMember ? existingMember.role : "",
      };
    });
  
    setSelectedTags(selectedIds);
    setSelectedName(selectedNames);
  };

  const selectedTagsText = (selected) => {
    if (!data || data.length === 0) return [];
    return selected?.map((tagID) => {
      const selectedTag = data.find((tag) => tag._id === tagID);
      return selectedTag?.tag || '';
    }) || [];
  }


  if (isLoading || isError) return null;
  return (
    
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label" sx={{ mt: "20px" }}>
        Care Team Members
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        required
        id="demo-multiple-checkbox"
        multiple
        value={selectedTags || []}
        onChange={handleChange}
        input={
          <OutlinedInput
            sx={{ height: "100%", mt: 2, padding: "3px" }}
            label="Care Team Members"
          />
        }
        renderValue={(selected) => selectedTagsText(selected).join(", ")}
        MenuProps={MenuProps}
      >
        {data?.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            <Checkbox checked={selectedTags.indexOf(item._id) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const Content = ({ data, teamID,memberIDs, toggleDrawer }) => {
  const [selectedMembers, setSelectedMembers] = useState(memberIDs || []);
  const [selectedMembersName, setSelectedMembersName] = useState(data?.members || []);

  const [team, setTeam] = useState({
    name: data?.name,
    description: data?.description,
  });

  const [editTeam, {isLoading}] = useEditTeamMutation();

  const onChange = (e) => {
    setTeam((team) => ({ ...team, [e.target.name]: e.target.value }));
  };

  const editTeamChange = (e) => {
    e.preventDefault();
    editTeam({
      id:teamID,
      body: {
        ...team,
        members: selectedMembersName,
      },
    })
      .unwrap()
      .then(() => {
        toggleDrawer(false);
        toast.success("Successfully edited!");
      })
      .catch((error) => {
        console.log("ON EDIT==...>>", error);
        toast.error("some error occured!");
      });
  };

  function handleRoleChange(id, e) {
    const updatedMembers = selectedMembersName.map((member) => {
      if (member.id === id) {
        return { ...member, role: e.target.value };
      }
      return member;
    });
    setSelectedMembersName(updatedMembers);
  }

  return (
    <Box sx={{ padding: "2rem" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "3rem" }}
      >
        <Typography variant="p" sx={{ fontSize: 30, fontWeight: 400 }}>
          Edit Team
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => toggleDrawer(false)}>
          <CloseIcon />
        </Box>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextField
              required
              id="outlined-required"
              label="Title"
              placeholder="Enter Team Name"
              name="name"
              value={team.name}
              onChange={onChange}
              sx={{ padding: "3px" }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              required
              multiline
              rows={4}
              id="outlined-required"
              label="Description"
              placeholder="Enter Team Description"
              name="description"
              value={team.description}
              onChange={onChange}
              sx={{ padding: "3px" }}
            />
          </Grid>
          <Box sx={{ width: "97%", ml: 2.2 }}>
            <MembersSelect
              selectedTags={selectedMembers}
              setSelectedTags={setSelectedMembers}
              selectName={selectedMembersName}
              setSelectedName={setSelectedMembersName}
            />

            {selectedMembers.length > 0 &&
              selectedMembersName.map((item) => (
                <TextField
                  required
                  id="outlined-required"
                  label={item.name}
                  placeholder={`Enter ${item.name} Role`}
                  name={item.name}
                  value={item.role}
                  onChange={(e) => handleRoleChange(item.id, e)}
                  sx={{ padding: "3px", mt: 2 }}
                />
              ))}
          </Box>
        </Grid>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          sx={{ marginTop: "3rem" }}
        >
          <SolidButton label="Edit" onClick={editTeamChange} disabled={isLoading} />
        </Stack>
      </Box>
    </Box>
  );
};

export function EditTeamDrawer({ state, toggleDrawer }) {
  
    const { isLoading, isSuccess, isError, error, data } = useGetTeamByIDQuery(state.teamID);

    // Using useState to manage the array of IDs
    const [memberIDs, setMemberIDs] = useState([]);

    useEffect(() => {
      if(data?.data?.members){
      // Extracting IDs from data?.data?.members array
      const extractedIDs = data?.data?.members?.map(member => member.id) || [];
      setMemberIDs(extractedIDs)
      }
    }, [data,state])
    

  if (isLoading) return <CircularLoader />;
  if (isError) return <div>{error}</div>;
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        key={state.teamID}
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "40%",
          },
        }}
        anchor="right"
        open={state.edit}
        onClose={toggleDrawer(false)}
      >
        <Content
          key={state.teamID}
          data={data?.data || []}
          memberIDs={memberIDs}
          teamID={state.teamID}
          toggleDrawer={toggleDrawer(false)}
        />
      </Drawer>
    </React.Fragment>
  );
}
