import React, { useState } from "react";
import { TextField, Box, Typography, Modal, Stack } from "@mui/material";
import { SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useAddNewTagMutation } from "../../../store/tagsSlice";
import { getCurrentSiteId } from "../../../utils";
import { toast } from "react-toastify";
import { ColorPicker } from "material-ui-color";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux"

export const CreateTagModal = ({ open, setOpen, title }) => {
  const {user} = useSelector(state => state.user)
  const [tag, setTag] = useState("");
  const [color, setColor] = useState("transparent");
  const isMobile = useIsMobile();
  const handleClose = () => {
    setOpen(false);
    setTag(""); // Clear the tag input field
    setColor("transparent"); // Reset the color
  };
  const [addNewTag, { isSuccess, isLoading: isNewTagLoading }] = useAddNewTagMutation();

  console.log(user)

  const onSave = async () => {
    await addNewTag({ tag, color, creator: user.id })
      .unwrap()
      .then((res) => {
        toast.success("Successfully created!");
        handleClose()
      })
      .catch((e) => {
        toast.error(e?.data?.error || e?.message || "some error occured!");
      });
  };

  // if (isSuccess) {
  //   setOpen(false);
  // }

  const handleColorChange = (newColor) => {
    setColor(newColor);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 400,
    bgcolor: "#F5F5F5",
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </Box>
          </Stack>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              required
              id="outlined-required"
              label="Name"
              name="name"
              placeholder="Enter Tag Name"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
              }}
              sx={{ width: "100%", mt: 2 }}
            />
            <Box sx={{ width: "100%", mt: 2 }}>
              <ColorPicker value={color} onChange={handleColorChange} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Box sx={{ ml: "auto" }}>
                <SolidButton label="Save" onClick={onSave} disabled={isNewTagLoading} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
