import React, { createContext, useContext, useState } from "react";

export const HoverContext = createContext();

export const HoveredProvider = ({ children }) => {
  const [hovered, setHovered] = useState(false);


  return (
    <HoverContext.Provider value={{ hovered, setHovered }}>
      {children}
    </HoverContext.Provider>
  );
};

export function useIsHovered() {
  return useContext(HoverContext);
}