import React, { useCallback, useState } from 'react';
import { useReactFlow, getOutgoers } from 'reactflow';
import { uuid, randomLabel } from '../utils';

export function useNodeClick(id,name,delay,method,metric,childNodeId, positiveChildNodeId=null,
  negativeChildNodeId=null, checkCondition=null, data=null, selectForm=null) {

  let extra='';
  let pages=[];
  let surveyValues={};
  let conditionOperator=''
  let form={},task={};
  let journeyId = '';
  let journeyName = '';
  if(data?.method === 'form') {
    extra= data.form.form || ''
    pages=typeof selectForm.pages !== 'undefined' ? selectForm.pages : [] || []
    surveyValues=data.form.surveyValues || {}
    conditionOperator=data.form.conditionOperator || ''
    form=data.form || {}
  }
  if(data?.method === 'task') {
    extra= data.task.task || ''
    task=data.task || ''
  }
  if(data?.method === "assign_journey") {
    journeyId = data.assign_journey.journeyId || '';
    journeyName = data.assign_journey.journeyName || ''
  }
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();

  const onClick = useCallback(() => {
    const parentNode = getNode(id);
    if (!parentNode) {
      return;
    }

    try{
    const childPlaceholderId = uuid();

    const childNode = {
      id: childNodeId,
      position: { x: parentNode.position.x, y: parentNode.position.y + 150 },
      type: 'workflow',
      data: {task:task, form:form, extra:extra, pages:pages, label: name,metric:method,method:metric,value:delay, parentNodeId:id, positiveChildNodeId, negativeChildNodeId, checkCondition, surveyValues:surveyValues, conditionOperator: conditionOperator, journeyId, journeyName},
    };

    let childEdge;
    let childEdgeOne = [{
      id: `${parentNode.id}=>${childNodeId}`,
      source: parentNode.id,
      target: childNodeId,
      type: 'workflow',
    }];
    let childEdges = []
    if(positiveChildNodeId !==null && negativeChildNodeId!==null) {
      childEdges.push({
        id: `${childNodeId}=>${positiveChildNodeId}`,
        source: childNodeId,
        target: positiveChildNodeId,
        type: 'workflow',
      });
      childEdges.push({
        id: `${childNodeId}=>${negativeChildNodeId}`,
        source: childNodeId,
        target: negativeChildNodeId,
        type: 'workflow',
      });
    }
    if(childEdges.length > 0 && checkCondition) {
      childEdge = childEdgeOne.concat(childEdges)
    } else {
      childEdge = childEdgeOne
    }

    console.log("childNode", childNode)
    const existingPlaceholders = getOutgoers(parentNode, getNodes(), getEdges())
      .filter((node) => node.type === 'placeholder')
      .map((node) => node.id);
    setNodes((nodes) =>
      nodes.filter((node) => !existingPlaceholders.includes(node.id)).concat([childNode])
    );

    setEdges((edges) =>
      edges.filter((edge) => !existingPlaceholders.includes(edge.target)).concat(childEdge)
    );

  }
  catch (error) {
    // Handle the error here (e.g., log the error or display a toast notification)
    console.error('An error occurred while creating the child node:', error);
  }
  }, [getEdges, getNode, data,getNodes, id,name,method,metric, setEdges, setNodes]);

  return onClick;
}

export default useNodeClick;
