import React, { useEffect, useState } from 'react'
import {
	Box,
	Drawer,
	Stack,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Grid,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	Tooltip,
	Collapse,
	Paper,
	Chip,
} from '@mui/material'
import { useGetOnePatientDetailQuery } from '../../store/patientsSlice'
import { ProsColumns } from '../Patient/PatientTable/DrawerComponents/DataTitlesUtils'
import { useIsMobile } from '../../contexts/isMobile'
import { toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Timeline from '../../Components/HorizontalTimeline'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import MapsUgcIcon from '@mui/icons-material/MapsUgc'
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import TimelineIcon from '@mui/icons-material/Timeline'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ProsDrawerHeader } from './ProsDrawerHeader'
import { SmsNotificationModal } from './SmsNotificationModal'
import { PaginationBar } from '../../Components'

const prosDummyData = [
	{ name: 'HOOS', date: '2024-04-02T21:00:39.948Z', status: 'Completed' },
	{ name: 'HSRN', date: '2024-04-02T21:00:39.948Z', status: 'Pending' },
]

const formData = [
	{ name: 'question1', result: 'Yes', title: 'question1', type: 'boolean' },
	{
		name: 'question2',
		result: 'No',
		title: 'Do you have housing?',
		type: 'boolean',
	},
	{
		name: 'question3',
		result: 'Yes',
		title: 'Are you worried about losing your housing? ',
		type: 'boolean',
	},
]

const events = [
	{
		date: '2024-01-01',
		label: 'Form Assigned',
		description: 'Initial form assigned to the user.',
	},
	{
		date: '2024-02-15',
		label: 'SMS Reminder',
		description: 'Reminder sent via SMS to complete the form.',
	},
	{
		date: '2024-03-30',
		label: 'Call Reminder',
		description: 'Reminder call made to ensure form completion.',
	},
	{
		date: '2024-04-10',
		label: 'Form Opened',
		description: 'User opened the form for the first time.',
	},
	{
		date: '2024-04-12',
		label: 'Form Completed',
		description: 'User completed and submitted the form.',
	},
	{
		date: '2024-04-14',
		label: 'Due Date',
		description: 'Final due date for form submission.',
	},
]

const DrawerTable = ({ data, title, tableType, columns, patientName }) => {
	const [openRows, setOpenRows] = useState([])
	const [rows, setRows] = useState([])
	const [open, setOpen] = useState(false)
	const [surveyId, setSurveyId] = useState(null)
	const [page, setPage] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const textColor = { color: '#979797' }

	const handleModal = (id) => {
		setSurveyId(id)
		setOpen(true)
	}

	const handleRowToggle = (index) => {
		const newOpenRows = [...rows]
		newOpenRows[index] = !newOpenRows[index]
		setRows(newOpenRows)
	}
	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const createRows = (items, open, setOpen, handleModal, surveyId, setSurveyId) => {
		const getStatus = (status) => {
			if (status === 'complete') {
				return <Chip label="Completed" size="small" color="success" />
			} else if (status === 'pending') {
				return <Chip label={status?.charAt(0)?.toUpperCase() + status?.slice(1)} size="small" color="warning" />
			} else if (status === 'opened') {
				return <Chip label={status?.charAt(0)?.toUpperCase() + status?.slice(1)} size="small" color="info" />
			} else {
				return 'N/A'
			}
		}
		return items.map((item, index) => ({
			name: (
				<>
					<Typography
						variant="p"
						sx={{
							fontSize: 16,
							fontWeight: 400,
							textAlign: 'left',
						}}
					>
						{item?.title ? item?.title : 'N/A'}
					</Typography>
				</>
			),
			date: (
				<>
					<Typography
						variant="p"
						sx={{
							fontSize: 16,
							fontWeight: 400,
							textAlign: 'left',
						}}
					>
						{moment(item?.createdAt).format('MM-DD-YYYY')}
					</Typography>
				</>
			),
			dueDate: (
				<>
					<Typography
						variant="p"
						sx={{
							fontSize: 16,
							fontWeight: 400,
							textAlign: 'left',
						}}
					>
						{item?.dueDate ? moment(item?.dueDate).format('MM-DD-YYYY') : 'N/A'}
					</Typography>
				</>
			),
			status: getStatus(item?.status),
			collapsibleIcon: (
				<>
					<IconButton aria-label="expand row" size="small" onClick={() => handleRowToggle(index)}>
						{rows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</>
			),
			collapsibleBody: (
				<>
					<Collapse in={rows[index]} timeout="auto" unmountOnExit style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<Paper
							elevation={0}
							sx={{
								padding: '1.5rem',
								my: '1em',
								borderRadius: '15px',
							}}
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									{item?.status === 'complete' && (
										<>
											{item?.score && (
												<div style={{marginBottom:'10px'}}>
													<Typography component="span" variant="body1" style={{ fontWeight: 'bold' }}>
														Score:
													</Typography>
													<Typography component="span" variant="body1">
														{' '}
														{item?.score ? item?.score : 'N/A'}
													</Typography>
												</div>
											)}
											<FormAccordion data={item?.formResult} score={item?.score} />
										</>
									)}

									<TimelineAccordion events={item?.timeLine} />
								</Grid>
							</Grid>
						</Paper>
					</Collapse>
				</>
			),
			action: (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{item?.status === 'complete' && (
						<>
							<IconButton
								sx={{
									'&:hover .css-tksnbz-MuiSvgIcon-root': {
										visibility: 'visible',
									},
									padding: '0px',
									mr: 1.5,
								}}
								onClick={() => {
									handleRowToggle(index)
								}}
							>
								<Tooltip title="View Survey" placement="top">
									<VisibilityOutlinedIcon sx={{ color: '#979797' }} />
								</Tooltip>
							</IconButton>

							<IconButton
								sx={{
									'&:hover .css-tksnbz-MuiSvgIcon-root': {
										visibility: 'visible',
									},
									padding: '0px',
									mr: 1.5,
								}}
								onClick={(e) => {
									toast.info('Feature coming soon! Stay tuned for updates.')
								}}
							>
								<Tooltip title="Generate Note" placement="top">
									<AutoFixHighIcon sx={{ color: '#979797' }} />
								</Tooltip>
							</IconButton>
						</>
					)}

					{(item?.status === 'pending' || item?.status === 'opened') && (
						<>
							<IconButton
								sx={{
									'&:hover .css-tksnbz-MuiSvgIcon-root': {
										visibility: 'visible',
									},
									padding: '0px',
									ml: 1,
								}}
								onClick={() => {
									handleModal(item?._id)
								}}
							>
								<Tooltip title="Text To Remind" placement="top">
									<MapsUgcIcon sx={textColor} />
								</Tooltip>
							</IconButton>
							<IconButton
								sx={{
									'&:hover .css-tksnbz-MuiSvgIcon-root': {
										visibility: 'visible',
									},
									// padding: "0px",
								}}
								onClick={() => {
									toast.info('Feature coming soon! Stay tuned for updates.')
								}}
							>
								<Tooltip title="Call To Remind" placement="top">
									<PhoneForwardedIcon sx={textColor} />
								</Tooltip>
							</IconButton>
						</>
					)}
					<SmsNotificationModal {...{ open, setOpen, patientName, surveyId, setSurveyId }} />
				</div>
			),
		}))
	}

	useEffect(() => {
		if (data) {
			const initialOpenState = new Array(data.length).fill(false)
			setOpenRows(initialOpenState)
		}
	}, [data])
	const paginatedData = data?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
	return (
		<>
			<Typography fontSize={'18px'} fontWeight={'bold'} mb={2}>
				{title}
			</Typography>
			{data && data.length > 0 ? (
				<>
					<Box
						sx={{
							border: '1px solid #D6D6E1',
							width: '100%',
							borderRadius: '10px',
							mb: tableType === 'surgeon' ? '1rem' : '3rem',
						}}
					>
						<TableContainer sx={{ borderRadius: '10px' }} className="DialogContent">
							<Table>
								<TableHead style={{ backgroundColor: 'rgba(0, 151, 236, 0.12)' }}>
									<TableRow>
										{columns?.map((column) => (
											<TableCell
												variant="head"
												key={column.id}
												align="left"
												sx={{
													fontWeight: 600,
													fontSize: '14px',
													color: '#50607A',
													paddingY: '5px',
												}}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{createRows(paginatedData, open, setOpen, handleModal, surveyId, setSurveyId).map((row, i) => (
										<>
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={i}
												sx={{
													background: row.status === 'resolved' || openRows[i] ? '#0000000a' : '',
												}}
											>
												{columns.map((column) => {
													const value = row[column.id]
													return (
														<TableCell
															key={column.id}
															align="left"
															sx={{
																color: 'text.secondary',
															}}
														>
															{value}
														</TableCell>
													)
												})}
											</TableRow>
											<TableRow>
												<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
													{row.collapsibleBody}
												</TableCell>
											</TableRow>
										</>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<PaginationBar page={page} count={Math.ceil(data.length / rowsPerPage)} handleChange={handleChangePage} />
				</>
			) : (
				<Typography sx={{ fontSize: '16px', fontWeight: 600, textAlign: 'center', my: 6 }}>No Data Available</Typography>
			)}
		</>
	)
}

const FormAccordion = ({ data, score }) => {
	const renderResult = (item) => {
		if (item?.type === 'signaturepad' && item?.result) {
			return <img src={item.result} alt="Signature" />
		} else if (item?.type === 'multipletext' && typeof item?.result === 'object') {
			return (
				<Box>
					{Object.entries(item.result).map(([key, value], index) => (
						<Typography key={index} sx={{ marginTop: '10px', fontSize: '14px' }}>
							{key}: {value}
						</Typography>
					))}
				</Box>
			)
		} else {
			return item?.result
		}
	}

	const [changeText, setChangeText] = useState(false)

	return (
		<Accordion sx={{ mb: 3, background: 'aliceblue' }}>
			<AccordionSummary
				sx={{
					typography: 'h6',
					fontWeight: 'bold',
					fontSize: '16px',
				}}
				aria-controls="panel1-content"
				id="panel1-header"
				onClick={() => setChangeText((prev) => !prev)}
			>
				<Stack
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
						alignItems: 'center',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<InsertDriveFileIcon />
						<p style={{ marginLeft: '10px' }}>
							Survey Results
							{score && <span style={{ marginLeft: '5px', fontSize: '13px', fontWeight: '600' }}>(Score : {score})</span>}
						</p>
					</Box>
					<Typography
						sx={{
							padding: '5px',
							fontSize: '14px',
							fontWeight: 500,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '12%',
							alignItems: 'center',
						}}
					>
						{changeText ? 'Reduce' : 'Expand'} {changeText ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</Typography>
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				{data?.map((item, index) => (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
					>
						<Box
							sx={{
								marginRight: '20px',
								marginBottom: '12px',
								padding: '6px',
							}}
						>
							<Typography variant="h2" sx={{ fontSize: '16px' }}>
								Q{index + 1}
							</Typography>
						</Box>
						<Box sx={{ marginTop: '20px' }}>
							<Typography variant="h2" sx={{ fontSize: '14px' }}>
								{item?.title}
							</Typography>
							<Typography sx={{ marginTop: '10px', fontSize: '14px' }}>
								{Array.isArray(item?.result)
									? item?.result.map((val, i) =>
											item.type == 'checkbox' ? (
												<Grid item md={12} xs={12}>
													<Divider />
													<Box
														sx={{
															display: 'flex',
															justifyContent: 'flex-start',
															alignItems: 'center',
														}}
													>
														<Box
															sx={{
																marginRight: '20px',
																marginBottom: '12px',
																padding: '6px',
															}}
														></Box>
														<Box sx={{ marginTop: '20px' }}>
															<Typography sx={{ marginTop: '10px', fontSize: '14px' }}>
																{i + 1}. {val}
															</Typography>
														</Box>
													</Box>
												</Grid>
											) : (
												Object.keys(val).map((key, indexes) => (
													<Grid item md={12} xs={12}>
														<Divider />
														<Box
															sx={{
																display: 'flex',
																justifyContent: 'flex-start',
																alignItems: 'center',
															}}
														>
															<Box
																sx={{
																	marginRight: '20px',
																	marginBottom: '12px',
																	padding: '6px',
																}}
															>
																<Typography variant="h2" sx={{ fontSize: '16px' }}>
																	Q{indexes + 1}
																</Typography>
															</Box>
															<Box sx={{ marginTop: '20px' }}>
																<Typography variant="h2" sx={{ fontSize: '14px' }}>
																	{key}
																</Typography>
																<Typography sx={{ marginTop: '10px', fontSize: '14px' }}>{val[key]}</Typography>
															</Box>
														</Box>
													</Grid>
												))
											)
									  )
									: renderResult(item)}
							</Typography>
						</Box>
					</Box>
				))}
			</AccordionDetails>
		</Accordion>
	)
}

const TimelineAccordion = ({ events }) => {
	const [changeText, setChangeText] = useState(false)

	return (
		<Accordion sx={{ mb: 3, background: 'aliceblue' }}>
			<AccordionSummary
				sx={{
					typography: 'h6',
					fontWeight: 'bold',
					fontSize: '16px',
				}}
				aria-controls="panel1-content"
				id="panel1-header"
				onClick={() => setChangeText((prev) => !prev)}
			>
				<Stack
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
						alignItems: 'center',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<TimelineIcon />
						<p style={{ marginLeft: '10px' }}>Timeline</p>
					</Box>
					<Typography
						sx={{
							padding: '5px',
							fontSize: '14px',
							fontWeight: 500,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '12%',
							alignItems: 'center',
						}}
					>
						{changeText ? 'Reduce' : 'Expand'} {changeText ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</Typography>
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				{!events || events?.length === 0 ? (
					<Typography
						sx={{
							fontSize: '14px',
							fontWeight: 'bold',
							paddingY: '10px',
						}}
					>
						No Data Found
					</Typography>
				) : (
					<Timeline events={events} />
				)}
			</AccordionDetails>
		</Accordion>
	)
}

const Content = ({ toggleDrawer, data, prosData }) => {
	const isMobile = useIsMobile()
	return (
		<>
			<Box
				sx={{
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
					zIndex: 990,
				}}
			>
				<ProsDrawerHeader handleClose={toggleDrawer} data={data} />
			</Box>
			<Box sx={{ p: 2, mt: 1 }}>
				<DrawerTable
					data={data?.assignedSurveys}
					title="Patient Reported Outcomes"
					tableType={'pros'}
					columns={ProsColumns}
					patientName={`${data?.firstName} ${data?.lastName}`}
				/>
			</Box>
		</>
	)
}

export default function ProsDrawer({ open, toggleDrawer, id, prosData }) {
	const isMobile = useIsMobile()

	return (
		<React.Fragment key={'anchor'}>
			<Drawer
				sx={{
					'& .MuiBackdrop-root': {
						backgroundColor: 'rgba(0, 0, 0, 0.1)',
					},
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						overflowX: 'hidden',
						width: isMobile ? '80%' : '60%',
						boxShadow: 'none',
					},
				}}
				className="DialogContent"
				anchor="right"
				open={open}
				onClose={toggleDrawer(false)}
			>
				<Content toggleDrawer={toggleDrawer(false)} data={prosData} />
			</Drawer>
		</React.Fragment>
	)
}
