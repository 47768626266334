import React, { useCallback, useMemo, useState } from "react";
import { compareAsc, format } from 'date-fns'
import {
  Stack,
  Grid,
  Badge,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Chip
} from "@mui/material";
import { DeleteSvg, EditSvg } from "../../../assets/svgs";
import { useDeleteSurveyMutation, usePublishSurveyMutation } from "../../../store/suerveySlice";
import { useNavigate } from "react-router-dom";
import { ActionButtons, CircularLoader, DeleteModal, PaginationBar } from "../../../Components";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";

const columns = [
  { id: "Srno", label: "Index", minWidth: 170 },
  {
    id: "title",
    label: "Title",
    minWidth: 170,
    align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "right",
  },
  {
    id: "createdOn",
    label: "Created On",
    minWidth: 170,
    align: "right",
  },
  {
    id: "createdBy",
    label: "Created By",
    minWidth: 170,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "right",
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 170,
    align: "right",
  },
];

const tags = ["3anything"].map((tag, i) => (
  <Badge
    key={i}
    badgeContent={tag}
    sx={{
      "& .MuiBadge-badge": {
        fontSize: "10px",
        color: "#FFFFFF",
        backgroundColor: "#BCB7B7",
        border: "1px solid #979797",
        padding: "10px 5px",
        borderRadius: " 5px",
        marginLeft: "2rem",
      },
    }}
  ></Badge>
));

const createRows = (data, deletePost, redirectToEditPage, isLoadingPublishForm, handlePublishForm, openConfirmationModal, setOpenConfirmationModal) => {
  const rows = data.map(({ _id: id, createdBy,createdAt,form, platform,status, editable }, index) => {
    const { title, description } = form;

    const getStatus = (status) => {
      if(status === "published"){
        return <Chip label={status?.charAt(0)?.toUpperCase() + status?.slice(1)} size="small" color="success" />
      }else if(status === "draft"){
        return <Chip label={status?.charAt(0)?.toUpperCase() + status?.slice(1)} size="small" color="warning" />
      }else{
        return 'N/A'
      }
    }

    return {
      Srno: (
        <Typography
          variant="p"
          sx={{ fontSize: 16, fontWeight: 400, color: "text.secondary" }}
        >
          {index + 1}
        </Typography>
      ),
      title,
      description:description?.slice(0,40) + "...",
      createdOn: format(new Date(createdAt),'MM-dd-yyyy'),
      createdBy: createdBy,
      tags: (
        <Stack flexWrap="wrap" direction="row" justifyContent="center">
          {tags}
        </Stack>
      ),
      status: getStatus(status),
      actions: (
        // <Stack spacing={3} direction="row" justifyContent="center">
        //   <div
        //     onClick={() => redirectToEditPage(id)}
        //     style={{ cursor: "pointer" }}
        //   >
        //     <EditSvg />
        //   </div>
        //   <div onClick={() => deletePost(id)} style={{ cursor: "pointer" }}>
        //     <DeleteSvg />
        //   </div>
        // </Stack>

        editable ?
          <ActionButtons id={id} handleEdit={redirectToEditPage} handleDelete={deletePost} status={status} isStatus={true} isLoadingPublishForm={isLoadingPublishForm} handlePublishForm={handlePublishForm} openConfirmationModal={openConfirmationModal} setOpenConfirmationModal={setOpenConfirmationModal} /> :
          <div>Not Editable</div>
      ),
    };
  });

  return rows;
};

export function FormTable({ page, setPage, data,fetchAgain,isLoading,isSuccess,error, isError }) {
  const [deletePost] = useDeleteSurveyMutation();
  const [publishSurvey, {isLoading: isLoadingPublishForm}] = usePublishSurveyMutation()
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState({id:null, open:false});

  const handleChangePage = (event, newPage) => {
    localStorage.setItem('page', newPage)
    setPage(newPage);
  };

  const onDelete = async (id) => {
    await deletePost(id).unwrap()
    .then(() => {
      fetchAgain();
    })
  };

  const handlePublishForm = async (id) => {
    publishSurvey(id).unwrap()
      .then(() => {
        fetchAgain();
        toast.success("Form Status Updated Successfully!");
        setOpenConfirmationModal({id:null, open:false})
      })
      .catch((error) => {
        toast.error("some error occured!");
        console.error(error);
        setOpenConfirmationModal({id:null, open:false})
      });
  }

  const redirectToEditPage = (e, id) => {
    navigate(`/forms/edit/${id}`, { state: { action: "edit", id } });
  };

  const rows = createRows(data?.data || [], onDelete, redirectToEditPage, isLoadingPublishForm, handlePublishForm, openConfirmationModal, setOpenConfirmationModal);

  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );

  if (isError || error) return <div>{isError ? error : "Something went wrong!"}</div>
  if (data?.data.length === 0) {
    return <Typography sx={{margin:"10px 20px"}}>No Form found.</Typography>;
  }
  return (
    <>
    <Paper
        sx={{
          border: "1px",
          width: "100%",
          borderRadius: "10px",
        }}
      >
      <TableContainer sx={{ borderRadius: "4px" }}>
        <Table >
          <TableHead style={{ backgroundColor: "rgba(0, 151, 236, 0.12)" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  variant="head"
                  key={column.id}
                  align="left"
											sx={{
												fontWeight: 600,
												fontSize: '14px',
												color: '#50607A',
												paddingY: '5px',
											}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="left"
															sx={{
																color: 'text.secondary',
															}}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar {...{ page, count: data.totalPages, handleChange: handleChangePage }} />
      </Paper>
      <DeleteModal open={openModal} setOpen={setOpenModal} />
    </>
  );
}
