import React, { useEffect } from 'react'
import { Paper, Typography, Grid, Autocomplete, TextField, FormControl, Select, MenuItem, Checkbox, ListItemText } from '@mui/material'
import { useIsMobile } from '../../../contexts/isMobile'
import { CircularLoader } from '../../../Components'
import { useGetAllJourneysQuery } from '../../../store/journeysSlice'
import { useSelector } from 'react-redux'


const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
}
const JourneyDropdown = ({ triggerJourney, setTriggerJourney, journeyListData }) => {
	const handleChange = (event) => {
	  const {
		target: { value },
	  } = event;
	
	  // Store the _id instead of title
	  setTriggerJourney(
		typeof value === 'string' ? value.split(',') : value
	  );
	};
  
	return (
	  <div>
		<FormControl variant="standard" fullWidth>
		  <Select
			required
			id="demo-multiple-checkbox"
			multiple
			value={triggerJourney}
			onChange={handleChange}
			renderValue={(selected) => 
			  selected
				.map((id) => journeyListData?.data?.find((src) => src._id === id)?.title)
				.join(', ')
			}
			MenuProps={MenuProps}
		  >
			{journeyListData?.data?.map((src) => (
			  <MenuItem key={src._id} value={src._id}>
				<Checkbox checked={triggerJourney.indexOf(src._id) > -1} />
				<ListItemText primary={src.title} />
			  </MenuItem>
			))}
		  </Select>
		</FormControl>
	  </div>
	);
  };
  

const AssignJourney = ({ assign, setAssign, selectedAssign, setSelectedAssign, surgeryDate, skipped }) => {
	const { user } = useSelector((state) => state.user)
	const isMobile = useIsMobile()

	const params = surgeryDate !== '' && !skipped?.has(2) ? 'exclude=appointment' : 'exclude=appointment,surgery'

	const { isLoading, isError, data, refetch } = useGetAllJourneysQuery({ id: user.id, params })

	useEffect(() => {
		if (data) {
			const journeyObject = data?.data?.find((item) => item?._id === assign)
			setSelectedAssign(journeyObject?.title)
		}
	}, [data])
	useEffect(() => {
		refetch()
	}, [])
	if (isLoading)
		return (
			<div>
				<CircularLoader />
			</div>
		)
	if (isError) return <div>some error</div>

	const handleChange = (event, newValue) => {
		const journeyId = data?.data?.find((item) => item?.title === newValue)
		setSelectedAssign(newValue)
		setAssign(journeyId?._id)
	}

	return (
		<div>
			<Paper elevation={2} sx={{ borderRadius: '10px', p: isMobile ? 1 : 2, mb: 3 }}>
				<Typography fontSize={'18px'} fontWeight={'bolder'}>
					Assign Journey
				</Typography>

				<Grid container spacing={2} justifyContent={'left'} alignItems={'center'}>
					{surgeryDate !== '' && !skipped?.has(2) ? (
						<Grid item md={4} xs={12}>
						<JourneyDropdown {...{triggerJourney: assign, setTriggerJourney: setAssign, journeyListData: data}} />
					</Grid>
					):(
<Grid item md={4} xs={12}>
						<Autocomplete
							value={selectedAssign}
							onChange={(event, newValue) => handleChange(event, newValue)}
							options={data?.data?.map((item) => item?.title)}
							renderInput={(params) => <TextField {...params} variant="standard" />}
						/>
					</Grid>
					)}
				</Grid>
			</Paper>
		</div>
	)
}

export default AssignJourney
