import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Box,
  Typography,
  TextField,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CircularLoader, DateField, SolidButton } from "../../Components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  useAddNewNoteMutation,
  useEditNoteMutation,
  useGenerateNoteMutation,
  useGetNoteTypesQuery
} from "../../store/notesSlice";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useIsMobile } from "../../contexts/isMobile";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { convertToObjectArray } from "../../Pages/Patient/utils";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {parse} from "marked";
import TurndownService from "turndown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 500,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius: "10px",
};

const NoteTypeDropdown = ({ noteType, setNoteType, noteData, editData}) => {

  const handleNoteType = (value) => {
    setNoteType(value)
  }
  return (
    <FormControl fullWidth disabled={editData && Object.keys(editData).length !== 0}>
      <InputLabel id="demo-simple-select-label">Note Type</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={noteType}
        label="Note Type"
        onChange={(e) => handleNoteType(e.target.value)}
      >
        {convertToObjectArray(noteData?.data)?.map((item) => (
          <MenuItem key={item?.id} value={item.value}>{item?.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const GenerateNoteButton = ({setForm, setGeneratedNote, setEditData, noteType, generateNoteAPI, id, isLoadingGenerateNote, startDate, endDate, formatDate, fetchAgain, handleMarkdownChange }) => {
  const handleGenerateButton = () => {
    if(noteType === ''){
      toast.error('Please select note type first');
      return
    }

    const body = {noteType, startDate: formatDate(startDate), endDate: formatDate(endDate)}
    generateNoteAPI({body, id}).then((res)=> {
      if(res.error){
        toast.error(res?.error?.data?.message)
        return;
      }
      
      fetchAgain()
      toast.success(res?.data?.message);
      setGeneratedNote(
        res?.data?.data?.description
          ?.replace(/^\`\`\`|`\`\`$/g, '')
          .replace(/\n/g, '<br>')
      );            // setForm((prevForm) => ({ ...prevForm, description: res?.data?.data?.description }))

      setTimeout(()=>{
        setEditData(res?.data?.data)
        // setEditData({...res?.data?.data, description: handleMarkdownChange(res?.data?.data?.description)})
      }, 300)
    })
  }

  return(
    <div style={{marginRight:'5px'}}>
    <SolidButton
            label="Generate Note"
            icon={<AutoFixHighIcon />}
            onClick={(e) => handleGenerateButton()}
            disabled={isLoadingGenerateNote}
          />
    </div>
  )
}

const NoteDatePicker = ({label, date, setDate, editData})=>{
  return(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DemoContainer components={['DatePicker']}>
      <DatePicker
      sx={{width: "100%"}}
      value={date} label={label} onChange={(newValue) => setDate(newValue)} disabled={editData && Object.keys(editData).length !== 0} />
    </DemoContainer>
  </LocalizationProvider>
  )
}

export default function CallNoteModal({
  open,
  setOpen,
  fetchAgain,
  setEditData,
  editData,
  status,
  isDrawer
}) {
  const { user } = useSelector((state) => state.user);
  const isMobile = useIsMobile();
  const { id } = useParams();
  const turndownService = new TurndownService();
  const [date, setDate] = useState("");
  const [generatedNote, setGeneratedNote] = useState('')
  const [form, setForm] = useState({
    title: "",
    description: "",
    location: "",
    patient: id,
    careTeamMember: user.id,
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('')
  const {data: noteData} = useGetNoteTypesQuery()
  const [noteType, setNoteType] = useState('')
  const [addNewNote, {isLoading: isAddNoteLoading}] = useAddNewNoteMutation();
  const [editNote, {isLoading: isEditNoteLoading}] = useEditNoteMutation();
  const [generateNoteAPI, {isLoading: isLoadingGenerateNote}] = useGenerateNoteMutation()

  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const resetAllValues = () => {
    setDate("");
    setForm({
      title: "",
      description: "",
      location: "",
      patient: id,
      careTeamMember: user.id,
    });
    if(!isDrawer){
      setEditData({});
    }
    setNoteType('');
    setGeneratedNote('')
    setStartDate('');
    setEndDate('');
  };

  const createNote = async (e) => {

    //   if (form.description === "" || form.title === "") {
    //     toast.error("Please fill out the required fields");
    //     return;
    //   }
    // } 
        const body = {
            ...form,
            date: date !== "" ? date : editData?.date,
            description: handleHtmlChange(form.description),
            noteType,
            status: editData?.status,
            // startDate: formatDate(startDate),
            // endDate: formatDate(endDate)
          };
          await editNote({
            id: editData?._id,
            body,
          })
            .unwrap()
            .then(() => {
                console.log('HERE')
              fetchAgain();
              resetAllValues();
              toast.success("Successfully Edited!");
              setDate("");
              setForm({
                title: "",
                description: "",
                location: "",
                patient: id,
                careTeamMember: user.id,
              });
              setEditData({});
              setNoteType('');
              setGeneratedNote('')
              setStartDate('');
              setEndDate('');
              setOpen(false);
            })
            .catch((error) => {
                console.log('HERE 2')
              toast.error(error.data || error?.data?.error);
            });

            console.log('OUT')
  };

  const handleClose = () => {
    resetAllValues();
    setDate("");
    setForm({
      title: "",
      description: "",
      location: "",
      patient: id,
      careTeamMember: user.id,
    });
    if(!isDrawer){
      setEditData({});
    }
    setNoteType('');
    setGeneratedNote('')
    setStartDate('');
    setEndDate('');
    setOpen(false);
  };

  const formatDate = (date) => {
    console.log(date)
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1)?.padStart(2, '0'); // Month is zero-indexed
    const day = String(date?.getDate())?.padStart(2, '0');
    return `${year}-${month}-${day}`;
};

function convertToISODateString(dateString) {
  const date = new Date(dateString);
  const isoDateString = date.toISOString();
  return isoDateString.slice(0, 19) + '.000Z';
}

function getCurrentISODate() {
  return new Date().toISOString();
}

  const copyGeneratedText = () => {
    navigator.clipboard.writeText(generatedNote)
    toast.success('Note copied successfully!')
  }

    // Function to convert markdown to HTML
    const handleMarkdownChange = (value) => {
      return parse(value);
    };

    const handleHtmlChange = (value) => {
      return turndownService.turndown(value);
    };

  useEffect(() => {
    if (editData && Object.keys(editData).length !== 0 && generatedNote === '') {
        console.log(editData?.description?.replace(/\n\s*\n/g, "\n")?.replace(/^\`\`\`|`\`\`$/g, ""))
      setForm({
        title: editData?.title ? editData?.title : "",
        description: editData?.description ? handleMarkdownChange(editData?.description?.replace(/^\`\`\`|`\`\`$/g, '')) : "",
        location: editData?.location? editData?.location : "",
        patient: editData?.patient,
        careTeamMember: editData?.careTeamMember || user.id,
      });

      if(editData?.noteType){
        setNoteType(noteData?.data[editData?.noteType])
      }

      if(editData?.startDate && editData?.endDate ){
        setStartDate(new Date(editData?.startDate));
        setEndDate(new Date(editData?.endDate));
      }

      setDate(new Date(editData?.date));
    }
  }, [editData]);

  useEffect(()=>{
    if(generatedNote !== '' ){
      setForm((prevForm) => ({ ...prevForm, description: handleMarkdownChange(generatedNote) }))
    }
  },[generatedNote, isLoadingGenerateNote, editData])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "80%" : 700,
          height: isDrawer ? "60%" :  "60%",
          bgcolor: "#F5F5F5",
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Note
          </Typography>
          <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Stack>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2}>
            {/* {!isDrawer && Object.keys(editData).length === 0 && (
            <Grid item md={12} xs={12} mt={1}>
              <Box sx={{display:'flex', justifyContent:'end'}}>
            <GenerateNoteButton {...{setForm, setGeneratedNote, setEditData, noteType, generateNoteAPI, id, isLoadingGenerateNote, startDate, endDate, formatDate, fetchAgain, handleMarkdownChange }} />
                </Box>

            </Grid>
            )} */}
            <Grid item md={12} xs={12}>
              {isLoadingGenerateNote ? (
                <CircularLoader height={'40vh'} />
              ):(
              <CKEditor
                key={generatedNote ? generatedNote : 'Key-here'}
                editor={ClassicEditor}
                data={form.description}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                  writer.setStyle(
                      "height",
                      "20px",
                      editor.editing.view.document.getRoot()
                  );
                  });
              }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setForm((prevForm) => ({ ...prevForm, description: data }));
                }}
                config={{
                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "undo",
                      "redo",
                    ],
                  },
                  placeholder: "Enter your description here...",
                }}
              />
              )}
            </Grid>

            {generatedNote !== "" && (
            <Grid item md={12} xs={12}>
              <Tooltip title="Copy Generated Note"  placement="right" onClick={()=>{copyGeneratedText()}}>
              <ContentCopyIcon sx={{color:'grey', cursor:'pointer'}} />
              </Tooltip>
            </Grid>
            )}

          </Grid>
          <Stack
            direction="row"
            justifyContent={"flex-end"}
            sx={{ position: "fixed", bottom: "1rem", right: "2rem" }}
          >
            <SolidButton label="Save" onClick={createNote} disabled={isAddNoteLoading || isEditNoteLoading} />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
