import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography  
} from "@mui/material";
import { CircularLoader, PaginationBar } from "../../../../Components";
import { useNotAllowedRole } from "../../../../hooks";
import {useNavigate} from "react-router-dom"


const createColumns = (isNotAllowed) => [
  {
    id: "name",
    label: "Patient Name",
    minWidth: 170,
    align: "right",
  },
  {
    id: "risk",
    label: "Reason For Risk",
    minWidth: 170,
    align: "right",
  },
  {
    id: "category",
    label: "Categories",
    minWidth: 170,
    align: "right",
  }
];

const createRows = ({ patient, navigateToProfile }) => {
  const rows = patient.map(
    ({ patientId,title,category }) => {
      const {_id, firstName, lastName} = patientId
      return {
        name:(
          <Typography variant="p" sx={{fontSize:16, fontWeight:400, color:"text.secondary", cursor:"pointer", "&:hover":{
            color:"#816179"
          }}}
          onClick={(e) => navigateToProfile(e,_id)}
          >
          {`${firstName} ${lastName}`}
          </Typography>
          ),
        risk: title,
        category
      };
    }
  );

  return rows;
};


export function AttentionTable({ page, setPage, data, isLoading, isError }) {

  const isNotAllowed = useNotAllowedRole()
  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const navigateToProfile = (e,id) =>{
    navigate(`/profile/${id}`, {state:{ action : "read-only", id }})
  }

  const columns = createColumns(isNotAllowed)
  const rows = createRows({ patient: data?.data || [], navigateToProfile });

  
  if (isLoading) return <div><CircularLoader /></div>;
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return <Typography sx={{margin:"10px 20px"}}>No Patient found.</Typography>;
  }
  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (column.hide) return null
                return (
                  <TableCell
                    variant="head"
                    key={column.id}
                    align={"center"}
                    sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                  >
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              if (row.hide) return null
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ color: "text.secondary", border: "none" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data.totalPages, handleChange: handleChangePage }}
      />
    </>
  );
}
