import { Box, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useIsMobile } from '../../contexts/isMobile'
import { SendMessageField } from './SendMessageField'
import { AiDisplayMessageBox } from './AiDisplayMessageBox'
import { useChatValues } from '../../contexts/chatValue'
import { Chat } from '../ExternalAIAssistant/DefaultLayout'
import { useAssistantChatValues } from '../../contexts/assistantChatValue'
import { AssistantAiDisplayMessageBox } from '../ExternalAIAssistant/AssistantAiDisplayMessageBox'

export default function AiConversationTab({ id, isDrawer, chatBot, consent, status, value, AiAssistantValue, agentId, isAIAssistant, include_revel_content, assistantPrompt }) {
	const isMobile = useIsMobile() // Track loading state for messages and pagination
	const scrollableRef = useRef()
	const { chatMessages, isAILoading } = useChatValues()
	const {assistantChatMessages, isAssistantLoading} = useAssistantChatValues()
	const sendMessageFieldRef = useRef()


	const scrollDown = () => {
		if (scrollableRef.current) {
			scrollableRef.current.scrollTop = scrollableRef.current?.scrollHeight
		}
	}

	const adjustDisplayMessageBoxHeight = () => {
		if (sendMessageFieldRef.current && scrollableRef.current) {
			const sendMessageFieldHeight = sendMessageFieldRef.current.clientHeight
			const maxHeight = isDrawer ? 'calc(100vh - 150px)' : isAIAssistant ? 'calc(100vh - 5px)' : 'calc(100vh - 250px)'
			const adjustedHeight = `calc(${maxHeight} - ${sendMessageFieldHeight}px)`
			scrollableRef.current.style.maxHeight = adjustedHeight

			scrollDown()
		}
	}

	useEffect(() => {
		scrollDown()
	}, [chatMessages, assistantChatMessages, isAILoading, isAssistantLoading])

	useEffect(() => {
		const resizeObserver = new ResizeObserver(() => {
			adjustDisplayMessageBoxHeight();
		});
	
		if (sendMessageFieldRef.current) {
			// Observe changes in the height of the sendMessageField
			resizeObserver.observe(sendMessageFieldRef.current);
		}
	
		// Cleanup observer when component unmounts
		return () => {
			if (sendMessageFieldRef.current) {
				resizeObserver.unobserve(sendMessageFieldRef.current);
			}
		};
	}, []);

	return (
		<>
			<Box
				className="ScrollDesign"
				ref={scrollableRef}
				sx={{
					overflowY: 'auto', // Enable vertical scrolling if needed
					marginBottom: '100px',
					p: 1,
				}}
			>
				{isAIAssistant ? (
					assistantChatMessages?.length > 0 ? (
						<AssistantAiDisplayMessageBox data={assistantChatMessages} isLoading={isAssistantLoading} isAIAssistant={isAIAssistant} />
					) : (
						<Chat/>
					)
				) : chatMessages?.length > 0 ? (
					<AiDisplayMessageBox data={chatMessages} isLoading={isAILoading} />
				) : (
					<Typography
						sx={{
							fontSize: '18px',
							fontWeight: 600,
							mt: '2rem',
							textAlign: 'center',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '50vh',
						}}
					>
						No Messages Found.
					</Typography>
				)}
			</Box>
			<Box
				sx={{
					position: 'fixed',
					bottom: 0,
					width: isDrawer && isMobile ? '78%' : isDrawer ? '57%' : '64%',
					background: 'white',
				}}
			>
				<div ref={sendMessageFieldRef}>
					<SendMessageField
						id={id}
						scrollDown={scrollDown}
						chatBot={chatBot}
						consent={consent}
						status={status}
						value={value}
						AiAssistantValue={AiAssistantValue}
						agentId={agentId}
						isAIAssistant={isAIAssistant}
						include_revel_content={include_revel_content} 
						assistantPrompt={assistantPrompt}
					/>
				</div>
			</Box>
		</>
	)
}
