import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssignJourney: builder.query({
      // query: (patientId) => `/assignJourneys/${patientId}`,
      query: (query = {}) => {
        const { page = 1, limit = 10,userID, patientId,...rest } = query;
        const finalQuery = { page, limit, userID, patientId,...rest };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/assignJourneys/?${params.toString()}`;
      },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "assignJourney", id: "LIST" },
          ...data.map((p) => ({ type: "Tag", id: p._id })),
        ];
      },
    }),
    addAssignJourney: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/assignJourneys",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "assignJourneys", id: "LIST" }],
    }),
    deleteAssignJourney: builder.mutation({
      query: (id) => {
        return {
          url: `/assignJourneys/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "assignJourneys", id: "LIST" }],
    }),
    getAssignJourneyByID: builder.query({
      query: (id) => `/assignJourneys/${id}`,
      invalidatesTags: [{ type: "assignJourneys", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAssignJourneyQuery,
  useGetAssignJourneyByIDQuery,
  useAddAssignJourneyMutation,
  useDeleteAssignJourneyMutation,
} = extendedApiSlice;
