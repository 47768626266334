import React from 'react'
import { Paper, Typography, Stack, Avatar, Grid, Divider, Button, Box, IconButton, Tooltip } from '@mui/material'
import { useIsAuthenticatedUser } from '../../contexts/isAuthenticatedUser'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'

export function ProsDrawerHeader({ path, handleClose, data }) {
	const { user } = useIsAuthenticatedUser()
	const navigate = useNavigate()

	const getColorStyles = (risk) => {
		if (risk === 'High Risk' || risk === 'High risk') {
			return {
				marginRight: '5px',
				color: '#DC362E',
			}
		} else if (risk === 'Moderate Risk' || risk === 'Rising Risk' || risk === 'Rising risk') {
			return {
				marginRight: '5px',
				color: '#FFB400',
			}
		} else if (risk === 'Low Risk' || risk === 'Healthy') {
			return {
				marginRight: '5px',
				color: '#63A02C',
			}
		} else {
			return {
				marginRight: '5px',
				color: 'black',
			}
		}
	}

	const getAge = (birthDate) => {
		if (birthDate) {
			const age = Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10)
			return `${age}Y`
		} else {
			return 'No DOB Available'
		}
	}

	const goToProfile = (id, name) => {
		localStorage.setItem('pageName', JSON.stringify(name))
		navigate(`/profile/${id}`, { state: { action: 'read-only', id } })
	}

	return (
		<Paper
			elevation={0}
			sx={{
				height: '100%',
				// border: "1px solid rgba(0, 151 ,236 ,0.30)",
				bgcolor: 'rgba(0, 151 ,236 ,0.04)',
				p: 2,
			}}
		>
			<Stack direction="row" alignItems="center">
				<Avatar sx={{ width: '70px', height: '70px' }} alt="Profile Image" src={path ? `${process.env.REACT_APP_BASE_API_URL}/${path}` : user?.picture} />
				<Grid
					container
					spacing={2}
					style={{
						paddingLeft: '5px',
						justifyContent: 'space-around',
						alignItems: 'center',
					}}
				>
					<Grid item md={4} xs={3}>
						<Box display="flex" alignItems="center">
							<Typography
								variant="p"
								sx={{
									fontSize: 16,
									fontWeight: 600,
								}}
							>
								{`${data?.firstName} ${data?.lastName}`}
							</Typography>
              <Tooltip title="Go to Profile">
							<IconButton
								sx={{ ml: '3px' }}
                size='small'
								onClick={() => {
									goToProfile(data?._id, 'pros')
								}}
							>
								<LaunchRoundedIcon fontSize='small' />
							</IconButton>
              </Tooltip>
						</Box>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Typography
								variant="p"
								sx={{
									fontSize: 14,
									fontWeight: 500,
									color: '#50607A',
									pr: 1,
								}}
							>
								{`${getAge(data?.birthday)}, ${data?.gender?.charAt(0)?.toUpperCase() || 'N/A'}`}
							</Typography>
						</div>
					</Grid>

					<Divider orientation="vertical" flexItem />
					<Grid item md={4} xs={4}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'left',
								width: '100%',
							}}
						>
							<Typography
								variant="p"
								sx={{
									fontSize: 12,
									fontWeight: 600,
									color: '#50607A',
								}}
							>
								Procedure Detail
							</Typography>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Typography
									variant="p"
									sx={{
										fontSize: 12,
										fontWeight: 500,
									}}
								>
									Type:{' '}
								</Typography>
								<Typography
									variant="p"
									sx={{
										fontSize: 12,
										fontWeight: 500,
									}}
								>
									{data?.procedureType ? data?.procedureType : 'N/A'}
								</Typography>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Typography
									variant="p"
									sx={{
										fontSize: 12,
										fontWeight: 500,
									}}
								>
									Date:
								</Typography>
								<Typography
									variant="p"
									sx={{
										fontSize: 12,
										fontWeight: 500,
									}}
								>
									{data?.procedureDate ? moment(data?.procedureDate).format('MM-DD-YYYY') : 'N/A'}
								</Typography>
							</div>
						</div>
					</Grid>
					<Divider orientation="vertical" flexItem />
					<Grid item md={3} xs={3}>
						<div
							style={{
								display: 'flex',
								// flexDirection: "column",
								justifyContent: 'left',
								width: '100%',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'left',
									width: '100%',
								}}
							>
								<Typography
									variant="p"
									sx={{
										fontSize: 12,
										fontWeight: 600,
										color: '#50607A',
										mb: '-16px',
									}}
								>
									Risk Category
								</Typography>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<>
										<span
											style={{
												...getColorStyles(data?.riskCategory?.score ? data?.riskCategory?.score : 'N/A'),
												fontSize: '36px',
											}}
										>
											•
										</span>
										<Typography fontSize={14} color="black">
											{data?.riskCategory?.score ? data?.riskCategory?.score?.charAt(0)?.toUpperCase() + data?.riskCategory?.score?.slice(1) : 'N/A'}
										</Typography>
									</>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									cursor: 'pointer',
									justifyContent: 'right',
									color: '#536878',
									alignItems: 'center',
								}}
								onClick={() => handleClose(false)}
							>
								<CloseIcon />
							</div>
						</div>
					</Grid>
				</Grid>
			</Stack>
		</Paper>
	)
}
