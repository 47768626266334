import { Chip, LinearProgress, Tooltip } from "@mui/material";
import React from "react";
import ProsDrawer from "./ProsDrawer";

export default function ProsProgram({ program, toggleProsDrawer, openProsDrawer, id, data }) {
  const pendingPrograms = program.filter(p => p.status === 'pending');
  return (
    <div style={{ display: "flex", width:'75%' }}>
      <div
        style={{
          position: "relative",
          borderRadius: "10px",
          overflow: "hidden",
          marginRight: "5px",
        }}
      >
        <Chip
          label={
            pendingPrograms.length > 0
            ? pendingPrograms[0].title || 'N/A'
            : "No PROs"
          }
          size="medium"
          sx={{
            marginTop: "-0.2em",
            color: "black",
            backgroundColor:
            pendingPrograms.length > 0 ? "#e5feec" : "rgba(255, 31, 0, 0.08)",
            borderRadius: "8px",
            position: "relative",
          }}
        />
        <LinearProgress
          variant="determinate"
          value={pendingPrograms.length > 0 ? 100 / pendingPrograms.length : 0}
          sx={{
            position: "absolute",
            bottom: "4px",
            left: "2px",
            width: "calc(100% - 4px)",
            borderRadius: "10px",
            height: "3.5px",
            zIndex: 0,
            backgroundColor: pendingPrograms.length > 0 ? "#8fdaa5" : "#D3602F",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#208b40", // Desired color
            },
          }}
        />
      </div>
      <Tooltip
        title={
          pendingPrograms.length > 1
              ? pendingPrograms
                  ?.slice(1) // Exclude the first object
                  ?.map((item) =>
                    item.title ? item.title : 'N/A'
                  )
                  ?.join(", ")
              : "No additional data"
          }
        onClick={toggleProsDrawer({value:true, data})}
      >
        <Chip
          label={"+" + (pendingPrograms.length > 0 ? pendingPrograms.length - 1 : 0)}
          sx={{ borderRadius: "10px" }}
        />
      </Tooltip>
      {openProsDrawer?.value && (
      <ProsDrawer 
        toggleDrawer={toggleProsDrawer}
        open={openProsDrawer?.value}
        id={id}
        prosData={openProsDrawer?.data}
      />
      )}
    </div>
  );
}
