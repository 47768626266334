import { Doughnut } from 'react-chartjs-2'
import { useIsMobile } from '../../contexts/isMobile'

export function DoughnutChart({ allMetric, setAllMetric, heading, labels, formData, colors, query, backendData }) {
	// For Responsiveness
	const isMobile = useIsMobile()

	// Data for doughnut
	const data = {
		labels,
		datasets: [
			{
				label: heading,
				data: formData,
				borderColor: ['#FFFFFF'],
				backgroundColor: colors,
				pointBackgroundColor: 'rgba(255,206,86,0.2)',
			},
		],
	}

	// Options for doughnut
	const options = {
		plugins: {
			title: {
				display: true,
				text: heading,
				font: {
					size: 16,
					weight: 'bold',
				},
				padding: {
					bottom: 20,
				},
				align: 'start',
			},
			legend: {
				display: true,
				position: 'right',
				align: 'end',
				labels: {
					boxWidth: 10,
					padding: 10,
					usePointStyle: true,
				},
			},
			datalabels: {
				color: (ctx) => {
					const value = ctx.dataset.data[ctx.dataIndex]
					if (
						data.labels[ctx.dataIndex] === 'Pending' ||
						data.labels[ctx.dataIndex] === 'Total Procedures' ||
						data.labels[ctx.dataIndex] === 'No Procedures' ||
						data.labels[ctx.dataIndex] === 'No'
					) {
						return 'black' // Black for Pending
					}
					return 'white' // White for Complete or others
				},
				formatter: (value, ctx) => {
					// Calculate the percentage value
					const dataset = ctx.chart.data.datasets[ctx.datasetIndex]
					const total = dataset.data.reduce((sum, current) => sum + current, 0)
					const percentage = Math.round((value * 100) / total)
					if (percentage > 0) {
						return `${percentage}%`
					} else {
						return '' // Empty string if percentage is not greater than 0
					}
				},
				font: {
					size: 12,
					weight: 'bold',
				},
				display: true, // Set the display property to true
			},
			afterDraw: function (chart) {
				console.log(chart);
				if (chart.data.datasets[0].data.length < 1) {
				  let ctx = chart.ctx;
				  let width = chart.width;
				  let height = chart.height;
				  ctx.textAlign = "center";
				  ctx.textBaseline = "middle";
				  ctx.font = "30px Arial";
				  ctx.fillText("No data to display", width / 2, height / 2);
				  ctx.restore();
				}
			},
		},
		maintainAspectRatio: false, // Disable aspect ratio to control size manually
		responsive: true,
		// onClick: (evt, element) => {
		// 	if (element.length > 0) {
		// 		const chart = element[0].element.$context.chart
		// 		const datasetIndex = element[0].datasetIndex
		// 		const index = element[0].index
		// 		const label = chart.data.labels[index]
		// 		const backendValue = backendData.find(item => item.label === label)
		// 		if (!allMetric?.some((obj) => obj.query === query && obj.values === backendValue?.value)) {
		// 			const newMetric = [...allMetric, { metric: heading, key: label, values: backendValue?.value, query }]
		// 			setAllMetric(newMetric)
		// 			localStorage.setItem('allMetric', JSON.stringify(newMetric));
		// 		}
		// 	}
		// },
		onHover: (event, chartElement) => {
			event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default'
		},
	}

	return (
		<div style={{ width: '100%', height: '250px' }}>
			<Doughnut data={data} options={options} />
		</div>
	)
}
