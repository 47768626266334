import React, { useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import { useIsMobile } from "../../../contexts/isMobile";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F5F5F5",
  p: 4,
borderRadius:"10px"
};

export const ConfirmStatusModal = ({ open, setOpen, handle, id }) => {
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign:"center"}}>
          Has your task been completed?
          </Typography>
          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Box sx={{ margin: "5px" }}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#816179",
                    padding: "1rem 2rem",
                    borderRadius: "0.5rem",
                    fontSize: "14px",
                    width: isMobile ? "100%" : "130px",
                    height: "35px",
                  }}
                  onClick={handle}
                >
                 Yes
                </Button>
              </Box>
              <Box sx={{ margin: "5px" }}>
                <Button
                  size="small"
                  sx={{
                    color: "#816179",
                    border: "1px solid #816179",
                    padding: "1rem 2rem",
                    borderRadius: "0.5rem",
                    fontSize: "14px",
                    width: isMobile ? "100%" : "130px",
                    height: "35px",
                    ":hover": {
                      border: "1px solid black",
                      color: "black",
                    },
                  }}
                  onClick={handleClose}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
