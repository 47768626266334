import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Collapse, IconButton } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import moment from 'moment';
import { useState } from 'react';
import CallAccordion from '../../Patient/PatientProfile/CallDrawers/CallAccordion';
import MessageAccordion from './MessageAccordion';

function createData(rowData, rows, handleRowToggle) {
  return rowData?.map((item, index) => ({
    method: item?.method,
    date: moment(item?.date).format("MM-DD-YYYY h:mmA"),
    collapsibleBody: (
      <Collapse
        in={rows[index]}
        timeout="auto"
        unmountOnExit
        style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '20px' }}
      >

          {item.method === 'Messages' ? (
            <MessageAccordion title={"Message"} data={item?.data?.body} />
          ) : item.method === 'Calls' ? (
            <CallAccordion title={"Transcript"} data={item?.data?.transcriptObject} defaultExpanded={true} />
          ) : (
            'Nothing To Display!'
          )}
      </Collapse>
    ),
    data: (
      <IconButton onClick={() => handleRowToggle(index)}>
        <Visibility />
      </IconButton>
    ),
  }));
}

// Define columns
const columns = [
  { id: 'method', title: 'Method' },
  { id: 'date', title: 'Date' },
  { id: 'data', title: 'Action' },
];

export default function CommunicationTable({ data }) {
  // Initialize rows state for collapse
  const [rows, setRows] = useState(Array(data.length).fill(false));

  // Toggle function to update the corresponding row index
  const handleRowToggle = (index) => {
    const newOpenRows = [...rows];
    newOpenRows[index] = !newOpenRows[index];
    setRows(newOpenRows);
  };

  return (
    <TableContainer>
      <Table sx={{ width: "100%", tableLayout: 'fixed' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns?.map((item, index) => (
              <TableCell align="left" key={index}>{item.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {createData(data, rows, handleRowToggle).map((item, index) => (
            <React.Fragment key={index}>
              <TableRow>
                {columns?.map((column) => {
                  const value = item[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align="left"
                      sx={{
                        color: "text.secondary",
                      }}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
              {/* Collapsible content should be a separate row */}
              <TableRow>
                <TableCell colSpan={columns.length} align="left" sx={{ padding: 0 }}>
                  {item.collapsibleBody}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
