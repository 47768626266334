import { Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchField from "../../../Components/SearchField";
import OutlinedButton from "../../../Components/OutlinedButton";
import { JourneyAccordianModal } from "../Profile/Journey";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import { appointmentData, journeyData } from "./ProfileUtils";
import AppointmentModal from "./ProfileModals/appointmentModal";
import { useGetPatientAppointmentsQuery } from "../../../store/appointmentsSlice";
import { useDebouncedValue } from "@mantine/hooks";
import {  CircularLoader } from "../../../Components";


export default function AppointmentTable({id: patientId,  user, status}) {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [editData, setEditData] = useState({});
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["reasonForVisit", "location"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
const [open,setOpen]=useState(false)
  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};
  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }

const handleModal=()=>{
    setOpen(true)
}

const { isLoading, isSuccess, isError, error, data, refetch } = useGetPatientAppointmentsQuery({
  userID: user.id,
  page,
  patientId,
  ...queries,
});

const fetchAgain = ()=>{
  refetch()
}

if (isLoading) return  <CircularLoader />;
if (isError) return <div>{error}</div>;
  return (
    <div id="appointment">
      <Paper
        elevation={3}
        sx={{ height: "100%", p: "2rem", borderRadius: "10px", mt: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
         Appointments
        </Typography>
        <Grid container justifyContent={"space-between"} sx={{paddingY: "12px"}}>
            <Grid item md={4} xs={6}>
          <SearchField
            {...{
              searchTerm,
              setSearchTerm,
              options: searchAbleFields,
              selectedOptions: selectedFields,
              handleOptionSelect: handleFieldSelect,
            }}
          />
          </Grid>
          <Grid item xs={3} textAlign={"right"}>
            <OutlinedButton label={"Create"} handleClick={handleModal}/>
          </Grid>
        </Grid>
        <ProviderInfo data={data?.data || []}  columns={appointmentData} tableType={"appointment"}  count={data?.totalPages} {...{page,setPage, setEditData, handleModalEdit:handleModal, fetchAgain}}/>
      </Paper>
      <AppointmentModal {...{open,setOpen,fetchAgain,setEditData, editData, status}}/>
    </div>
  );
}
