import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ViewDropdown } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { CountStatus } from "./CountStatus";

export function PatientCountHeader({ view, setView }) {
  const isMobile = useIsMobile();

  const gridMargin = {
    margin: isMobile && "10px 5px",
  };

  return (
    <>
      <Box sx={{ padding: isMobile ? "6px" : 3 }}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item xs={12} sm={4} sx={gridMargin}>
            <Typography variant="h5">Patient Count</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CountStatus />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
