import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { FormTable } from "./FormTable";
import { TableHeader } from "./TableHeader";
import { useGetSurveysQuery } from "../../../store/suerveySlice";
import { useDebouncedValue } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useIsMobile } from "../../../contexts/isMobile";
import {InactivityCheck} from "../../../Components/InactivityCheck";

export function FormsTable() {
  const isMobile = useIsMobile();
  const { user } = useSelector((state) => state.user);
  const [page, setPage] = useState(localStorage.getItem('page') ? parseInt(localStorage.getItem('page')) : 1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["createdBy", "title", "description"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};

  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }

  const { isLoading, isSuccess, isError, error, data, refetch } =
    useGetSurveysQuery({
      userID: user.id,
      page,
      ...queries,
    });

  const fetchAgain = () => {
    refetch();
  };

  useEffect(() => {
    refetch(); // Trigger the initial query fetch
  }, [refetch]);


  useEffect(() => {
		if (page > data?.totalPages) {
			localStorage.setItem('page', 1)
			setPage(1)
		}
	}, [data])

  // If Token is expire it navigate to Login page

  return (
    <div style={{ padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem" }}>
      <TableHeader
        {...{
          searchTerm,
          setSearchTerm,
          options: searchAbleFields,
          selectedOptions: selectedFields,
          handleOptionSelect: handleFieldSelect,
        }}
      />
      <FormTable
        data={data || {}}
        {...{
          page,
          setPage,
          fetchAgain,
          isLoading,
          isSuccess,
          isError,
          error,
        }}
      />
      <InactivityCheck />
    </div>
  );
}
