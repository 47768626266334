import { Bar } from "react-chartjs-2";
import { useIsMobile } from "../../../contexts/isMobile";
import { careProgramsData } from "../../../careProgramUtils";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

export function BarChart({ chartData, chartDataError }) {
  const isMobile = useIsMobile();

  const [labels, setLabels] = useState([]);
  const [countValues, setCountValues] = useState([]);

  const options = {
    responsive: true,
    aspectRatio: isMobile ? 1 : 2,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        padding: {
          top: 30,
          bottom: 30,
        },
        maintainAspectRatio: false, // Fix typo here
        animation: {
          animateScale: true,
        },
      },
      // scales: {
      //   x: {
      //     ticks: {
      //       callback: function (value, index, values) {
      //         const words = value.split(" ");
      //         const maxWordsPerLine = 1;
      //         const lines = [];

      //         for (let i = 0; i < words.length; i += maxWordsPerLine) {
      //           lines.push(words.slice(i, i + maxWordsPerLine).join(" "));
      //         }

      //         return lines;
      //       },
      //     },
      //   },
      // },
    },
  };

  // Calculate the barThickness by the total labels and values
  const calculateBarThickness = () => {
    const totalLabels = labels?.length;
    const totalValues = countValues?.length;
    
    const maxBarThickness = 150; // Maximum desired bar thickness
    const minBarThickness = 10; // Minimum desired bar thickness
    
    const calculatedThickness = Math.max(
      minBarThickness,
      maxBarThickness / Math.max(totalLabels, totalValues)
    );
    
    return calculatedThickness;
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Care Program",
        data: countValues,
        backgroundColor: "#816179",
        barThickness: calculateBarThickness(),
      },
    ],
  };

  useEffect(() => {
    const programData = chartData
      ?.map((dataItem) => {
        const sameValueData = careProgramsData?.find(
          (careProgramItem) => careProgramItem?.value === dataItem?.program
        );

        if (sameValueData) {
          return {
            label: sameValueData?.label,
            value: sameValueData?.value,
            count: dataItem?.count,
          };
        }

        return null;
      })
      .filter((item) => item !== null);

    const labelsArray = programData?.map((item) => item.label);
    const countValuesArray = programData?.map((item) => item.count);

    setLabels(labelsArray);
    setCountValues(countValuesArray);
  }, [chartData]);

  if (chartDataError) return <p>Some Error</p>;
  return (
    <Box
      sx={{ width: isMobile ? "100%" : "60%", height: "50%", margin: "auto" , padding: "20px 0px" }}
    >
      <Bar data={data} options={options} />
    </Box>
  );
}
