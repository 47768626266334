import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { PatientSidebar } from "../CreatePatient/Sidebar";
import { viewPatientSidebarData } from "../patientUtils";
import { PatientOverview } from "./PatientOverview";
import JourneyTable from "./JourneyTable";
import FormsTable from "./FormsTable";
import TaskTable from "./TaskTable";
import AppointmentTable from "./AppointmentTable";
import SurgeryTable from "./SurgeryTable";
import NoteTable from "./NoteTable";
import { useIsMobile } from "../../../contexts/isMobile";
import { useLocation, useNavigation } from "react-router-dom";
import { useGetPatientByIDQuery } from "../../../store/patientsSlice";
import { useGetProfileByPatientIDQuery } from "../../../store/riskProfilesSlice";
import { CircularLoader, SolidButton, InactivityCheck } from "../../../Components";
import { useSelector } from "react-redux";
import CareAllyTable from "./CareAllyTable";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import { ProviderColumns } from "../PatientTable/DrawerComponents/DataTitlesUtils";
import CallTable from "./CallTable";

export default function PatientProfileIndex() {
  const isMobile = useIsMobile();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigation();
  const id = location.pathname.split("/").reverse()[0];

  const { data, isError, isLoading, isSuccess, refetch } =
    useGetPatientByIDQuery(id, { refetchOnMountOrArgChange: true });

  const { data: riskProfileData } = useGetProfileByPatientIDQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  if (isError) return <div>some error</div>;
  if (isLoading)
    return (
      <div>
        <CircularLoader />
      </div>
    );
  const handleRedirect = () => {
    navigate(`/createProfile/`);
  };

  const handleDelete=()=>{
    
  }
  return (
    <>
      <Box sx={{ mt: "1.8rem" }} />
      <Grid container>
        {!isMobile && (
          <Grid item md={2} xs={4}>
            <PatientSidebar data={viewPatientSidebarData} isProfile={true} id={id} status={data?.status} refetch={refetch}/>
          </Grid>
        )}

        <Grid
          item
          md={8}
          my={3}
          ml={isMobile ? 2 : "6rem"}
          xs={11}
          mr={isMobile && 2}
        >
          <PatientOverview data={data} />
          <div id="providerInfo">
          <Paper
        elevation={3}
        sx={{ height: "100%", p: "2rem", borderRadius: "10px", mt: "3rem" }}
      >
          <ProviderInfo
          data={ data?.physician ? [data?.physician] : []}
          title="Provider Information" tableType={"physician"}
          columns={ProviderColumns}
        /></Paper>
          </div>
          <SurgeryTable id={id} user={user} status={data?.status} />
          <CallTable id={id} user={user} status={data?.status} receiverId={data?.phone} />
          <FormsTable id={id} user={user} status={data?.status} patientName={data ? `${data?.firstName} ${data?.lastName}` : 'Patient'} />
          <JourneyTable id={id} user={user} status={data?.status} />
          <TaskTable id={id} user={user} status={data?.status} />
          <AppointmentTable id={id} user={user} status={data?.status} />
          <NoteTable id={id} user={user} status={data?.status} />
          <CareAllyTable id={id} user={user} status={data?.status} />
          {isMobile && (
            <div style={{ display:"flex",justifyContent:"flex-end",padding:"1rem"}}>
              <SolidButton label={"Edit Patient"} onClick={handleRedirect} /> 
              <SolidButton label={"Delete Patient"} onClick={handleDelete} />
            </div>
          )}
        </Grid>
      </Grid>
      <InactivityCheck />
    </>
  );
}
