export const PatientTaskData = [
    {id:1, title: "Patient information", description: "Patient personal information", createdAt:"2023-07-27T19:09:38.729+00:00", createdBy: " Dr. Delson"},
    {id:2, title: "Patient disease", description: "What problem is the patient facing", createdAt:"2023-07-27T19:09:38.729+00:00", createdBy: " Dr. Delson"},
    {id:3, title: "Patient information", description: "Patient personal information", createdAt:"2023-07-27T19:09:38.729+00:00", createdBy: " Dr. Delson"}
]

export const PatientAttentionData = [
    {id:1, name: 'Mariya', risk: "XYZX", category: "High"},
    {id:2, name: 'Mariya', risk: "ABCDF", category: "Medium"},
    {id:3, name: 'Mariya', risk: "GHIKL", category: "High"},
    {id:4, name: 'Mariya', risk: "YUOKS", category: "Low"},
]