import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCareAlly: builder.query({
      query: (id) => `/v2/careAlly/patient/${id}`,
    }),
    getCareAllyChat: builder.query({
      query: ({id, page}) => `/chat/careAlly/${id}?limit=10&&page=${page}`,
    }),
    addCareAllyChat: builder.mutation({
      query: ({id,body}) => {
        return {
          url: `/chat/careAlly/${id}`,
          method: "POST",
          body
        };
      },
      invalidatesTags: [{ type: "Care-Ally1", id: "LIST" }],
    }),
    addNewCareAlly: builder.mutation({
      query: ({id,body}) => {
        return {
          url: `/v2/careAlly/${id}`,
          method: "POST",
          body
        };
      },
      invalidatesTags: [{ type: "Care-Ally", id: "LIST" }],
    }),
    updateCareAlly: builder.mutation({
      query: ({id,body}) => {
        return {
          url: `/v2/careAlly/${id}`,
          method: "PUT",
          body
        };
      },
      invalidatesTags: [{ type: "Care-Ally", id: "LIST" }],
    }),
    deleteCareAlly: builder.mutation({
      query: (id) => {
        return {
          url: `/v2/careAlly/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Care-Ally", id: "LIST" }],
    }),
  }),
});

export const {
  useGetCareAllyQuery,
  useGetCareAllyChatQuery,
  useAddNewCareAllyMutation,
  useAddCareAllyChatMutation,
  useUpdateCareAllyMutation,
  useDeleteCareAllyMutation,
} = extendedApiSlice;
