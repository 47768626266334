export const FormTabData = [
    {
        "formResult": [
            {
                "name": "question1",
                "title": "question1",
                "type": "radiogroup",
                "result": "Item 2"
            }
        ],
        "_id": "66b0a03c1e2ef74bdb86551a",
        "formId": "668ed75ae6be39bb8bbcf5ef",
        "patientId": "65e06d1d23b2833249ff4bd0",
        "status": "complete",
        "hospital": "64df21a65bc5e4faf58c2115",
        "dueDate": "2024-08-05T19:00:00.000Z",
        "createdAt": "2024-08-05T09:49:48.067Z",
        "updatedAt": "2024-08-08T15:53:25.535Z",
        "__v": 0,
        "surveyDetails": {
            "_id": "668ed75ae6be39bb8bbcf5ef",
            "form": {
                "title": "sdsdsadasdasd",
                "logoPosition": "right",
                "pages": [
                    {
                        "name": "page1",
                        "elements": [
                            {
                                "type": "radiogroup",
                                "name": "question1",
                                "choices": [
                                    "Item 1",
                                    "Item 2",
                                    "Item 3"
                                ]
                            }
                        ]
                    }
                ]
            },
            "createdBy": "superadmin1@gmail.com",
            "hospital": "64df21a65bc5e4faf58c2115",
            "platform": false,
            "editable": true,
            "formula": "none",
            "status": "published",
            "createdAt": "2024-07-10T18:47:54.810Z",
            "updatedAt": "2024-07-10T18:49:43.977Z",
            "__v": 0
        },
        "title": "This is new title",
        "description": "here is the description",
        "score": "",
        "timeLine": [
            {
                "date": "2024-08-08T15:53:25.534Z",
                "eventType": "submitted",
                "description": "Form has been submitted by patient",
                "_id": "66b4e9f59ad826b144808269"
            }
        ]
    },
    {
        "formResult": [
            {
                "name": "question1",
                "title": "question1",
                "type": "radiogroup",
                "result": "Item 2"
            }
        ],
        "_id": "66b0a03c1e2ef74bdb86551a",
        "formId": "668ed75ae6be39bb8bbcf5ef",
        "patientId": "65e06d1d23b2833249ff4bd0",
        "status": "complete",
        "hospital": "64df21a65bc5e4faf58c2115",
        "dueDate": "2024-08-05T19:00:00.000Z",
        "createdAt": "2024-08-05T09:49:48.067Z",
        "updatedAt": "2024-08-08T15:53:25.535Z",
        "__v": 0,
        "surveyDetails": {
            "_id": "668ed75ae6be39bb8bbcf5ef",
            "form": {
                "title": "sdsdsadasdasd",
                "logoPosition": "right",
                "pages": [
                    {
                        "name": "page1",
                        "elements": [
                            {
                                "type": "radiogroup",
                                "name": "question1",
                                "choices": [
                                    "Item 1",
                                    "Item 2",
                                    "Item 3"
                                ]
                            }
                        ]
                    }
                ]
            },
            "createdBy": "superadmin1@gmail.com",
            "hospital": "64df21a65bc5e4faf58c2115",
            "platform": false,
            "editable": true,
            "formula": "none",
            "status": "published",
            "createdAt": "2024-07-10T18:47:54.810Z",
            "updatedAt": "2024-07-10T18:49:43.977Z",
            "__v": 0
        },
        "title": "This is new title",
        "description": "here is the description",
        "score": "",
        "timeLine": [
            {
                "date": "2024-08-08T15:53:25.534Z",
                "eventType": "submitted",
                "description": "Form has been submitted by patient",
                "_id": "66b4e9f59ad826b144808269"
            }
        ]
    }
]