import React from 'react'
import {
    Box
} from "@mui/material";
import QueueTabs from "../../patientQueue/QueueTabs";
import QueueTabsV2 from './QueueTabsV2';
import { useGetPatientQueueBadgeCountQuery } from '../../../store/patientQueuesSlice';
import { QueueTable } from './QueueTable';
import { messagesColumn } from '../../patientQueue/QueueUtils';
import MarkAsResolved from './MarkAsResolvedUI';

export function PatientQueue({queueData, queries, setQueries, selectedPatientIds, setSelectedPatientIds, refetchQueueAgain, page, setPage, handleSorting, sortPatientOrder, sortAcuityOrder, selectedValue, setSelectedValue, isNotDashboard, sortTaskCategoryOrder, sortPhysicianNameOrder, sortProcedureTypeOrder, sortQueueTypeOrder, sortSocialNeedsOrder, sortContactTimeOrder}) {
    const { data, isLoading, refetch } = useGetPatientQueueBadgeCountQuery()

    console.log('selectedPatientIds', selectedPatientIds)

    const refetchCount = () => {
		refetch()
	}

    if (isLoading) return <>{console.log('Loading...')}</>

    return (
        <Box
            sx={{
                mt: "3rem",
                width:'100%'
            }}
        >
            <div>
                {selectedPatientIds?.length === 0 ? (
                    <QueueTabsV2 queries={queries} setQueries={setQueries} selectedValue={selectedValue} setSelectedValue={setSelectedValue} isNotDashboard={isNotDashboard}  />
                ): (
                    <MarkAsResolved selectedPatientIds={selectedPatientIds} setSelectedPatientIds={setSelectedPatientIds} queries={queries} refetchQueueAgain={refetchQueueAgain} />
                )}
            {/* <QueueTabs /> */}
            <QueueTable value={0} columns={messagesColumn} refetchCount={refetchCount} acuityCount={data?.patientQueueCount[0]?.queueWithHighAcuityCount} queueData={queueData} selectedPatientIds={selectedPatientIds} setSelectedPatientIds={setSelectedPatientIds} page={page} setPage={setPage} handleSorting={handleSorting} sortPatientOrder={sortPatientOrder} sortAcuityOrder={sortAcuityOrder} refetchQueueAgain={refetchQueueAgain}             sortTaskCategoryOrder={sortTaskCategoryOrder}
            sortPhysicianNameOrder={sortPhysicianNameOrder}
            sortProcedureTypeOrder={sortProcedureTypeOrder}
            sortQueueTypeOrder={sortQueueTypeOrder}
            sortSocialNeedsOrder={sortSocialNeedsOrder}
            sortContactTimeOrder={sortContactTimeOrder} />
            </div>
        </Box>
    );
}
