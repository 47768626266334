import { Paper, Box } from "@mui/material";
import React from "react";
import { MapHeader } from "./MapHeader";
import { ReactZipCodeMap } from "./ZipCodeMap";

export function Map({ zipCodeData, isError }) {
  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
        border: "1px solid #D0EAE1",
        margin: "20px 0px",
      }}
      elevation={0}
    >
      <MapHeader />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ReactZipCodeMap {...{ zipCodeData, isError }} />
      </Box>
    </Paper>
  );
}
