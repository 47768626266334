import React from 'react'

export function AssistantSvg() {
	return (
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.53417 13.7603L8.80209 15.4369C8.52084 16.0815 7.62876 16.0815 7.34751 15.4369L6.61542 13.7603C5.96376 12.2682 4.79126 11.0803 3.32875 10.4311L1.31375 9.53652C0.672922 9.25236 0.672922 8.32027 1.31375 8.03569L3.26584 7.16902C4.76626 6.50319 5.95959 5.27152 6.60001 3.72819L7.34167 1.94152C7.61667 1.27861 8.53292 1.27861 8.80834 1.94152L9.55001 3.72819C10.1904 5.27111 11.3842 6.50277 12.8842 7.16902L14.8363 8.03569C15.4771 8.31986 15.4771 9.25194 14.8363 9.53652L12.8213 10.4311C11.3583 11.0807 10.1858 12.2682 9.53417 13.7603Z"
				fill="url(#paint0_radial_1624_2930)"
			/>
			<path
				d="M16.3375 19.0473L16.1317 19.519C15.9808 19.8644 15.5025 19.8644 15.3521 19.519L15.1462 19.0473C14.7792 18.206 14.1183 17.536 13.2933 17.1698L12.6592 16.8881C12.3162 16.7356 12.3162 16.2373 12.6592 16.0848L13.2579 15.8185C14.1037 15.4427 14.7767 14.7481 15.1375 13.8781L15.3487 13.3681C15.4962 13.0127 15.9875 13.0127 16.1346 13.3681L16.3458 13.8781C16.7067 14.7481 17.3796 15.4427 18.2254 15.8185L18.8242 16.0848C19.1671 16.2373 19.1671 16.7356 18.8242 16.8881L18.19 17.1698C17.3654 17.536 16.7046 18.206 16.3375 19.0473Z"
				fill="url(#paint1_radial_1624_2930)"
			/>
			<defs>
				<radialGradient
					id="paint0_radial_1624_2930"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.1515 9.33592) rotate(78.858) scale(11.369 11.3745)"
				>
					<stop stop-color="#1BA1E3" />
					<stop offset="0.3" stop-color="#5489D6" />
					<stop offset="0.545" stop-color="#9B72CB" />
					<stop offset="0.825" stop-color="#D96570" />
					<stop offset="1" stop-color="#F49C46" />
				</radialGradient>
				<radialGradient
					id="paint1_radial_1624_2930"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(12.1515 9.33585) rotate(78.858) scale(11.369 11.3745)"
				>
					<stop stop-color="#1BA1E3" />
					<stop offset="0.3" stop-color="#5489D6" />
					<stop offset="0.545" stop-color="#9B72CB" />
					<stop offset="0.825" stop-color="#D96570" />
					<stop offset="1" stop-color="#F49C46" />
				</radialGradient>
			</defs>
		</svg>
	)
}
