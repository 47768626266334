import React,{useState} from "react";
import ReactFlow from "reactflow";
import "reactflow/dist/style.css";
import { AddButtonFlowV2 } from "../../../Components";
import { Box, Typography } from "@mui/material";
import { EditSvg } from "../../../assets/svgs";
import { AddDrawer, EditDrawer } from "../Drawers";

function FlowDiagram() {
  // Changing border color of Nodes
  const nodeStyle={
    borderColor:"#816179",
    borderRadius:"8px",
    paddingTop:"15px",
    paddingBottom:"15px",
  } 

  // State and function for Start Journey
  const [openJourney, setOpenJourney] = useState(false);

  const toggleJourenyDrawer = (openJourney) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenJourney(openJourney);
  };

  // State and function for Form 1
  const [openForm, setOpenForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);

  const toggleFormDrawer = (openForm) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenForm(openForm);
  };
  const toggleEditFormDrawer = (openEditForm) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenEditForm(openEditForm);
  };

  // State and function for Time Delay
  const [openDelay, setOpenDelay] = useState(false);
  const [openEditDelay, setOpenEditDelay] = useState(false);

  const toggleDelayDrawer = (openDelay) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDelay(openDelay);
  };
  const toggleEditDelayDrawer = (openEditDelay) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenEditDelay(openEditDelay);
  };

    // State and function for Time Delay
    const [openEditTask, setOpenEditTask] = useState(false);
    const [openTask, setOpenTask] = useState(false);
  
    const toggleEditTaskDrawer = (openEditTask) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setOpenEditTask(openEditTask);
    };
    const toggleTaskDrawer = (openTask) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setOpenTask(openTask);
    };


  const initialNodes = [
    {
      id: "1",
      position: { x: 150, y: 0 },
      data: {
        label: (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontSize: "13px", fontWeight: "600" }}
              >
                Start Journey
              </Typography>
              <AddButtonFlowV2 onClick={toggleJourenyDrawer(true)} />
            </Box>

            {/* Start Jounrey Drawer */}
            <AddDrawer toggleDrawer={toggleJourenyDrawer} state={openJourney} title={"Start Journey"} />
          </>
        ),
      },
      style:nodeStyle
    },
    {
      id: "2",
      position: { x: 0, y: 100 },
      data: {
        label: (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative", width: "100%" }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: "13px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Form 1
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: "0",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={toggleEditFormDrawer(true)}
                >
                  <EditSvg />
                </Box>
              </Box>
              <AddButtonFlowV2 onClick={toggleFormDrawer(true)} />
            </Box>
            {/* Form 1 Drawer */}
            <AddDrawer toggleDrawer={toggleFormDrawer} state={openForm} title={"Form 1"} />
            <EditDrawer toggleDrawer={toggleEditFormDrawer} state={openEditForm} title={"Form 1"} />
          </>
        ),
      },
      style:nodeStyle
    },
    {
      id: "3",
      position: { x: 150, y: 200 },
      data: {
        label: (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "10px", color: "red" }}
                  >
                    10 sec
                  </Typography>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "center",
                      marginLeft: "8px",
                    }}
                  >
                    Time Delay
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: 0,
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={toggleEditDelayDrawer(true)}
                >
                  <EditSvg />
                </Box>
              </Box>
              <AddButtonFlowV2 onClick={toggleDelayDrawer(true)} />
            </Box>

            {/* Time Delay Drawer */}
            <AddDrawer toggleDrawer={toggleDelayDrawer} state={openDelay} title={"Time Delay"} />
            <EditDrawer toggleDrawer={toggleEditDelayDrawer} state={openEditDelay} title={"Time Delay"} />
          </>
        ),
      },
      style:nodeStyle
    },
    {
      id: "4",
      position: { x: 0, y: 300 },
      data: {
        label: (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative", width: "100%" }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: "13px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Task 1
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: "0",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={toggleEditTaskDrawer(true)}
                >
                  <EditSvg />
                </Box>
              </Box>
              <AddButtonFlowV2 onClick={toggleTaskDrawer(true)} />

              {/* Task 1 drawer */}
              <AddDrawer toggleDrawer={toggleTaskDrawer} state={openTask} title={"Task 1"} />
              <EditDrawer toggleDrawer={toggleEditTaskDrawer} state={openEditTask} title={"Task 1"} />
            </Box>
          </>
        ),
      },
      style:nodeStyle
    },
  ];
  const initialEdges = [
    { id: "e1-2", source: "1", target: "2" },
    { id: "e2-3", source: "2", target: "3" },
    { id: "e3-4", source: "3", target: "4" },
  ];
  
  return (
    <div style={{ width:"100%", height: "90vh" }}>
      <ReactFlow nodes={initialNodes} edges={initialEdges} arrowHeadColor="transparent" fitView  />
    </div>
  );
}

export default FlowDiagram;
