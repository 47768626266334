import React, { useState } from "react";
import { format } from 'date-fns'
import {
  Stack,
  Grid,
  Badge,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import CompleteStatus from "./CompleteStatus";
import { useGetPatientsQuery } from "../../../store/patientsSlice";
import { CircularLoader, PaginationBar } from "../../../Components";
import { useGetPatientsSurveyQuery } from "../../../store/patientsSurveySlice";
import { useNavigate } from "react-router-dom";
import GeneratedUrl from "./GeneratedUrl";
import { useGetSurveysByIDQuery, useGetSurveysQuery } from "../../../store/suerveySlice";
import { ListItemSecondaryAction } from "@material-ui/core";
import {useSelector} from "react-redux"

const columns = [
  { id: "Title", label: "Title", minWidth: 170 },
  {
    id: "Date Assign",
    label: "Date Assign",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Date Filled",
    label: "Date Filled",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "GeneratedUrl",
    label: "Actions",
    minWidth: 270,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

const rows = Array.from(Array(10).keys()).map((item) => ({
  Title: "admin@123568*",
  "Date Assign": "admin@123568*",
  "Date Filled": "Client admin",
  // tags: <Tags />,
  Status: "Pending",
}));

const createRows = (items, pid, surveys) => {
  return items&&items.map(({ formId, createdAt,updatedAt, DateFilled, status, _id }) => ({
    Title: formId?.form?.title || '',
    "Date Assign": format(new Date(createdAt),'dd-MM-yyyy') || '',
    "Date Filled": status === "pending" ? "Pending Update" : format(new Date(updatedAt),'dd-MM-yyyy')  || '',

    // tags: <Tags />,
    Status: <CompleteStatus status={status == "pending" ? false : true} />,
    GeneratedUrl: (
      <GeneratedUrl
        pendingurl={
          `${process.env.REACT_APP_FRONTEND_URI}/patientSurveys/` + formId?._id + "/" + _id
        }
        pendingcopyurl={`${process.env.REACT_APP_FRONTEND_URI}/showPatientSurvey/` + formId?._id + "/" + _id}
        url={`${process.env.REACT_APP_FRONTEND_URI}/SolveSurveys/` + formId?._id + "/" + _id}
        status={status}
      />
    ),
  }));
};

export function AccordianTable({ pid ,data,isLoading,isError,isSuccess,handleChangePage,page,totalPages,currentPage}) {
  const {user} = useSelector(state => state.user)
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const id = pid;

  const {
    isLoading: isLoadingSurvey,
    isSuccess: isSuccessSurvey,
    isError: isErrorSurvey,
    error: errorSurvey,
    data: surveys,
  } = useGetSurveysQuery({userID : user.id},{ refetchOnMountOrArgChange: true });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setPage(0);
  };

  const navigate = useNavigate();
  const redirectTonewPage = (d) => {
    const data = d.GeneratedUrl.split("/");
    console.log(data, 10000);
    navigate(`/SolveSurveys/${data[4]}/${data[5]}`);
  };

  if (isLoading && isLoadingSurvey) return <CircularLoader />;
  if (isError || isErrorSurvey) return <div>some error</div>;
  if (surveys?.data?.length === 0) {
    return <Typography>No Form found.</Typography>;
  }


  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  variant="head"
                  key={column.id}
                  align="center"
                  sx={{ fontWeight: 600 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              isSuccess &&
              createRows(data, pid, surveys).map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align="center"
                          sx={{ color: "text.secondary" }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar 
        {...{ page, count: totalPages, handleChange: handleChangePage }}
      />
    </>
  );
}
