import React, { useState } from "react";
import {
  Paper,
  Typography,
  Stack,
  Avatar,
  styled,
  Chip,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { useIsAuthenticatedUser } from "./../../../contexts/isAuthenticatedUser";
import { useNavigate } from "react-router";
import { useMessageSeenMutation } from "../../../store/chatSlice";
import GolfCourseOutlinedIcon from "@mui/icons-material/GolfCourseOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { useIsMobile } from "../../../contexts/isMobile";

export function QueueProfileCard({
  id,
  name,
  email,
  path,
  taskCategory,
  acuity,
  patient,
}) {
  const [messageSeen] = useMessageSeenMutation();
  const isMobile = useIsMobile();
  const { user } = useIsAuthenticatedUser();
  // States and Functions for Message Drawer
  const [open, setOpen] = React.useState(false);

  const getColorStyles = (acuity) => {
    if (acuity === "high") {
      return {
        marginRight: "5px",
        color: "#DC362E",
      };
    } else if (acuity === "medium") {
      return {
        marginRight: "5px",
        color: "#FFB400",
      };
    } else {
      return {
        marginRight: "5px",
        color: "#63A02C",
      };
    }
  };

  const getAge = (birthDate) => {
    if (birthDate) {
      const age = Math.floor(
        (new Date() - new Date(birthDate).getTime()) / 3.15576e10
      );
      return `${age}Y`;
    } else {
      return "No DOB Available";
    }
  };
  const getCarePhase = (surgeryDate) => {
    if (new Date(surgeryDate) > new Date()) {
      return "Post-op";
    } else if (new Date(surgeryDate) < new Date()) {
      return "Pre-op";
    }
  };
  return (
    <Paper
      elevation={0}
      sx={{
        height: "100%",
        borderRadius: "10px",
        p: "20px",
        border: "1px solid rgba(0, 151 ,236 ,0.30)",
mt:"1rem",
        backgroundColor: "rgba(0, 151 ,236 ,0.04) !important",
      }}
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent={isMobile ? "center" : "space-around"}
        textAlign={isMobile && "center"}
      >
        <Avatar
          sx={{ width: "40px", height: "40px" }}
          alt="Profile Image"
          src={
            path
              ? `${process.env.REACT_APP_BASE_API_URL}/${path}`
              : user?.picture
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 10px",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: 16,
              fontWeight: 600,
              mb: 1,
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: "#50607A",
            }}
          >
            ({getAge(patient?.birthday)},{" "}
            {patient.gender?.charAt(0)?.toUpperCase() || "N/A"})
          </Typography>
        </div>
        <div
          style={{
            borderLeft: !isMobile && "1px solid #979797",
            height: !isMobile && "50px",
            marginLeft: "3rem",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 1rem",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#50607A",
              mb: 1,
            }}
          >
            Task Category/Conversation
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: "black",
            }}
          >
            {taskCategory}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 20px",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#50607A",
              mb: 1,
            }}
          >
            Care Program
          </Typography>
          <div style={{ display: "flex" }}>
            <div
              style={{
                position: "relative",
                borderRadius: "10px",
                overflow: "hidden",
                marginRight: "5px",
              }}
            >
              <Chip
                label={
                  patient.program.length > 0
                    ? patient.program[0]
                        .replace(/_/g, " ")
                        .split(/\s+/)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : "No Care Program Assigned"
                }
                size="small"
                sx={{
                  marginTop: "-0.2em",
                  color: "black",
                  backgroundColor:
                    patient.program.length > 0
                      ? "#e5feec"
                      : "rgba(255, 31, 0, 0.08)",
                  borderRadius: "8px",
                  position: "relative",
                }}
              />
              <LinearProgress
                variant="determinate"
                value={
                  patient.program.length > 0 ? 100 / patient.program.length : 0
                }
                sx={{
                  position: "absolute",
                  bottom: "2px",
                  left: "2px",
                  width: "calc(100% - 4px)",
                  borderRadius: "10px 0 0 10px",
                  height: "4px",
                  zIndex: 0,
                  backgroundColor:
                    patient.program.length > 0 ? "#8fdaa5" : "#D3602F",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#208b40", // Desired color
                  },
                }}
              />
            </div>
            <Tooltip
              title={
                patient.program.length > 1
                  ? patient.program
                      .slice(1) // Exclude the first index
                      .map((word) =>
                        word
                          .replace(/_/g, " ")
                          .split(/\s+/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      )
                      .join(", ")
                  : "No additional data"
              }
            >
              <Chip
                label={
                  "+" +
                  (patient.program.length > 0 ? patient.program.length - 1 : 0)
                }
                sx={{ borderRadius: "10px" }}
                size="small"
              />
            </Tooltip>
          </div>
        </div>
        {patient.surgeryDate != null && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px 20px",
              width: "150px",
            }}
          >
            <Typography
              variant="p"
              sx={{
                fontSize: 12,
                fontWeight: 600,
                color: "#50607A",
                mb: 1,
              }}
            >
              Phase of Care{" "}
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: "black",
              }}
            >
              {getCarePhase(patient.surgeryDate)}{" "}
            </Typography>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 5rem",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#50607A",
            }}
          >
            Acuity
          </Typography>
          <Typography fontSize={14} color="black">
            <span style={{ ...getColorStyles(acuity), fontSize: "24px" }}>
              •
            </span>

            {acuity?.charAt(0).toUpperCase() + acuity.slice(1)}
          </Typography>
        </div>
        {/* <div
					style={{
						display: 'flex',
						flexDirection: 'column',
						padding: '0px 20px',
					}}
				>
					<Typography
						variant="p"
						sx={{
							fontSize: 12,
							fontWeight: 600,
							color: '#50607A',
						}}
					>
						Risk Factors
					</Typography>
					<Typography fontSize={14} color="black">
						<GolfCourseOutlinedIcon sx={{ color: 'red', height: '15px', mt: 1 }} />
						Fall Risk
					</Typography>
				</div> */}
        {/* <CallOutlinedIcon sx={{ color: "#536878", ml: "10px" }} /> */}
      </Stack>
    </Paper>
  );
}
