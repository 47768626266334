import React from "react";
import { Button } from "@mui/material";
import { useIsMobile } from "../contexts/isMobile";

export function SolidButton({
  onClick,
  label,
  btnWidth,
  disabled = false,
  isOutlined,
  icon,
  ...props
}) {
  return (
    <Button
      size="medium"
      variant="contained"
      {...props}
      sx={{
        background: isOutlined ? 'inherit' : "#0049C6",
        px: "16px",
        borderRadius: "30px",
        fontSize: "14px",
        fontWeight: "bold",
        width: btnWidth,
        height: "35px",
        textTransform: "capitalize",
        color: isOutlined ? '#0049C6' : 'white',
        border: isOutlined && '1px solid #0049C6',
        marginRight: isOutlined && '8px',
        '&:hover': {
          color: isOutlined && 'white'
        }
      }}
      
      onClick={onClick}
      disabled={disabled}
    >
      {label} {icon && (<span style={{marginLeft:'5px'}}>{icon}</span>)}
    </Button>
  );
}
