import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { NodeMenuV2 } from "./NodeMenuV2";
import { EditDrawer } from "../Pages/Journeys/Drawers";
import { DeleteModal } from "./DeleteModal";
import { useDeleteJourneyNodeMutation } from "../store/journeysSlice";
import { Box, Tooltip } from "@mui/material";
import { SmsForm } from "./React-Flow-Form-V2";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import { AddDrawerV2 } from "./AddDrawerV2";
import { useChildNode } from "../contexts/addChildNode";
import { useGetAllSurveysQuery } from "../store/suerveySlice";
import { useSelector } from "react-redux";

export function NodeAccordionV2({
  data,
  nodeInternals,
  id,
  journeyID,
  nodeID,
  parentID,
  handleOpenModal,
  handleChange,
  expanded,
  setExpanded,
  setForceRenderNode,
  isEdit,
  setIsEdit,
  childNodeId,
  setChildNodeId,
  childDelayNodeId,
  setChildDelayNodeId,
  positiveChildNodeId,
  setPositiveChildNodeId,
  negativeChildNodeId,
  setNegativeChildNodeId,
  isfilled,
  hasChildNode,
  setAddChildNode,
  addChildNode,
  setAnchorEl: setAnchorElButton,
  isNextNodefilled,
  assignId,
  isConditionLastNode,
  handleDelete
}) {
  let height;
  const { setOpenDrawer, openDrawer, editMenu, openMenu, setEditMenu } = useChildNode();
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEdit, setOpenEdit] = React.useState({ id: null, state: false });
  const accordionRef = React.useRef(null);
  const [elements, setElements] = React.useState();
  const [accordianHeight, setAccordianHeight] = React.useState(52);
  const [checkConditionRender, setCheckConditionRender] = React.useState(false);
  const [selectedQuestionsRender, setSelectedQuestionsRender] = React.useState(
    []
  );
  const [selectedRemindersRender, setSelectedRemindersRender] = React.useState(
    []
  );

  const { user } = useSelector((state) => state.user)
  const { data: FormData } = useGetAllSurveysQuery(user.id)

  // Destructuring Mutation
  const [deleteJourneyNode] = useDeleteJourneyNodeMutation();

  // Functions:
  const toggleEditDrawer = (openEdit) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAnchorEl(null);
    setOpenEdit({ id: openEdit.id, state: openEdit.state });
  };

  const updateAccordianHeight = () => {
    const accordionElement = accordionRef.current;
    if (accordionElement) {
      setAccordianHeight(accordionElement?.clientHeight);
      return accordianHeight;
    }
  };

  const capitalizeLabel = (label) => {
    const specialCases = ["SMS", "FORM", "TASK", "TRIGGER", "ASSIGN_JOURNEY"];
    // Capitalize the label if it's in the special cases array
    if (specialCases.includes(label?.toUpperCase())) {
      return label.toUpperCase();
    }

    // Capitalize the first letter only
    return label?.charAt(0)?.toUpperCase() + label?.slice(1)?.toLowerCase();
  };


  // const handleAccordianExpand = (expandedNodeId) => {
  //   let expandedNodePosition = 0;
  //   let expandedNodeAbsolutePosition = 0;
  //   const keysArray = Array.from(nodeInternals.keys());
  //   const expandedNodeIndex = keysArray.indexOf(expandedNodeId);
  //   if (expanded) {
  //     for (const [key, value] of nodeInternals.entries()) {
  //       const index = keysArray.indexOf(key);
  //       if (expandedNodeIndex) {
  //         const expandedNodeData = nodeInternals.get(key);
  //         expandedNodePosition = expandedNodeData.position.y;
  //         expandedNodeAbsolutePosition = expandedNodeData.positionAbsolute.y;
  //       }
  //       if (index > expandedNodeIndex) {
  //         const expandedNode = nodeInternals.get(key);

  //         if (expandedNode && expandedNode.position) {
  //           if (index > expandedNodeIndex) {
  //             // Increasing the Y position of node when Selected Questions will increase
  //             const selectedQuestionsLength =
  //               selectedQuestionsRender?.length !== 0
  //                 ? 800 + selectedQuestionsRender.length * 600
  //                 : 800;
              
  //                 const selectedRemindersLength =
  //                 selectedRemindersRender?.length !== 0
  //                   ? 800 + selectedRemindersRender.length * 300
  //                   : 800;

  //                 const positionAbsoluteY = selectedQuestionsRender?.length !== 0 ? selectedQuestionsLength + 150 * index: selectedRemindersLength + 250 * index

  //                 expandedNode.positionAbsolute.y = positionAbsoluteY
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     let prevExpandedPosition = 0;
  //     let prevExpandedAbsolutePosition = 0;

  //     for (const [key, value] of nodeInternals.entries()) {
  //       const index = keysArray.indexOf(key);
  //       const unExpandedNode = nodeInternals.get(key);

  //       if (index === expandedNodeIndex - 1) {
  //         // Check if the previous node is expanded
  //         prevExpandedPosition = unExpandedNode.position.y;
  //         prevExpandedAbsolutePosition = unExpandedNode.positionAbsolute.y;
  //       }

  //       if (index >= expandedNodeIndex - 1) {
  //         // If the current node is expanded, adjust the position.y
  //         if (index === expandedNodeIndex - 1) {
  //           // This is the last expanded node
  //           unExpandedNode.position.y = prevExpandedPosition;
  //           unExpandedNode.positionAbsolute.y = prevExpandedAbsolutePosition;
  //         } else {
  //           // For other expanded nodes

  //           unExpandedNode.position.y =
  //             prevExpandedPosition + 150 * (index - expandedNodeIndex + 1);
  //           unExpandedNode.positionAbsolute.y =
  //             prevExpandedAbsolutePosition +
  //             150 * (index - expandedNodeIndex + 1);
  //         }
  //       } else {
  //         // If the current node is unexpanded, set position.y based on the default value
  //         if (index === keysArray.length - 1) {
  //           // If it is the last node, adjust the position by adding 150
  //           unExpandedNode.position.y = prevExpandedPosition + 150;
  //           unExpandedNode.positionAbsolute.y =
  //             prevExpandedAbsolutePosition + 150;
  //         } else {
  //           unExpandedNode.position.y = 150 * index;
  //           unExpandedNode.positionAbsolute.y = 150 * index;
  //         }
  //       }
  //     }
  //   }
  // };

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen)
};

const handleEditOnClick = (id) =>{
  if(!assignId){
    setIsEdit(true);
    setEditMenu(id);
    setOpenDrawer(true)
  }
}

  React.useEffect(() => {
    let initialData = [];
    const keysArray = Array.from(nodeInternals.keys());

    for (const [key, value] of nodeInternals.entries()) {
      const index = keysArray.indexOf(key);
      const expandedNode = nodeInternals.get(key);
      initialData.push({
        id: key,
        index,
        position: expandedNode?.position.y,
        positionAbsolute: expandedNode.positionAbsolute.y,
      });
    }

    setElements(initialData);
  }, []);

  //   REF: !assignId - Adding it to restrict from height change if click on accordion , if View Journey from Patient Profile.
//   React.useEffect(() => {
// 	if(!assignId){
// 		height = updateAccordianHeight();
// 		let expandedNode = expanded ? expanded?.split("-") : ["001", "002"];
// 		handleAccordianExpand(expandedNode[1]);
// 	}
//   }, [expanded, checkConditionRender, selectedRemindersRender]);

// //   This useEffect is to adjust journey once only.
//   React.useEffect(() => {
// 	if(assignId){
// 		height = updateAccordianHeight();
// 		let expandedNode = expanded ? expanded?.split("-") : ["001", "002"];
// 		handleAccordianExpand(expandedNode[1]);
// 	}
//   }, []);

  return (
    <div ref={accordionRef}>
      <Accordion
        expanded={false}
        // onChange={handleChange(`panel-${id}`)}
      >
        <AccordionSummary
          //   expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            "& .MuiAccordionSummary-content": {
              justifyContent: "space-between",
              alignItems:'center'
            },
          }}
          onClick={()=>handleEditOnClick(id)}
        >
          <div style={{ display: "flex" }}>
            <div>
              {(data?.title || data?.form?.title) && (
            <Typography
              variant="h2"
              sx={{
                fontSize: "13px",
                fontWeight: "600",
                textAlign: "left",
                marginTop: "4px",
                marginRight: "40px",
                color:'blue',
                // marginLeft:
                //   data.label !== "Start Journey"
                //     ? data.method === "sms" && data.label.length <= 2
                //       ? "40px"
                //       : data.label?.length > 17
                //       ? "2px"
                //       : "8px"
                //     : "35px",
                padding: (data.label?.length >= 17 || data?.form?.label?.length) && "3px",
              }}>
                { data?.title?.length >= 50 ? data.label.slice(0, 50) + "..." : data?.form?.title?.length >= 50 && data?.form?.method === data?.method ? data?.form?.label?.slice(0, 50) + "..."  : data?.form?.title && data?.form?.method === data?.method ? data?.form?.title : data?.title}</Typography>
              )}
              <Tooltip title={expanded !== `panel-${id}` && data?.label}>
            <Typography
              variant="h2"
              sx={{
                fontSize: "13px",
                fontWeight: "600",
                textAlign: data?.title || data?.form?.title ? "left" : 'center',
                marginTop: data?.title || data?.form?.title ? "10px" : '4px',
                marginBottom: '6px',
                marginRight: "40px",
                  // marginLeft:
                  //   data.label !== "Start Journey"
                  //     ? data.method === "sms" && data.label.length <= 2
                  //       ? "40px"
                  //       : data.label?.length > 17
                  //       ? "2px"
                  //       : "8px"
                  //     : "35px",
                padding: (data.label?.length >= 17 || data?.form?.label?.length) && "3px",
              }}
            >
              {data.method === "sms" && data.label.length >= 50
                ? data.label.slice(0, 50) + "..."
                : data?.form?.title && data?.label === 'form' ? FormData?.data?.find(item => item?._id === data?.extra)?.form?.title || capitalizeLabel(data?.form?.title)  : capitalizeLabel(data.label)}
            </Typography>
            </Tooltip>
            </div>

            {/* {expanded ? (
              <ExpandLessIcon
                style={{
                  backgroundColor: "#816179",
                  borderRadius: "50%",
                  color:'white'
                }}
              />
            ) : (
              <ChevronRightRoundedIcon
                style={{
                  backgroundColor: "#816179",
                  borderRadius: "50%",
                  color:'white'
                }}
              />
            )} */}
          </div>

          {!assignId && (
            <div style={{display:'flex', alignItems:'center'}}>
              {!data?.isFilled && (
              <Tooltip title="To proceed, please either save or delete the node.">
              <img src={require('../assets/alert.gif')} style={{width:'40px'}} alt="Alert Gif" />
            </Tooltip>
              )}
            <NodeMenuV2
              handleOpenModal={handleOpenModal}
              toggleEditDrawer={toggleEditDrawer({ id, state: true })}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setExpanded={setExpanded}
              isfilled={isfilled}
              hasChildNode={hasChildNode}
              setAddChildNode={setAddChildNode}
              id={id}
              checkCondition={ data?.method === 'call' ? true : (data?.checkCondition && data?.waitUntilReminderAreSend) || (data?.checkSubmission && data?.waitUntilReminderAreSend)}
              setAnchorElButton={setAnchorElButton}
              isNextNodefilled={isNextNodefilled}
              setAnchorE2={setAnchorEl}
              title={data?.title}
              nodeInternals={nodeInternals}
              isConditionLastNode={isConditionLastNode}
            />
            </div>
          )}

              {data.visited === true && data.label !== "Start Journey" && (
                <Box sx={{ color: "green" }}>
                  <Tooltip title="The node has been executed successfully" placement="right">
                  <CheckCircleIcon />
                  </Tooltip>
                </Box>
              )}

              {data.skipped === true && data.label !== "Start Journey" && (
                <Box sx={{ color: "grey" }}>
                  <Tooltip title="The node has been skipped" placement="right">
                  <SkipNextIcon />
                  </Tooltip>
                </Box>
              )}

              {data.nodeError === true && data.label !== "Start Journey" && (
                <Box sx={{ color: "#dc3545" }}>
                  <Tooltip title="The node execution failed" placement="right">
                  <CloseIcon />
                  </Tooltip>
                </Box>
              )}

              {data.currentNodeId === true &&
                data.label !== "Start Journey" && (
                  <Box sx={{ color: "#249f8f" }}>
                    <Tooltip title="The node is currently in process" placement="right">
                    <HourglassBottomIcon />
                    </Tooltip>
                  </Box>
                )}
        </AccordionSummary>
        <AccordionDetails>
          <SmsForm
            method={data?.method}
            data={data}
            id={id}
            nodeID={nodeID}
            nodeInternals={nodeInternals}
            setForceRenderNode={setForceRenderNode}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setExpanded={setExpanded}
            childNodeId={childNodeId}
            setChildNodeId={setChildNodeId}
            childDelayNodeId={childDelayNodeId}
            setChildDelayNodeId={setChildDelayNodeId}
            positiveChildNodeId={positiveChildNodeId}
            setPositiveChildNodeId={setPositiveChildNodeId}
            negativeChildNodeId={negativeChildNodeId}
            setNegativeChildNodeId={setNegativeChildNodeId}
            addChildNode={addChildNode}
            setAnchorEl={setAnchorEl}
            checkConditionRender={checkConditionRender}
            setCheckConditionRender={setCheckConditionRender}
            selectedQuestionsRender={selectedQuestionsRender}
            setSelectedQuestionsRender={setSelectedQuestionsRender}
            selectedRemindersRender={selectedRemindersRender} 
            setSelectedRemindersRender={setSelectedRemindersRender}
          />
        </AccordionDetails>
      </Accordion>

      <EditDrawer
        toggleDrawer={toggleEditDrawer}
        state={openEdit.state}
        title={data.label}
        id={openEdit.id}
        data={data}
      />
      {openDrawer && (editMenu === id || (id === openMenu)) && (
            <AddDrawerV2 state={openDrawer} toggleDrawer={toggleDrawer}  method={data?.method}
            data={data}
            id={id}
            nodeID={nodeID}
            nodeInternals={nodeInternals}
            setForceRenderNode={setForceRenderNode}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setExpanded={setExpanded}
            childNodeId={childNodeId}
            setChildNodeId={setChildNodeId}
            childDelayNodeId={childDelayNodeId}
            setChildDelayNodeId={setChildDelayNodeId}
            positiveChildNodeId={positiveChildNodeId}
            setPositiveChildNodeId={setPositiveChildNodeId}
            negativeChildNodeId={negativeChildNodeId}
            setNegativeChildNodeId={setNegativeChildNodeId}
            addChildNode={addChildNode}
            setAnchorEl={setAnchorEl}
            checkConditionRender={checkConditionRender}
            setCheckConditionRender={setCheckConditionRender}
            selectedQuestionsRender={selectedQuestionsRender}
            setSelectedQuestionsRender={setSelectedQuestionsRender}
            selectedRemindersRender={selectedRemindersRender} 
            setSelectedRemindersRender={setSelectedRemindersRender}
            handleDelete={handleDelete}
            isfilled={isfilled}
             />
      )}
      {/* <DeleteModal
        open={openModal}
        setOpen={setOpenModal}
        handle={handleDelete}
        journeyID={journeyID}
        nodeID={id}
        parentID={parentID}
        deleteText={
          data.checkCondition
            ? `Are you sure you want to delete this node? This will result in the deletion of all it's child nodes?`
            : ``
        }
      /> */}
    </div>
  );
}
