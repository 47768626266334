import {
  Grid,
  Stack,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Autocomplete,
  Tooltip
} from "@mui/material";
import { DateField, DateTimeField, SolidButton } from "../../../../Components";
import { useAddNewAppointmentMutation, useEditAppointmentMutation } from "../../../../store/appointmentsSlice";
import { useGetAllJourneysQuery } from "../../../../store/journeysSlice";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { useIsMobile } from "../../../../contexts/isMobile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useChildNode } from "../../../../contexts/addChildNode";

export default function  AppointmentModal  ({open,setOpen, fetchAgain,setEditData, editData, status }) {
  const { appointmentReferesh, setAppointmentReferesh } = useChildNode();
const isMobile=useIsMobile()
  const { user } = useSelector((state) => state.user);
  const { data: journeyListData } = useGetAllJourneysQuery({id:user.id , params : 'exclude=surgery'});
  const [triggerJourney, setTriggerJourney] = useState({ id: null, name: "" });
  const [delayValue, setDelayValue] = useState(5);
  const [metric, setMetric] = useState('hours');
  const { id } = useParams();
  const [dateTime, setDateTime] = useState('');
  const [form, setForm] = useState({
    reasonForVisit: "",
    completed: false,
    providerDetails: "",
    patient: id,
    location: "",
    careTeamMember: user.id
  });
  const [addNewAppointment, {isLoading: isAddAppointmentLoading}] = useAddNewAppointmentMutation();
  const [editAppointment, {isLoading: isEditAppointmentLoading}] = useEditAppointmentMutation();

  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const resetAllValues = () => {
    setTriggerJourney({ id: null, name: "" })
    setDateTime('')
    setForm({
      reasonForVisit: "",
      completed: false,
      providerDetails: "",
      patient: id,
      location: "",
      careTeamMember: user.id
    })
    setEditData({})
  }


  const createAppointment = async (e) => {
    if(status === 'active'){
      if (
        dateTime === '' ||
        form.reasonForVisit === '' ||
        form.providerDetails === '' ||
        form.location === '' ||
        delayValue === '' ||
        metric === ''
      ) {
        toast.error("Please fill out the required fields");
        return;
      }
  
      if(!editData?._id){
        await addNewAppointment({
          ...form,
          dateTime,
          delayValue,
          delayMetric: metric,
          journeyId: triggerJourney.id,
        })
          .unwrap()
          .then(() => {
            fetchAgain();
            resetAllValues();
            setOpen(false);
            if(triggerJourney.id){
              toast.success("Appointment created and Journey assigned successfully!")
              setAppointmentReferesh(appointmentReferesh + 1)
            }else{
              toast.success("Appointment created successfully!")
            }
          })
          .catch((error) => {
            if (error?.status === 400) {
              toast.error(error?.data?.error);
            } else {
            toast.error(error.data || error?.data?.error);
            }
          });
      }else{
        const body ={
          ...form,
          dateTime,
          delayValue,
          delayMetric: metric,
          journeyId: triggerJourney.id
        }
       await editAppointment({id:editData?._id, body})
       .unwrap()
       .then(() => {
         fetchAgain();
         setAppointmentReferesh(appointmentReferesh + 1)
         resetAllValues();
         setOpen(false);
         toast.success("Successfully Edited!")
       })
       .catch((error) => {
         toast.error(error.data || error?.data?.error);
       });
      }
    }else{
      toast.error('The patient status is currently inactive.');
      resetAllValues();
      setOpen(false);
    }
  };

const handleClose=()=>{
  resetAllValues();
  setOpen(false)
}

useEffect(()=>{
  if(editData){
    const filteredJourney = journeyListData?.data?.find((item) =>item?.title === editData?.assignedJourneyName )
    setForm({
        reasonForVisit: editData?.reasonForVisit || "",
        completed: editData?.completed || false,
        providerDetails: editData?.providerDetails || "",
        patient: id,
        location: editData?.location ||"",
        careTeamMember: user.id
    })

    setDateTime(new Date(editData?.dateTime) || "")
    setMetric(editData?.delayMetric || "hours")
    setTriggerJourney({
      id: filteredJourney?._id,
      name: filteredJourney?.title
    })

  }
},[editData])
  return (
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
    <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "80%" : 700,
          height:isMobile ? "70%" :"65%",
          bgcolor: "#F5F5F5",
          p: 4,
        overflowY:"auto",
          borderRadius: "10px",
        }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
           Appointments
          </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => handleClose()}>
          <CloseIcon />
        </Box>
      </Stack>
      <Box
        component="form"
      
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            xs={12}
           
          >
            <DateTimeField dateTime={dateTime} setDateTime={setDateTime} {...{label:"Appointment Date"}}/>
          </Grid>
          <Grid item md={6} xs={12}>
          <Typography fontSize={12} color={"#979797"} mt={2}>
            Location
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            name="location"
         
            value={form.location}
            onChange={onChange}
          />
           
          </Grid>
          <Grid item md={6} xs={12}>
          <Typography fontSize={12} color={"#979797"} mt={2}>
          Reason For Visit
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            name="reasonForVisit"
            multiline
            rows={4}
            value={form.reasonForVisit}
            onChange={onChange}
          />
            
          </Grid>
          <Grid item md={6} xs={12}>
          <Typography fontSize={12} color={"#979797"} mt={2}>
          Provider Details
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            name="providerDetails"
            multiline
            rows={4}
            value={form.providerDetails}
            onChange={onChange}
          />
            
          </Grid>
          {(Object.keys(editData).length === 0 || !editData?.assignedJourneyId) && (
          <Grid item md={6} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography fontSize={12} color={"#979797"} mb={1}>
              Select Journey
              </Typography>
              <Tooltip title="Select journey to link with this appointment" placement="right-start">
                  <Box
                    sx={{ color: "grey", paddingLeft: "3px", cursor: "pointer" }}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </Box>
                </Tooltip>
            </Box>
              <Autocomplete
                value={triggerJourney.name}
                onChange={(e, newvalue) =>{
                  setTriggerJourney({
                    id:  journeyListData?.data?.find(
                      (item) => item.title === newvalue
                    )?._id,
                    name: newvalue,
                  })}
                }
                options={journeyListData?.data?.map((item) => item?.title)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid>
          )}
          {/* <Grid item md={6} xs={12}> */}
            {/* <DelaySelect metric={metric} setMetric={setMetric} /> */}
          {/* </Grid> */}
          {/* <Grid item md={6} xs={12}> */}
          {/* <Typography fontSize={12} color={"#979797"} mt={2}> */}
         {/* {`${metric}`} */}

          {/* </Typography> */}
          {/* <TextField */}
            {/*  variant="standard"
             fullWidth
            name="title"
             value={delayValue}
           onChange={(e) => {
              setDelayValue(e.target.value);
             }}
          /> 
           
          </Grid> */}
          {/* <Grid item md={6} xs={12}>
            
            <Typography fontSize={12} mt={2}>
            Journey to Assign              </Typography>
            <Autocomplete
              value={triggerJourney.name}
               onChange={(e, newValue) =>{
              setTriggerJourney({
                  id: journeyListData?.data?.find((item) => item._id === newValue)?._id,
                  name: newValue
                })}}
              options={journeyListData?.data?.map((item) =>item?.title)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
              
           
          </Grid> */}
        </Grid>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          sx={{ position:"fixed", bottom:'1rem' , right: "1rem"}}
        >
          <SolidButton label="Save" onClick={createAppointment} disabled={isAddAppointmentLoading || isEditAppointmentLoading} />
        </Stack>
      </Box>
    </Box>
    </Modal>

  );
};

// export function DelaySelect({ metric, setMetric }) {
//   const handleChange = (e, newValue) => {
//     setMetric(newValue);
//   };

//   return (
//     <Box>
//       <Typography fontSize={12} mt={2}>
//       Time Delay
//             </Typography>
//             <Autocomplete
//               value={metric}
//               onChange={(e, newValue)=>handleChange(e, newValue)}
//               options={[
//             { id: "minutes", label: "Minutes" },
//             { id: "hours", label: "Hours" },
//             { id: "days", label: "Days" },
//             { id: "weeks", label: "Weeks" },
//             { id: "months", label: "Months" },
//           ].map((item) =>
           
//               item.label
          
//           )}
//               renderInput={(params) => (
//                 <TextField {...params} variant="standard" />
//               )}
//             />
      
//     </Box>
//   );
// }