import { Box, Grid, IconButton, Typography, Fab } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import OutlinedButton from "../../Components/OutlinedButton";
import { ReasonDropdown } from "./ReasonDropdown";
import { SolidButton } from "../../Components";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { toast } from "react-toastify";
import axios from "axios";
import { getTokenFromLocalStroage } from "../../utils";
import { useIsMobile } from "../../contexts/isMobile";

export default function UploadImageModal({
  patientImages,
  setPatientImages,
  patientImagesBody, 
  setPatientImagesBody,
  reasonBool,
  setReasonBool,
  scrollDown,
  handleFinish,
}) {
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageArray, setSelectedImageArray] = useState([]);
  const [reason, setReason] = useState("");
  const [inputReason, setInputReason] = useState("");
  const [active, setActive] = useState(false);
  const [filterdReason, setFilterdReason] = useState(null);

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setSelectedImageArray([...selectedImageArray, file]);
    setTimeout(() => {
      setReasonBool(!reasonBool);
    }, 200);
  };

  const handleTakePicture = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: "environment",
        },
      })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        setActive(true);
        setSelectedImage(null);
      })
      .catch((error) => {
        console.error("Error accessing the camera: ", error);
      });
  };

  const takePicture = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      const fileFromBlob = new File([blob], "captured-image.png", {
        type: "image/png",
      });
      setSelectedImage(fileFromBlob);
      setSelectedImageArray([...selectedImageArray, fileFromBlob]);
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      const objectURL = URL.createObjectURL(blob);
      setActive(false);
    }, "image/png");

    setTimeout(() => {
      setReasonBool(!reasonBool);
    }, 200);
  };

  // const handleClose = () => {
  // videoRef?.current?.srcObject?.getTracks().forEach((track) => track.stop())
  // setActive(false)
  // setSelectedImage(null)

  // }

  const handleUpload = async () => {
    try {
      if (!selectedImage || !filterdReason) {
        toast.error("Please fill in all fields!");
        return;
      }

      setLoading(true);

      // Create a new FormData object
      const body = new FormData();

      // Append each image to the FormData object
      body.append("asset[]", selectedImageArray[selectedImageArray.length - 1]);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/v2/asset/upload`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${getTokenFromLocalStroage()}`,
          },
        }
      );

      if (response?.data?.uploadedImages) {
        const imageUrl = response?.data?.uploadedImages[0]?.imageUrl;
        const imageKey = response?.data?.uploadedImages[0]?.imageKey
        setPatientImages((patientImages) => [
          ...patientImages,
          { reason: filterdReason, imageUrl },
        ]);
        setPatientImagesBody((patientImages) => [
          ...patientImages,
          { reason: filterdReason, imageKey },
        ]);

        toast.success("Image uploaded! Add more or select Finish below.");
        setSelectedImage(null);
        setReason("");
        setLoading(false);
        setInputReason("");
        setFilterdReason(null);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.log("Error:", error.message);
      toast.error("Error:", error.message);
    }
  };

  const [components, setComponents] = useState([{ id: 0 }]); // Initial state with one component

  // Function to add more components
  const addMoreComponents = () => {
    setComponents((prevComponents) => [
      ...prevComponents,
      { id: prevComponents.length },
    ]);
    setReasonBool(!reasonBool);
  };

  useEffect(() => {
    if (reason) {
      const reasoning = reason === "Other" ? inputReason : reason;
      setFilterdReason(reasoning);
    }
  }, [reason, inputReason]);
  return (
    <>
      {components.map((component, index) => (
        <Box
          sx={{
            mt: "1rem",
            width: isMobile ? "70%" : 500,
            bgcolor: "#F5F5F5",
            p: 4,
            ml: isMobile ? 2 : 0,
            borderRadius: "10px",
          }}
          key={component.id}
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item md={12} xs={12}>
              {!patientImages[index]?.imageUrl && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <IconButton
                    sx={{
                      border: "1px dotted #979797",
                      borderRadius: "10px",
                      width: "49%",
                    }}
                    onClick={() => fileInputRef.current.click()}
                  >
                    <PhotoCameraIcon />
                    <Typography sx={{ ml: "5px" }}>Choose Image</Typography>
                  </IconButton>
                  <IconButton
                    sx={{
                      border: "1px dotted #979797",
                      borderRadius: "10px",
                      width: "49%",
                    }}
                    onClick={handleTakePicture}
                  >
                    <AddPhotoAlternateIcon />
                    <Typography sx={{ ml: "5px" }}> Take a Picture</Typography>
                  </IconButton>
                </div>
              )}

              {!patientImages[index]?.imageUrl && selectedImage && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <img
                    alt="Uploaded"
                    width={"250px"}
                    height={"250px"}
                    src={URL.createObjectURL(selectedImage)}
                  />
                </div>
              )}

              {patientImages[index]?.imageUrl && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <img
                    alt="Uploaded"
                    width={"250px"}
                    height={"250px"}
                    src={patientImages[index]?.imageUrl}
                  />
                </div>
              )}

              <input
                type="file"
                name="asset[]"
                accept="image/*"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleUploadImage}
              />
              {!patientImages[index]?.imageUrl && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <video
                    ref={videoRef}
                    style={{
                      width: "100%",
                      height: active ? "250px" : "0px",
                    }}
                    autoPlay
                    playsInline
                  />
                </div>
              )}
              {active && !patientImages[index]?.imageUrl && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <OutlinedButton
                      label={"Capture"}
                      handleClick={takePicture}
                    />
                  </div>
                </>
              )}
            </Grid>
            <Grid item md={12} xs={12} textAlign={"left"}>
              {patientImages[index]?.reason ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography fontSize={"16px"} fontWeight={600}>
                    Reason:
                  </Typography>
                  <Typography fontSize={"16px"} sx={{ marginLeft: "5px" }}>
                    {patientImages[index]?.reason}
                  </Typography>
                </div>
              ) : (
                <>
                  <ReasonDropdown
                    {...{
                      reason,
                      setReason,
                      inputReason,
                      setInputReason,
                      reasonBool,
                      setReasonBool,
                    }}
                  />
                </>
              )}
            </Grid>
            {index === components.length - 1 && (
              <Grid item md={12} xs={12}>
                {!patientImages[index]?.imageUrl ? (
                  selectedImageArray[index] &&
                  filterdReason && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {loading ? (
                        <OutlinedButton label={"Loading..."} disabled />
                      ) : (
                        <SolidButton label={"Upload"} onClick={handleUpload} />
                      )}
                    </div>
                  )
                ) : (
                  <div
                  style={{
                    position: "sticky",
                    top: "0",
                    marginTop: "2rem",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {components.length < 5 && (
                    <>
                      <SolidButton label={'Add More'} onClick={addMoreComponents} /> &ensp;
                    </>
                  )}
                  <SolidButton label={'Finish'} onClick={handleFinish} />
                </div>
                
								)}
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
    </>
  );
}
