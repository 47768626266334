import React, { useState, useEffect } from 'react'
import { Grid, TextField, Button, IconButton, CircularProgress, Typography, Skeleton, Box } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useSelector } from 'react-redux'
import { useGetAllContentsQuery } from '../../store/contentsSlice'
import { useSendImageLinkMutation } from '../../store/patientsSlice'
import { ContentModal } from './ContentModal'
import { ConfirmationModal } from './ConfirmationModal'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { toast } from 'react-toastify'
import { useAddChatMutation } from '../../store/chatSlice'
import { useAddCareAllyChatMutation } from '../../store/careAllysSlice'
import {useSendMessageByAgentIdMutation} from "../../store/agentsSlice"
import axios from 'axios'
import { getTokenFromLocalStroage } from '../../utils'
import { useChatValues } from "../../contexts/chatValue";
import { useAssistantChatValues } from '../../contexts/assistantChatValue'

export const SendMessageField = React.memo(
	({
		id,
		setPage,
		scrollDown,
		chatBot,
		consent,
		refetchAgain,
		isCareAlly,
		status,
		careAllyData,
		showLoader,
		aiGeneratedMessage = '',
		setAiGeneratedMessage = '',
		patientQuestion = '',
		setPatientQuestion = '',
		value,
		AiAssistantValue,
		agentId,
		isAIAssistant,
		include_revel_content, 
		assistantPrompt
	}) => {
		const { user } = useSelector((state) => state.user)
		const {updateMessage, setChatMessages, setIsAILoading} = useChatValues()
		const {postMessage} = useAssistantChatValues()

		// States
		const [url, setUrl] = useState('')
		const [openModal, setOpenModal] = useState(false)
		const [inputMessage, setInputMessage] = React.useState(aiGeneratedMessage)
		const [open, setOpen] = React.useState(false)
		const captureFeedback = aiGeneratedMessage === '' ? false : true

		useEffect(() => {
			setInputMessage(aiGeneratedMessage)
		}, [aiGeneratedMessage])

		// Integrating API's
		const { data } = useGetAllContentsQuery(user.id)
		const [sendImageLink] = useSendImageLinkMutation()
		const [addChat] = useAddChatMutation()
		const [addCareAllyChat] = useAddCareAllyChatMutation()
		const [sendMessageByAgentId, {isLoading}] = useSendMessageByAgentIdMutation()

		// Functions
		const handleOpenCreateModal = () => {
			setOpen(true)
		}
		const handleOpenConfirmation = () => {
			setOpenModal(true)
		}

		const config = {
			headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
		}

		const sentMessage = async () => {
			if(value === AiAssistantValue){
				if(agentId === null){
					toast.warning('Please select available assistant!')
					return;
				}

				if(isAIAssistant){
					const body = {
						prompt: assistantPrompt,
						assistantId: agentId,
						include_revel_content,
					}
					postMessage(inputMessage, body, config)
				}else{
					const userResponse = updateMessage(inputMessage)
					sendMessageByAgentId({ agentId, patientId: id, agent_messages: userResponse })
					.then((response) => {
						if (response?.error?.status === 400) {
							toast.error(response?.error?.data?.message)
							return;
						  } else {
							// Successful response
							setChatMessages([
								...userResponse, 
								{ content: response.data.chatbotReply, role: 'assistant' }
							  ]);
						  }
					})
					.catch((error) => {
					  console.error('Error:', error);
					});
				}
			}else{
				if (!isCareAlly) {
					if (consent !== 'yes') {
						toast.error('Patient has not provided consent yet')
						return
					} else if (status === 'active') {
						addChat({
							body: inputMessage.replace(/\n/g, '\r\n'),
							pid: id,
							senderId: 'panal',
							sentBy: 'panal',
							chatbot: chatBot,
						})
	
						if (captureFeedback) {
							await axios
								.post(
									`${process.env.REACT_APP_BASE_API_URL}/v2/messageFeedback`,
									{
										patientQuestion,
										aiGeneratedMessage,
										userEdits: inputMessage,
										patientId: id,
										userId: user.id,
									},
									config
								)
								.then((res) => {
									setAiGeneratedMessage('')
									setPatientQuestion('')
								})
						}
	
						refetchAgain()
						setInputMessage('')
						setPage(1)
						scrollDown()
					} else {
						toast.error('The patient status is currently inactive.')
						setInputMessage('')
					}
				} else {
					if (careAllyData?.consentResponse !== 'yes') {
						toast.error('Care ally has not provided the consent yet!')
						return
					} else {
						const body = {
							body: inputMessage.replace(/\n/g, '\r\n'),
							senderId: 'panal',
							sentBy: 'panal',
						}
	
						addCareAllyChat({ id: careAllyData?._id, body })
	
						if (captureFeedback) {
							await axios
								.post(
									`${process.env.REACT_APP_BASE_API_URL}/v2/messageFeedback`,
									{
										patientQuestion,
										aiGeneratedMessage,
										userEdits: inputMessage,
										patientId: id,
										userId: user.id,
									},
									config
								)
								.then((res) => {
									setAiGeneratedMessage('')
									setPatientQuestion('')
								})
						}
						refetchAgain()
						setInputMessage('')
						setPage(1)
						scrollDown()
					}
				}
			}
		}

		const handleKeyPress = (e) => {
			if (e.key === 'Enter' && !e.shiftKey) {
				e.preventDefault()
				const cursorPosition = e.target.selectionStart
				const text = inputMessage
				const beforeCursor = text.substring(0, cursorPosition)
				const afterCursor = text.substring(cursorPosition, text.length)
				setInputMessage(beforeCursor + '\n' + afterCursor)
			}
		}

		const handleConfirmModal = async () => {
			if (status === 'active' || value === 2) {
				try {
					const response = await sendImageLink({ id, uploadBy: value === 0 ? 'patient' : 'careAlly' })
					if (response?.error?.data?.error) {
						toast.error('Something went wrong!')
					} else {
						toast.success('Image link sent to patient successfully!')
						refetchAgain()
						setOpenModal(false)
					}
				} catch (error) {
					console.log(error)
					toast.error('Error:', error.message)
				}
			} else {
				toast.error('The patient status is currently inactive.')
				setOpenModal(false)
			}
		}

		const handleTextField = (e) => {
			setInputMessage(e.target.value)
		}

		useEffect(()=>{
			setIsAILoading(isLoading)
		},[isLoading])

		return (
			<>
				<Grid alignItems="center">
					<Grid item xs={12} sm={12}>
						{showLoader ? (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									padding: '20px',
								}}
							>
								{/* <CircularProgress color="inherit" size={40} style={{ marginRight: "8px" }} /> */}
								<Typography fontSize={16} fontWeight={'bold'}>AI is Generating Response</Typography>
								<Box sx={{ width: '100%' }}>
									<Skeleton />
									<Skeleton animation="wave" />
									<Skeleton animation={false} />
								</Box>
							</Box>
						) : (
							<TextField
								placeholder="Write your message..."
								value={inputMessage}
								onChange={(e) => handleTextField(e)}
								multiline
								maxRows={12}
								onKeyPress={handleKeyPress}
								InputProps={{
									disableUnderline: true,

									style: { borderRadius: '10px', padding: '10px', minHeight: '6rem' },
									endAdornment: (
										<>
										{value !== AiAssistantValue && (
											<>
											<IconButton onClick={handleOpenConfirmation}>
												<AddAPhotoIcon />
											</IconButton>
											<IconButton onClick={handleOpenCreateModal}>
												<AttachFileIcon />
											</IconButton>
											</>
										)}
											<Button
												onClick={() => {
													sentMessage()
													setInputMessage('')
													setUrl('')
												}}
												size="medium"
												variant="contained"
												sx={{
													background: '0049C6',
													borderRadius: '30px',
													textTransform: 'capitalize',
													fontSize: '14px',
													fontWeight: 600,
													padding: 1,
												}}
											>
												Send
											</Button>
										</>
									),
								}}
								sx={{
									width: '100%',
									padding: '10px 0px',
									'& .MuiInputBase-inputMultiline': {
										overflowY: 'auto',
										'&::-webkit-scrollbar': {
											display: 'none', // Hide scrollbar for WebKit browsers
										},
										scrollbarWidth: 'none',
									},
								}}
							/>
						)}
					</Grid>
				</Grid>
				<ContentModal
					open={open}
					setOpen={setOpen}
					setUrl={setUrl}
					url={url}
					message={inputMessage}
					setMessage={setInputMessage}
					title={'Choose Content'}
					data={data?.data || []}
				/>
				<ConfirmationModal
					open={openModal}
					setOpen={setOpenModal}
					txt={'The secure link will be sent to the patient to upload images. Do you wish to proceed with this?'}
					handle={handleConfirmModal}
					id={id}
				/>
			</>
		)
	}
)
