import React from 'react';
import { getBezierPath, MarkerType } from 'reactflow';

import useEdgeClick from '../hooks/useEdgeClick';
import styles from './EdgeTypes.module.css';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
}) {
  const onClick = ()=>alert("This");
  // const onClick = useEdgeClick(id);

  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path id={id} style={style} className={styles.edgePath} d={edgePath} markerEnd={markerEnd} />
    </>
  );
}