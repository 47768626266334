import React from "react";
import ReactAudioPlayer from "react-audio-player";

export function AudioPlayer({ url }) {
  return (
    <>
      <ReactAudioPlayer src={url} controls />
    </>
  );
}
