import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPhysicians: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10 , ...rest } = query;
        const finalQuery = { page, limit , ...rest };

        let params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/v2/physician?${params.toString()}`;
      }
    }),
    getPhysiciansOptions: builder.query({
      query: () => `/v2/physician/options`,
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Physicians", id: "LIST" },
          ...data.map((p) => ({ type: "Physicians", id: p._id })),
        ];
      },
    }),
    getSelfEnrolledPhysiciansOptions: builder.query({
      query: (id) => `/v2/physician/selfEnrollmentOption/${id}`,
      providesJourneys: ({ data }, error, arg) => {
        return [
          { type: "Physicians", id: "LIST" },
          ...data.map((p) => ({ type: "Physicians", id: p._id })),
        ];
      },
    }),
    addNewPhysician: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/v2/physician",
          method: "POST",
          body: initialPost
        };
      },
      invalidatesTags: [{ type: "Physician", id: "LIST" }],
    }),
    editPhysician: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/v2/physician/${id}`,
          method: "PUT",
          body,
        };
      }
    }),
    deletePhysician: builder.mutation({
      query: (id) => {
        return {
          url: `/v2/physician/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetPhysiciansQuery,
  useGetPhysiciansOptionsQuery,
  useAddNewPhysicianMutation,
  useEditPhysicianMutation,
  useDeletePhysicianMutation,
  useGetSelfEnrolledPhysiciansOptionsQuery
} = extendedApiSlice;
