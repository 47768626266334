import { Box, Paper, Typography } from '@mui/material'
import { DoughnutChart } from './DoughnutChart'

export function PieChart({ heading, setAllMetric, allMetric, labels, formData, colors, query, backendData }) {
	return (
		<Paper
			sx={{
				width: '95%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				marginTop: '20px',
				borderRadius: '10px',
				border: '1px',
				padding: '12px'

			}}
			elevation={1}
		>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<DoughnutChart {...{ allMetric, setAllMetric, heading, labels, formData, colors, query, backendData }} />
			</Box>
			{/* <Box sx={{ marginBottom: '10px' }}>
				<Typography sx={{ textAlign: 'center', fontSize: '14px', fontWeight: 'bold' }}>{heading}</Typography>
			</Box> */}
		</Paper>
	)
}
