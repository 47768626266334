import React from 'react'
import { Modal, Box, Typography, IconButton, Divider, Select, MenuItem, FormControl, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SolidButton } from '../../Components'

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	maxHeight: '80vh',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	overflowY: 'auto',
	borderRadius: '12px',
}

const textareaContainerStyle = {
	mt: 2,
}

const buttonContainerStyle = {
	display: 'flex',
	justifyContent: 'flex-end',
	gap: 1,
	marginTop: 3,
}

const GenerateNote = ({ open, handleClose, onSubmit, notes }) => {
	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={modalStyle}>
				<Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 400, fontSize: '1.5rem' }}>
					{notes ? 'Billing Notes' : 'Generate Clinical Notes'}
				</Typography>
				<Divider sx={{ mt: 2, mb: 2 }} />

				<Box sx={textareaContainerStyle}>
					<TextField
						multiline
						minRows={4}
						placeholder={notes ? 'Generate Notes goes here...' : 'Generate Clinical goes here...'}
						fullWidth
						variant="outlined"
						label={notes ? 'Billing Notes' : 'Generate Clinical Notes'}
						sx={{ mt: 1 }}
					/>
				</Box>

				<Box sx={buttonContainerStyle}>
					{notes ? '' : <SolidButton isOutlined={true} label={'Cancel'} onClick={handleClose} />}
					<SolidButton
						label={notes ? 'OK' : 'Save'}
						onClick={() => {
							handleClose()
						}}
					/>
				</Box>
			</Box>
		</Modal>
	)
}

export default GenerateNote
