import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarePathwayTable: builder.query({
        query: (query = {}) => {
            const { page = 1, limit = 10 , ...rest } = query;
            const finalQuery = { page, limit , ...rest };
  
            let params = new URLSearchParams();
            if (page) {
              for (const [key, value] of Object.entries(finalQuery)) {
                params.append(key, value);
              }
            }
  
          return `/carePathway/all?${params.toString()}`;
        },
      }),
    getCarePathway: builder.query({
      query: (query = {}) => {
        //   const { page = 1, limit = 10 , ...rest } = query;
        //   const finalQuery = { page, limit , ...rest };

        //   let params = new URLSearchParams();
        //   if (page) {
        //     for (const [key, value] of Object.entries(finalQuery)) {
        //       params.append(key, value);
        //     }
        //   }

        return `/carePathway`;
      },
    }),
    addNewCarePathway: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/carePathway",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Care Pathway", id: "LIST" }],
    }),
    editCarePathway: builder.mutation({
        query: ({ id, body }) => {
          return {
            url: `/carePathway/${id}`,
            method: "PUT",
            body,
          };
        }
    }),
    deleteCarePathway: builder.mutation({
    query: (id) => {
        return {
        url: `/carePathway/${id}`,
        method: "DELETE",
        };
    },
    }),
  }),
});

export const { useGetCarePathwayQuery, useGetCarePathwayTableQuery, useAddNewCarePathwayMutation, useDeleteCarePathwayMutation, useEditCarePathwayMutation } =
  extendedApiSlice;
