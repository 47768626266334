import { baseApiSlice } from "./baseApiSlice";


export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (id) => `/notifications/${id}`,
      // query: (query = {}) => {
      //   const { page = 1, limit = 10,...rest } = query;
      //   const finalQuery = { page, limit,...rest };

      //   let params = new URLSearchParams();
      //   if (page) {
      //     for (const [key, value] of Object.entries(finalQuery)) {
      //       params.append(key, value);
      //     }
      //   }

      //   return `/teams/?${params.toString()}`;
      // },
      providesTags: ({ data }, error, arg) => {
        return [
          { type: "Notfications", id: "LIST" },
          ...data.map((p) => ({ type: "Notfications", id: p._id })),
        ];
      },
    }),
    readNotification: builder.mutation({
      query: (id) => {
        return {
          url: `/notifications/${id}`,
          method: "PUT"
        };
      },
      invalidatesTags: ({ data }, error, { id }) => {
        return [
          { type: "Notifications", id: "LIST" },
          { type: "Notifications", id: data.id || id },
        ];
      },
    }),
    viewNotification: builder.mutation({
      query: () => {
        return {
          url: `/notifications`,
          method: "PUT"
        };
      },
      invalidatesTags: ({ data }, error, { id }) => {
        return [
          { type: "Notifications", id: "LIST" },
          { type: "Notifications", id: data.id || id },
        ];
      },
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useViewNotificationMutation
} = extendedApiSlice;
