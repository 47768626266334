import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CircularLoader, PaginationBar } from "../../../Components";
import { useNotAllowedRole } from "../../../hooks";
import moment from "moment"
import { useNavigate } from "react-router-dom";


const createColumns = (isNotAllowed) => [
  { id: "Srno", label: "Index", minWidth: 170 },
  {
    id: "title",
    label: "Title",
    minWidth: 170,
    align: "right",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 170,
    align: "right",
  },
  {
    id: "createdAt",
    label: "Created On",
    minWidth: 170,
    align: "right",
  },
  {
    id: "teamName",
    label: "Assigned Team",
    minWidth: 170,
    align: "right",
  }
];

const createRows = ({ tasks, handleRedirect }) => {
  const rows = tasks?.map(
    ({ _id, createdAt, taskId, team,patientId }, index) => {
    
    // Destructing the taskId and team as they are populated with their fields too. 
     const  {title, description} = taskId;
     const name = team ? team.name : "N/A"

      return {
        Srno: (
          <Typography
            variant="p"
            sx={{ fontSize: 16, fontWeight: 400, color: "text.secondary" }}
          >
            {index + 1}
          </Typography>
        ),
        title : (
          <Typography
          variant="p"
          sx={{
            fontSize: 16,
            fontWeight: 400,
            color: "text.secondary",
            cursor: "pointer",
            "&:hover": {
              color: "#816179",
            },
          }}
          onClick={(e) => handleRedirect(e,patientId)}
        >
          {title}
        </Typography>
        ),
        description: description?.length > 28 ? description?.slice(0, 28) + "....." : description,
        createdAt: moment(createdAt).format("MM-DD-YYYY"),
        teamName : name
      };
    }
  );

  return rows;
};


export function TaskTable({ page, setPage, data,isLoading, isError }) {

  const isNotAllowed = useNotAllowedRole()
  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleRedirect = (e, id) => {
    navigate(`/profile/${id}`, { state: { action: "read-only", id } });
  };

  const columns = createColumns(isNotAllowed)
  const rows = createRows({ tasks: data?.data || [], handleRedirect });

  if (isLoading) return <div><CircularLoader/></div>;
  if (isError) return <div>some error</div>;
  if (data?.data?.length === 0) {
    return <Typography sx={{margin:"10px 20px"}}>No Patient Task found.</Typography>;
  }
  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (column.hide) return null
                return (
                  <TableCell
                    variant="head"
                    key={column.id}
                    align={"center"}
                    sx={{ fontWeight: 600, borderBottom: "1px solid #979797" }}
                  >
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              if (row.hide) return null
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ color: "text.secondary", border: "none" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBar
        {...{ page, count: data.totalPages, handleChange: handleChangePage }}
      />
    </>
  );
}
