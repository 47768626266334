import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import { useEffect, useState } from "react";
import PopoverQueueUI from "../FilterUtils/PopoverQueueUI";
import { useGetPatientQueueBadgeCountQuery } from "../../../store/patientQueuesSlice";
import { useLocation } from "react-router-dom";
import {addInParams} from "../../../utils"

const dummyTabData = [
  { count: "31", label: "Messages", key: "message" },
  { count: "2", label: "Photo Uploads", key: "new_media" },
  { count: "3", label: "Forms", key: "form_submission" },
  { count: "4", label: "Care Ally Messages", key: "care_ally_message" },
  { count: "5", label: "Call", key: "call" },
  { count: "5", label: "Dialogue Assessments", key: "dialogue" },
  { count: "5", label: "Tasks", key: "task" },
];

const getCountByKey = (key, counts) => {
  switch (key) {
    case "message":
      return counts.messageCount;
    case "new_media":
      return counts.newMediaCount;
    case "form_submission":
      return counts.formSubmissionCount;
    case "care_ally_message":
      return counts.careAllyMessageCount;
    case "dialogue":
      return counts.dialoguesCount;
    case "call":
      return counts?.callCount || 0;
    case "task":
      return counts?.taskCount || 0;
    default:
      return 0; // Default fallback value
  }
};

const timeFrameData = [
  {
    title: "All",
    value: "all",
  },
  {
    title: "Today",
    value: "today",
  },
  {
    title: "Yesterday",
    value: "yesterday",
  },
  {
    title: "Last 7 Days",
    value: "7",
  },
  {
    title: "Last 30 Days",
    value: "30",
  },
];

export default function QueueTabsV2({queries, setQueries, selectedValue, setSelectedValue, isNotDashboard}) {
  const { data, isLoading, refetch } = useGetPatientQueueBadgeCountQuery()
  const [anchorEl, setAnchorEl] = useState(null);

  const location = useLocation()

  // Create a URLSearchParams object from the location search string
  const queryParams = new URLSearchParams(location.search);

  // For Popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Calculate today's date and the date two months ago
  const today = new Date();
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(today.getMonth() - 2);

  const [startDate, setStartDate] = useState(twoMonthsAgo);
  const [endDate, setEndDate] = useState(today);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (e, key) => {
    const updatedQueries = {
      ...queries,
      types: queries?.types?.includes(key)
        ? queries?.types?.filter((item) => item !== key) // Remove the key if it already exists
        : [...(queries?.types || []), key], // Add the key if it doesn't exist
    }

    addInParams('types', queries?.types?.includes(key)
    ? queries?.types?.filter((item) => item !== key)?.join(',') // Remove the key if it already exists
    : [...(queries?.types || []), key]?.join(','))
    setQueries(updatedQueries);
    localStorage.setItem(isNotDashboard ? 'queriesValue1' : 'queriesValue', JSON.stringify(updatedQueries))
  };

  const handleChangeDropdown = (event) => {
    localStorage.setItem(isNotDashboard ? 'selectedValue1' : 'selectedValue', event.target.value)
    setSelectedValue(event.target.value);
    addInParams('createdAfter', event.target.value)
};

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    const queryTypes = queryParams.get('types')?.split(',') || []
    const queryCreatedAfter = queryParams.get('createdAfter')
    if(queryTypes.length !== 0){
      const updatedQueries = {
        ...queries,
        types: queryTypes
      }

      setQueries(updatedQueries);
      localStorage.setItem(isNotDashboard ? 'queriesValue1' : 'queriesValue', JSON.stringify(updatedQueries))
    }

    if(queryCreatedAfter){
      localStorage.setItem(isNotDashboard ? 'selectedValue1' : 'selectedValue', queryCreatedAfter)
      setSelectedValue(queryCreatedAfter);
    }
  },[])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Tabs Part */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {dummyTabData?.map((item) => (
            <Box
              sx={{
                border: "1px solid #d9d9d9",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                paddingY: "4px",
                paddingX: "8px",
                mr: "5px",
              }}
            >
              <Checkbox sx={{ padding: "0px 2px" }} checked={queries?.types?.includes(item.key) || [].includes(item.key)} size="small" onChange={(e)=> handleChange(e, item.key)} />
              <Typography sx={{ fontSize: "11px", cursor: "pointer" }}>
                {item?.label} {`(${getCountByKey(item.key, data?.patientQueueCount[0])})`}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Filters Part */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {/* <DateRangeRoundedIcon
            sx={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleClick}
          /> */}
          <Typography sx={{ marginRight: "8px"}} color={'#B3B7BA'}>Sort by:</Typography>
          <FormControl sx={{ minWidth: 80 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={"all"}
              sx={{ height: 35, borderRadius: '8px' }}  // Set the height here
              value={selectedValue}
              onChange={handleChangeDropdown}
            >
              {timeFrameData?.map((values, index) => (
              <MenuItem key={values._id} value={values.value}>{values.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <PopoverQueueUI
        {...{
          id,
          open,
          anchorEl,
          handleClose,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
        }}
      />
    </>
  );
}
