import React, { useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@mui/material";
import { useIsMobile } from "../../contexts/isMobile";
import { SolidButton } from "../SolidButton";
import OutlinedButton from "../OutlinedButton";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "#F5F5F5",
  p: 4,
  borderRadius:"10px"
};

export const PermissionModal = ({ open, setOpen, permissionValues, setPermissionValues, handleSave, nodeData, isMoveBranch }) => {
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveButton = () => {
    if(permissionValues === null){
        toast.warning('Please select any option!');
        return
    }
    handleSave()
    setOpen(false);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign:"justify"}}>
            {isMoveBranch ? 
            `Could you please confirm whether you would like to connect the subsequent nodes to the "Yes/Attended" node or the "No/Not Attended" node?` 
            : 
            `Please confirm whether you'd like to retain the subsequent nodes following the 'Yes/Attended' or 'No/Not Attended' node. The alternative set of subsequent nodes will be removed.`}
          </Typography>
          
          <FormControl sx={{display:'flex'}}>

      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        sx={{ display: 'flex', flexDirection: 'column', py: '12px' }}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes / Attended" onChange={(e)=> setPermissionValues({id:nodeData?.data?.negativeChildNodeId || null , value:e.target.value, otherId: nodeData?.data?.positiveChildNodeId || null})} />
        <FormControlLabel value="no" control={<Radio />} label="No / Not Attended" onChange={(e)=> setPermissionValues({id:nodeData?.data?.positiveChildNodeId || null, value: e.target.value, otherId: nodeData?.data?.negativeChildNodeId || null})} />
      </RadioGroup>
    </FormControl>

          <Box id="modal-modal-description" md={12} xs={12} sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Box sx={{ margin: "5px" }}>
                <SolidButton label={"Save"} onClick={handleSaveButton} btnWidth={isMobile ? "100%" : "90px"} />
                
              </Box>
              <Box sx={{ margin: "5px" }}>
                <OutlinedButton label={"Cancel"} handleClick={handleClose} />
                
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
