import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ThreeDots } from "react-loader-spinner";
import ReferenceAccordion from "./ReferenceAccordion";

export function AssistantAiDisplayMessageBox({ data, isLoading, isAIAssistant }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {data &&
          data.map((item, index) => (
            <>
              {item?.content?.length > 0 &&
                (item.role ===  "system" ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: "80%",
                          borderRadius: "10px",
                          border: "1px ",
                          backgroundColor: "#F5F5F5",
                          color: "#000",
                          borderTopLeftRadius: "0px",
                          p: 2,
                          my: 1,
                          textAlign: "left",
                          display: "flex", // Add display flex
                          alignItems: "center", // Center items vertically
                        }}
                      >
                       
                        <Typography
                          sx={{
                            color: "#000",
                            mt: 1,
                            fontSize: "1.1rem",
                            whiteSpace:"pre-wrap"
                          }}
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                              a: (props) => (
                                <a
                                  href={props.href}
                                  style={{ color: "#000" }}
                                  target="blank"
                                >
                                  {props.children}
                                </a>
                              ),
                            }}
                          >
                            {item?.content?.replace(/\n\s*\n/g, "\n")?.replace(/^\`\`\`|`\`\`$/g, "")}
                          </ReactMarkdown>
                          <ReferenceAccordion reference={item?.reference} />
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        "flex-end",
                      my: 1,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "50%",
                        borderRadius: "10px",
                        border: "1px ",
                        backgroundColor: "rgba(0,151,236,0.12)",
                        color: "#000",
                        borderTopRightRadius: "0px",
                        p: 2,
                        mt: 1,
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          mt: 1,
                          fontSize: "1.1rem",
                          whiteSpace:"pre-wrap"
                        }}
                      >
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            a: (props) => {
                              return (
                                <a
                                  href={props.href}
                                  target="blank"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {props.children}
                                </a> // All other links
                              );
                            },
                          }}
                        >
                          {item?.content}
                        </ReactMarkdown>
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </>
          ))}
          {isLoading && (
                                <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    maxWidth: "50%",
                                    borderRadius: "10px",
                                    border: "1px ",
                                    backgroundColor: "#F5F5F5",
                                    color: "#000",
                                    borderTopLeftRadius: "0px",
                                    p: 2,
                                    my: 1,
                                    textAlign: "left",
                                    display: "flex", // Add display flex
                                    alignItems: "center", // Center items vertically
                                  }}
                                >
                                 
                                  <Typography
                                    sx={{
                                      color: "#000",
                                      mt: 1,
                                      fontSize: "1.1rem",
                                      whiteSpace:"pre-wrap"
                                    }}
                                  >
                                    <ThreeDots
              visible={true}
              height="25"
              width="25"
              color="#0049C6"
              radius="12"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
                                  </Typography>
                                </Box>
                              </Box>
          )}
      </Box>
    </>
  );
}